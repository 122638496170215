import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { plantList } from "./RailenceData";
import { ISystem,ISystemInverterInfo,ISystemMeterInfo } from "./interfaces.types";
import { toast } from "react-toastify";
import { AuthContext, AuthContextType } from "../../email-account-login/src/AuthContext/AuthContext.web";
import CreateRestApiMessage from "../../../components/src/Util/CreateRestApiMessage.web";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes?:any;
}

interface S {
  plantList: any[];
  currentPlant: any;
  settingModalOpen: boolean;
  addProtletModal: boolean;
  generateReportModal: boolean;
  getSystemsLoading:boolean,
  systemList:ISystem[];
  selectedSystem:ISystem|null;
  inverterInfo:ISystemInverterInfo;
  meterInfo:ISystemMeterInfo;
  getPlantInfoLoading:boolean;
  getMainGraphDataLoading:boolean;
  mainGraphData:any[];
  filterBy:string,
  filterDate:{
     day:number;
     month:number;
     year:number;
   }
}

interface SS {
  id: any;
}

export default class ClientDetailsPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetSystemListCallId: string = "";
  apiGetSystemMeterDataCallId: string = "";
  apiGetSystemInverterDataCallId: string = "";
  apiGetMainGraphDataCallId: string = "";
   static contextType:any = AuthContext;
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      plantList: plantList,
      currentPlant: {
        id: -1,
        plantName: "",
      },
      settingModalOpen: false,
      addProtletModal: false,
      generateReportModal: false,
      getSystemsLoading:false,
      systemList:[],
      selectedSystem:null,
      meterInfo:{
        commisioning:"",
        data_date_time:"",
        id:0,
        monitered_by:"",
        monitered_email:"",
        operated_by:"",
        operated_email:"",
        power:"0"
      },
      inverterInfo:{
        data_date_time:"",
        energy_total:0,
        id:0,
        production:0
      },
      getPlantInfoLoading:false,
      getMainGraphDataLoading:false,
      mainGraphData:[],
      filterBy:"year",
      filterDate:{
        day:new Date()?.getDate(),
        month:new Date()?.getMonth()+1,
        year:new Date()?.getFullYear()
      }
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
     const {isClient,currentUser}=this.context as AuthContextType;
    if(isClient()){
      const clientId=currentUser?.account?.client_id;
       this.getSystemForClient(clientId)
    }else{
      const clientId=this.props.navigation.getParam("clientId");
      this.getSystemForClient(clientId)
    }
  }

 async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleApiRequest(responseJson,apiRequestCallId);
    }
  }
  handleApiRequest=(responseJson:any,apiRequestCallId:string)=>{
    if (responseJson === undefined) {
        return;
      } else if (
        responseJson &&
        responseJson.errors &&
        responseJson.errors[0].token
      ) {
        this.props.navigation.navigate("EmailAccountLoginBlock");
        return;
      }
      if(this.apiGetSystemListCallId===apiRequestCallId){
         this.handleGetAllSystemListCallId(responseJson);
      }
      if(this.apiGetSystemInverterDataCallId===apiRequestCallId){
         this.handleGetSystemInverterInfoResponse(responseJson);
      }
      if(this.apiGetSystemMeterDataCallId===apiRequestCallId){
         this.handleGetSystemMeterInfoResponse(responseJson);
      }
      if(this.apiGetMainGraphDataCallId===apiRequestCallId){
         this.handleGetMainGraphDataResponse(responseJson);
      }
  }
  handleGetAllSystemListCallId=(responseJson:any)=>{
        if(responseJson?.data?.data?.length===0){
          toast.error("No System Found For This Client.")
        }
        const firstPlant=responseJson?.data?.data?.length>0?responseJson.data.data[0]:null;
        this.setState({
          systemList: responseJson?.data?.data?.length>0?responseJson.data.data:[],
          getSystemsLoading: false,
          selectedSystem:firstPlant
        },()=>{
           if(firstPlant?.id){
              this.getFtpInverterData(firstPlant.id);
              this.getFtpMeterData(firstPlant.id);
           }
        });
  }
  handleGetSystemInverterInfoResponse=(responseJson:any)=>{
    if(responseJson?.response?.data?.attributes?.id){
      this.setState({
        inverterInfo:responseJson?.response?.data?.attributes,
        getPlantInfoLoading:false
      })
    }else{
      this.setState({
         inverterInfo:{
          data_date_time:"",
          energy_total:0,
          id:0,
          production:0
        },
        getPlantInfoLoading:false
      })
    }
  }
  handleGetSystemMeterInfoResponse=(responseJson:any)=>{
      if(responseJson?.response?.data?.attributes?.id){
        this.setState({
          meterInfo:responseJson?.response?.data?.attributes,
          getPlantInfoLoading:false
        })
      }else{
        this.setState({
          meterInfo:{
             commisioning:"",
              data_date_time:"",
              id:0,
              monitered_by:"",
              monitered_email:"",
              operated_by:"",
              operated_email:"",
              power:"0"
          },
          getPlantInfoLoading:false
        })
      }
  } 
  handleGetMainGraphDataResponse=(responseJson:any)=>{
    if(responseJson?.response){
     if(Object.keys(responseJson?.response)?.length>0){
        const mainGraphData=Object.keys(responseJson?.response).map((keys:string)=>{
          return{
            name:keys,
            pv:Number(responseJson?.response[keys]?.power),
            uv:responseJson?.response[keys]?.avg_voltage
          }
        })
        this.setState({
          mainGraphData:mainGraphData,
          getMainGraphDataLoading:false
        })
     }else{
       this.setState({
         getMainGraphDataLoading:false,
         mainGraphData:[]
       })
     }
    }else{
      this.setState({
         getMainGraphDataLoading:false,
         mainGraphData:[]
       })
    }
  }


  getSystemForClient = (clientId:string) => {
    this.setState({
      getSystemsLoading: true
    });
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };
    const requestMessage=CreateRestApiMessage({
      header,
      apiUrl: `${configJSON.getSystemApiUrl}?id=${clientId}`,
      body: null,
      method: configJSON.getApiMethodType,
    })
    this.apiGetSystemListCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getFtpInverterData=(systemId:string)=>{
    this.setState({
      getPlantInfoLoading:true
    })
    const hitUrl = `${
      configJSON.getFtpInverterDataApiUrl
    }?system_id=${systemId}`;
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: userToken,
    };

    const requestMessage = CreateRestApiMessage({
      header,
      apiUrl: hitUrl,
      body: null,
      method: configJSON.getApiMethodType,
    });
    this.apiGetSystemInverterDataCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  } 
  getFtpMeterData=(systemId:string)=>{
    const hitUrl = `${
      configJSON.getFtpMeterDataApiUrl
    }?system_id=${systemId}`;
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: userToken,
    };

    const requestMessage = CreateRestApiMessage({
      header,
      apiUrl: hitUrl,
      body: null,
      method: configJSON.getApiMethodType,
    });
    this.apiGetSystemMeterDataCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  } 
  getMainGraphData=(endUrl:string)=>{
    this.setState({
      getMainGraphDataLoading:true,
    })
    const hitUrl = `${
      configJSON.getMainGraphDataURL
    }?${endUrl}`;
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: userToken,
    };

    const requestMessage = CreateRestApiMessage({
      header,
      apiUrl: hitUrl,
      body: null,
      method: configJSON.getApiMethodType,
    });
    this.apiGetMainGraphDataCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  changeCurrentPlant = (plant: ISystem) => {
    this.setState({
      selectedSystem: plant,
      getMainGraphDataLoading:false,
    },()=>{
       this.getFtpInverterData(plant.id);
       this.getFtpMeterData(plant.id);
      this.handleGraphFilterChange(this.state.filterBy,this.state.filterDate)
    });
  };
  changeSettingModalOpen = (open: boolean) => {
    this.setState({
      settingModalOpen: open,
      addProtletModal: false,
      generateReportModal: false,
    });
  };
  changeAddProtletModal = (open: boolean) => {
    this.setState({
      settingModalOpen: false,
      addProtletModal: open,
      generateReportModal: false,
    });
  };
  changeGenerateReportModal = (open: boolean) => {
    this.setState({
      settingModalOpen: false,
      addProtletModal: false,
      generateReportModal: open,
    });
  };
  navigateToClientDashboard=()=>{
    const {currentUser,isClient}=this.context as AuthContextType;
    let clientId=this.props.navigation.getParam("clientId");
    if(isClient()){
       clientId=currentUser?.account?.client_id;
    }

    const systemId=this.state.selectedSystem?.id;

    this.props.navigation.navigate("clientDashboard",{
      clientId:clientId,
      plantId:systemId
    })
  }
  handleGraphFilterChange=(filterBy:string,date:{
     day:number;
     month:number;
     year:number;
   })=>{
     this.setState({
      filterBy:filterBy,
      filterDate:date
     })
     const systemId=this.state.selectedSystem?.id;
     let endUrl="";
     if(filterBy==="month"){
      endUrl=`system_id=${systemId}&filter=3&by_month=${date?.month}&by_year=${date?.year}`;
     }else if(filterBy==="day"){
        endUrl=`system_id=${systemId}&filter=0&by_date=${date?.day}&by_month=${date?.month}&by_year=${date?.year}`;
     }else if(filterBy==="year"){
         endUrl=`system_id=${systemId}&filter=2&by_year=${date?.year}`;
     }
     if(!this.state.getMainGraphDataLoading){
      this.getMainGraphData(endUrl);
     }
     
  }
  navigateToWAClientDashboard=()=>{
    this.props.navigation.navigate("webClientDashboard")
  }

  goBack=()=>{
     this.props.navigation.goBack();
  }
}
