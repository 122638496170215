import actionCable from 'actioncable';
import { toast } from "react-toastify";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { AuthContext, AuthContextType } from '../../email-account-login/src/AuthContext/AuthContext.web';
import { UserContextType } from '../../email-account-login/src/AuthContext/type';
import { initialState } from './ChatContext.web';
import { Client, ConversationLastMessage, CurrentChatType, DirectMessage, GroupItemType, IAllUser, MessageItemType,UserType} from './Interfaces.web';
export const configJSON = require("./config");
let urlConfig = require("../../../framework/src/config");
export interface Props {
  navigation: any;
  id: string;
  theme: any;
}

export interface S {
  getUserLoading:boolean;
  addNewChatModal: boolean;
  getClientLoading:boolean;
  createNewChatLoading:boolean;
  getMessageLoading:boolean;
  getDirectConversationsLoading:boolean;
  clientList:Client[],
  userList:IAllUser[],
  groupMemberList:UserType[],
  directMessageList:DirectMessage[],
  selectedDirectChat:IAllUser[],
  currentChat:CurrentChatType,
  sendMessageLoading:boolean,
  loggedInUser:any,
  deleteRoomLoading:boolean,
  deleteConversationModal:boolean,
//group message states
  createGroupModal:boolean,
  newGroup:{
    groupName:string,
    groupDescription:string,
    image:null|any,
    selectedGroupUser:IAllUser[],
  },
  selectGroupUserModal:boolean,
  createGroupLoading:boolean,
  groupList:GroupItemType[],
  getGroupLoading:boolean,
  groupMembersModal:boolean,
  addGroupMembersModal:boolean,
  addGroupMemberLoading:boolean,
  getGroupMemberLoading:boolean,
  removeGroupMemberModal:boolean,
  removeGroupMemberLoading:boolean,
  deleteGroupModal:boolean,
  deleteChatRoomModal:boolean,
}

interface SS {
  id: any;
}

export default class ChatController extends BlockComponent<Props, S, SS> {
  apiResetGetAllClientCallId: string = "";
  apiCreateNewChatCallId: string = "";
  apiGetDirectConversationsCallId: string = "";
  apiSendNewMessageCallId: string = "";
  apiGetCurrentChatMessageCallId: string = "";
  apiGetMyGroupListApiCallId: string = "";
  apiDeleteChatRoomCallId: string = "";
  apiCreateNewGroupCallId: string = "";
  needSelectConversationRoomId:string="";
  apiGetGroupMemberCallId:string="";
  apiDeleteMessagesCallId:string="";
  apiAddMoreMembersApiCallId:string="";
  removeGroupMemmberApiCallId:string="";
  needToSelectGroupId:number=-1;
  apiGetAllUserCallId:string="";
  unreadCountWorker:any=undefined;
  static contextType:any = AuthContext;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = initialState;
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson === undefined) {
        this.setState({getGroupLoading:false})
        return;
      } else if (
        responseJson &&
        responseJson.errors &&
        responseJson.errors[0].token
      ) {
        this.props.navigation.navigate("EmailAccountLoginBlock");
        return;
      }

       //<=============response for get allclient list==========>
       if( this.apiResetGetAllClientCallId ===apiRequestCallId){
         //handle response for get all client list
         this.handleGetAllClientResponse(responseJson)
       }   
      //<=============response for get all user list==========>
       if( this.apiGetAllUserCallId ===apiRequestCallId){
         //handle response for get all client list
         //short by admin ascending order
         this.handleGetAllUserResponse(responseJson);
       }
        //<=============response for get my direct converstions==========>
       if( this.apiGetDirectConversationsCallId ===apiRequestCallId){
         //handle response for get all direct converstion
          this.handleGetDirectConversationResponse(responseJson);
       }  
        //<=============response for get my group lists==========>
       if( this.apiGetMyGroupListApiCallId ===apiRequestCallId){
         this.handleGetAllGroupResponse(responseJson);
       } 
        //<=============response for get group member lists==========>
       if( this.apiGetGroupMemberCallId ===apiRequestCallId){
          this.handleGetGroupMemberResponse(responseJson);
       } 
       //<=============response for remove group member ==========>
       if( this.removeGroupMemmberApiCallId ===apiRequestCallId){
          this.handleRemoveGroupMemberResponse(responseJson);
       } 
       //<=============response for create new chat==========>
       if( this.apiCreateNewChatCallId ===apiRequestCallId){
         //handle response for create new chat
         this.handleCreateNewChatResponse(responseJson);
       } 
       //<=============response for create new group chat==========>
       if( this.apiCreateNewGroupCallId ===apiRequestCallId){
         //handle response for create new chat
         this.handleCreateNewGroupResponse(responseJson);
       }  
       //<=============response for getChat Head Messages==========>
       if( this.apiGetCurrentChatMessageCallId ===apiRequestCallId){
         this.handleGetCurrentChatMessageResponse(responseJson);
       }  
        //<=============response for add more members to the group==========>
       if( this.apiAddMoreMembersApiCallId ===apiRequestCallId){
          this.handleAddGroupMembersResponse(responseJson);
       }  
       
       //<=============response for delete chat roooms==========>
       if( this.apiDeleteChatRoomCallId ===apiRequestCallId){
          this.handleDeleteChatRoomResponse(responseJson)
        }
        //<=============response for delete chat messages==========>
       if( this.apiDeleteMessagesCallId ===apiRequestCallId){
           this.handleDeleteMessages(responseJson);
        }
      }
  }

  handleGetAllClientResponse=(responseJson:any)=>{
    this.setState(prevState=>{
      return({
        getClientLoading:false,
        clientList:responseJson?.data
      })
    })
  }
  handleGetAllUserResponse=(responseJson:any)=>{
    const {loggedInUser}=this.state;
      let loggedInUserAccountId:string;

      if(loggedInUser?.account?.client_id){
        loggedInUserAccountId=loggedInUser?.account?.account?.account_id;
      }else{
        loggedInUserAccountId=loggedInUser?.account?.account_id; 
      }
    //check user list exists or not
    if(responseJson?.errors){
      toast(responseJson?.errors[0]?.message);
      this.setState({
        getUserLoading:false
      })
      return;
    }

      //remove logged in user from user lists
    const newUserList=responseJson?.filter((user:IAllUser)=>user?.id!=Number(loggedInUserAccountId));

    const shortedUserList=newUserList?.sort((a:IAllUser, b:IAllUser) => {
                              if (b?.role === "admin") return 1;
                              else return -1;
                            });
      this.setState(prevState=>{
        return({
          getUserLoading:false,
          userList:shortedUserList
        })
      })
  }
  handleGetDirectConversationResponse=(responseJson:any)=>{
    responseJson?.data?.forEach((dM:any)=>{
      this.subscribeToRoom(dM?.id)
    })
    this.setState({
      directMessageList:responseJson?.data,
      getDirectConversationsLoading:false
    },()=>{
     if(this.needSelectConversationRoomId){
         const chatHeadRoom=responseJson?.data?.filter((dm:DirectMessage)=>dm?.id===this.needSelectConversationRoomId)
         
           if(chatHeadRoom?.length>0){
               localStorage.removeItem("currentGroupChat");
               localStorage.removeItem("currentChat");
               this.changeCurrentChatHead(chatHeadRoom[0],"direct")
           }
       }else if(!localStorage.getItem("currentChat") && !localStorage.getItem("currentGroupChat") && !this.needSelectConversationRoomId && responseJson?.data?.length>0){
         this.changeCurrentChatHead(responseJson?.data[0],"direct")
      }else if(localStorage.getItem("currentChat")){
           const currentChat:CurrentChatType=JSON.parse(localStorage.getItem("currentChat") as string)
           this.changeCurrentChatHead(currentChat?.chat as DirectMessage,currentChat?.type)
         }
       this.needSelectConversationRoomId="";
       this.needToSelectGroupId=-1;
    })
    

  }
  handleGetAllGroupResponse=(responseJson:any)=>{

    this.setState({
      groupList:responseJson?.group_details,
      getGroupLoading:false
    },()=>{
      //subscribe to all Group rooms
     this.state.groupList?.forEach(groupItem=>this.subscribeToGroupRoom(groupItem?.chat_room?.id?.toString()))
       if(this.needToSelectGroupId !==-1){
         const chatHeadRoom=responseJson?.group_details?.filter((groupItem:GroupItemType)=>groupItem?.chat_id===this.needToSelectGroupId)
           if(chatHeadRoom?.length>0){
               this.setGroupToChatHead(chatHeadRoom[0],"group")
           }
       }else if(localStorage.getItem("currentGroupChat")){
         const currentChat:CurrentChatType=JSON.parse(localStorage.getItem("currentGroupChat") as string)
         this.setGroupToChatHead(currentChat?.group as GroupItemType,currentChat?.type)
       }
    })
  }
  handleGetGroupMemberResponse=(responseJson:any)=>{
    this.setState({
      groupMemberList:responseJson?.data,
      getGroupMemberLoading:false
    },()=>{
     
    })
  }
  handleRemoveGroupMemberResponse=(responseJson:any)=>{
    this.setState({
      removeGroupMemberLoading:false,
      removeGroupMemberModal:false
    },()=>{
       this.getGroupMembers();
       this.getMyGroupList();
    })
  }
  handleCreateNewChatResponse=(responseJson:any)=>{
    if(responseJson?.message){
      toast(responseJson?.message);
      this.setState({
        createNewChatLoading:false,
        addNewChatModal:false,
      })
      return;
     }
     if(responseJson?.data?.id){
        toast(`New Chat Created`)
         this.needSelectConversationRoomId=responseJson?.data?.id;
      
     }
     this.getMyDirectConversations()
     this.setState(prevState=>{
       return({
        createNewChatLoading:false,
        addNewChatModal:false,
        selectedDirectChat:[],
       })
     })
  }
  handleCreateNewGroupResponse=(responseJson:any)=>{
    if(responseJson?.data?.id){
      toast(`New Group Created`)
      this.needToSelectGroupId=responseJson?.data?.attributes?.chat_id;
    
   }
  this.getMyGroupList();
   this.setState(prevState=>{
     return({
      createGroupLoading:false,
      selectGroupUserModal:false,
      newGroup:{
        groupName:"",
        groupDescription:"",
        image:null,
        selectedGroupUser:[]
      }
     })
   })
  }
  handleGetCurrentChatMessageResponse=(responseJson:any)=>{
    this.setState(prevState=>{
      return{
        currentChat:{
          chat:prevState?.currentChat?.chat,
          type:prevState?.currentChat?.type,
          messages:responseJson?.data && responseJson?.data?.length>0?responseJson?.data:[],
          group:prevState?.currentChat?.group,
        },
        getMessageLoading:false
      }
    })
  }
  handleAddGroupMembersResponse=(responseJson:any)=>{
    this.getMyGroupList();
    this.setState({
      addGroupMemberLoading:false,
      addGroupMembersModal:false,
      newGroup:{
        groupName:"",
        groupDescription:"",
        selectedGroupUser:[],
        image:""
      }
    })           
  }

  handleDeleteChatRoomResponse=(responseJson:any)=>{

    const {directMessageList,currentChat,groupList}=this.state;
    if(currentChat?.type==="direct"){
        const filteredDirectMessageList=directMessageList?.filter(dM=>dM?.id!==currentChat?.chat?.id);
        localStorage.removeItem("currentChat");
        localStorage.removeItem("currentGroupChat");

          this.setState(prevState=>{
            return{
              currentChat:{
                type:"",
                chat:null,
                group:null,
                messages:[]
              },
              deleteRoomLoading:false,
              directMessageList:filteredDirectMessageList,
              deleteConversationModal:false,
              deleteGroupModal:false,
              deleteChatRoomModal:false,
            }
          })
      }else{
         const filteredGroupList=groupList?.filter(dM=>dM?.chat_id!==currentChat?.group?.chat_id);
          localStorage.removeItem("currentChat");
          localStorage.removeItem("currentGroupChat");

          this.setState(prevState=>{
            return{
              currentChat:{
                type:"",
                chat:null,
                group:null,
                messages:[]
              },
              deleteRoomLoading:false,
              groupList:filteredGroupList,
              deleteConversationModal:false,
              deleteGroupModal:false,
              deleteChatRoomModal:false,
            }
          })
      }
  }
  handleDeleteMessages=(responseJson:any)=>{
    const {currentChat}=this.state;
    if(currentChat?.type==="direct"){
          this.setState(prevState=>{
            return{
              currentChat:{
                type:prevState?.currentChat?.type,
                chat:prevState?.currentChat?.chat,
                group:prevState?.currentChat?.group,
                messages:[]
              },
              deleteRoomLoading:false,
              deleteConversationModal:false,
            }
          },()=>{
              if(currentChat?.chat && currentChat?.chat?.attributes?.chat_id){
                this.getCurrentChatMessages(currentChat?.chat?.attributes?.chat_id?.toString())
              }
          })
      }else{
          this.setState(prevState=>{
            return{
              currentChat:{
                type:prevState?.currentChat?.type,
                chat:prevState?.currentChat?.chat,
                group:prevState?.currentChat?.group,
                messages:[]
              },
              deleteRoomLoading:false,
              deleteConversationModal:false,
            }
          },()=>{
            if(currentChat?.group)
              this.getCurrentChatMessages(currentChat?.group?.chat_id?.toString())
          })
      }
  }

  async componentDidMount() {
    document.title="OrianaPower"
    this.setState({
      loggedInUser:this.context?.currentUser,
    })
   
      this.getMyDirectConversations();
      this.getMyGroupList(); 
       if(!localStorage.getItem("authToken")){
         this.props.navigation.navigate("EmailAccountLoginBlock");
       }
  }

  getAllClients = () => {
   
    this.setState({
      getClientLoading:true
    })
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiResetGetAllClientCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllClientListApiUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getAllUsers = () => {
    const {isSuperAdmin}=this.context as AuthContextType
    let apiUrl="";
    if(isSuperAdmin()){
      apiUrl=configJSON.getAllUserApiUrl
    }else{
      apiUrl=configJSON.getClientUserApiUrl;
    }

    this.setState({
      getUserLoading:true
    })
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetAllUserCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getCurrentUserRole=():string=>{
    const {loggedInUser}=this.state;
    if(loggedInUser?.account?.client_id){
      return loggedInUser?.account?.account?.role as string;
    }else if(loggedInUser?.account?.role && loggedInUser?.account?.role==="admin"){
      return loggedInUser?.account?.role as string;
    }else if(loggedInUser?.account?.account && loggedInUser?.account?.account?.role==="user"){
      return loggedInUser?.account?.account?.role as string;
    }else{
      return ""
    }
   
  }
  getCurrentUserId=():number=>{
    const {loggedInUser}=this.state;
    if(loggedInUser?.account?.client_id){
       return loggedInUser?.account?.account?.account_id as number;
    }else if(loggedInUser?.account?.account_id){
     return loggedInUser?.account?.account_id as number;
    }else if(loggedInUser?.account?.account && loggedInUser?.account?.account?.role==="user"){
      return loggedInUser?.account?.account?.account_id;
    }
    return 0;
    
  }
  getGroupMembers = ()=> {
    let groupChatId=-1;
    const {currentChat}=this.state;
    if(currentChat?.group){
      groupChatId=currentChat?.group?.chat_id;
    }
    this.setState({
      getGroupMemberLoading:true
    })
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetGroupMemberCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getGroupMemberApiUrl}?chat_id=${groupChatId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getMyDirectConversations = () => {
    
      this.setState({
        getDirectConversationsLoading:true
      })
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDirectConversationsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMyDirectMessageApiUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getMyGroupList = () => {
    
      this.setState({
        getGroupLoading:true
      })
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetMyGroupListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getGroupListApiURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }; 
  getCurrentChatMessages = (chat_id:string) => {
    
    this.setState({
        getMessageLoading:true
    })
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCurrentChatMessageCallId = requestMessage.messageId;
    const hitUrl=`${ configJSON.getCurrentChatMessageUrl}?chat_id=${chat_id}`
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      hitUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createNewDirectMessage=()=>{
    const {selectedDirectChat}=this.state;
    if(selectedDirectChat?.length>0){
      //user selected call create new user api
      this.setState({
        createNewChatLoading:true
      })
      const userToken = localStorage.getItem("authToken");
      const header = {
        token: userToken,
        "Content-Type":configJSON.exampleApiContentType
      };
     const data= {
        data: {
          persnol_chat: true,
          group_title: "",
          //"description": "something",
          name: "direct_chat"+[selectedDirectChat[0]?.id],
          description:"",
          user_ids: [selectedDirectChat[0]?.id]
        }
      }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiCreateNewChatCallId = requestMessage.messageId;
   

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiPostMethod
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createNewChatApiUrl
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);

    }else{
      toast("Please Select An User")
    }

  } 
  deleteChatRoom=()=>{
    const {currentChat}=this.state;
    if(currentChat?.chat || currentChat?.group){
      //user selected call create new user api
      this.setState({
        deleteRoomLoading:true
      })
      const userToken = localStorage.getItem("authToken");
      const header = {
        token: userToken,
      };
     
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiDeleteChatRoomCallId = requestMessage.messageId;
   

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.deleteApiMethodType
      );
      let deleteApi="";
      if(currentChat?.type==="direct" && currentChat?.chat){
        const chatId=currentChat?.chat?.attributes?.chat_id?.toString();
        deleteApi=`${configJSON.deleteRoomApiUrl}/${chatId}`
      }else if(currentChat?.group){
        const chatId=currentChat?.group?.chat_id?.toString();
        deleteApi=`${configJSON.deleteGroupApiURL}/?id=${chatId}`
      }

      

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        deleteApi
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);

    }else{
      toast("Please Select An Chat Room To Delete.")
      return;
    }

  }
  deleteMessages=()=>{
    const {currentChat}=this.state;
    if(currentChat?.chat || currentChat?.group){
      //user selected call create new user api
      this.setState({
        deleteRoomLoading:true
      })
      const userToken = localStorage.getItem("authToken");
      const header = {
        token: userToken,
      };
     
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiDeleteMessagesCallId = requestMessage.messageId;
   

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.deleteApiMethodType
      );
      let deleteApi="";
      if(currentChat?.type==="direct" && currentChat?.chat){
        const chatId=currentChat?.chat?.attributes?.chat_id?.toString();
        deleteApi=`${configJSON.deleteMessagesApiUrl}?id=${chatId}`
      }else if(currentChat?.group){
        const chatId=currentChat?.group?.chat_id?.toString();
        deleteApi=`${configJSON.deleteMessagesApiUrl}?id=${chatId}`
      }
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        deleteApi
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);

    }else{
      toast("Please Select An Chat Room To Delete.")
      return;
    }

  }
  addNewDirectChat=(user:IAllUser)=>{
      const {selectedDirectChat}=this.state;
     
      if(selectedDirectChat?.length>0){
        toast("Yo Can't Add More Than One User In Direct Chat")
      }else{
        //add client to direct chat state
        this.setState({
          selectedDirectChat:[user]
        })
        // this.setState(prevState=>{
        //   return {selectedDirectChat:[...prevState?.selectedDirectChat,user]}
        // })
      }
  }
  removeFromDirectChatItem=(user:IAllUser)=>{
    const {selectedDirectChat}=this.state;
    const filteredDirectChat=selectedDirectChat?.filter(cl=>cl?.id!==user?.id);
    this.setState({
      selectedDirectChat:filteredDirectChat
    })
  }
  changeAddNewChatModal = (open: boolean) => {
    this.setState({
      addNewChatModal: open,
    });
  };
  changeCurrentChatHead=(dMessage:DirectMessage,chatType:string)=>{
   
      document.title="OrianaPower"

      const {directMessageList,currentChat}=this.state;
      let newChatExists:boolean=false;
     
      //set unread count 0;
      const newDirectMessageList= directMessageList?.map((dM,index) => {
            if(dM?.id===dMessage?.id){
              newChatExists=true;
            }
             if (dM?.id===dMessage?.id || dM?.id===currentChat?.chat?.id) {
                const updatedDm={
                  ...dM,
                  attributes:{
                    ...dM?.attributes,
                    unread_count:0
                  }
                }
                return updatedDm;
             }
             return dM;
           });
       if(!newChatExists) return;
      const newCurrentChat:CurrentChatType={
              type:newChatExists?chatType:"",
              chat:newChatExists?dMessage:null,
              messages:[],
              group:null,
        }

      this.setState({
        currentChat:newCurrentChat,
        directMessageList:newDirectMessageList,
      })
      localStorage.setItem("currentChat",JSON.stringify(newCurrentChat))
      localStorage.removeItem("currentGroupChat");

      this.getCurrentChatMessages(dMessage?.attributes?.chat_id?.toString())


      const connectionBaseUrl = urlConfig.baseURL.replace('https://','');
      const protocol:string=window.location.protocol === 'https:' ? 'wss' : 'ws';
      
      const cable=actionCable.createConsumer(`${protocol}://${connectionBaseUrl}/cable?token=${localStorage.getItem("authToken")}`)//${connectionBaseUrl}/cable?token=${localStorage.getItem("authToken")})
      
      cable.subscriptions.create({
        channel: 'ChatChannel',
        room: dMessage?.id
      },
        {
            received: (updatedRoom) => {
                 this.handleReceiveDirectMessageBroadcust(updatedRoom);
            },
            connected:()=>{
              console.log("Connected..");
            },
            disconnected:()=>{
              console.log("Disconnected..")
            }
        })
  }
  setGroupToChatHead=(group:GroupItemType,chatType:string)=>{
      document.title=group?.group_name;
      const {groupList,currentChat}=this.state;
      let newChatExists:boolean=false;
      //set unread count 0;
      const newGroupList= groupList?.map((groupItem,index) => {
        //check new chat exists on group or not
          if(groupItem?.chat_id===group?.chat_id){
            newChatExists=true;
          }
          if (groupItem?.chat_id===group?.chat_id || groupItem?.chat_id===currentChat?.group?.chat_id) {
                const updatedGroup={
                  ...groupItem,
                 unread_count:0
                }
                return updatedGroup;
             }
           
             return groupItem;
           });
     
      if(!newChatExists) return;
      const newCurrentChat:CurrentChatType={
          type:newChatExists?chatType:"",
          chat:null,
          group:newChatExists?group:null,
          messages:[]
       }
      this.setState({
        currentChat:newCurrentChat,
        groupList:newGroupList,
      },()=>{
          this.getCurrentChatMessages(group?.chat_id?.toString())
          this.getGroupMembers();
      })
      localStorage.setItem("currentGroupChat",JSON.stringify(newCurrentChat))
      localStorage.removeItem("currentChat");
       

      const connectionBaseUrl = urlConfig.baseURL.replace('https://','');
      const protocol:string=window.location.protocol === 'https:' ? 'wss' : 'ws';
      
      const cable=actionCable.createConsumer(`${protocol}://${connectionBaseUrl}/cable?token=${localStorage.getItem("authToken")}`)//${connectionBaseUrl}/cable?token=${localStorage.getItem("authToken")})
      
      cable.subscriptions.create({
        channel: 'ChatChannel',
        room: group?.chat_room?.id
      },
        {
            received: (updatedRoom) => {
              const {currentChat}=this.state;
              const broadCustRoomId=updatedRoom?.room?.data?.id;
              if(broadCustRoomId===currentChat?.group?.chat_room?.id){
                    //modify direct message list also
                    
                  //end moify direct message list lets update direct message also from state

                    this.setState(prevState=>{
                      return{
                        currentChat:{
                          chat:prevState?.currentChat?.chat,
                          type:prevState?.currentChat?.type,
                          messages:updatedRoom?.messages?.data,
                          group:null,
                        },
                      }
                    })
             }

          
             
            },
            connected:()=>{
              console.log("Connected..");
            },
            disconnected:()=>{
              console.log("Disconnected..")
            }
        })
  }

  sendNewMessage=(message:string,attatchment?:any)=>{
     const {currentChat,loggedInUser}=this.state;
     if(currentChat?.type !=="" && (currentChat?.chat!=null || currentChat?.group!=null)){
       const currentDate=new Date();

      const role:string=this.getCurrentUserRole();
      const loginUserId=this.getCurrentUserId();
       const newMessage:MessageItemType={
          id:currentDate.getTime().toString(),
          type:"message",
          attributes:{
            room_id:currentChat?.chat ? parseInt(currentChat?.chat?.id):currentChat?.group?.chat_room?.id as number,
            content:message,
            created_at:currentDate.toString(),
            isSending:true,
            account_id:{
              id:loginUserId,
              role:role,
              admin:false,
              first_name:"",
              last_name:"",
              email:""
            },
            room_details:{
              id:currentChat?.chat ? parseInt(currentChat?.chat?.id):currentChat?.group?.chat_room?.id as number,
              sender_id:loggedInUser?.account?.account_id,
              receiver_id:-1
            },
            attachments:attatchment?[
              {
                id:new Date().getTime(),
                url:URL.createObjectURL(attatchment)
              }
            ]:null
          },
         
        }
      this.setState(prevState=>{
        return{
          currentChat:{
            chat:prevState?.currentChat?.chat,
            type:prevState?.currentChat?.type,
            group:prevState?.currentChat?.group,
            messages:[...prevState?.currentChat?.messages,{...newMessage}]
          },
          sendMessageLoading:true,
        }
      })




      const userToken = localStorage.getItem("authToken");
      const header = {
        token: userToken,
      };

      let chatId:string="";
      if(currentChat?.chat !=null){
        chatId=currentChat?.chat?.attributes?.chat_id?.toString();
      }else if(currentChat?.group!=null){
        chatId=currentChat?.group?.chat_id?.toString();
      }

      const formData = new FormData();
      formData.append("[data][content]",message);
      formData.append("[data][chat_id]",chatId);
      if(attatchment){
         formData.append("[data][attachments][]",attatchment);
     
      }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiSendNewMessageCallId = requestMessage.messageId;
   

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethodType
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.sendMessageApiUrl
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
     }else{
       toast.warn("Please Select A Chat For Send Message",{
         delay:500
       })
     }
  }
  subscribeToRoom=(roomId:string)=>{

    const connectionBaseUrl = urlConfig.baseURL.replace('https://','');
    const protocol:string=window.location.protocol === 'https:' ? 'wss' : 'ws';
    
    const cable=actionCable.createConsumer(`${protocol}://${connectionBaseUrl}/cable?token=${localStorage.getItem("authToken")}`)//${connectionBaseUrl}/cable?token=${localStorage.getItem("authToken")})
     cable.subscriptions.create({
      channel: 'ChatChannel',
      room: roomId
    },
      {
          received: (updatedRoom) => {
              this.handleReceiveDirectMessageBroadcust(updatedRoom);
          },
          connected:()=>{
            console.log("Connected..",cable);

          },
          disconnected:()=>{
            console.log({cable})
            console.log("Disconnected..")
          }
      })
  }
  subscribeToGroupRoom=(roomId:string)=>{

    const connectionBaseUrl = urlConfig.baseURL.replace('https://','');
    const protocol:string=window.location.protocol === 'https:' ? 'wss' : 'ws';
    
    const cable=actionCable.createConsumer(`${protocol}://${connectionBaseUrl}/cable?token=${localStorage.getItem("authToken")}`)//${connectionBaseUrl}/cable?token=${localStorage.getItem("authToken")})
     cable.subscriptions.create({
      channel: 'ChatChannel',
      room: roomId
    },
      {
          received: (updatedRoom) => {
             const {groupList,currentChat}=this.state;
             const broadCustRoomId=updatedRoom?.room?.data?.id;
            //modify direct message list also
            let broadCustGroupMessageItemPosition=-1;
            const newGroupList = groupList?.map((groupItem,index) => {
             if (groupItem?.chat_room?.id==broadCustRoomId) {
               broadCustGroupMessageItemPosition=index;
                const updatedDm:GroupItemType={
                  ...groupItem,
                  unread_count:currentChat?.group?.chat_room?.id==broadCustRoomId ?0: groupItem?.unread_count!==null? groupItem?.unread_count+1:0,
                }
                return updatedDm;
             }
           
             return groupItem;
           });
           //end moify direct message list lets update direct message also from state

           //swipe new message to first
           if(broadCustGroupMessageItemPosition!==-1){
              let newBroadCustedMessageItem = newGroupList[broadCustGroupMessageItemPosition];
              newGroupList.splice(broadCustGroupMessageItemPosition, 1);
              newGroupList.splice(0, 0, newBroadCustedMessageItem);
           }
            this.setState(prevState=>{
               return{
                 currentChat:{
                   chat:prevState?.currentChat?.chat,
                   type:prevState?.currentChat?.type,
                   group:prevState?.currentChat?.group,
                   messages:prevState?.currentChat?.group?.chat_room?.id==broadCustRoomId?updatedRoom?.messages?.data:prevState?.currentChat?.messages
                 },
                 groupList:newGroupList
               }
             })
          },
          connected:()=>{
            console.log("Connected..",cable);

          },
          disconnected:()=>{
            console.log({cable})
            console.log("Disconnected..")
          }
      })
  }

  handleReceiveDirectMessageBroadcust=(updatedRoom:any)=>{
      const {directMessageList,currentChat,loggedInUser}=this.state;
             const broadCustRoomId=updatedRoom?.room?.data?.id;
            //modify direct message list also
             const lastMessage:MessageItemType=updatedRoom?.messages?.data[updatedRoom?.messages?.data?.length-1]

             // check need to show title or not
            
             if(loggedInUser?.account?.client_id){
              //logged in user is a client check with client id
                if(lastMessage?.attributes?.account_id?.id!=loggedInUser?.account?.account?.account_id){
                  document.title=`New Message..`
                }
              }else{
                  //logged in user is super admin check with account id
                  if(lastMessage?.attributes?.account_id?.id!=loggedInUser?.account?.account_id){
                    document.title=`New Message..`
                  }
              }



             const newMessageItem:ConversationLastMessage={
               account_id:loggedInUser?.account?.account_id,
               content:lastMessage?.attributes?.content,
               created_at:lastMessage?.attributes?.created_at,
               id:parseInt(lastMessage?.id),
               room_id:broadCustRoomId,
               unread:currentChat?.chat?.id==broadCustRoomId?false:true,
             }
            let broadCustDirectMessageItemPosition=-1;
            const newDirectMessage = directMessageList?.map((dM,index) => {
             if (dM?.id===broadCustRoomId) {
               broadCustDirectMessageItemPosition=index;
                const updatedDm={
                  ...dM,
                  attributes:{
                    ...dM?.attributes,
                    messages:newMessageItem,
                    unread_count:currentChat?.chat?.id===dM?.id ?0: dM?.attributes?.unread_count && dM?.attributes?.unread_count>0 ? dM?.attributes?.unread_count+1:1
                  }
                }
                return updatedDm;
             }
           
             return dM;
           });
           //end moify direct message list lets update direct message also from state

           //swipe new message to first
           if(broadCustDirectMessageItemPosition!==-1){
              let newBroadCustedMessageItem = newDirectMessage[broadCustDirectMessageItemPosition];
              newDirectMessage.splice(broadCustDirectMessageItemPosition, 1);
              newDirectMessage.splice(0, 0, newBroadCustedMessageItem);
           }
             this.setState(prevState=>{
               return{
                 currentChat:{
                   chat:prevState?.currentChat?.chat,
                   type:prevState?.currentChat?.type,
                   group:prevState?.currentChat?.group,
                   messages:prevState?.currentChat?.chat?.id===broadCustRoomId?updatedRoom?.messages?.data:prevState?.currentChat?.messages
                 },
                 directMessageList:newDirectMessage
               }
             })
  }
  async componentWillUnmount() {
     document.title="OrianaPower"
  }

getBase64(file:any, cb:any) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        cb(reader.result)
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
}
changeDeleteConverstaionModal=(open:boolean)=>{
  const{currentChat}=this.state
  if(currentChat?.chat || currentChat?.group){
      this.setState({
        deleteConversationModal:open
      })
  }else{
    toast("Nothing To Delete.")
  }
}

//group conversation methods

changeGroupCreateModal=(open:boolean)=>{
   const {loggedInUser}=this.state;
    // if(loggedInUser?.account?.client_id && loggedInUser?.account?.account?.role==="client"){
    //   toast("You Can't Create Group As a Client For Now")
    //   return;
    // }

  this.setState({
    createGroupModal:open,
    newGroup:{
      groupName:"",
      groupDescription:"",
      image:null,
      selectedGroupUser:[]
    }
  })
}
changeGroupMembersModal=(open:boolean)=>{
  this.setState({
    groupMembersModal:open,
  })
}
changeAddGroupMembersModal=(open:boolean)=>{

   let loggedInUserAccountId:string;
   if(open){
    const {loggedInUser,groupMemberList}=this.state;
    if(loggedInUser?.account?.client_id){
        loggedInUserAccountId=loggedInUser?.account?.account?.account_id;
    }else{
        loggedInUserAccountId=loggedInUser?.account?.account_id; 
    }

  let deletePermission:boolean=false;
  groupMemberList?.forEach(member=>{
      if(member?.id==loggedInUserAccountId && member?.attributes?.is_group_admin){
          deletePermission=true;
      }
  })
    if(!deletePermission){
      toast("Only group admin can add or remove members")
      return;
    }
  }


  this.setState({
    addGroupMembersModal:open,
    groupMembersModal:false,
  })
}
changeDeleteChatRoomModal=(open:boolean)=>{
  this.setState({
    deleteChatRoomModal:open,
  })
}
changeGroupMemberRemoveModal=(open:boolean)=>{
   let loggedInUserAccountId:string;
   if(open){
    const {loggedInUser,groupMemberList}=this.state;
    if(loggedInUser?.account?.client_id){
        loggedInUserAccountId=loggedInUser?.account?.account?.account_id;
    }else{
        loggedInUserAccountId=loggedInUser?.account?.account_id; 
    }

  let deletePermission:boolean=false;
  groupMemberList?.forEach(member=>{
      if(member?.id==loggedInUserAccountId && member?.attributes?.is_group_admin){
          deletePermission=true;
      }
  })
    if(!deletePermission){
      toast("Only group admin can add or remove members")
      return;
    }
  }
  this.setState({
    removeGroupMemberModal:open,
  })
}
changeDeleteGroupModal=(open:boolean)=>{
   let loggedInUserAccountId:string;
   if(open){
    const {loggedInUser,groupMemberList}=this.state;
    if(loggedInUser?.account?.client_id){
        loggedInUserAccountId=loggedInUser?.account?.account?.account_id;
    }else{
        loggedInUserAccountId=loggedInUser?.account?.account_id; 
    }

  let deletePermission:boolean=false;
  groupMemberList?.forEach(member=>{
      if(member?.id==loggedInUserAccountId && member?.attributes?.is_group_admin){
          deletePermission=true;
      }
  })
    if(!deletePermission){
      toast("Only group admin can delete group.")
      return;
    }
  }
  this.setState({
    deleteGroupModal:open,
  })
}

changeGroupUserSelectModal=(name:string,description:string,image:any|null,open:boolean)=>{
  this.setState({
    selectGroupUserModal:open,
    createGroupModal:false,
    newGroup:{
      groupName:name,
      groupDescription:description,
      image:image,
      selectedGroupUser:[]
    }
  })
}

addNewGroupUser=(user:IAllUser)=>{
      const {newGroup}=this.state;
      if(newGroup?.selectedGroupUser?.length>0){
          const filteredUser=newGroup?.selectedGroupUser?.filter(groupUser=>groupUser?.id===user?.id);
          if(filteredUser?.length>0){
            return;
          }

          
        this.setState(prevState=>{
              return{
                newGroup:{
                 ...prevState?.newGroup,
                  selectedGroupUser:[...prevState?.newGroup?.selectedGroupUser,{...user}]
                }
              }
        })


      }else{
        this.setState(prevState=>{
          return{
            newGroup:{
              ...prevState?.newGroup,
              selectedGroupUser:[{...user}]
            }
          }
        })
      }
}

removeFromGroupUser=(user:IAllUser)=>{
    const {newGroup}=this.state;
    const filteredUserList=newGroup?.selectedGroupUser?.filter(cl=>cl?.id!==user?.id);
    this.setState(prevState=>{
      return{
        newGroup:{
          ...prevState?.newGroup,
          selectedGroupUser:filteredUserList
        }
      }
    })
}


createNewGroup=()=>{
    const {newGroup}=this.state;
    if(newGroup?.selectedGroupUser?.length>0){
      //user selected call create new user api

      if(newGroup?.selectedGroupUser?.length<2){
        toast("Minimum 2 Members Required to create new group")
        return;
      }

      this.setState({
        createGroupLoading:true
      })
      const userToken = localStorage.getItem("authToken");
      const header = {
        "Content-Type": configJSON.ApiContentType,
        token: userToken,
      };
      const userIds=newGroup?.selectedGroupUser?.map((user,index)=>{
        return user?.id.toString();
      }).join(",")
      const body=	{
                    data: {
                        persnol_chat: false,
                        description: newGroup?.groupDescription,
                        name:newGroup?.groupName,
                        user_ids: [""+userIds]
                    }
                  }

        console.log("Group",JSON.stringify(body))
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiCreateNewGroupCallId = requestMessage.messageId;
   

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethodType
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createNewChatApiUrl
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);

    }else{
      toast("Please Select Group Members");
    }

} 
addNewMembersToTheGroup=()=>{
    const {newGroup,currentChat}=this.state;
    if(newGroup?.selectedGroupUser?.length>0){
      //user selected call create new user api

      this.setState({
        addGroupMemberLoading:true
      })
      const userToken = localStorage.getItem("authToken");
      const header = {
        token: userToken,
      };

    
      let userIds="";
      newGroup?.selectedGroupUser?.forEach((user,index)=>{
        if(index===newGroup?.selectedGroupUser?.length-1){
           userIds+=user?.id
        }else{
          userIds+=user?.id+","
        }
        
      })

      const formData = new FormData();
      if(currentChat?.group){
          this.needToSelectGroupId=currentChat?.group?.chat_id;
       formData.append("[data][chat_id]",currentChat?.group?.chat_id?.toString());
      }
      formData.append("[data][member_id]", userIds);
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiAddMoreMembersApiCallId = requestMessage.messageId;
   

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.putApiMethodType
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.addGroupMembersApiURl
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);

    }else{
      toast("Please Select Some Members")
      return;
    }

} 


removeGroupMember=(member:UserType)=>{
    const {currentChat}=this.state;
     this.setState({
      removeGroupMemberLoading:true
       })
      const userToken = localStorage.getItem("authToken");
      const header = {
        token: userToken,
      };

      const formData = new FormData();
      if(currentChat?.group){
          this.needToSelectGroupId=currentChat?.group?.chat_id;
       formData.append("[data][chat_id]",currentChat?.group?.chat_id?.toString());
      }
      formData.append("[data][member_id]",member?.id.toString());
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.removeGroupMemmberApiCallId = requestMessage.messageId;
   

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.putApiMethodType
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.removeGroupMembersApiUrl
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);

} 


}
