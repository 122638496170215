import React from "react";
import UserController, { Props } from "./UserController.web";
import { Box, Tab, Tabs, Typography } from "@material-ui/core";
import { Theme, withStyles } from "@material-ui/core/styles";
import BarChartOutlinedIcon from "@material-ui/icons/BarChartOutlined";
import AddedClients from "../../../../components/src/UserComponents/AddedClients.web";
import KeyboardArrowRightOutlinedIcon from "@material-ui/icons/KeyboardArrowRightOutlined";
import "../../assets/user.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import UserLists from "../../../../components/src/UserComponents/UserLists.web";
import UserRoles from "../../../../components/src/UserComponents/UserRoles.web";
import { UserContext } from "./UserContext.web";
import CreateNewUser from "../../../../components/src/UserComponents/CreateNewUser.web";
interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const styles = (theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
});

class User extends UserController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { value, userModal, notifiedUser } = this.state;
    const { theme } = this.props;
    return (
      <UserContext.Provider
        value={{
          ...this.state,
          ...this,
        }}
      >
        <div className="userContainer">
          <div className="userHeader">
            <div>
              <BarChartOutlinedIcon style={{ color: "#FDCE32" }} />
            </div>
            <div>
              <KeyboardArrowRightOutlinedIcon style={{ color: "#8F92A1" }} />
            </div>
            <div onClick={() => toast.warn("HELLO")}>
              <h3 style={{ fontSize: "14px" }}>User</h3>
            </div>
            <div>
              <KeyboardArrowRightOutlinedIcon style={{ color: "#8F92A1" }} />
            </div>
            <div>
              <h3 style={{ fontSize: "14px" }}>User Lists</h3>
            </div>
          </div>

          <div className="userBody">
            <Tabs
              value={value}
              indicatorColor="secondary"
              textColor="primary"
              onChange={this.handleChange}
              aria-label="disabled tabs example"
            >
              <Tab
                label="User Lists"
                style={{
                  textTransform: "capitalize",
                  color: "black",
                  fontSize: "14px",
                  fontWeight: 400,
                }}
              />
              <Tab
                label="Added Clients"
                style={{
                  textTransform: "capitalize",
                  color: "black",
                  fontSize: "14px",
                  fontWeight: 400,
                }}
              />
              <Tab
                label="Roles"
                style={{
                  textTransform: "capitalize",
                  color: "black",
                  fontSize: "14px",
                  fontWeight: 400,
                }}
              />
            </Tabs>
            <TabPanel value={value} index={0} dir={theme.direction}>
              <UserLists userErrors={this.state.userCreateError} />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <AddedClients />
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
              <UserRoles
                changeGroupDesc={this.changeGroupDescription}
                changeGroupName={this.changeGroupName}
                changeRootPrivileges={this.changeRootPrivileges}
                chngeSubPrivileges={this.chngeSubPrivileges}
                newGroup={this.state.newGroup}
                changeEditors={this.changeNewGroupEditors}
                groupCreateErrors={this.state.createUserGroupError}
                checkGroupCreateInput={this.checkUserGroupInput}
              />
            </TabPanel>
          </div>
          {userModal && (
            <CreateNewUser
              edit={false}
              open={this.state.userModal}
              setOpen={this.changeUserModal}
              actionLoading={this.state.createUserLoading}
              clientList={this.state.allClientList}
              userGroupList={this.state.userGroupList}
              getUserGroupsLoading={this.state.getUserGroupsLoading}
              getClientsLoading={this.state.getClientsLoading}
              actionHandler={this.createNewUser}
              userErrors={this.state.userCreateError}
            />
          )}
          <ToastContainer theme="dark" />
        </div>
      </UserContext.Provider>
    );
  }
}

export default withStyles(styles, { withTheme: true })(User);
