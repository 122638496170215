import React from "react";
import { Grid, Paper, Typography, Button, Card } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import AddIcon from "@material-ui/icons/Add";
import BarChartOutlinedIcon from "@material-ui/icons/BarChartOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import "./relience.css";
import FlashOnOutlinedIcon from "@material-ui/icons/FlashOnOutlined";

function getModalStyle() {
  const top = 51;
  const left = 53;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    borderRadius: "20px",
    border: "none",
    outline: "none",
    padding: "20px",
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "60vw",
      height: "auto",
    },
  })
);

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  navigate: any;
}

export default function GenerateReportDialog({
  open,
  setOpen,
  navigate,
}: Props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Paper style={modalStyle} className={classes.paper}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                Reports
              </Typography>
            </div>
            <div>
              <Button
                onClick={() => navigate("selectClientToCompare")}
                variant="contained"
                style={{
                  background: "#FFCE21",
                  marginRight: "10x",
                  borderRadius: "15px",
                  textTransform: "capitalize",
                  boxShadow: "none",
                }}
                startIcon={<ListAltOutlinedIcon />}
              >
                Compare
              </Button>
            </div>
          </div>
          <br />
          <hr style={{ borderTop: "1px solid #f9f9fa" }} />
          <br />
          <Grid container spacing={4} style={{ marginBottom: "15px" }}>
            <Grid item xs={12} sm={12} md={6} xl={6}>
              <Card
                style={{
                  borderRadius: "20px",
                  background: "#FFFFFF",
                  padding: "20px",
                  height: "200px",
                  border: "1px solid #CFCFCF",
                }}
                elevation={0}
              >
                <div className="evalutionContent">
                  <div className="evalutionLeft">
                    <div className="evalutionLogo">
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          height: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <FlashOnOutlinedIcon
                          style={{
                            color: "white",
                            fontSize: "25px",
                            paddingTop: "5px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="evalutionRight">
                    <div>
                      <div>
                        <h2 style={{ fontSize: "18px" }}>Portfolio Reports</h2>
                      </div>
                      <div style={{ marginTop: "10px" }}>
                        <p style={{ color: "#C9C3D3", fontSize: "13px" }}>
                          Create reports for the entire portfolio with just a
                          few clicks by using the intuitive drag & drop editor.
                        </p>
                      </div>
                    </div>

                    <div>
                      <Button
                        style={{
                          background: "#FFCE21",
                          borderRadius: "15px",
                          marginTop: "12px",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                          height: "40px",
                          textTransform: "capitalize",
                          fontWeight: "bold",
                        }}
                        startIcon={<BarChartOutlinedIcon />}
                      >
                        Portfolio reports
                      </Button>
                    </div>
                  </div>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} xl={6}>
              <Card
                style={{
                  borderRadius: "20px",
                  background: "#FFFFFF",
                  padding: "20px",
                  height: "200px",
                  border: "1px solid #CFCFCF",
                }}
                elevation={0}
              >
                <div className="evalutionContent">
                  <div className="evalutionLeft">
                    <div className="evalutionLogo">
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          height: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <FlashOnOutlinedIcon
                          style={{
                            color: "white",
                            fontSize: "25px",
                            paddingTop: "5px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="evalutionRight">
                    <div>
                      <div>
                        <h2 style={{ fontSize: "18px" }}>CSV Exports</h2>
                      </div>
                      <div style={{ marginTop: "10px" }}>
                        <p style={{ color: "#C9C3D3", fontSize: "13px" }}>
                          With the CSV export, you can transfer the data of your
                          systems and your portfolio in a high resolution and for
                          longer periods of time.
                        </p>
                      </div>
                    </div>

                    <div>
                      <Button
                        style={{
                          background: "#FFCE21",
                          borderRadius: "15px",
                          marginTop: "12px",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                          height: "40px",
                          textTransform: "capitalize",
                          fontWeight: "bold",
                        }}
                        startIcon={<DescriptionOutlinedIcon />}
                      >
                        CSV exports
                      </Button>
                    </div>
                  </div>
                </div>
              </Card>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
    </div>
  );
}
