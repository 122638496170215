import React from "react";
import BarChartOutlinedIcon from "@material-ui/icons/BarChartOutlined";
import AddedClients from "../../../../components/src/UserComponents/AddedClients.web";
import KeyboardArrowRightOutlinedIcon from "@material-ui/icons/KeyboardArrowRightOutlined";
import AddNewSystemController, {
  Props,
  configJSON,
} from "./AddNewSystemController.web";
import AddSystem from "../../../../components/src/NetworkComponents/AddSystem.web";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { countryList } from "../assets";
import { Button, Typography } from "@material-ui/core";
import "../../assets/user.css";
import AddNewClientModal from "../../../../components/src/NetworkComponents/AddNewClientModal.web";
// Icons

export default class AddNewSystem extends AddNewSystemController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { addSystemLoading, isSuccess } = this.state;
    return (
      <>
        <div className="addNewSystemContainer">
          <div className="userHeader">
            <div>
              <BarChartOutlinedIcon style={{ color: "#FDCE32" }} />
            </div>
            <div>
              <KeyboardArrowRightOutlinedIcon style={{ color: "#8F92A1" }} />
            </div>
            <div>
              <h3 style={{ fontSize: "14px" }}>Network</h3>
            </div>
          </div>
          <div className="userBody">
            <Typography
              component="h2"
              style={{
                margin: "1px 5px",
                fontSize: "22px",
                fontWeight: "bold",
                flex: 1,
              }}
            >
              Adding a New System
            </Typography>

            <AddSystem
              loading={addSystemLoading}
              createNewSystem={this.createNewSystem}
              goBack={this.goBack}
              countryList={countryList}
              isSuccess={isSuccess}
            />
          </div>
        </div>
        <ToastContainer theme="dark" />
      </>
    );
  }
}
