import { Checkbox } from "@material-ui/core";
import React from 'react'
interface Props{
    value: string;
}

function CheckItem({ value }: Props) {
    
    const [check,setCheck]=React.useState<boolean>(false)


    return (
      <div className="checkItem">
        <div>
            <Checkbox
            style={{padding:"0px",margin:"0px 0px 0px 20px"}}
            onChange={e=>setCheck(e.target.checked)}
            color="primary"
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        </div>
        <div style={{ marginLeft: "10px" }}>
            <p style={{fontSize:"12px",margin:"0px",padding:"0px"}} className={`${check?"checked":"checkbox"}`}>{ value}</p>
        </div>
      </div>
    );
}


export default CheckItem;