import * as React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
interface Props {
  count: number;
  column: number;
}
const TableLoader = ({ count, column }: Props) => {
  return (
    <>
      {[...new Array(count)].map((item, index) => (
        <tr key={index}>
          {[...new Array(column)].map((itm, ind) => (
            <td key={ind} style={{ paddingRight: "10px" }}>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" width="60%" />
            </td>
          ))}
        </tr>
      ))}
    </>
  );
};

export default TableLoader;
