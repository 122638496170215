import { IconButton, Paper } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import GetAppIcon from "@material-ui/icons/GetApp";
import RefreshIcon from "@material-ui/icons/RefreshRounded";
import { saveAs } from "file-saver";
import React from "react";
import {
  imgErrorImage,
  imgImageLoader,
} from "../../../blocks/Chat9/src/assets";

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function ViewImageModal({ src, open, setOpen }: Props) {
  const [imgSrc, setSrc] = React.useState(imgImageLoader || src);

  const onLoad = React.useCallback(() => {
    if (src) {
      setSrc(src);
    }
  }, [src]);

  const onError = React.useCallback(() => {
    setSrc(src || imgErrorImage);
  }, [imgErrorImage, imgImageLoader]);

  React.useEffect(() => {
    const img = new Image();
    img.src = src as string;
    img.addEventListener("load", onLoad);
    img.addEventListener("error", onError);
    return () => {
      img.removeEventListener("load", onLoad);
      img.removeEventListener("error", onError);
    };
  }, [src, onLoad]);

  const downloadFile = async (url: string) => {
    saveAs(url);
  };
 
  return (
    <div>
      <Modal
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Paper style={{ width: "60%", padding: "20px", borderRadius: "20px" }}>
          <div style={{ padding: "10px" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <IconButton onClick={(e) => downloadFile(src as string)}>
                  <GetAppIcon />
                </IconButton>
                <IconButton onClick={(e) => setSrc(src as string)}>
                  <RefreshIcon />
                </IconButton>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <IconButton onClick={(e) => setOpen(false)}>
                  <CloseOutlinedIcon />
                </IconButton>
              </div>
            </div>

            <div
              style={{
                marginTop: "10px",
                marginBottom: "15px",
                height: "2px",
                width: "100%",
                background: "#f9f9fa",
              }}
            />
            <img
              style={{ width: "100%", height: "400px", objectFit: "cover" }}
              alt=""
              src={imgSrc}
            />
          </div>
        </Paper>
      </Modal>
    </div>
  );
}
