import React from "react";
import {
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  BarChart,
  ResponsiveContainer,
} from "recharts";
import CustomTooltip, {
  COLOR_HIGHT_CONTRAST,
  COLOR_LOW_CONTRAST,
} from "../Util/CustomTooltip";
import { barChartdata } from "./data";

interface Props {
  data?: any[];
}

const CustomBarChart = ({ data = barChartdata }: Props) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={data}
        margin={{
          top: 20,
          right: 30,
          bottom: 5,
        }}
      >
        <XAxis
          dataKey="name"
          tickLine={false}
          axisLine={false}
          style={{ marginTop: "18px" }}
        />
        <YAxis tickLine={false} axisLine={false} dataKey="pv" />
        <Tooltip content={<CustomTooltip />} />
        {/* <Legend /> */}
        <Bar
          dataKey="pv"
          barSize={10}
          fill={COLOR_HIGHT_CONTRAST}
          radius={15}
        />
        <Bar dataKey="uv" barSize={10} fill={COLOR_LOW_CONTRAST} radius={15} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default CustomBarChart;
