import React from 'react';
import {Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  TextField
} from '@material-ui/core';

interface Props{
  open:boolean,
  setOpen:(open:boolean)=>void,
  filterByDate:(filterBy:string,startDate?:string,endDate?:string)=>void;
}

const SelectSearchDateModal=({
  open,
  setOpen,
  filterByDate
}:Props)=>{
   const [stDate,setStDate]=React.useState("")
   const[enDate,setEnDate]=React.useState("")
   const [error,setError]=React.useState({
     stError:"",
     endError:""
   })

   const handleFilter=()=>{
     if(stDate && enDate){
         if(stDate>enDate){
           setError({
             ...error,
             stError:"Start Date Bigger Then End Date"
           })
         }else if(stDate===enDate){
          setError({
            ...error,
            stError:"same date cannot be filtered"
          })
         }else{
            setError({   
              stError:"",
              endError:""
          })
          setOpen(false)
          filterByDate("date",stDate,enDate)
         
         }
       
     }else{
        setError({
          stError:stDate?"":"Please Select Start Date",
          endError:enDate?"":"Please Select End Date"
        })
     }
   }

    return(
        <Dialog
        fullWidth
        maxWidth="xs"
        PaperProps={{style: {borderRadius: 16}}}
        open={open}
        onClose={() => setOpen(false)}
        >
          <DialogTitle id="max-width-dialog-title" style={{paddingBottom: 0}}>
            <strong>Search By Date</strong>
          </DialogTitle>
          <DialogContent>
              <h6>Select Start Date</h6>
               <TextField 
                    value={stDate}
                    onChange={(e)=>setStDate(e.target.value)}
                    fullWidth id="standard-basic"
                    type='date'
                    helperText={<span style={{color:"red"}}>{error?.stError}</span>}
                    />
                    <br/>
                    <br/>
               <h6>Select End Date</h6>
               <TextField 
                    value={enDate}
                    onChange={(e)=>setEnDate(e.target.value)}
                    fullWidth id="standard-basic"
                    type='date'
                    helperText={<span style={{color:"red"}}>{error?.endError}</span>}
                    />

          </DialogContent>

          <DialogActions style={{margin: "15px"}}>
            <Button
                    variant="contained"
                    disableElevation
                    style={{borderRadius: "9px", height: "40px", width: "100%", fontWeight: 600, textTransform: "capitalize"}}
                    onClick={() => setOpen(false)}
                  >
              Cancel
            </Button>
                <Button
                    onClick={e=>handleFilter()}
                    variant="contained"
                    disableElevation
                    style={{borderRadius: "9px", height: "40px", width: "100%", background: "#FDCE32", fontWeight: 600, textTransform: "capitalize"}}
                   >
                  Filter
                </Button>
            </DialogActions>
        </Dialog>

    )
}

export default SelectSearchDateModal;