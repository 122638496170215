import { createStyles, IconButton, makeStyles, Theme } from "@material-ui/core";
import { EditOutlined, SettingsOutlined } from "@material-ui/icons";
import React from "react";
import { useClientSettingContext } from "../../../blocks/ActivityLog/src/ClientSettingContext.web";
import TableLoader from "../Util/TableLoader.web";
import PaginationController from '../Util/PaginationController'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table__container: {
      boxSizing: "border-box",
      marginTop: "10px",
      overflowY: "auto",
      width: "100%",
      "& table": {
        width: "100%",
        minWidth: "800px",
        borderSpacing: "0px 15px",
        "& thead": {
          background: "#f9f9fa",
          borderRadius: "10px",
          height: "40px",
        },
        "& tr": {
          textAlign: "left",
          "& td": {
            textTransform: "capitalize",
            padding: "5px 0px",
            borderBottom: "2px solid #f9f9fa",
          },
          "&:hover": {
            backgroundColor: "var(--main-color)",
            color: "var(--txt-white)",
            cursor: "pointer",
          },
        },
      },
    },
    itemText: {
      fontSize: "16px",
      fontWeight: 500,
    },
    pagination_container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    pagination__text__container: {
      width: "120px",
      background: "#f9f9fa",
      borderRadius: "15px",
      padding: "10px",
      textAlign: "center",
    },
    table__item__data__containier: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
    row: {
      display: "flex",
      alignItems: "center",
      gap: "15px",
    },
    actions__buttons: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      marginTop: "-40px",
      gap: "20px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "20px",
      },
    },
    buttonDarkYellow: {
      textTransform: "capitalize",
      background: "#FFCE21",
      boxShadow: "none",
      borderRadius: "15px",
      padding: "10px 15px 10px 15px",
      color: "black",
      fontWeight: "normal",
      minWidth: "120px",
      "&:hover": {
        background: "#FDCE32",
      },
    },
    buttonAsh: {
      textTransform: "capitalize",
      background: "#F9F9FA",
      boxShadow: "none",
      borderRadius: "15px",
      padding: "10px 15px 10px 15px",
      color: "black",
      fontWeight: "normal",
      minWidth: "100px",
      "&:hover": {
        background: "#F9F9FA",
      },
    },
    optionMenuItem: {
      width: "100%",
      display: "flex",
      // justifyContent: "space-around",
      gap: "20px",
    },
  })
);
const OverviewTable = () => {
  const styles = useStyles();

  const {
    dataLogger,
    fetchNextDataLogger,
    fetchPrevDataLogger,
    editDataLogger
  }=useClientSettingContext();


  const {
    dataLogggerList,
    getLoading,
    perPage,
    currentPage,
  }=dataLogger || {}


  return (
    <div>
      <div className={styles.table__container}>
        <table>
          <thead>
            <tr>
              <th>
                <h5 style={{ fontWeight: "bold", paddingLeft: "20px" }}>
                  DESCRIPTION
                </h5>
              </th>
              <th>
                <h5 style={{ fontWeight: "bold" }}>SOFTWARE SERIAL NUMBER</h5>
              </th>
              <th>
                <h5 style={{ fontWeight: "bold" }}>HARDWARE SERIAL NUMBER</h5>
              </th>
              <th>
                <h5 style={{ fontWeight: "bold" }}>MEASURING INTERVAL</h5>
              </th>
              <th>
                <h5 style={{ fontWeight: "bold" }}>ACTIONS</h5>
              </th>
            </tr>
          </thead>
          <tbody>
            {
              getLoading ? <TableLoader count={perPage} column={5}/>
              :dataLogggerList.response.data?.slice((currentPage-1) * perPage, (currentPage-1) * perPage + perPage)
                .map((item, index) => (
                  <tr key={item.id}>
                    <td>
                      <div style={{ paddingLeft: "20px" }}>
                        <h2 className={styles.itemText}>{item?.attributes?.data_logger_name}</h2>
                      </div>
                    </td>
                    <td>
                      <div>
                        <h2 className={styles.itemText}>__</h2>
                      </div>
                    </td>{" "}
                    <td>
                      <div>
                        <h2 className={styles.itemText}>{item?.attributes?.hardware_s_no}</h2>
                      </div>
                    </td>
                    <td>
                      <div>
                        <h2 className={styles.itemText}>5 minutes</h2>
                      </div>
                    </td>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "15px",
                        }}
                      >
                        <IconButton onClick={()=>editDataLogger(item.id)}>
                          <EditOutlined />
                        </IconButton>
                        <IconButton>
                          <SettingsOutlined />
                        </IconButton>
                      </div>
                    </td>
                  </tr>
            ))}
          </tbody>
        </table>
      </div>
      <PaginationController  
          currentPage={currentPage}
          totalPage={Math.ceil(dataLogggerList.length / perPage)}
          prev={fetchPrevDataLogger}
          next={fetchNextDataLogger}
      />
    </div>
  );
};

export default OverviewTable;
