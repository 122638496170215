import React from "react";
import ApprovalController, {
  Props,
  configJSON,
} from "./AuthProviderController.web";
import { AuthContext } from "./AuthContext.web";

class AuthProvider extends ApprovalController {
  constructor(props: Props) {
    super(props);
  }

  render() {
   
    
    return (
      <AuthContext.Provider
        value={{
          currentUser: this?.state?.currentUser,
          ...this
        }}>
        {!this.state.loading && this.props?.children }
      </AuthContext.Provider>
    );
  }
}

export default AuthProvider;
