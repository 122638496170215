import {
  Button,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
  makeStyles,
  IconButton,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import React from "react";
import { date } from "yup";
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined';
import {useApprovalContext} from '../../../blocks/AdminConsole3/src/Approval/ApprovalContext.web'
import ApproveDeclineModal from "./ApproveDeclineModal";
const useStyles=makeStyles({
   inputPlaceholder:{
     color:"#8F92A1",
     fontWeight:"bolder"
   },
   headingText:{
     fontWeight:"bolder",
     fontSize:"22px"

   },
   dropZone:{
     width:"100%",
     flexDirection:"column",
     height:"90px",
     boxSizing:"border-box",
     padding:"10px",
     border:"2px dashed #000",
     borderRadius:"10px",
     background:"#f9f9fa",
     display:"flex",
     justifyContent:"center",
     alignItems:"center",
     cursor:"pointer",
     marginBottom:"15px",
     marginTop:"10px"
   }
})

export default function SystemInformationModal() {

  const {systemInfoModal:open,changeSystemInfoModal:setOpen,selectedApproval,changeApproveModal}=useApprovalContext();

  const styles=useStyles();
  const [designation,setDesignation]=React.useState<string>(selectedApproval?.systems?.designation);
  const [primaryAddress,setPrimaryAddress]=React.useState<string>(selectedApproval?.systems?.street+selectedApproval?.systems?.postal_code);
  const [country,setCountry]=React.useState<string>(selectedApproval?.systems?.country);
  const [timezone,setTimeZone]=React.useState<string>(selectedApproval?.systems?.timezone);
  const [longitute,setLongitute]=React.useState<number>(selectedApproval?.systems?.longitude);
  const [latitude,setLatitude]=React.useState<number>(selectedApproval?.systems?.latitude);
  const [altitude,setAltitude]=React.useState<string>(selectedApproval?.systems?.altitude);
  const [systemType,setSystemType]=React.useState<string>(selectedApproval?.systems?.system_type);
  const [systemProfile,setSystemProfile]=React.useState<string>(selectedApproval?.systems?.system_profile);
  const [co2Factor,setCo2Factor]=React.useState<string>(selectedApproval?.systems?.co2_emission_factor);


  return (
    <div>
      <Modal
        id="systemInformation"
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        open={open}
        onClose={() => setOpen(false,{})}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Paper
          style={{ width: "680px", padding: "20px", borderRadius: "10px" }}
        >
          <div style={{ padding: "10px",height:"80vh",overflow:"auto" }}>
            <Typography
              className={styles.headingText}
              variant="h5"
            >
              System Information
            </Typography>

              <TextField 
                style={{marginTop:"20px",fontWeight:"bolder"}}
                value={designation}
                onChange={(e)=>setDesignation(e.target.value)}
                fullWidth id="standard-basic"
                label={<span className={styles.inputPlaceholder}>Designation of the system*</span>}
                 inputProps={{readOnly:true}}
                />
              <TextField 
                     style={{ marginTop: "15px" }}
                      value={primaryAddress}
                      onChange={(e)=>setPrimaryAddress(e.target.value)}
                      fullWidth id="standard-basic"
                      label={<span className={styles.inputPlaceholder}>Primary Address</span>}
                       inputProps={{readOnly:true}}
                      />
            <Grid container spacing={2} style={{ marginTop: "15px" }}>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                  <TextField 
                      value={country}
                      onChange={(e)=>setCountry(e.target.value)}
                      fullWidth id="standard-basic"
                      label={<span className={styles.inputPlaceholder}>Country</span>}
                       inputProps={{readOnly:true}}
                      />
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <TextField 
                        value={timezone}
                        onChange={(e)=>setTimeZone(e.target.value)}
                        fullWidth id="standard-basic"
                        label={<span className={styles.inputPlaceholder}>Timezone</span>}
                         inputProps={{readOnly:true}}
                        />
              </Grid>
            </Grid>
            
              
            <Grid container spacing={2} style={{ marginTop: "15px" }}>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                 <TextField 
                     value={longitute===0?"":longitute?.toString()}
                    onChange={(e)=>setLongitute(Number(e.target.value))}
                     type="number"
                      fullWidth 
                      id="standard-basic"
                      label={<span className={styles.inputPlaceholder}>Longitute</span>}
                      inputProps={{readOnly:true}}
                        />
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                    <TextField 
                     value={latitude===0?"":latitude?.toString()}
                      onChange={(e)=>setLatitude(Number(e.target.value))}
                     type="number"
                      fullWidth 
                      id="standard-basic"
                      label={<span className={styles.inputPlaceholder}>Latitude</span>}
                      inputProps={{readOnly:true}}
                        />
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: "15px" }}>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                 <TextField 
                      value={altitude}
                      onChange={(e)=>setAltitude(e.target.value)}
                      type="number"
                      fullWidth 
                      id="standard-basic"
                      label={<span className={styles.inputPlaceholder}>Altitude above sea leve</span>}
                      inputProps={{readOnly:true}}
                         />
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                    
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: "15px" }}>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                 <div style={{display:"flex",flexDirection:"column"}}>
                     <span className={styles.inputPlaceholder}>System Profile</span>
                     <FormControlLabel
                        style={{marginTop:"12px"}}
                        control={
                        <Checkbox
                            checked={true}
                            name="checkedB"
                            color="primary"
                        />
                        }
                        label={<label style={{fontWeight:"bolder"}}>{systemProfile}</label>}
                    />
                    </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                    <TextField
                      value={systemType}
                      onChange={(e)=>setSystemType(e.target.value)}
                      style={{ marginTop: "15px" }}
                      fullWidth
                      label={<span className={styles.inputPlaceholder}>System Type</span>}
                      inputProps={{readOnly:true}}
                       />
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: "15px" ,marginBottom:"15px"}}>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                 <div style={{display:"flex",flexDirection:"column"}}>
                     <span className={styles.inputPlaceholder}>CO2-Emission factor</span>
                    <FormControlLabel
                        style={{marginTop:"12px"}}
                        control={
                        <Checkbox
                            checked={true}
                            name="checkedB"
                            color="primary"
                        />
                        }
                        label={<label style={{fontWeight:"bolder"}}>{co2Factor}</label>}
                    />
                 </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>
              </Grid>
            </Grid>
              <hr/>
                <Button
                        onClick={()=>setOpen(false,{})}
                        fullWidth
                        variant="contained"
                        style={{
                            background: "#f9f9fa",
                            marginRight: "10x",
                            borderRadius: "15px",
                            marginTop: "20px",
                            marginBottom: "10px",
                            boxShadow: "none",
                            textTransform: "capitalize",
                            fontWeight: 400,

                          }}
                     >
                    Close
                </Button>
          </div>
        </Paper>
      </Modal>
    </div>
  );
}
