import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import React from "react";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: -3.745,
  lng: -38.523,
};

interface Props {
  lat: number;
  lng: number;
  mapClickHandler: (e: any) => void;
}

function MyComponent({ lat, lng, mapClickHandler }: Props) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDUmjLxl_qEb35uInhGq4ncy_a5pz2YYxA",
    // googleMapsApiKey: "AIzaSyD5myaBcSgGZOChinjLQsDZrjn0MBePg_0",
  });
  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={10}
      onLoad={onLoad}
      onClick={(e) => mapClickHandler(e)}
    >
      <Marker position={{ lat: lat, lng: lng }} />
    </GoogleMap>
  ) : (
    <h1>Loading..</h1>
  );
}

export default MyComponent;
