Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "bx_block_login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";
exports.forgotApiEndPoint = "bx_block_forgot_password/passwords/send_forgot_password_email";
exports.forgotAPiMethod = "POST";
exports.forgotApiContentType = "application/json";
exports.resetPasswordApiEndPoint = "bx_block_forgot_password/passwords/reset_password?";
exports.resetPasswordAPiMethod = "PUT";
exports.resetPasswordApiContentType = "application/json";
exports.profileApiContentType = "application/json";
exports.profileApiEndPoint = "bx_block_profile/profiles/profile_detail";
exports.profileAPiMethod = "GET";
exports.profileUpdateApiContentType = "application/json";
exports.profileUpdateApiEndPoint = "bx_block_profile/profiles/modify";
exports.profileUpdateAPiMethod = "PUT";
exports.addNewSystemApiContentType = "application/json";
exports.addNewSystemApiEndPoint = "bx_block_client/create_system";
exports.addNewSystemAPiMethod = "POST";
exports.readNotificationApiContentType = "application/json";
exports.readNotificationApiEndPoint = "bx_block_notifications/notifications/read_notifications";
exports.readNotificationAPiMethod = "PUT";
exports.directMessageApiContentType = "application/json";
exports.directMessageAPiMethod = "GET";
exports.groupsApiContentType = "application/json";
exports.groupsApiEndPoint = "bx_block_chat9/get_login_user_groups";
exports.groupsAPiMethod = "GET";

// CHAT MODULE APIS
/*** API Constants */
exports.ApiContentType = "application/json";
exports.EditApiContentType = "multipart/form-data";

/*** Methods List */
exports.apiGetMethod = "GET";
exports.apiPostMethod = "POST";
exports.apiPutMethod = "PUT";
exports.apiDeleteMethod = "DELETE";
exports.apiPatchMethod = "PATCH";

exports.getAllClientListApiUrl = "bx_block_client/clients";
exports.createNewChatApiUrl = "bx_block_chat9/rooms";
exports.getGroupMemberApiUrl = "bx_block_chat9/get_member_details";
exports.removeGroupMembersApiUrl = "bx_block_chat9/remove_member";
exports.deleteMessagesApiUrl = "bx_block_chat9/delete_messages";
exports.getMyDirectMessageApiUrl = "bx_block_chat9/get_current_user_rooms";
exports.sendMessageApiUrl = "bx_block_chat9/messages";
exports.addGroupMembersApiURl = "bx_block_chat9/add_members";
exports.getAllUserApiUrl = "account_block/account/get_attributes";
exports.getCurrentChatMessageUrl = "bx_block_chat9/get_message";
exports.deleteGroupApiURL = "bx_block_chat9/destroy_group";
exports.getGroupListApiURL = "bx_block_chat9/get_login_user_groups";
exports.deleteRoomApiUrl = "bx_block_chat9/rooms";
exports.messageReadApiUrl = "bx_block_chat9/change_unread_count";

// Customizable Area Start
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid =
  "Password not valid, must have atleast 6 characters containing 1 upercase, 1 lowercase, 1 special symbol and 1 number.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "Login";
exports.labelRememberMe = "Remember Me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";

exports.loginBlockAPiEndPoint = "bx_block_login/login";
exports.jsonContentType = "application/json";
exports.getApiMethodType = "GET";
exports.getCurrentUserApiUrl = "bx_block_profile/profiles/profile_detail";

exports.emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
exports.passwordRegExp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/;
exports.notificationEndPoint = "bx_block_notifications/notifications";

// Customizable Area End