import React from "react";
import ApprovalController, { Props, configJSON } from "./ApprovalController.web";
import { Box, Tab, Tabs, Typography,IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import {
  makeStyles,
  Theme,
  useTheme,
  withStyles,
} from "@material-ui/core/styles";
import BarChartOutlinedIcon from "@material-ui/icons/BarChartOutlined";
import KeyboardArrowRightOutlinedIcon from "@material-ui/icons/KeyboardArrowRightOutlined";
import DescriptionIcon from '@material-ui/icons/Description';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../../assets/approval.css"

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import SystemInformationModal from "../../../../components/src/AdminApprovalComponents/SystemInformationModal";
import {ApprovalContext} from './ApprovalContext.web'
import ApproveDeclineModal from "../../../../components/src/AdminApprovalComponents/ApproveDeclineModal";


class User extends ApprovalController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const {approvalRequestList,currentPage,systemInfoModal,selectedApproval,approveModal,getApprovalLoading}=this.state;
    return (
    <ApprovalContext.Provider value={{
        ...this.state,
        changeSystemInfoModal:this.changeSystemInfoModal,
        changeApproveModal:this.changeApproveModal,
        approveDeclineSystem:this.approveDeclineSystem
    }}>
      <div className="approvalContainer">
          <div className="approvalHeader">
              <div>
                <BarChartOutlinedIcon style={{ color: "#FDCE32" }} />
              </div>
              <div>
                <KeyboardArrowRightOutlinedIcon style={{ color: "#8F92A1" }} />
              </div>
              <div>
                <h3 style={{ fontSize: "14px" }}>Approval</h3>
              </div>
        </div>
         <div className="approvalBody">
            <div className="approval__body__top">
             <h3 style={{ fontSize: "18px" }}>Approvals</h3>
            </div>
            <div className="approval__body__table">
            <table>
                  <thead>
                      <tr>
                           <th> <h5 style={{fontWeight:"bold"}}>CLIENTS</h5></th>  
                           <th>
                              <h5 style={{fontWeight:"bold"}}>SYSTEM INFORMATION</h5>
                            </th>  
                            <th>
                              <h5 style={{fontWeight:"bold"}}>ACTIONS</h5>
                            </th>
                       </tr>
                   </thead>
                   <tbody>
                          {
                            getApprovalLoading?<Typography variant="h5">Loading..</Typography>:
                            approvalRequestList?.response?.data?.slice((currentPage-1) * approvalRequestList.numOfItem, (currentPage-1) * approvalRequestList.numOfItem + approvalRequestList.numOfItem)
                            .map((aRequest,index)=>(
                                <tr key={index}>
                                  <td>
                                     <div>
                                         <h2 className="itemTxt">{aRequest?.client_name}</h2>
                                      </div>
                                    </td>
                                    <td>
                                        <div onClick={()=>this.changeSystemInfoModal(true,aRequest)} style={{display:"flex",alignItems:"center",cursor:"pointer"}}>
                                          <DescriptionIcon style={{verticalAlign: "middle", marginLeft: "15px", color: "#8F92A1"}}/>
                                          <h2 className="itemTxt">Open</h2>
                                      </div>
                                    </td>
                                    <td>
                                        <div>
                                            <div style={{display:"flex",alignItems:"center"}}>
                                                <IconButton onClick={()=>this.changeApproveModal(true,aRequest,{alert:"decline",action:"declined"})}  style={{marginLeft:"10px",padding:"0"}}><CloseIcon/></IconButton>
                                                <IconButton onClick={()=>this.changeApproveModal(true,aRequest,{alert:"approve",action:"approved"})} style={{margin:"0",padding:"0"}} ><CheckIcon/></IconButton>
                                            </div>
                                        </div>
                                      </td>


                                </tr>
                              ))
                          }   
                    </tbody>
              
            </table>
        
            </div>
            <div className="approval__body__pagination">
                  <div className="tablePagination">
                            <IconButton
                                 className="ashIconButton"
                                  onClick={() => {
                                      if (currentPage> 1) {
                                        getApprovalLoading ||  this.changePage(currentPage - 1);
                                      }
                                  }}
                                  aria-label="delete"
                              >
                                  <ArrowBackIosIcon />
                              </IconButton>
                            <div className="paginationContent">
                              <h2 style={{fontSize:"14px"}}>
                                {currentPage }/{Math.ceil(approvalRequestList.length / approvalRequestList.numOfItem)}
                              </h2>
                            </div>
                            <IconButton
                               className="ashIconButton"
                                  onClick={() => {
                                      if (
                                          currentPage<
                                          Math.ceil(approvalRequestList.length / approvalRequestList.numOfItem)
                                      ) {
                                        getApprovalLoading ||  this.changePage(currentPage + 1);
                                      }
                                      }}
                                  aria-label="delete">
                                  <ArrowForwardIosIcon />
                              </IconButton>
                      </div>
            </div>
          
         </div>  
         {systemInfoModal && <SystemInformationModal/>}
          {selectedApproval?.client_name && approveModal && <ApproveDeclineModal />}
   
      </div>
       <ToastContainer 
          theme="dark"
        />
    </ApprovalContext.Provider>
    );
  }
}

export default User;
