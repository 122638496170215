import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from "yup";
import { toast } from "react-toastify";
export const customConfigJSON = require("./customConfig");
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
}

interface S {
  newPassword: string;
  confirmNewPassword: string;
  showNewPassword:boolean;
  showConfirmNewPassword:boolean;
  loading: boolean;
  errors: any;
}

interface SS {
  navigation: any;
}

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const passwordInvisibleImage = require("../assets/ic_password_invisible.png");
const passwordVisibleImage = require("../assets/ic_password_visible.png");

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiResetPasswordCallId: string = "";
  userSessionToken: string = "";

  //Properties from config
  labelTextIsAccountRecovery: string = configJSON.labelTextIsAccountRecoveryNew;
  placeholderIsReTypePassword: string =
    configJSON.placeholderIsReTypePasswordNew;

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      newPassword: "",
      confirmNewPassword: "",
      loading: false,
      showNewPassword:false,
      showConfirmNewPassword:false,
      errors: {},
    };
  }

  async componentDidMount() {
    super.componentDidMount();
    // this.validationRulesRequest();
  }

  handleTxtInputNewPassword(event: any) {
    this.setState({ newPassword: event.target.value });
  }

  handleTxtInputConfirmNewPassword(event: any) {
    this.setState({ confirmNewPassword: event.target.value });
  }
  handleNewPasswordShow=()=>{
    this.setState(prevState=>{
      return{
        showNewPassword:!prevState.showNewPassword
      }
    })
  }
 handleConfirmNewPasswordShow=()=>{
    this.setState(prevState=>{
      return{
        showConfirmNewPassword:!prevState.showConfirmNewPassword
      }
    })
  }

  doResetPassword() {
    const passwordRegExp = customConfigJSON.passwordRegExp;

    const { newPassword, confirmNewPassword } = this.state;

    if (!newPassword.match(passwordRegExp)) {
      this.setState({
        errors: {
          newPassword: customConfigJSON.errorPasswordNotValid,
        },
      });
      return;
    } else {
      const token = this.props.navigation.getParam("token");
      this.setState({
        loading: true,
        errors: {
          newPassword: "",
          confirmPassword: "",
        },
      });

      const header = {
        "Content-Type": customConfigJSON.forgotPasswordAPiContentType,
      };

      const attrs = {
        password: this.state.newPassword,
        confirm_password: this.state.confirmNewPassword,
      };

      const httpBody = {
        data: attrs,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiResetPasswordCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${customConfigJSON.resetPasswordAPIEndPoint}?token=${token}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        customConfigJSON.httpPutMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }


  async receive(from: string, message: Message) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiResetPasswordCallId &&
      this.apiResetPasswordCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.setState({
        loading: false,
      });
      if(responseJson?.errors && responseJson?.errors[0]?.token){
        toast.error(""+responseJson?.errors[0]?.token)
        return;
      }
      if(responseJson?.status===200 && responseJson?.message){
         const device=this.props.navigation.getParam("device");
         if(device==="mb"){
             toast.success(responseJson?.message)
             toast.success("Go to mobile app and use new password for login.",{
              autoClose:false
             })
         }else{
           toast.success(responseJson?.message)
           const timeout=setTimeout(()=>{
             this.props.navigation.navigate("Home")
             clearTimeout(timeout)
           },1500)
         }
      }
    }
  }
}
