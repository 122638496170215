import React from "react";
import {
  Typography,
  Grid,
  Card,
  makeStyles,
  IconButton,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import DonutSmallOutlinedIcon from "@material-ui/icons/DonutSmallOutlined";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";
import InsertChartOutlinedOutlinedIcon from "@material-ui/icons/InsertChartOutlinedOutlined";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import HeightIcon from "@material-ui/icons/Height";
import { useNewPortfolioReportContext } from "../../../blocks/LandingPage2/src/Reports/NewPortfolioReportContext.web";
import {
  imgChartImg,
  imgCoverImg,
  imgChartEdit,
  imgKeyPerformance,
  imgKeyPerformanceEdit,
  imgTicket,
  imgTicketEdit,
} from "../../../blocks/LandingPage2/src/assets";
import AddIcon from '@material-ui/icons/Add';
//@ts-ignore
// import { FileUploader } from "react-drag-drop-files";

const fileTypes = ["JPG", "PNG", "GIF"];

const useStyles = makeStyles({
  inputPlaceholder: {
    color: "#8F92A1",
    fontWeight: "bolder",
  },
  cardContentWrapper: {
    background: "white",
    borderRadius: "15px",
    padding: "20px 20px",
    transition:"0.5s"
  },
  boldLabel: {
    color: "black",
    fontWeight: 600,
  },
  actionButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "15px",
    marginTop: "10px",
  },
  file__drop__zone: {
    width: "100% !important",
    maxWidth: "100% !important",
    minHeight: "150px !important",
  },
  dropZone: {
    width: "100%",
    flexDirection: "column",
    height: "120px",
    boxSizing: "border-box",
    padding: "10px",
    border: "2px dashed #000",
    borderRadius: "10px",
    background: "#f9f9fa",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    marginBottom: "15px",
    marginTop: "10px",
  },
  root: {
    width: "100%",
  },
  heading: {
    fontSize: "15px",
  },
  columnOrientaionContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  columnOrientationLeft: {
    display: "flex",
    alignItems: "center",
    gap: "15px",
  },
  columnOrientationRight: {
    width: "20px",
    height: "20px",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    fontSize: "10px",
    background: "black",
    textAlign: "center",
    lineHeight: "20px",
    borderRadius: "50%",
  },
  iconImg: {
    width: "20px",
    height: "20px",
    objectFit: "cover",
  },
});

const ContentPage = () => {
  const { 
    changeActiveStep,
    goTo,
    coverTitle,
    coverDescription,
    coverLogo,
    coverImage,
    chartEnergy,
    chartEnergyComparison,
    chartPeriodReportOfTime,
    chartPeriodYearDate,
    chartPRComparison,
    keyPerformanceIndicator,
    ticketCreated,
    ticketCompleted,
    ticketDisplayOption,
    enable,
    open,
    handleKeyPerformanceIndicatorItemChecked:setKPItemChecked,
    handleCoverTitleChange:setCoverTitle,
    handleCoverDescriptionChange:setCoverDescription,
    handleLogoChange:setCoverLogo,
    handleCoverImageChange:setCoverImage,
    contentErrors:errors,
    handleChartsItemChecked:setChartItemChecked,
    handleTicketItemChecked:setTicketItemChecked,
    changeEnable,
    changeOpen,
    checkContentCoverInput,
    checkChartInput,
    checkKeyPerformanceInput,
    checkTicketInput
   } = useNewPortfolioReportContext();
  const styles = useStyles();
  const {
    chart:chartEnable,
    keyPerformance:keyPerformanceEnable,
    ticket:ticketEnable
  }=enable || {}

  const {
    chart:chartOpen,
    keyPerformance:indicatorOpen,
    ticket:ticketOpen,
    cover:coverOpen,
  }=open || {}


  const setChartOpen=(vl:boolean)=>{
    changeOpen("chart",vl);
  } 
  const setIndicatorOpen=(vl:boolean)=>{
    changeOpen("keyPerformance",vl);
  }
  const setTicketOpen=(vl:boolean)=>{
    changeOpen("ticket",vl);
  } 
 const setCoverOpen=(vl:boolean)=>{
    changeOpen("cover",vl);
  }


  return (
    <>
      <Grid container spacing={10}>
        <Grid item xs={12} sm={12} md={9} lg={8} xl={8}>
          <Typography
            component="h5"
            style={{ fontWeight: "bolder", fontSize: "1rem" }}
          >
            Preview
          </Typography>
          <Card
            elevation={0}
            style={{
              background: "#f2f2f2",
              padding: "20px 20px",
              borderRadius: "15px",
              marginTop: "15px",
              transition: "height .5s",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "15px" }}
              >
                <img className={styles.iconImg} alt="" src={imgCoverImg} />
                <Typography
                  component="h5"
                  style={{ fontWeight: "bolder", fontSize: "1rem" }}
                >
                  Cover
                </Typography>
              </div>
              <IconButton onClick={() => setCoverOpen(!coverOpen)}>
                <img className={styles.iconImg} alt="" src={imgChartEdit} />
              </IconButton>
            </div>
            {coverOpen && (
              <div className={styles.cardContentWrapper}>
                <TextField
                  fullWidth
                  id="standard-basic"
                  label={<span className={styles.inputPlaceholder}>Title</span>}
                  value={coverTitle}
                  onChange={(e)=>setCoverTitle(e.target.value)}
                  error={errors?.coverTitle===undefined || errors?.coverTitle===""?false:true}
                  helperText={errors?.coverTitle}
                />
                <br />
                <br />
                <TextField
                  fullWidth
                  id="standard-basic"
                  label={
                    <span className={styles.inputPlaceholder}>Description</span>
                  }
                  value={coverDescription}
                  onChange={(e)=>setCoverDescription(e.target.value)}
                  error={errors?.coverDescription===undefined || errors?.coverDescription===""?false:true}
                  helperText={errors?.coverDescription}
                />

                <br />
                <br />
                <input
                  accept="image/*"
                  id="coverLogo"
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e:any) => setCoverLogo(e?.target?.files[0])}
                />
                <span className={styles.inputPlaceholder}>Logo</span>

                <label htmlFor="coverLogo" className={styles.dropZone}>
                    {coverLogo ? 
                    <img style={{height:"100%",width:"100%",objectFit:"cover"}} src={coverLogo?.name?URL.createObjectURL(coverLogo):coverLogo}/>:
                    <label
                        htmlFor="coverLogo"
                        style={{
                          background: "#f1f0f0",
                          borderRadius: "50%",
                          cursor: "pointer",
                          padding: "10px",
                        }}
                      >
                        <PublishOutlinedIcon />
                      </label>}
                </label>
                {
                  coverLogo?  
                  <div style={{marginBottom:"10px"}}>
                    <h3>{coverLogo?.name}</h3> 
                  </div>: 
                   <div style={{marginBottom:"10px"}}>
                    <h3 style={{color:"red",fontSize:"14px"}}>{errors?.coverLogo}</h3> 
                  </div>
                }
               
                <input
                  accept="image/*"
                  id="coverImage"
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e:any) => setCoverImage(e?.target?.files[0])}
                />
                <span className={styles.inputPlaceholder}>Image</span>

                    <label htmlFor="coverImage" className={styles.dropZone}>
                      {coverImage ? 
                        <img style={{height:"100%",width:"100%",objectFit:"cover"}} src={coverImage?.name?URL.createObjectURL(coverImage):coverImage}/>
                        :<label
                            htmlFor="coverImage"
                            style={{
                              background: "#f1f0f0",
                              borderRadius: "50%",
                              cursor: "pointer",
                              padding: "10px",
                            }}
                          >
                            <PublishOutlinedIcon />
                          </label>
                  }
                </label>
                 {
                  coverImage?  
                  <div style={{marginBottom:"10px"}}>
                    <h3>{coverImage?.name}</h3> 
                  </div>: 
                   <div style={{marginBottom:"10px"}}>
                    <h3 style={{color:"red",fontSize:"14px"}}>{errors?.coverImage}</h3> 
                  </div>
                }

                <div className={styles.actionButton}>
                  <Button
                    onClick={() => setCoverOpen(false)}
                    variant="contained"
                    style={{
                      padding: "10px 40px",
                      background: "#f9f9fa",
                      borderRadius: "15px",
                      boxShadow: "none",
                      textTransform: "capitalize",
                      fontWeight: 400,
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={checkContentCoverInput}
                    variant="contained"
                    style={{
                      padding: "10px 40px",
                      marginLeft: "20px",
                      background: "#FFCE21",
                      borderRadius: "15px",
                      boxShadow: "none",
                      textTransform: "capitalize",
                      fontWeight: 400,
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            )}
          </Card>
          <br />
          <span className={styles.inputPlaceholder}>
            Element that will be visible in your chart
          </span>
          <br />
          {
            chartEnable &&  (<Card
            elevation={0}
            style={{
              background: "#f2f4fc",
              padding: "20px 20px",
              borderRadius: "15px",
              marginTop: "15px",
              transition: "height .1s",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "15px" }}
              >
                <img className={styles.iconImg} alt="" src={imgChartImg} />
                <Typography
                  component="h5"
                  style={{ fontWeight: "bolder", fontSize: "1rem" }}
                >
                  Charts
                </Typography>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "15px" }}
              >
                <IconButton onClick={() => setChartOpen(!chartOpen)}>
                  <img className={styles.iconImg} alt="" src={imgChartEdit} />
                </IconButton>
                <IconButton onClick={()=>changeEnable("chart",false)}>
                  <DeleteOutlineOutlinedIcon style={{ color: "#592fdb" }} />
                </IconButton>
              </div>
            </div>
            {chartOpen && (
              <div className={styles.cardContentWrapper}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={chartEnergy}
                      onChange={e=>setChartItemChecked("chartEnergy",e.target.checked)}
                      color="primary"
                    />
                  }
                  label={<span className={styles.boldLabel}>Energy</span>}
                />
                <br />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={chartEnergyComparison}
                      onChange={e=>setChartItemChecked("chartEnergyComparison",e.target.checked)}
                      color="primary"
                    />
                  }
                  label={
                    <span className={styles.boldLabel}>Energy Comparison</span>
                  }
                />
                <br />
                <div
                  style={{
                    marginLeft: "32px",
                    marginTop: "15px",
                    marginBottom: "15px",
                  }}
                >
                  <span className={styles.inputPlaceholder}>Period</span>
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={chartPeriodReportOfTime}
                        onChange={e=>setChartItemChecked("chartPeriodReportOfTime",e.target.checked)}
                        color="primary"
                      />
                    }
                    label={
                      <span className={styles.boldLabel}>
                        Report period of time
                      </span>
                    }
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={chartPeriodYearDate}
                        onChange={e=>setChartItemChecked("chartPeriodYearDate",e.target.checked)}
                        color="primary"
                      />
                    }
                    label={
                      <span className={styles.boldLabel}>Year to date</span>
                    }
                  />
                </div>
                <FormControlLabel
                  control={
                    <Checkbox
                       checked={chartPRComparison}
                       onChange={e=>setChartItemChecked("chartPRComparison",e.target.checked)}
                       color="primary"
                    />
                  }
                  label={
                    <span className={styles.boldLabel}>PR Comparison</span>
                  }
                />
                <br />
                <div className={styles.actionButton}>
                  <Button
                    onClick={() => setChartOpen(false)}
                    variant="contained"
                    style={{
                      padding: "10px 40px",
                      background: "#f9f9fa",
                      borderRadius: "15px",
                      boxShadow: "none",
                      textTransform: "capitalize",
                      fontWeight: 400,
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={checkChartInput}
                    variant="contained"
                    style={{
                      padding: "10px 40px",
                      marginLeft: "20px",
                      background: "#FFCE21",
                      borderRadius: "15px",
                      boxShadow: "none",
                      textTransform: "capitalize",
                      fontWeight: 400,
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            )}
               </Card>)
          }
         
         {
           keyPerformanceEnable && (
              <Card
            elevation={0}
            style={{
              background: "#f6f2fb",
              padding: "20px 20px",
              borderRadius: "15px",
              marginTop: "15px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "15px" }}
              >
                {/* <ImageOutlinedIcon style={{ color: "#7e09bb" }} /> */}
                <img
                  className={styles.iconImg}
                  alt=""
                  src={imgKeyPerformance}
                />

                <Typography
                  component="h5"
                  style={{ fontWeight: "bolder", fontSize: "1rem" }}
                >
                  Key Performance Indicator
                </Typography>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "15px" }}
              >
                <IconButton onClick={() => setIndicatorOpen(!indicatorOpen)}>
                  {/* <EditIcon style={{ color: "#7e09bb" }} /> */}
                  <img
                    className={styles.iconImg}
                    alt=""
                    src={imgKeyPerformanceEdit}
                  />
                </IconButton>
                <IconButton onClick={()=>changeEnable("keyPerformance",false)}>
                  <DeleteOutlineOutlinedIcon style={{ color: "#7e09bb" }} />
                </IconButton>
              </div>
            </div>
            {indicatorOpen && (
              <div className={styles.cardContentWrapper}>
                <span className={styles.inputPlaceholder}>
                  Selection of Columns
                </span>
                <br />
                {
                  Object.keys(keyPerformanceIndicator).map((key:any)=>(
                    <>
                       <FormControlLabel
                            control={
                              <Checkbox
                               // @ts-ignore
                                checked={keyPerformanceIndicator[key]?.checked}
                                onChange={e=>setKPItemChecked(key.toString(),e.target.checked)}
                                color="primary"
                              />
                            }
                            // @ts-ignore
                            label={<span className={styles.boldLabel}>{keyPerformanceIndicator[key]?.title}</span>}
                          />
                            <br />
                          </>
                          ))
                }
                <Accordion style={{ boxShadow: "none" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={styles.inputPlaceholder}>
                      Column Orientation
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ flexDirection: "column" }}>
                    <div className={styles.columnOrientaionContainer}>
                      <div className={styles.columnOrientationLeft}>
                        <div>
                          <HeightIcon />
                        </div>
                        <div>
                          <Typography className={styles.inputPlaceholder}>
                            Availiability
                          </Typography>
                        </div>
                      </div>
                      <div className={styles.columnOrientationRight}>1</div>
                    </div>
                    <br />
                    <div className={styles.columnOrientaionContainer}>
                      <div className={styles.columnOrientationLeft}>
                        <div>
                          <HeightIcon />
                        </div>
                        <div>
                          <Typography className={styles.inputPlaceholder}>
                            Specific yield
                          </Typography>
                        </div>
                      </div>
                      <div className={styles.columnOrientationRight}>2</div>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <div className={styles.actionButton}>
                  <Button
                    onClick={() => setIndicatorOpen(false)}
                    variant="contained"
                    style={{
                      padding: "10px 40px",
                      background: "#f9f9fa",
                      borderRadius: "15px",
                      boxShadow: "none",
                      textTransform: "capitalize",
                      fontWeight: 400,
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={checkKeyPerformanceInput}
                    variant="contained"
                    style={{
                      padding: "10px 40px",
                      marginLeft: "20px",
                      background: "#FFCE21",
                      borderRadius: "15px",
                      boxShadow: "none",
                      textTransform: "capitalize",
                      fontWeight: 400,
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            )}
          </Card>)
         }
         {
          ticketEnable &&  (<Card
            elevation={0}
            style={{
              background: "#fdfef0",
              padding: "20px 20px",
              borderRadius: "15px",
              marginTop: "15px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "15px" }}
              >
                {/* <InsertChartOutlinedOutlinedIcon
                  style={{ color: "#f8da00" }}
                /> */}
                <img className={styles.iconImg} alt="" src={imgTicket} />
                <Typography
                  component="h5"
                  style={{ fontWeight: "bolder", fontSize: "1rem" }}
                >
                  Tickets
                </Typography>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "15px" }}
              >
                <IconButton onClick={() => setTicketOpen(!ticketOpen)}>
                  {/* <EditIcon style={{ color: "#f8da00" }} /> */}
                  <img className={styles.iconImg} alt="" src={imgTicketEdit} />
                </IconButton>
                <IconButton onClick={()=>changeEnable("ticket",false)}>
                  <DeleteOutlineOutlinedIcon style={{ color: "#f8da00" }} />
                </IconButton>
              </div>
            </div>
            {ticketOpen && (
              <div className={styles.cardContentWrapper}>
                <span className={styles.inputPlaceholder}>
                  Show tickets in the reporting period that were:{" "}
                </span>
                <br />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={ticketCreated}
                      onChange={e=>setTicketItemChecked("ticketCreated",e.target.checked)}
                      color="primary"
                    />
                  }
                  label={<span className={styles.boldLabel}>Created</span>}
                />
                <br />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={ticketCompleted}
                      onChange={e=>setTicketItemChecked("ticketCompleted",e.target.checked)}
                      color="primary"
                    />
                  }
                  label={<span className={styles.boldLabel}>Completed</span>}
                />
                <br />
                <span className={styles.inputPlaceholder}>Options</span>
                <br />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={ticketDisplayOption}
                      onChange={e=>setTicketItemChecked("ticketDisplayOption",e.target.checked)}
                      color="primary"
                    />
                  }
                  label={
                    <span className={styles.boldLabel}>
                      Display available pictures of tickets
                    </span>
                  }
                />
                <br />
                <div className={styles.actionButton}>
                  <Button
                    onClick={() => setTicketOpen(false)}
                    variant="contained"
                    style={{
                      padding: "10px 40px",
                      background: "#f9f9fa",
                      borderRadius: "15px",
                      boxShadow: "none",
                      textTransform: "capitalize",
                      fontWeight: 400,
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={checkTicketInput}
                    variant="contained"
                    style={{
                      padding: "10px 40px",
                      marginLeft: "20px",
                      background: "#FFCE21",
                      borderRadius: "15px",
                      boxShadow: "none",
                      textTransform: "capitalize",
                      fontWeight: 400,
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            )}
             </Card>)
         }
          
         
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={4} xl={4}>
          <Typography
            component="h5"
            style={{ fontWeight: "bolder", fontSize: "1rem" }}
          >
            Report Elements
          </Typography>
          <Typography
            component="h5"
            style={{ fontWeight: 300, color: "#b6b6b6", fontSize: ".90rem" }}
          >
            Add elements to place them in chart
          </Typography>

         {
          chartEnable || (<Card
            elevation={0}
            style={{
              background: "#f1f5fb",
              padding: "20px 20px",
              borderRadius: "15px",
              marginTop: "15px",
              cursor:"pointer"
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "15px",justifyContent:"space-between" }}>
              <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                <img className={styles.iconImg} alt="" src={imgChartImg} />
                <Typography
                  component="h5"
                  style={{ fontWeight: "bolder", fontSize: "1rem" }}
                >
                  Charts
                </Typography>
              </div>
              <IconButton onClick={()=>changeEnable("chart",true)}>
                 <AddIcon/>
              </IconButton>
            </div>
          </Card>)
         }
         {
          keyPerformanceEnable || (
             <Card
            elevation={0}
            style={{
              background: "#f5f4fa",
              padding: "20px 20px",
              borderRadius: "15px",
              marginTop: "15px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "15px",justifyContent:"space-between" }}>
              <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                  {/* <ImageOutlinedIcon style={{ color: "#5648ac" }} /> */}
                  <img className={styles.iconImg} alt="" src={imgKeyPerformance} />
                  <Typography
                    component="h5"
                    style={{ fontWeight: "bolder", fontSize: "1rem" }}
                  >
                    Key Performance Indicator
                  </Typography>
                </div>
                <IconButton onClick={()=>changeEnable("keyPerformance",true)}>
                  <AddIcon/>
                </IconButton>
            </div>
          </Card>
          )
         }
         {
           ticketEnable ||   <Card
            elevation={0}
            style={{
              background: "#fefbf1",
              padding: "20px 20px",
              borderRadius: "15px",
              marginTop: "15px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "15px",justifyContent:"space-between" }}>
              <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                <img className={styles.iconImg} alt="" src={imgTicket} />
                <Typography
                  component="h5"
                  style={{ fontWeight: "bolder", fontSize: "1rem" }}
                >
                  Tickets
                </Typography>
              </div>
               <IconButton onClick={()=>changeEnable("ticket",true)}>
                 <AddIcon/>
              </IconButton>
            </div>
          </Card>
         }
          
         
        
        </Grid>
      </Grid>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "29px",
        }}
      >
        <Button
          onClick={() => changeActiveStep(0)}
          variant="contained"
          style={{
            padding: "10px 40px",
            background: "#f9f9fa",
            borderRadius: "15px",
            boxShadow: "none",
            textTransform: "capitalize",
            fontWeight: 400,
          }}
        >
          Back
        </Button>
        <Button
          onClick={() => changeActiveStep(2)}
          variant="contained"
          style={{
            padding: "10px 40px",
            marginLeft: "20px",
            background: "#FFCE21",
            borderRadius: "15px",
            boxShadow: "none",
            textTransform: "capitalize",
            fontWeight: 400,
          }}
        >
          Next
        </Button>
      </div>
    </>
  );
};

export default ContentPage;
