import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { toast } from "react-toastify";
import {AuthContext, AuthContextType} from './AuthContext/AuthContext.web'
import { Alert, Linking } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage'
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  showPasswordText: boolean;
  loading: boolean;
  checked:boolean;
  requestLoading:boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  apiEmailLoginId: string = "";
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp=configJSON.emailRegExp;
  labelTitle: string = "";
  static contextType:any = AuthContext;
  // Customizable Area End

  constructor(props: Props) {

    super(props);
    this.receive = this.receive.bind(this);
  
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checked: false,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      showPasswordText: false,
      loading: false,
      requestLoading:false,

    };
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    
    this.getLinking()


  }
  getLinking = async () => {
    const urlIn = await Linking.getInitialURL()
    console.log('====================================');
    console.log('DEEP Link init: ', urlIn);
    console.log('====================================');
    if(urlIn) {
      if(urlIn.includes('reset-password')) {
        let urlSplit = urlIn.split('/') 
        let token = urlSplit[4]
        console.log('====================================');
          console.log('Token: ', token);
          console.log('====================================');
           const notNavigation = await this.props.navigation.getParam('notNavigation')
        if(!notNavigation){
         this.props.navigation.navigate('NewPasswordMobile', {token})
        }
      }
    }
    else {
      Linking.addEventListener('url', async ({url}) => {
        console.log('====================================');
        console.log('Deep Link listener: ', url);
        console.log('====================================');
        if(url.includes('reset-password')) {
          let urlSplit = url.split('/') 
          let token = urlSplit[4]
          console.log('====================================');
          console.log('Token: ', token);
          console.log('====================================');
          const notNavigation = await this.props.navigation.getParam('notNavigation')
          if(!notNavigation)
           this.props.navigation.navigate('NewPasswordMobile', {token})
        }
      })
    }
    
  }

  getEmailPassword = async () => {
    const check = await AsyncStorage.getItem('check')
    const email = await AsyncStorage.getItem('email') || ''
    const password = await AsyncStorage.getItem('password') || ''
    if(check === 'true'){
      
      this.setState({checked: true, email, password}, () => {
        
      })
    }
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    this.getEmailPassword()
    // Customizable Area Start
    // const context=this.context;
    // if(context?.currentUser?.email){
    //   this?.props?.navigation?.navigate("LandingPage2")
    // }
    // Customizable Area End
  }

  // Customizable Area Start
  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin(),
  };

  btnEmailLogInProps = {
    color: "#FFC100",
    onPress: () => this.doEmailLogIn(),
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  handleClickShowPassword() {
    this.setState({ enablePasswordField: !this.state.enablePasswordField });
    this.setState({ showPasswordText: !this.state.showPasswordText });
    this.txtInputPasswordProps.secureTextEntry = !this.state
      .enablePasswordField;
    this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
      .secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible;
  }

  clickForgotPassword = () => {
    this.props.navigation.navigate('ForgotPasswordMobile')
  }

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  btnForgotPasswordProps = {
    onPress: () => this.goToForgotPassword(),
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
      
    },
    // value: this.state.password,
    secureTextEntry: true,
  };

  emailProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      // this.emailProps.value = text;
    },
    // value: this.state.email

  }
  PasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      // this.txtInputPasswordProps.value = text;
      
    },
    // value: this.state.password,
    secureTextEntry: true,
  };


  

  handleTxtInputEmail(event: any) {
    this.setState({ email: event.target.value });

    //@ts-ignore
    this.txtInputEmailProps.value = event.target.value;
  }

  handleTxtInputPassword(event: any) {
    this.setState({ password: event.target.value });

    //@ts-ignore
    this.txtInputPasswordProps.value = event.target.value;
  }

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  handleLoginSubmit(): boolean {
    // Validate Email
    const emailRegExp = configJSON.emailRegExp;
    if(!this.state.email){
      toast.warn("Enter Your Email Address.")
      return false; 
    }
    if(!this.state.password){
      toast.warn("Enter Your Password!")
    }

    if (!this.state.email.match(emailRegExp)) {
      toast.warn(configJSON.errorEmailNotValid);
      return false;
    }
    // Validate Password
    // const passwordRegExp = configJSON.passwordRegExp;
    // if (!this.state.password.match(passwordRegExp) ) {
    //   toast.warn(configJSON.errorPasswordNotValid);
    //   return false;
    // }
    this.doEmailLogIn();
    return true;
  }

  loginSubmit = async () => {
    const emailRegExp = configJSON.emailRegExp;
    let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
    if(!this.state.email){
      Alert.alert("Enter Your Email Address.")
      return false; 
    }
    if (!this.state.email.match(emailRegExp)) {
      Alert.alert(configJSON.errorEmailNotValid);
      return false;
    }
   
      if(!this.state.password){
        Alert.alert("Enter Your Password!")
        return false;
      }
      if(!this.state.password.match(reg)) {
        Alert.alert('Password not matched the criteria', 'Password should be 8 characters with one uppercase, one lowercase, one digit and one special character')
        return false;
      }
      if(this.state.checked){
      await AsyncStorage.setItem('check', 'true')
      await AsyncStorage.setItem('email', this.state.email)
      await AsyncStorage.setItem('password', this.state.password)

      } else {
      await AsyncStorage.setItem('check', 'false')
      await AsyncStorage.removeItem('email')
      await AsyncStorage.removeItem('password')
      }

      const header = {
        "Content-Type": configJSON.loginApiContentType,
      };
  
      const data = {
        data: {
                type: "email_account",
                attributes: {
                    "email": this.state.email.toLowerCase(), 
                    "password": this.state.password
                }   
            }
        }
      console.log('data',data)
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.apiEmailLoginId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.loginAPiEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.loginAPiMethod
      );
      this.setState({
        loading: true,
      });
      console.log('requestMessage',requestMessage)
      runEngine.sendMessage(requestMessage.id, requestMessage);
  

  }

  // Customizable Area End

  async receive(from: string, message: Message) {

    // Customizable Area Start
    const {getCurrentUser}:AuthContextType=this.context;

    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      const userName = message.getData(getName(MessageEnum.LoginUserName));

      const password = message.getData(getName(MessageEnum.LoginPassword));

      const countryCode = message.getData(
        getName(MessageEnum.LoginCountryCode)
      );

      if (!countryCode && userName && password) {
        this.setState({
          email: userName,
          password: password,
          checkedRememberMe: true,
        });

        //@ts-ignore
        this.txtInputEmailProps.value = userName;

        //@ts-ignore
        this.txtInputPasswordProps.value = password;

        this.CustomCheckBoxProps.isChecked = true;
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      console.log('errorReponse',errorReponse)

      if (apiRequestCallId != null) {
        if (
          apiRequestCallId === this.validationApiCallId &&
          responseJson !== undefined
        ) {
          let arrayholder = responseJson.data;

          if (arrayholder && arrayholder.length !== 0) {
            let regexData = arrayholder[0];

            if (regexData && regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        }

        if (apiRequestCallId === this.apiEmailLoginCallId) {
          this.setState({
            requestLoading:false
          })
          if (responseJson && responseJson.meta && responseJson.meta.token) {
            runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
            this.saveLoggedInUserData(responseJson);
            this.sendLoginSuccessMessage();
            toast("Login Successful.")
            getCurrentUser();
            if(responseJson?.meta?.role==="admin"){
              this.props.navigation.navigate("LandingPage2");
            }else{
              this.props.navigation.navigate("webClientLandingPage");
            }
          } else {
            //Check Error Response
            if(responseJson?.errors[0]?.failed_login){
              toast.warn(responseJson?.errors[0]?.failed_login)
            }else{
            this.parseApiErrorResponse(responseJson);
            this.sendLoginFailMessage();
            }
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }
        if (apiRequestCallId === this.apiEmailLoginId) {
          if (responseJson && responseJson?.meta) {
            console.log('dcfc',responseJson)
            runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
            this.saveLoggedInUserData(responseJson);
            this.sendLoginSuccessMessage();
            await AsyncStorage.setItem('token', responseJson.meta.token[0])
           await AsyncStorage.setItem('profile', JSON.stringify(responseJson.profile.data.attributes))
            Alert.alert("Login Successful.")
            this.props.navigation.navigate("LandingPage");
          } else {
            
            Alert.alert('', responseJson?.errors[0]?.failed_login)
          }
        }
      }
    }
    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    toast.warn(msg)
  }
  
  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
  }

  goToForgotPassword() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      toast.warn(configJSON.errorEmailNotValid);
      return false; 
    }

    if (this.state.password === null || this.state.password.length === 0) {
     toast.warn(configJSON.errorPasswordNotValid);
      return false;
    }

    this.setState({
      requestLoading:true
    })
    const header = {
      "Content-Type": configJSON.loginApiContentType
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

}
