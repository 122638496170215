import React from "react";
import { makeStyles } from "@material-ui/core";
import {
  COLOR_HIGHT_CONTRAST,
  COLOR_LOW_CONTRAST,
} from "../Util/CustomTooltip";
const useStyles = makeStyles({
  chart__points_cp: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "20px",
    marginTop: "10px",
  },
  chart__point__item_cp: {
    display: "flex",
    alignItems: "center",
    background: "#f3f2f5",
    borderRadius: "10px",
    gap: "15px",
    padding: "10px 15px",
    fontWeight: "bolder",
  },
  point_cp: {
    borderRadius: "50%",
    width: "15px",
    height: "15px",
  },
});

const ChartPoint = () => {
  const styles = useStyles();
  return (
    <div className={styles.chart__points_cp}>
      <div className={styles.chart__point__item_cp}>
        <div
          className={styles.point_cp}
          style={{ background: COLOR_HIGHT_CONTRAST }}
        />
        Energy
      </div>
      <div className={styles.chart__point__item_cp}>
        <div
          className={styles.point_cp}
          style={{ background: COLOR_LOW_CONTRAST }}
        />
        Irradiance
      </div>
    </div>
  );
};

export default ChartPoint;
