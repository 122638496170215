import React from 'react';

export const COLOR_HIGHT_CONTRAST="#8884d8";
export const COLOR_LOW_CONTRAST="#82ca9d";
export const COLOR_DARK_YELLOW="#ffc100";

const CustomTooltip = (props:any) => {
 const { active, payload, label }=props;
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip" style={{background:"#f9f9fa",padding:"15px",borderRadius:"15px"}}>
        <p> {label}</p>
        <div style={{display:"flex",alignItems:"center",gap:"10px"}}>
          <div style={{height:"10px",width:"10px",background:COLOR_HIGHT_CONTRAST,borderRadius:"50%"}}/>
          <p> {`${payload[0]?.payload?.pv}`}</p>
        </div>
        <div style={{display:"flex",alignItems:"center",gap:"10px"}}>
          <div style={{height:"10px",width:"10px",background:COLOR_LOW_CONTRAST,borderRadius:"50%"}}/>
          <p> {`${payload[0]?.payload?.uv}`}</p>
        </div>
      </div>
    );
  }

  return null;
};

export default CustomTooltip;