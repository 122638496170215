import {
  Button,
  Checkbox,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  Switch,
  TextField,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import { DeleteOutlined, EditOutlined } from "@material-ui/icons";
import NewCustomAlarmModal from "../client-dashboard/NewCustomAlarmModal.web";
import React from "react";
import { useClientSettingContext } from "../../../blocks/ActivityLog/src/ClientSettingContext.web";
import TableLoader from "../Util/TableLoader.web";
import PaginationController from '../Util/PaginationController'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: "20px",
    },
    dividerStyle: {
      marginTop: "10px",
      marginBottom: "15px",
      height: "2px",
      width: "100%",
      background: "#f9f9fa",
    },
    ashBoldText: {
      color: "#8F92A1",
      fontSize: "15px",
      marginTop: "10px",
      marginBottom: "10px",
      fontWeight: "bolder",
    },
    selectContainer: {
      border: "none",
      fontSize: "1.2rem",
      fontWeight: "bolder",
      color: "black",
      "&:hover": {
        borderBottom: "0px",
      },
      "&::before": {
        borderBottom: "0px !important",
      },
      "&::after": {
        borderBottom: "0px !important",
      },
      "&$selected": {
        borderBottom: "0px !important",
      },
    },
    blackBoldText: {
      fontSize: "1.2rem",
      fontWeight: "bolder",
      color: "black",
    },
    headingText: {
      fontSize: "2rem",
      fontWeight: 550,
      color: "black",
    },
    row: {
      display: "flex",
      alignItems: "center",
      gap: "15px",
    },
    ashButton: {
      background: "#f9f9fa",
      borderRadius: "15px",
      boxShadow: "none",
      textTransform: "capitalize",
      fontWeight: 400,
      color: "black",
    },
    yellowButton: {
      background: "#FFCE21",
      borderRadius: "15px",
      boxShadow: "none",
      textTransform: "capitalize",
      fontWeight: 600,
      color: "black",
      padding: "10px 15px",
    },
    textInput: {
      fontWeight: "bolder",
      color: "black",
    },
    inputPlaceholder: {
      color: "#8F92A1",
      fontWeight: "bolder",
      fontSize: ".85rem",
    },
    table__container: {
      boxSizing: "border-box",
      marginTop: "10px",
      overflowY: "auto",
      width: "100%",
      "& table": {
        width: "100%",
        minWidth: "800px",
        borderSpacing: "0px 15px",
        "& thead": {
          background: "#f9f9fa",
          borderRadius: "10px",
          height: "40px",
        },
        "& tr": {
          textAlign: "left",
          "& td": {
            textTransform: "capitalize",
            padding: "5px 0px",
            borderBottom: "2px solid #f9f9fa",
          },
          "&:hover": {
            backgroundColor: "var(--main-color)",
            color: "var(--txt-white)",
            cursor: "pointer",
          },
        },
      },
    },
    itemText: {
      fontSize: "16px",
      fontWeight: 500,
    },
  })
);

const StyledSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 50,
      height: 25,
      padding: 0,
      display: "flex",
      alignItems: "center",
    },
    switchBase: {
      padding: 3,
      color: "#fdce32",
      transform: "translateX(5px)",
      "&$checked": {
        transform: "translateX(28px)",
        color: "white",
        "& + $track": {
          opacity: 1,
          backgroundColor: "#fdce32",
        },
      },
    },
    thumb: {
      width: 10,
      height: 18,
      boxShadow: "none",
      borderRadius: "10px",
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: "15px",
      opacity: 1,
      backgroundColor: theme.palette.common.white,
      height: "90%",
    },
    checked: {},
  })
)(Switch);

const MonitoringPage = () => {
  const styles = useStyles();
  const [customAlarmModal, setCustomAlarmModal] = React.useState(false);


  const {
    dataLogger,
    fetchNextDataLogger,
    fetchPrevDataLogger,
    editDataLogger
  }=useClientSettingContext();


  const {
    dataLogggerList,
    getLoading,
    perPage,
    currentPage,
  }=dataLogger || {}



  return (
    <div className={styles.container}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography
            component="h1"
            variant="h3"
            className={styles.headingText}
          >
            Monitoring- Custom Alarms
          </Typography>
        </Grid>
        <Grid item>
          <Button
            onClick={() => setCustomAlarmModal(true)}
            className={styles.yellowButton}
          >
            New Custom Alarms
          </Button>
        </Grid>
      </Grid>
      <br />
      <Typography component="h5" className={styles.ashBoldText}>
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt,
        optio. Tenetur nulla ad, aspernatur, dolore qui, blanditiis ratione
        ipsam veniam magnam velit dignissimos officia atque perspiciatis cum
        porro praesentium dolores?
      </Typography>
      <br />
      <br />
      <TextField
        style={{ fontWeight: "bolder", fontSize: "1rem", width: "70%" }}
        className={styles.textInput}
        fullWidth
        id="standard-basic"
        label={<span className={styles.inputPlaceholder}>Name</span>}
      />
      <br />
      <br />
      <TextField
        style={{ fontWeight: "bolder", fontSize: "1rem", width: "70%" }}
        className={styles.textInput}
        fullWidth
        id="standard-basic"
        label={<span className={styles.inputPlaceholder}>Email</span>}
      />
      <br />
      <br />
      <br />
      <div className={styles.row}>
        <Checkbox
          // checked={systems?.all}
          // onChange={handleChecked}
          color="primary"
        />
        <Select className={styles.selectContainer} value="Enabled">
          <MenuItem value="Enabled">Enabled</MenuItem>
          <MenuItem value="Disabled">Disabled</MenuItem>
        </Select>
      </div>
      <br />
      <br />
      <br />
      <Button className={styles.yellowButton}>Save Contacts</Button>
      <div className={styles.dividerStyle} />

      <div className={styles.table__container}>
        <table>
          <thead>
            <tr>
              <th>
                <h5 style={{ fontWeight: "bold", paddingLeft: "20px" }}>
                  NAME
                </h5>
              </th>
              <th>
                <h5 style={{ fontWeight: "bold" }}>STATUS</h5>
              </th>
              <th>
                <h5 style={{ fontWeight: "bold" }}>DESCRIPTION</h5>
              </th>
              <th>
                <h5 style={{ fontWeight: "bold" }}>ACTIONS</h5>
              </th>
            </tr>
          </thead>
          <tbody>
            {
             getLoading ? <TableLoader count={perPage} column={4}/>
             :dataLogggerList.response.data?.slice((currentPage-1) * perPage, (currentPage-1) * perPage + perPage)
             .map((item, index) => (
              <tr key={item.id}>
                <td>
                  <div style={{ paddingLeft: "20px" }}>
                    <h2 className={styles.itemText}>{item?.attributes?.data_logger_name}</h2>
                  </div>
                </td>
                <td>
                  <StyledSwitch name="checkedC" />
                </td>
                <td>
                  <div>
                    <h2 className={styles.itemText}>{item?.attributes?.hardware_s_no}</h2>
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "15px",
                    }}
                  >
                    <IconButton>
                      <EditOutlined />
                    </IconButton>
                    <IconButton>
                      <DeleteOutlined />
                    </IconButton>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <PaginationController  
          currentPage={currentPage}
          totalPage={Math.ceil(dataLogggerList.length / perPage)}
          prev={fetchPrevDataLogger}
          next={fetchNextDataLogger}
        />
        <br />
        <br />
        <br />
      </div>
      <NewCustomAlarmModal
        open={customAlarmModal}
        setOpen={setCustomAlarmModal}
      />
    </div>
  );
};

export default MonitoringPage;
