import React from 'react';
import {Dialog,DialogTitle,DialogContent,DialogActions,Button} from '@material-ui/core';
import { useChatContext } from '../../../blocks/Chat9/src/ChatContext.web';

const DeleteChatRoomModal=()=>{

  const {
    deleteChatRoomModal:open,
    deleteRoomLoading:deleteLoading,
    changeDeleteChatRoomModal:setOpen,
    deleteChatRoom,
  }=useChatContext();

 console.log("<================Delete Chat Rendering==============>");


    return(
        <Dialog
        fullWidth
        maxWidth="xs"
        PaperProps={{style: {borderRadius: 16}}}
        open={open}
        onClose={() => setOpen(false)}
        >
          <DialogTitle id="max-width-dialog-title" style={{paddingBottom: 0}}>
            <strong>Delete Chat?</strong>
          </DialogTitle>
          <DialogContent>
            <p>Are you sure you want to to delete this chat?</p>
          </DialogContent>
          <DialogActions style={{margin: "15px"}}>
            <Button
                    variant="contained"
                    disableElevation
                    style={{borderRadius: "9px", height: "40px", width: "100%", fontWeight: 600, textTransform: "capitalize"}}
                    onClick={() => setOpen(false)}
                  >
              Cancel
            </Button>
            <Button
                disabled={deleteLoading}
                    variant="contained"
                    disableElevation
                    style={{borderRadius: "9px", height: "40px", width: "100%", background: "#FDCE32", fontWeight: 600, textTransform: "capitalize"}}
                    onClick={async() =>{
                      deleteChatRoom();
                    }}
                  >
              {deleteLoading?`Deleting Chat...`:"Yes"}
            </Button>
            </DialogActions>
        </Dialog>

    )
}

export default DeleteChatRoomModal;