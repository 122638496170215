import React from "react";
import {
  Container,
  Box,
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Checkbox,
  TableHead,
  Button,
  Tab,
  Tabs,
} from "@material-ui/core";
import {
  makeStyles,
  Theme,
  useTheme,
  withStyles,
} from "@material-ui/core/styles";
import "../../assets/network.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Icons
import BarChartOutlinedIcon from "@material-ui/icons/BarChartOutlined";
import KeyboardArrowRightOutlinedIcon from "@material-ui/icons/KeyboardArrowRightOutlined";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import ClientListTable from "../../../../components/src/NetworkComponents/ClientTableComponent";
import AddNewClientModal from "../../../../components/src/NetworkComponents/AddNewClientModal.web";
import ClientsSystemList from "../../../../components/src/NetworkComponents/SystemListModal.web";
import AdminDataController, { Props } from "./AdminDataController.web";
import ImportOverview from "../../../../components/src/AdminData/ImportOverview.web";

import "../../assets/adminData.css";
import DataLoggerContact from "../../../../components/src/AdminData/DataLoggerContact.web";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          <div>{children}</div>
        </div>
      )}
    </div>
  );
}

export default class AdminData extends AdminDataController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { value } = this.state;
    const { theme } = this.props;
    return (
      <>
        <main style={{ overflowX: "auto" }}>
          <Container
            maxWidth="xl"
            style={{ marginBottom: "20px", marginTop: "50px" }}
          >
            <Box
              style={{
                backgroundColor: "#f9f9fa",
                borderRadius: "24px",
                padding: "20px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className="networkHeader">
                <div>
                  <BarChartOutlinedIcon style={{ color: "#FDCE32" }} />
                </div>
                <div>
                  <KeyboardArrowRightOutlinedIcon
                    style={{ color: "#8F92A1" }}
                  />
                </div>
                <div>
                  <h3 style={{ fontSize: "14px" }}>Data</h3>
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: "24px",
                  width: "100%",
                }}
              >
                <div style={{ display: "flex", marginTop: "15px" }} />

                <div className="userBody">
                  <Tabs
                    value={value}
                    indicatorColor="secondary"
                    textColor="primary"
                    onChange={this.handleChange}
                    aria-label="disabled tabs example"
                  >
                    <Tab
                      label="Import Overview"
                      style={{
                        textTransform: "capitalize",
                        color: "black",
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                    />
                    <Tab
                      label="Data Logger Server Contact"
                      style={{
                        textTransform: "capitalize",
                        color: "black",
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                    />
                  </Tabs>
                  <TabPanel value={value} index={0}>
                    <ImportOverview
                      isShowImportSurvery={this.state.showImportSurvey}
                      handleSearchDataLogger={this.searchDataLogger}
                      impoerSurveryData={this.state.importSurveyData}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <DataLoggerContact
                      contactsData={this.state.todayContacts}
                    />
                  </TabPanel>
                </div>
              </div>
            </Box>
          </Container>
        </main>
      </>
    );
  }
}
