import React, { useContext } from "react";
import { S } from "./NewPortfolioReportController.web";
interface NewPortfolioReportContextType extends S {
  //states

  //functions
  changeActiveStep: (step: number) => void;
  goTo: (path: string) => void;
  handleNameChange: (value: string) => void;
  handleContentChange: (value: string) => void;
  handleDescriptionChange: (value: string) => void;
  handleSystemSelected: (system: string | number) => void;
  handleGeneralSettingsItemChecked: (key: string, checked: boolean) => void;
  handleCoverTitleChange: (value: string) => void;
  handleCoverDescriptionChange: (value: string) => void;
  handleLogoChange: (value: any) => void;
  handleCoverImageChange: (value: any) => void;
  handleChartsItemChecked: (key: string, checked: boolean) => void;
  handleTicketItemChecked: (key: string, checked: boolean) => void;
  handleKeyPerformanceIndicatorItemChecked: (
    key: string,
    checked: boolean
  ) => void;
  createNewPortfolioReport: () => void;
  changeEnable: (key: string, value: boolean) => void;
  changeOpen: (key: string, value: boolean) => void;
  checkContentCoverInput: () => boolean;
  checkChartInput: () => boolean;
  checkKeyPerformanceInput: () => boolean;
  checkTicketInput: () => boolean;
}
const defaultContext: NewPortfolioReportContextType = {
  //states
  edit: false,
  editReportId: "",
  activeStep: 0,
  name: "",
  description: "",
  systemId: "",
  content: "",
  filter: false,
  allSystem: false,
  specificSystem: false,
  coverTitle: "",
  coverDescription: "",
  coverLogo: null,
  coverImage: null,
  chartEnergy: false,
  chartEnergyComparison: false,
  chartPeriodReportOfTime: false,
  chartPeriodYearDate: false,
  chartPRComparison: false,
  keyPerformanceIndicator: {
    production: {
      checked: false,
      title: "Production",
    },
    power: {
      checked: false,
      title: "Power",
    },
    availiability: {
      checked: false,
      title: "Availiability",
    },
    powerKWp: {
      checked: false,
      title: "Power [kWp]",
    },
    productionKwp: {
      checked: false,
      title: "Production [kWp]",
    },
    specificYield: {
      checked: false,
      title: "Specific yield",
    },
    dataInput: {
      checked: false,
      title: "Data input",
    },
    lastDataInput: {
      checked: false,
      title: "Last Data input",
    },
  },
  ticketCreated: false,
  ticketCompleted: false,
  ticketDisplayOption: false,
  generalSettingErrors: {},
  contentErrors: {},
  summeryErrors: {},
  createLoading: false,
  getLoading: false,
  updateLoading: false,
  enable: {
    chart: false,
    keyPerformance: false,
    ticket: false,
  },
  open: {
    chart: false,
    keyPerformance: false,
    ticket: false,
    cover: false,
  },
  systemList: {
    data: [],
  },
  //functions
  changeActiveStep: (step: number) => {},
  goTo: (path: string) => {},
  handleNameChange: (value: string) => {},
  handleContentChange: (value: string) => {},
  handleDescriptionChange: (value: string) => {},
  handleSystemSelected: (system: string | number) => {},
  handleGeneralSettingsItemChecked: (key: string, checked: boolean) => {},
  handleCoverTitleChange: (value: string) => {},
  handleCoverDescriptionChange: (value: string) => {},
  handleLogoChange: (value: any) => {},
  handleCoverImageChange: (value: any) => {},
  handleChartsItemChecked: (key: string, checked: boolean) => {},
  handleTicketItemChecked: (key: string, checked: boolean) => {},
  handleKeyPerformanceIndicatorItemChecked: (
    key: string,
    checked: boolean
  ) => {},
  createNewPortfolioReport: () => {},
  changeEnable: (key: string, value: boolean) => {},
  changeOpen: (key: string, value: boolean) => {},
  checkContentCoverInput: (): boolean => {
    return false;
  },
  checkChartInput: (): boolean => {
    return false;
  },
  checkKeyPerformanceInput: (): boolean => {
    return false;
  },
  checkTicketInput: (): boolean => {
    return false;
  },
};

export const NewPortfolioContext = React.createContext<
  NewPortfolioReportContextType
>(defaultContext);

export function useNewPortfolioReportContext() {
  return useContext(NewPortfolioContext);
}
