import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import FilterListIcon from "@material-ui/icons/FilterList";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import SelectSearchDateModal from "./SelectSearchDateModal";

interface Props {
  filterNotification: (
    filterBy: string,
    startDate?: string,
    endDate?: string
  ) => void;
}

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));
export default function FilterNotification({ filterNotification }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [searchByDateModal, setSearchByDateModal] = React.useState<boolean>(
    false
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        startIcon={<FilterListIcon />}
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        onClick={handleClick}
        style={{
          background: "#FFCE21",
          borderRadius: "15px",
          boxShadow: "none",
          textTransform: "capitalize",
          fontWeight: 400,
          width: "100px",
        }}
      >
        Filter
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <div
            onClick={() => {
              filterNotification("all");
              setAnchorEl(null);
            }}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>All</div>
          </div>
        </MenuItem>
        <MenuItem>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>Client</div>
          </div>
        </MenuItem>
        <MenuItem>
          <div
            onClick={() => {
              filterNotification("user_notification");
              setAnchorEl(null);
            }}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>User</div>
          </div>
        </MenuItem>
        <MenuItem>
          <div
            onClick={() => {
              filterNotification("system_notification");
              setAnchorEl(null);
            }}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>Plant</div>
          </div>
        </MenuItem>
        <MenuItem>
          <div
            onClick={() => {
              filterNotification("chat_message");
              setAnchorEl(null);
            }}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>Chat</div>
          </div>
        </MenuItem>
        <MenuItem>
          <div
            onClick={() => {
              setSearchByDateModal(true);
              setAnchorEl(null);
            }}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>Date</div>
          </div>
        </MenuItem>
      </StyledMenu>
      <SelectSearchDateModal
        open={searchByDateModal}
        setOpen={setSearchByDateModal}
        filterByDate={filterNotification}
      />
    </div>
  );
}
