import {
  Card,
  makeStyles,
  Theme,
  createStyles,
  Typography,
  IconButton,
} from "@material-ui/core";
import React from "react";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import FlashOnOutlinedIcon from "@material-ui/icons/FlashOnOutlined";
interface StyledType {
  background: string;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardContainer: {
      display: "flex",
      alignItems: "center",
      padding: "20px 0px",
      justifyContent: "space-between",
      borderRadis: "20px",
    },
    cardLeftContainer: {
      display: "flex",
      alignItems: "center",
      gap: "20px",
    },
    cardLeftMarker: {
      background: (props: StyledType) => props.background,
      width: "3px",
      height: "30px",
      borderRadius: "0px 10px 10px 0px",
    },
    cardDataContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "5px",
    },
    cardBox: {
      width: "30px",
      height: "30px",
      background: (props: StyledType) => props.background,
      borderRadius: "12px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    blackNormalBoldText: {
      color: "black",
      fontSize: "1rem",
      fontWeight: "bolder",
    },
    ashBoldText: {
      color: "#A19DAF",
      fontSize: ".85rem",
      fontWeight: "bolder",
    },
  })
);

interface Props {
  title: string;
  value: number;
  color: string;
}
const InverterDataItem = ({ title, value, color }: Props) => {
  const styles = useStyles({ background: color });
  return (
    <Card
      component="div"
      elevation={0}
      className={styles.cardContainer}
      style={{ borderRadius: "20px" }}
    >
      <div className={styles.cardLeftContainer}>
        <div className={styles.cardLeftMarker} style={{ background: color }} />
        <div className={styles.cardBox} style={{ background: color }}>
          <FlashOnOutlinedIcon
            style={{ color: "white", fontSize: "20px", paddingTop: "5px" }}
          />
        </div>
        <div className={styles.cardDataContainer}>
          <Typography
            component="h3"
            variant="h5"
            className={styles.blackNormalBoldText}
          >
            {title}
          </Typography>
          <Typography
            component="h3"
            variant="h5"
            className={styles.ashBoldText}
          >
            {value} kW
          </Typography>
        </div>
      </div>
      <div>
        <IconButton>
          <MoreHorizIcon />
        </IconButton>
      </div>
    </Card>
  );
};

export default InverterDataItem;
