import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
import {} from "react-router-dom";
import add from "date-fns/add"
import setDate from "date-fns/setDate"
import sub from "date-fns/sub";

export interface Props {
  navigation: any;
  id: string;
  classes?: any;
}

interface S {
  date: any;
}

interface SS {
  id: any;
}

export default class ProjectMangementController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      date: new Date(),
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {}

  async receive(from: String, message: Message) {}

  // handle ui changes
   nextMonth = () => {
    this.setState((prevState) => ({
      date: add(prevState.date, { months: 1 }),
    }));
  };
  prevMonth = () => {
    this.setState((prevState) => ({
      date: sub(prevState.date, { months: 1 }),
    }));
  };

  handleClickDate = (index: number) => {
    const { date } = this.state;
    const updDate = setDate(date, index);
    this.setState({
      date: updDate,
    });
  };
}
