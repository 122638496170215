import {
  createStyles,
  Theme,
  withStyles,
  withTheme,
} from "@material-ui/core/styles";
import React from "react";
import { ToastContainer } from "react-toastify";
import ClientDashboardController, {
  Props,
} from "./ClientDashboardController.web";

import {
  Button,
  Card,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import ArrowBackIosOutlinedIcon from "@material-ui/icons/ArrowBackIosOutlined";
import ArrowDownwardOutlinedIcon from "@material-ui/icons/ArrowDownwardOutlined";
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import ArrowUpwardOutlinedIcon from "@material-ui/icons/ArrowUpwardOutlined";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import SettingsIcon from "@material-ui/icons/Settings";
import AlarmsAndTickets from "../../../components/src/client-dashboard/AlarmsAndTickets.web";
import CockpitPage from "../../../components/src/client-dashboard/CockpitPage.web";
import EvalutionPage from "../../../components/src/client-dashboard/EvalutionPage.web";
import { imgLogo } from "./assets";
interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          <div>{children}</div>
        </div>
      )}
    </div>
  );
}
const StyledTabs = withStyles({ indicator: { backgroundColor: "orange" } })(
  Tabs
);

class ClientDashboard extends ClientDashboardController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    const {
      energyGraphData,
      getEnergyGraphDataLoading,
      getMeterDetailsLoading,
      meterDetails,
      invertarDetails,
      activeTab,
    } = this.state;
    return (
      <div style={{ margin: "35px" }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <div className={`${classes.row}`}>
              <img src={imgLogo} alt="" className={classes.logoImg} />
              <Typography
                component="h1"
                variant="h5"
                style={{ fontWeight: "bolder" }}
              >
                Khalaya Power Plant
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <div
              className={`${classes.row}`}
              style={{ justifyContent: "flex-end" }}
            >
              <IconButton
                className={classes.iconButton}
                onClick={this.navigateToClientSetting}
              >
                <SettingsIcon />
              </IconButton>
              <Button
                onClick={() => this.props.navigation.goBack()}
                className={classes.ashButton}
                startIcon={<ArrowBackIosOutlinedIcon />}
              >
                Back
              </Button>
            </div>
          </Grid>
        </Grid>
        <div className={classes.client__content__container}>
          {/* client content header s */}
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography
                component="h1"
                variant="h6"
                style={{ fontWeight: "bolder" }}
              >
                Analytics Overview
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div
                className={`${classes.row}`}
                style={{ justifyContent: "flex-end" }}
              >
                <IconButton className={classes.darkIconButton}>
                  <CalendarTodayOutlinedIcon />
                </IconButton>
                <IconButton className={classes.darkIconButton}>
                  <ArrowBackIosOutlinedIcon />
                </IconButton>

                {/* area for show drop down  */}
                <div className={classes.dropdown_container}>
                  <Typography
                    component="h5"
                    variant="h6"
                    className={classes.dropdown__title}
                  >
                    Show :
                  </Typography>
                  <Select
                    style={{ borderBottom: "0px" }}
                    className={classes.dropdown__main}
                    defaultValue="Week"
                  >
                    <MenuItem value="Week">Week</MenuItem>
                    <MenuItem value={20}>Month</MenuItem>
                    <MenuItem value={30}>Year</MenuItem>
                  </Select>
                </div>

                <IconButton className={classes.darkIconButton}>
                  <ArrowForwardIosOutlinedIcon />
                </IconButton>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={4} style={{ marginTop: "20px" }}>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Card elevation={0} className={classes.card__item}>
                <Typography
                  component="h4"
                  className={classes.card__heading__text}
                >
                  ACTUAL POWER
                </Typography>
                <Typography
                  component="h1"
                  className={classes.card__content__text}
                >
                  180
                </Typography>
                <Typography
                  component="h6"
                  className={classes.card__shadow__text}
                >
                  kWs
                </Typography>
                <div className={classes.card__overview__container}>
                  <ArrowUpwardOutlinedIcon
                    className={classes.card_result__satisfied__icon}
                  />
                  <Typography
                    component="h6"
                    className={classes.card_result__satisfied}
                  >
                    105.23%
                  </Typography>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Card elevation={0} className={classes.card__item}>
                <Typography
                  component="h4"
                  className={classes.card__heading__text}
                >
                  EXPECTED ENERGY
                </Typography>
                <Typography
                  component="h1"
                  className={classes.card__content__text}
                >
                  190
                </Typography>
                <Typography
                  component="h6"
                  className={classes.card__shadow__text}
                >
                  kWs
                </Typography>
                <div className={classes.card__overview__container}>
                  <ArrowDownwardOutlinedIcon
                    className={classes.card_result__not__satisfied__icon}
                  />
                  <Typography
                    component="h6"
                    className={classes.card_result__not__satisfied}
                  >
                    20.15%
                  </Typography>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Card elevation={0} className={classes.card__item}>
                <Typography
                  component="h4"
                  className={classes.card__heading__text}
                >
                  EXPECTED PERFORMANCE
                </Typography>
                <Typography
                  component="h1"
                  className={classes.card__content__text}
                >
                  100%
                </Typography>
                <Typography
                  component="h6"
                  className={classes.card__shadow__text}
                >
                  In Percentage
                </Typography>
                <div className={classes.card__overview__container}>
                  <ArrowUpwardOutlinedIcon
                    className={classes.card_result__satisfied__icon}
                  />
                  <Typography
                    component="h6"
                    className={classes.card_result__satisfied}
                  >
                    15.20%
                  </Typography>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Card elevation={0} className={classes.card__item}>
                <Typography
                  component="h4"
                  className={classes.card__heading__text}
                >
                  PERFORMANCE RATIO
                </Typography>
                <Typography
                  component="h1"
                  className={classes.card__content__text}
                >
                  90%
                </Typography>
                <Typography
                  component="h6"
                  className={classes.card__shadow__text}
                >
                  In Percentage
                </Typography>
                <div className={classes.card__overview__container}>
                  <ArrowUpwardOutlinedIcon
                    className={classes.card_result__satisfied__icon}
                  />
                  <Typography
                    component="h6"
                    className={classes.card_result__satisfied}
                  >
                    32.84%
                  </Typography>
                </div>
              </Card>
            </Grid>
          </Grid>

          <div className={classes.tab__container}>
            <StyledTabs
              value={activeTab}
              onChange={(e, newValue) => this.changeTab(newValue)}
              className={classes.tab__pannel}
            >
              <Tab label="Cockpit" className={classes.tab__label} />
              <Tab label="Evalution" className={classes.tab__label} />
              <Tab label="Alarms and tickets" className={classes.tab__label} />
            </StyledTabs>
            <TabPanel value={activeTab} index={0}>
              <CockpitPage
                energyGraphData={energyGraphData}
                getEnergyGraphLoading={getEnergyGraphDataLoading}
                getMeterInfoLoading={getMeterDetailsLoading}
                meterDetails={meterDetails}
                inverterDetails={invertarDetails}
                onEnergyGraphFilterChange={this.handleEnergyGraphFilterChange}
              />
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
              <EvalutionPage />
            </TabPanel>
            <TabPanel value={activeTab} index={2}>
              <AlarmsAndTickets
                navigateToAlarmsPage={this.navigateToAlarmsPage}
              />
            </TabPanel>
          </div>
        </div>
        <ToastContainer theme="dark" />
      </div>
    );
  }
}

export default withTheme(
  withStyles((theme: Theme) =>
    createStyles({
      client_dashboard_container: {
        marginLeft: "40px",
        marginRight: "40px",
        boxSizing: "border-box",
      },
      row: {
        display: "flex",
        alignItems: "center",
        gap: "15px",
        boxSizing: "border-box",
      },
      logoImg: {
        width: "30px",
        height: "30px",
        objectFit: "cover",
        boxSizing: "border-box",
      },
      iconButton: {
        background: "#f9f9fa",
      },
      darkIconButton: {
        background: "#f3f2f5",
      },
      ashButton: {
        background: "#f9f9fa",
        borderRadius: "15px",
        textTransform: "capitalize",
      },
      client__content__container: {
        background: "#f9f9fa",
        borderRadius: "15px",
        padding: "30px",
        marginTop: "25px",
      },
      dropdown_container: {
        display: "flex",
        background: "#f3f2f5",
        borderRadius: "20px",
        padding: "10px 20px",
        alignItems: "center",
      },
      dropdown__title: {
        fontSize: "12px",
        color: "#A19DAF",
        fontWeight: "bolder",
      },
      dropdown__main: {
        marginLeft: "10px",
        fontSize: "14px",
        fontWeight: "bolder",
        "&:hover": {
          borderBottom: "0px",
        },
        "&::before": {
          borderBottom: "0px !important",
        },
      },
      card__item: {
        background: "white",
        borderRadius: "15px",
        padding: "20px 10px",
        aspectRatio: "16/10",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.only("sm")]: {
          aspectRatio: "16/11",
        },
        [theme.breakpoints.only("xs")]: {
          aspectRatio: "16/8",
        },
        [theme.breakpoints.only("md")]: {
          aspectRatio: "16/15",
        },
      },
      card__heading__text: {
        textTransform: "uppercase",
        fontSize: ".85rem",
        fontWeight: "bolder",
        textAlign: "center",
      },
      card__content__text: {
        textTransform: "uppercase",
        fontSize: "2rem",
        fontWeight: 600,
        textAlign: "center",
        color: "#5100d3",
      },
      card__shadow__text: {
        color: "#A19DAF",
        fontSize: ".75rem",
        fontWeight: 600,
        textAlign: "center",
      },
      card__overview__container: {
        display: "flex",
        alignItems: "center",
        gap: "6px",
        justifyContent: "center",
        marginTop: "20px",
      },
      card_result__satisfied: {
        color: "#00a067",
        fontWeight: 600,
        fontSize: "1rem",
      },
      card_result__satisfied__icon: {
        color: "#00a067",
        fontWeight: 600,
        fontSize: "30px",
      },
      card_result__not__satisfied: {
        color: "#e61900",
        fontWeight: 600,
        fontSize: "1rem",
      },
      card_result__not__satisfied__icon: {
        color: "#e61900",
        fontWeight: 600,
        fontSize: "30px",
      },
      tab__container: {
        background: "white",
        borderRadius: "25px",
        padding: "20px",
        boxSizing: "border-box",
        marginTop: "20px",
      },
      tab__pannel: {
        borderBottom: "1px solid #f9f9fa",
        "&$selected": {
          color: "#1890ff",
          fontWeight: theme.typography.fontWeightMedium,
        },
        "&:focus": {
          color: "#40a9ff",
        },
      },
      tab__label: {
        fontWeight: 600,
        color: "black",
        textTransform: "capitalize",
        paddingBottom: "10px",
      },
    })
  )(ClientDashboard)
);
