import React from "react";
import { Button, Card, Grid } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import FlashOnOutlinedIcon from "@material-ui/icons/FlashOnOutlined";

interface Props {
  title: string;
  description: string;
}

const EvalutionItem = ({ title, description }: Props) => {
  return (
    <Grid item xs={12} sm={12} md={6} xl={6} style={{ alignSelf: "stretch" }}>
      <Card
        style={{
          borderRadius: "20px",
          background: "#FFFFFF",
          padding: "20px",
          border: "1px solid #CFCFCF",
          height:"100%"
        }}
        elevation={0}
      >
        <div className="evalutionContent">
          <div className="evalutionLeft">
            <div className="evalutionLogo">
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <FlashOnOutlinedIcon
                  style={{
                    color: "white",
                    fontSize: "25px",
                    paddingTop: "5px",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="evalutionRight">
            <div>
              <div>
                <h2 style={{ fontSize: "18px" }}>{title}</h2>
              </div>
              <div style={{ marginTop: "10px" }}>
                <p
                  style={{
                    color: "#6a6c6d",
                    fontSize: "15px",
                    fontWeight: "bolder",
                  }}
                >
                  {description}
                </p>
              </div>
            </div>

            <div>
              <Button
                style={{
                  background: "#F3F4F5",
                  borderRadius: "10px",
                  marginTop: "12px",
                  width: "150px",
                  height: "40px",
                  textTransform: "capitalize",
                  fontWeight: "bold",
                }}
                startIcon={<AddIcon />}
              >
                Add Portlet
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </Grid>
  );
};

export default EvalutionItem;
