import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
export const configJSON = require("./customConfig");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  userProfileInfo: any;
  userAccountList: any[];
  open: boolean;
  createNewAccount: any;
  isEmailValid: boolean;
  isUsernameValid: boolean;
  isContactValid: boolean;
  isPasswordValid: boolean;
  isCreateAcountResponseError: boolean;
  createAccountResponseError: string;
  openDeleteAccountConfirmation: boolean;
  createAccountAPIInProgress: boolean;
  contactNoError:string;
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class UserProfileBasicController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiResetGetAccountProfileCallId: string = "";
  apiResetGetAccountUserListCallId: string = "";
  apiResetCreateAccountCallId: string = "";
  apiResetDeleteAccountCallId: string = "";
  accountData: any = {};
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SessionSaveMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      userProfileInfo: null,
      userAccountList: [],
      createNewAccount: {
        email: "",
        username: "",
        contact: "",
        password: "",
      },
      open: false,
      isEmailValid: true,
      isUsernameValid: true,
      isContactValid: true,
      isPasswordValid: true,
      isCreateAcountResponseError: true,
      createAccountResponseError: "",
      openDeleteAccountConfirmation: false,
      createAccountAPIInProgress: false,
      contactNoError:"",
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.getAccountProfile();
    this.getAccountUserList();
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiResetGetAccountProfileCallId &&
      this.apiResetGetAccountProfileCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson === undefined) {
        return;
      } else if (
        responseJson &&
        responseJson.errors &&
        responseJson.errors[0].token
      ) {
        this.props.navigation.navigate("EmailAccountLoginBlock");
        return;
      }
      this.setState({ userProfileInfo: responseJson.data });
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiResetGetAccountUserListCallId &&
      this.apiResetGetAccountUserListCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson === undefined) {
        return;
      } else if (
        responseJson &&
        responseJson.errors &&
        responseJson.errors[0].token
      ) {
        this.props.navigation.navigate("EmailAccountLoginBlock");
        return;
      }
      this.setState({ userAccountList: responseJson.data });
     
      if(responseJson?.data?.length>=4){
        this.setState({ isCreateAcountResponseError: true,createAccountResponseError:"Maximum four account allowed for super admin" });
       
      }else{
        this.setState({ isCreateAcountResponseError: false,createAccountResponseError:"" });
       
      }
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiResetCreateAccountCallId &&
      this.apiResetCreateAccountCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ createAccountAPIInProgress: false });
      if (responseJson === undefined) {
        return;
      } else if (
        responseJson &&
        responseJson.errors &&
        responseJson.errors[0].token
      ) {
        this.props.navigation.navigate("EmailAccountLoginBlock");
        return;
      }
      if (responseJson === undefined) {
        return;
      } else if (
        responseJson &&
        responseJson.errors &&
        responseJson.errors[0].super_admin
      ) {
        this.setState({ isCreateAcountResponseError: true });
        this.setState({
          createAccountResponseError: responseJson.errors[0].super_admin,
        });
        return;
      }

      this.handleClose();
      this.getAccountUserList();
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiResetDeleteAccountCallId &&
      this.apiResetDeleteAccountCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson === undefined) {
        return;
      } else if (
        responseJson &&
        responseJson.errors &&
        responseJson.errors[0].token
      ) {
        this.props.navigation.navigate("EmailAccountLoginBlock");
        return;
      }
      this.handleAccountDeleteConfimationClose();
      this.getAccountUserList();
    }
   // Customizable Area Start
   // Customizable Area End
  }

  getAccountProfile() {
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiResetGetAccountProfileCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAccountProfileEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAccountUserList() {
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token:userToken };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiResetGetAccountUserListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAccountUserListEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  navigateToEditProfile() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEditProfileMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(
      getName(MessageEnum.NavigationRaiseMessage),
      JSON.stringify(this.state.userProfileInfo)
    );
    this.send(msg);
  }

  addNewAccount() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
    this.resetCreateAccoutForm();
  }

  handleEmailInput(event: any) {
    const accountData = this.state.createNewAccount;
    accountData.email = event.target.value;
    this.setState({ createNewAccount: accountData });
  }

  handleUsernameInput(event: any) {
    const accountData = this.state.createNewAccount;
    accountData.username = event.target.value;
    this.setState({ createNewAccount: accountData });
  }

  handleContactInput(event: any) {
    const accountData = this.state.createNewAccount;
    accountData.contact = event.target.value;
    this.setState({ createNewAccount: accountData });
  }

  handlePasswordInput(event: any) {
    const accountData = this.state.createNewAccount;
    accountData.password = event.target.value;
    this.setState({ createNewAccount: accountData });
  }

  saveNewAccount() {
    let isFormValid = true;

    this.setState({
      isEmailValid: true,
      isUsernameValid: true,
      isContactValid: true,
      isPasswordValid: true,
      contactNoError:""
    });

    // Validate Email
    const emailRegExp = configJSON.emailRegExp;
    if (
      this.state.createNewAccount.email === null ||
      this.state.createNewAccount.email.length === 0 ||
      !this.state.createNewAccount.email.match(emailRegExp)
    ) {
      console.log("Here")
      this.setState({ isEmailValid: false });
      isFormValid = false;
    }
    console.log(this.state.createNewAccount.email === null)
    console.log(this.state.createNewAccount.email.length === 0)
    console.log(!this.state.createNewAccount.email.match(emailRegExp))

    // Validate Password
    const passwordRegExp = configJSON.passwordRegExp;
    console.log(this.state.createNewAccount.password === null ||
      this.state.createNewAccount.password.length === 0 ||
      !this.state.createNewAccount.password.match(passwordRegExp))
      console.log(passwordRegExp)
    if (
      this.state.createNewAccount.password === null ||
      this.state.createNewAccount.password.length === 0 ||
      !this.state.createNewAccount.password.match(passwordRegExp)
    ) {
      this.setState({ isPasswordValid: false });
      isFormValid = false;
    }

    // Validate Username
    if (
      this.state.createNewAccount.username === null ||
      this.state.createNewAccount.username.length === 0
    ) {
      this.setState({ isUsernameValid: false });
      isFormValid = false;
    }

    // Validate Contact
    if (
      this.state.createNewAccount.contact === null ||
      this.state.createNewAccount.contact.length === 0
    ) {
      this.setState({ isContactValid: false,contactNoError:configJSON.errorContactNotValid });
      isFormValid = false;
    }
    if(this.state.createNewAccount.contact?.length>10){
      this.setState({ isContactValid: false,contactNoError:"Contact is too long (maximum is 10 characters)" });
      isFormValid = false;
    }
    if(this.state.createNewAccount.contact?.length<10){
      this.setState({ isContactValid: false,contactNoError:"Contact is too short (minimum is 10 characters)" });
      isFormValid = false;
    }
    if (isFormValid) {
      this.createNewAccountApi();
    }
  }

  createNewAccountApi() {
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };
    // Customizable Area Start
    const attrs = {
      email: this.state.createNewAccount.email,
      password: this.state.createNewAccount.password,
      user_name: this.state.createNewAccount.username,
      phone_number: this.state.createNewAccount.contact,
      admin: true,
      super_admin: true,
      role: "admin",
    };
  // Customizable Area End

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiResetCreateAccountCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postAccountUserEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );
    this.setState({ createAccountAPIInProgress: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteAccount(accountData: any) {
    this.accountData = accountData;
    this.setState({ openDeleteAccountConfirmation: true });
  }

  deleteConfirmAccount() {
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiResetDeleteAccountCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteAccountUserEndPoint}?id=${this.accountData.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  logoutUser=()=>{
     localStorage.removeItem("authToken")
     this.props.navigation.navigate("EmailAccountLoginBlock");
  }

  handleAccountDeleteConfimationClose() {
    this.setState({ openDeleteAccountConfirmation: false });
  }

  resetCreateAccoutForm() {
    this.setState({
      createNewAccount: {
        email: "",
        username: "",
        contact: "",
        password: "",
      },
    });
    this.setState({
      isEmailValid: true,
      isUsernameValid: true,
      isContactValid: true,
      isPasswordValid: true,
    });
  }
 // Customizable Area Start
 // Customizable Area End
}
