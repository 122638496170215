Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.deleteApiMethodType = "DELETE";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AdminConsole3";
exports.labelBodyText = "AdminConsole3 Body";
exports.getAllClientAPIURL = "bx_block_dashboard/dashboards/get_all_clients";
exports.getAllUserApiUrl = "bx_block_client/users";
exports.createNewUserApiUrl = "bx_block_client/users";
exports.deleteUserApiUrl = "bx_block_client/users";
exports.getUserGroupsApiUrl = "bx_block_usergroups/user_groups/";
exports.getClientDetailsApiUrl = "bx_block_client/display_clients_detail";
exports.getClientSystemsApiUrl = "bx_block_client/get_systems_by_id";
exports.updateUserApiUrl = "bx_block_client/users";
exports.createSystemApiUrl = "bx_block_client/create_system";
exports.addNewClientApiUrl = "bx_block_client/create";
exports.deleteClientApiUrl = "bx_block_client/delete_client";
exports.updateClientApiUrl = "bx_block_client/clients";
exports.getEditorsApiUrl = "bx_block_usergroups/user_groups/get_editors";
exports.deleteSystemApiUrl = "bx_block_client/delete_system";
exports.updateUserGroupApiUrl = "bx_block_usergroups/user_groups";
exports.createUserGroupApiUrl = "bx_block_usergroups/user_groups";
exports.clientUsersApiUrl = "bx_block_client/get_users_by_id";
exports.getApprovalSystemApiUrl =
  "bx_block_client/get_clients_for_system_approval";
exports.getApiMethodType = "GET";
exports.patchApiMethodType = "PATCH";
exports.putApiMethod = "PUT";
exports.createNewUserMethodType = "POST";
exports.postApiMethod = "POST";
exports.deleteUserMethodType = "DELETE";
exports.deleteApiMethod = "DELETE";
exports.contentType = "application/json";
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End
