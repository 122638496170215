import React from "react";
import Notification2Controller, { Props, configJSON } from "./Notification2Controller.web";
import {Typography,IconButton,Backdrop} from "@material-ui/core"
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import CloseIcon from '@material-ui/icons/Close';
import NotificationItem from "../../../components/src/NotificationDropdown/NotificationItem.web";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../assets/dropdown.css"
import DeleteNotificationModal from "../../../components/src/NotificationDropdown/DeleteNotificationModal";
import { NavLink as Link } from "react-router-dom";
class Notification2 extends Notification2Controller {
  dropdown_toggle_el:any=null;
  dropdown_content_el:any=null;
  constructor(props: Props) {
    super(props);
    this.dropdown_toggle_el = React.createRef();
    this.dropdown_content_el = React.createRef();
  }
  toggleActive=(from:string)=>{
     if(from==="close"){
        this.dropdown_content_el?.current?.classList?.remove("active");
        return;
     }
      this.dropdown_content_el?.current?.classList?.toggle("active");
      if(from==="open" && this.state.unread>0){
           this.makeAsReadNotification();
      }
  }


  



  render() {
      const {notificationList,getNotificationLoading,unread:unreadNotification,deleteLoading}=this.state;
    return (    
    <div className='dropdown'>
        <div onClick={()=>this.toggleActive("open")} ref={this.dropdown_toggle_el} className="dropdown_toggle">
            <NotificationsNoneOutlinedIcon  style={{ color: "#D3D3D3" }} />
            {unreadNotification>0?<span className='dropdown__toggle_badge'>{unreadNotification}</span> :null}
            
        </div>
        <div ref={this.dropdown_content_el} className="dropdown_content" onClick={()=>this.toggleActive("close")}>
            <div onClick={(event)=> event.stopPropagation()} className="notification__wrapper">
                <div className="notification__header">
                      <div style={{display:"flex",alignItems:"center"}}>
                          <div>  
                              <h2>Notifications {deleteLoading&&<span style={{color:"red",fontSize:"12px"}}>(Deleteting One Notification..)</span>}</h2>
                          </div>
                          <div style={{display:"flex"}}>
                              <Link style={{fontSize:"12px"}} to="/NotificationDetails">(View Details)</Link>
                          </div>
                       </div> 
                      <div>
                          <IconButton  onClick={()=>{
                            this.toggleActive("close")}
                            }>
                            <CloseIcon/>
                          </IconButton>

                    </div>         
                </div>
                <div className='notification_main_content'>
                    {
                        getNotificationLoading?<Typography style={{color:"black"}} variant="h5">Loading...</Typography>:
                        notificationList?.length===0?<Typography style={{color:"black"}} variant="h5">No Notification Available For You</Typography>:
                        notificationList?.map((item,index)=>(
                            <NotificationItem 
                                toggleActive={this.toggleActive}
                            notification={item}
                             key={item?.id}
                              clickNotification={this.navigateToActionPage}
                              changeDeleteNotificationModal={this.changeDeleteModal}
                              />
                        ))
                    }
                </div>

            </div>
            
        </div>
        <DeleteNotificationModal
            deleteLoading={this.state.deleteLoading}
            deleteModal={this.state.deleteModal}
            deleteNotification={this.deleteNotification}
            changeDeleteModal={this.changeDeleteModal}
        />
        {/* <ToastContainer theme="dark" /> */}
    </div>
  )
  }
}

export default Notification2;
