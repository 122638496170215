import React from "react";
import {
  Line,
  XAxis,
  YAxis,
  Tooltip,
  LineChart,
  ResponsiveContainer,
} from "recharts";
import CustomTooltip, {
  COLOR_HIGHT_CONTRAST,
  COLOR_LOW_CONTRAST,
} from "../Util/CustomTooltip";
import { lineChartData } from "./data";

const CustomLineChart = () => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart data={lineChartData} margin={{ top: 5, right: 30, bottom: 5 }}>
        <XAxis
          dataKey="name"
          tickLine={false}
          axisLine={false}
          style={{ marginTop: "18px" }}
        />
        <YAxis tickLine={false} axisLine={false} />
        <Tooltip content={<CustomTooltip />} />
        <Line
          type="monotone"
          dataKey="pv"
          stroke={COLOR_HIGHT_CONTRAST}
          strokeWidth={5}
        />
        <Line
          type="monotone"
          dataKey="uv"
          stroke={COLOR_LOW_CONTRAST}
          strokeWidth={5}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default CustomLineChart;
