import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  Typography,
} from "@material-ui/core";
import React from "react";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: "20px",
    },
    dividerStyle: {
      marginTop: "10px",
      marginBottom: "15px",
      height: "2px",
      width: "100%",
      background: "#f9f9fa",
    },
    ashBoldText: {
      color: "#8F92A1",
      fontSize: "15px",
      marginTop: "10px",
      marginBottom: "10px",
      fontWeight: "bolder",
    },
    selectContainer: {
      border: "none",
      fontSize: "1.2rem",
      fontWeight: "bolder",
      color: "black",
      "&:hover": {
        borderBottom: "0px",
      },
      "&::before": {
        borderBottom: "0px !important",
      },
      "&::after": {
        borderBottom: "0px !important",
      },
      "&$selected": {
        borderBottom: "0px !important",
      },
    },
    blackBoldText: {
      fontSize: "1.2rem",
      fontWeight: "bolder",
      color: "black",
    },
    headingText: {
      fontSize: "2rem",
      fontWeight: 550,
      color: "black",
    },
    row: {
      display: "flex",
      alignItems: "center",
      gap: "15px",
    },
    ashButton: {
      background: "#f9f9fa",
      borderRadius: "15px",
      boxShadow: "none",
      textTransform: "capitalize",
      fontWeight: 400,
      color: "black",
    },
    yellowButton: {
      background: "#FFCE21",
      borderRadius: "15px",
      boxShadow: "none",
      textTransform: "capitalize",
      fontWeight: 400,
      color: "black",
    },
  })
);

interface Props {
  switchToOverview: (value: number) => void;
  changeAddNewDeviceModal: (open: boolean) => void;
}

const DataSources = ({ switchToOverview, changeAddNewDeviceModal }: Props) => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography
            component="h1"
            variant="h3"
            className={styles.headingText}
          >
            Data Logger Overview
          </Typography>
        </Grid>
        <Grid item>
          <Button
            onClick={() => switchToOverview(0)}
            className={styles.ashButton}
          >
            Switch to Overview
          </Button>
        </Grid>
      </Grid>
      <br />
      <Typography component="h5" className={styles.ashBoldText}>
        New source of the type
      </Typography>
      <Select
        fullWidth
        style={{ width: "70%" }}
        className={styles.selectContainer}
        value="Import Source V2"
      >
        <MenuItem value="Import Source V2">Import Source V2</MenuItem>
      </Select>
      <div className={styles.dividerStyle} />
      <Button
        onClick={() => changeAddNewDeviceModal(true)}
        className={styles.yellowButton}
      >
        Add
      </Button>
      <div className={styles.dividerStyle} />
    </div>
  );
};

export default DataSources;
