import React from "react";
import { Grid, Paper, Typography, Button, Card } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import EvalutionItem from "./EvalutionItem.web";
import AddIcon from "@material-ui/icons/Add";

import "./relience.css";
function getModalStyle() {
  const top = 51;
  const left = 53;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    borderRadius: "20px",
    border: "none",
    outline: "none",
    padding: "20px",
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "50vw",
      height: "auto",
    },
  })
);

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function AddNewProtletModal({ open, setOpen }: Props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Paper style={modalStyle} className={classes.paper}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            Evalution/Monitoring
          </Typography>
          <br />
          <br />
          <Grid container spacing={4}>
            <EvalutionItem
              title="Analysis"
              description="Display Of Save Analysis Graphics"
            />
            <EvalutionItem
              title="Inverters showing conspicuous behavior"
              description="List of inverters for which the specific power deviates significantly from the system power"
            />
            <EvalutionItem
              title="Park Diagram"
              description="Presentation of park diagrams(energy. energy comparison history and pr overview)"
            />
            <EvalutionItem
              title="Park Diagram"
              description="Presentation of park diagrams(energy.energy comparison history and pr overview"
            />
          </Grid>
          <Button
            fullWidth
            onClick={() => setOpen(false)}
            style={{
              background: "#F3F4F5",
              borderRadius: "10px",
              marginTop: "12px",
              height: "40px",
              textTransform: "capitalize",
              fontWeight: "bold",
            }}
          >
            Close
          </Button>
        </Paper>
      </Modal>
    </div>
  );
}
