import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
export const configJSON = require("../config");
import {} from "react-router-dom";
import { CreateApiRequestBody, ReportListType } from "./Reports.type";
import { toast } from "react-toastify";

export interface Props {
  navigation: any;
  id: string;
  classes?: any;
}

interface S {
  generatePortfolioReportModal: boolean;
  loading: boolean;
  reportList: ReportListType;
  loadedPage: number;
  currentPage: number;
  perPage: number;
  deleteModal:boolean;
  deleteReportId:string|number;
  deleteLoading:boolean;
}

interface SS {
  id: any;
}

export default class PortfolioReportController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetAllReportMessageId: string = "";
  apiDeleteReportMessageId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      generatePortfolioReportModal: false,
      loading: false,
      loadedPage: 0,
      currentPage: 1,
      perPage: 5,
      reportList: {
        response:{
           data: [],
        },
        length:0
      },
      deleteModal:false,
      deleteReportId:"",
      deleteLoading:false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    this.getReports();
  }

  async receive(from: String, message: Message) {

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson === undefined) {
        return;
      } else if (
        responseJson &&
        responseJson.errors &&
        responseJson.errors[0].token
      ) {
        this.props.navigation.navigate("EmailAccountLoginBlock");
        return;
      }

       if( this.apiGetAllReportMessageId ===apiRequestCallId){
         if(responseJson?.response?.data?.length>0){
            this.setState((prevState)=>{
              const nReport:ReportListType={
                  response:{
                      data:prevState?.currentPage===1?[...responseJson.response.data]:[...prevState.reportList.response.data,...responseJson.response.data]
                    },
                  length:responseJson?.length
                }
                return{
                    reportList:nReport,
                    loading:false,
                  }
              }
             );
          }else{
            this.setState({
              loading:false,
            })
          }
       } 
       if(this.apiDeleteReportMessageId===apiRequestCallId){
         if(responseJson?.status===200 && responseJson?.Message){
          toast.success(responseJson?.Message)
          const {deleteReportId,reportList}=this.state;

          const newReportList=reportList?.response?.data?.filter(item=>item?.id!==deleteReportId);

          this.setState(prevState=>({
            deleteLoading:false,
            deleteModal:false,
            deleteReportId:"",
            reportList:{
              response:{
                data:newReportList
              },
              length:prevState.reportList.length-1
            }
          }))


         }else{
          this.setState({
            deleteLoading:false,
          })
          toast.error("Report Delete Failed.")
        }
       }
      }
  }

  openDeleteModal=(reportId:string|number)=>{
    this.setState({
      deleteModal:true,
      deleteReportId:reportId
    })
  }
  changeDeleteModal=(open:boolean)=>{
    this.setState({
      deleteModal:open,
    })
  }

  editPortfolioReport=(editId:string)=>{
    this.props.navigation.navigate(`editReport`,{
      reportId:editId
    })
  }

fetchNextReports=() => {
   const {currentPage,reportList,perPage,loading}=this.state;
      if (currentPage<Math.ceil(reportList.length / perPage)) {
          loading || this.getReports(currentPage + 1);
      }
}
fetchPrevReports=()=>{
  const {currentPage,loading}=this.state;
  if (currentPage> 1) {
         loading || this.getReports(currentPage - 1);
  }
}

  getReports = (page?: number) => {
    const { perPage } = this.state;
    let callPage = 1;
    if (page) {
      callPage = page;
    }

    if (page != null && page <= this.state.loadedPage) {
      this.setState({
        currentPage: callPage,
      });
      return;
    }
    this.setState({
      currentPage: callPage,
      loading: true,
      loadedPage: callPage,
    });
    const hitUrl = `${
      configJSON.getAllPortfolioReportApiUrl
    }?per_page=${perPage}&page=${callPage}`;
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: userToken,
    };

    const requestMessage = this.createApiRequest({
      header,
      apiUrl: hitUrl,
      body: null,
      method: configJSON.getApiMethodType,
    });
    this.apiGetAllReportMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteReports=()=>{
    const {deleteReportId}=this.state;
    this.setState({
      deleteLoading:true
    })
    const hitUrl = `${
      configJSON.getAllPortfolioReportApiUrl
    }/${deleteReportId}`;
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: userToken,
    };

    const requestMessage = this.createApiRequest({
      header,
      apiUrl: hitUrl,
      body: null,
      method: configJSON.deleteApiMethodType,
    });
    this.apiDeleteReportMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  createApiRequest = ({
    header,
    apiUrl,
    body,
    method,
  }: CreateApiRequestBody) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    return requestMessage;
  };

  // handle ui changes
  changeGeneratePortfolioReport = (open: boolean) => {
    this.setState({
      generatePortfolioReportModal: open,
    });
  };
  goToNewPortfolioReportPage = () => {
    this.props.navigation.navigate("newPortfolioReport");
  };
}
