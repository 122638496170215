import { Button,TableContainer,Table,TableHead,TableRow,TableCell ,TableBody,Grid,
  makeStyles,Typography} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import FilterListOutlinedIcon from "@material-ui/icons/FilterListOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  LineChart,
  Line,
  ResponsiveContainer,
  Tooltip
} from 'recharts';
import { ISystem, ISystemInverterInfo, ISystemMeterInfo } from "./interfaces.types";
import MapWeb from "../../../components/src/Util/MapWeb";
import CustomTooltip,{COLOR_DARK_YELLOW,COLOR_HIGHT_CONTRAST,COLOR_LOW_CONTRAST} from "../../../components/src/Util/CustomTooltip";
import CustomSorting from "../../../components/src/Util/CustomSorting.web";


const data = [
  {
    name: 'January',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'February',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'March',
    uv: 2000,
    pv: 8,
    amt: 2290,
  },
  {
    name: 'April',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'May',
    uv: 18,
    pv: 4800,
    amt: 2181,
  },
];

const tableHeadsItem: any[] = [
  {
    text: "PLANT",
    icon: false,
  },
  {
    text: "POWER [KWP]",
    icon: true,
  },
  {
    text: "PRODUCTION [KWH]",
    icon: true,
  },
  {
    text: "PR%",
    icon: true,
  },
  {
    text: "IRRADIANCE",
    icon: true,
  },
];






interface Props {
  plant: ISystem | null;
  changeAddNewProtletDialog:(open:boolean)=>void;
  navigateToClientDashboard:()=>void;
  goBack:()=>void;
  isClient:boolean;
  inverterInfo:ISystemInverterInfo;
  meterInfo:ISystemMeterInfo;
  getPlantInfoLoading:boolean;
  onChangeGraphFilter:(filterBy:string,date:{
     day:number;
     month:number;
     year:number;
   })=>void;
  mainGraphLoading:boolean;
  mainGraphData:any[]
}

const useStyles = makeStyles({
  chart__points_po: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    alignSelf:"flex-end",
    gap: "20px",
    marginTop: "10px",
  },
  chart__point__item_po: {
    display: "flex",
    alignItems: "center",
    background: "#f3f2f5",
    borderRadius: "10px",
    gap: "15px",
    padding: "10px 15px",
    fontWeight: "bolder",
  },
  point_po: {
    borderRadius: "50%",
    width: "15px",
    height: "15px",
  },
  row_po:{
    display:"flex",
    alignItems:"center",
    gap:"10px"
  }
});

const PlantOverview = (
  { isClient,
    plant,
    changeAddNewProtletDialog,
    navigateToClientDashboard,
    goBack,
    inverterInfo,
    meterInfo,
    getPlantInfoLoading,
    onChangeGraphFilter,
    mainGraphLoading,
    mainGraphData
  }: Props) => {
   const styles = useStyles();

  
  return (
    <>
      <div className="overviewTable">
        <div style={{display:"flex",alignItems:"center",gap:"15px"}}>
          <h4 style={{fontSize:"22px"}}>{plant?.attributes.designation} Overview</h4>
          {getPlantInfoLoading && <CircularProgress size={24} />}
        </div>
         <TableContainer className="overviewTableContent" style={{marginTop:"15px"}}>
        <Table  aria-label="simple table">
          <TableHead style={{background:"#f9f9fa",height:"44px",borderRadius:"10px",width:"100%",boxSizing:"border-box"}}>
                <TableRow >
                            {
                                tableHeadsItem.map((head,index)=>(
                                  <TableCell key={index} style={{textAlign:"center",minWidth:"180px",padding:"0px 10px",lineHeight:"0px"}}>
                                    <div className="headItem">
                                      <h3 style={{fontSize:"12px"}}>{head?.text}</h3>
                                      {head?.icon && (
                                          <div style={{ marginLeft: "8px" }}>
                                            <FilterListOutlinedIcon style={{ color: "#8F92A1" }} />
                                          </div>
                                        )}
                                    </div>
                                  </TableCell>
                                ))
                              }
                    </TableRow>

          </TableHead>
          <TableBody>
              <TableRow >
                  <TableCell style={{minWidth:"180px"}}>
                       <div>
                            <h4 style={{ color: "#0052CC", fontSize: "14px" }}>
                              {plant?.attributes.designation}
                            </h4>
                            <p style={{ color: "#8F92A1", fontSize: "12px" }}>
                              {plant?.attributes.country}
                            </p>
                          </div>
                    </TableCell>
                    <TableCell style={{minWidth:"180px"}}>
                       <div>
                        <h3 style={{ color: "#171717", fontSize: "14px" }}>{meterInfo?.power}</h3>
                      </div>
                    </TableCell>
                    <TableCell style={{minWidth:"180px"}}>
                       <div className={styles.row_po}>
                         <div style={{width:"120px",height:"80px"}}>
                            <ResponsiveContainer width="100%" height="100%">
                              <LineChart 
                                    data={data}
                                    margin={{ top: 5, right: 30, bottom: 5 }}>
                                    <Line type="monotone" dataKey="pv" stroke={COLOR_DARK_YELLOW} strokeWidth={5}/>
                                </LineChart>
                            </ResponsiveContainer>   
                          </div> 
                          <h3 style={{ color: "#171717", fontSize: "14px" }}>{inverterInfo?.production}</h3>
                        </div>
                    </TableCell>
                    <TableCell style={{minWidth:"180px"}}>
                        <div className="bar">
                          {/* <span style={{ width: `${plant?.pr}%` }}></span> */}
                          <span style={{ width: `60%` }}></span>
                        </div>
                    </TableCell>
                    <TableCell style={{minWidth:"180px"}}>
                        <div style={{width:"120px",height:"80px"}}>
                            <ResponsiveContainer width="100%" height="100%">
                              <LineChart 
                                    data={data}
                                    margin={{ top: 5, right: 30, bottom: 5 }}>
                                    <Line type="monotone" dataKey="pv" stroke={COLOR_DARK_YELLOW} strokeWidth={5}/>
                                </LineChart>
                            </ResponsiveContainer>   
                        </div>    
                    </TableCell>
                                   
              </TableRow>
          </TableBody>
        </Table>        
      </TableContainer> 

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            width: "100%",
            justifyContent: "space-between",
            marginTop: "25px",
            flexWrap:"wrap",
            gap:"10px",
          }}
        >
          <div className="tableFooter">
            <div>
              <h3 style={{ color: "#8F92A1", fontSize: "12px" }}>
                INSTALLED POWER
              </h3>
            </div>
            <div>
              <h3 style={{ color: "#171717", fontSize: "14px" }}>
                {1500} kWp
              </h3>
            </div>
          </div>
          <div className="tableFooter">
            <div>
              <h3 style={{ color: "#8F92A1", fontSize: "12px" }}>MONITORING</h3>
            </div>
            <div>
              <h3 style={{ color: "#171717", fontSize: "14px" }}>
                {meterInfo?.monitered_by}
              </h3>
            </div>
            <div>
              <h6 style={{ color: "#171717", fontSize: "10px" }}>
                {meterInfo?.monitered_email}
              </h6>
            </div>
          </div>
          <div className="tableFooter">
            <div>
              <h3 style={{ color: "#8F92A1", fontSize: "12px" }}>OPERATOR</h3>
            </div>
            <div>
              <h3 style={{ color: "#171717", fontSize: "14px" }}>
                {meterInfo?.operated_by}
              </h3>
            </div>
            <div>
              <h6 style={{ color: "#171717", fontSize: "10px" }}>
                {meterInfo?.operated_email}
              </h6>
            </div>
          </div>
          <div className="tableFooter">
            <div>
              <h3 style={{ color: "#8F92A1", fontSize: "12px" }}>
                COMMISSIONING
              </h3>
            </div>
            <div>
              <h3 style={{ color: "#171717", fontSize: "14px" }}>
                  {meterInfo?.commisioning}
              </h3>
            </div>
          </div>
          <div className="tableFooter">
            <div>
              <h3 style={{ color: "#8F92A1", fontSize: "12px" }}>OPERATOR</h3>
            </div>
            <div>
              <h3 style={{ color: "#171717", fontSize: "14px" }}>
               {meterInfo?.operated_by}
              </h3>
            </div>
            <div>
              <h6 style={{ color: "#171717", fontSize: "10px" }}>
               {meterInfo?.operated_email}
              </h6>
            </div>
          </div>
          <div className="tableFooter">
            <div>
              <h3 style={{ color: "#8F92A1", fontSize: "12px" }}>
                LAST DATA IMPORT
              </h3>
            </div>
            <div>
              <h3 style={{ color: "#171717", fontSize: "14px" }}>
                 {new Date(meterInfo?.data_date_time)?.toDateString()} at {new Date(meterInfo?.data_date_time)?.toLocaleTimeString()}
              </h3>
            </div>
          </div>
        </div>

          
        <div className="chartMainContent">
          <div className="chartContent">
          <Grid container justifyContent="space-between" style={{marginBottom:"15px"}}>
            <Grid item>
              <h2 style={{fontSize:"18px"}}>Widgets</h2>
            </Grid>
            <Grid item>
              <Button
                 onClick={()=>changeAddNewProtletDialog(true)}
                 style={{boxShadow:"none",borderRadius:"15px",background:"#f9f9fa",textTransform:"capitalize"}}
                 className="backButton"
                 variant="contained"
                 startIcon={<AddIcon />}
                >
                  Add New Protlet Center
                </Button>
            </Grid>
          </Grid>
            <Grid
              container
              spacing={2}
            >
              <Grid item xs={12} sm={12} md={8} xl={8}>
                <Grid  container justifyContent="space-between" alignItems="center" style={{marginBottom:"15px"}}>
                  <Grid item >
                    <h3 style={{fontSize:"16px"}}>Energy Generation</h3>
                  </Grid>
                  <Grid item>
                      <CustomSorting onChange={onChangeGraphFilter}/>
                  </Grid>     
                </Grid>
                  <div
                    style={{
                      width: "100%",
                      height: "330px",
                      display: "flex",
                      alignItems:"center",
                      justifyContent:"center",
                      marginTop:"15px",
                      flexDirection:"column"
                    }}
                  >
                    {
                      mainGraphLoading && <CircularProgress size={50} />
                    }
                    {
                      !mainGraphLoading && mainGraphData?.length===0 && <Typography component="h4" variant="h4">No Data Available</Typography>
                    }
                    {
                      !mainGraphLoading && mainGraphData?.length>0 && (
                          <>
                          <ResponsiveContainer width="100%" height="100%">
                            <BarChart
                              data={mainGraphData}
                              margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5,
                              }}
                            >
                              <XAxis dataKey="name" tickLine={false} axisLine={false} style={{marginTop:"18px"}}/>
                              <YAxis tickLine={false} axisLine={false} dataKey="pv"/>
                              <Tooltip content={<CustomTooltip />} />
                              <Bar dataKey="pv" barSize={10} fill={COLOR_HIGHT_CONTRAST} radius={15}>
                                </Bar>
                              <Bar dataKey="uv" barSize={10} fill={COLOR_LOW_CONTRAST} radius={15}/>
                            </BarChart>
                          </ResponsiveContainer>
                          <div className={styles.chart__points_po}>
                            <div className={styles.chart__point__item_po}>
                              <div
                                  className={styles.point_po}
                                  style={{ background: COLOR_HIGHT_CONTRAST }}
                                />
                                Energy
                            </div>
                            <div className={styles.chart__point__item_po}>
                            <div
                              className={styles.point_po}
                              style={{ background: COLOR_LOW_CONTRAST }}
                            />
                                Irradiance
                            </div>
                          </div>
                          </>
                      )
                    }
                    
                   
                  </div>
                  
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                xl={4}
              >
                <MapWeb zoom={3} systemList={plant===null ?[]:[plant]}/>
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={6} xl={6}>
                {
                  isClient || (  <Button
                  onClick={()=>goBack()}
                  fullWidth
                  style={{ background: "#F3F4F5", borderRadius: "10px",textTransform:"capitalize"  }}
                >
                  Close
                </Button>)
                }
              
              </Grid>
              <Grid item xs={12} sm={12} md={6} xl={6}>
                <Button
                  onClick={()=>navigateToClientDashboard()}
                  style={{ background: "#FDCE32", borderRadius: "10px",textTransform:"capitalize" }}
                  fullWidth
                >
                  View More Details
                </Button>
              </Grid>
            </Grid>

            <br />
            <br />
          </div>
        </div>
      </div>
      <div className="overviewCharts"></div>
    </>
  );
};
export default PlantOverview;
