import React from "react";
import { Typography,TextField,makeStyles,Button } from "@material-ui/core";
import {useNewPortfolioReportContext} from '../../../blocks/LandingPage2/src/Reports/NewPortfolioReportContext.web'
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles=makeStyles({
   inputPlaceholder:{
     color:"#8F92A1",
     fontWeight:"bolder"
   },
})
const SummeryPage = () => {
  const styles=useStyles();
   const {
    changeActiveStep,
    name,
    handleNameChange:setName,
    systemId,
    content,
    handleContentChange:setContent,
    summeryErrors:errors,
    createLoading,
    createNewPortfolioReport,
    edit,
    systemList
  }=useNewPortfolioReportContext()

  const selectedSystem=systemList?.data?.find(item=>item.id==systemId);


  return (
    <>
       <Typography
          component="h5"
          style={{ fontWeight: "bolder", fontSize: "1rem",marginBottom:"15px" }}
        >
         Summary
        </Typography>
        <hr/>
        <br/>
           <TextField 
              style={{width:"60%",minWidth:"200px"}}
              value={name}
              onChange={(e)=>setName(e.target.value)}
               id="standard-basic"
              label={<span className={styles.inputPlaceholder}>NAME</span>}
              error={errors?.name===undefined || errors?.name===""?false:true}
              helperText={errors?.name}
            />
            <br/>
            <br/>
            <TextField 
              aria-readonly
              style={{width:"60%",minWidth:"200px"}}
              value={
                  `${selectedSystem?.attributes?.designation},${selectedSystem?.attributes?.street}-${selectedSystem?.attributes?.postal_code}`
              }
               id="standard-basic"
              label={<span className={styles.inputPlaceholder}>SYSTEM SELECTION</span>}
              error={errors?.systemSelection===undefined || errors?.systemSelection===""?false:true}
              helperText={errors?.systemSelection}
            />
            <br/>
            <br/>
            <TextField 
              type="number"
              style={{width:"60%",minWidth:"200px"}}
              value={content}
              onChange={(e)=>setContent(e.target.value)}
              id="standard-basic"
              label={<span className={styles.inputPlaceholder}>CONTENT</span>}
              error={errors?.content===undefined || errors?.content===""?false:true}
              helperText={errors?.content}
            />
            <br/>
            <br/>
            <div style={{display: "flex",justifyContent: "center",marginTop:"29px"}}>
               <Button
                   onClick={()=>changeActiveStep(1)}
                  variant="contained"
                  style={{
                    padding:"10px 40px",
                    background: "#f9f9fa",
                    borderRadius: "15px",
                    boxShadow: "none",
                    textTransform: "capitalize",
                    fontWeight: 400,
                  }}
                >
                  Back
                </Button>
                <Button
                  disabled={createLoading}
                  onClick={createNewPortfolioReport}
                  variant="contained"
                  style={{
                    padding:"10px 40px",
                    marginLeft:"20px",
                    background: "#FFCE21",
                    borderRadius: "15px",
                    boxShadow: "none",
                    textTransform: "capitalize",
                    fontWeight: 400,
                  }}
                >
                  {createLoading ? (
                    <CircularProgress size={24} />
                  ) :edit?"Update": (
                    "Save"
                  )}
                </Button>
            </div>  
    </>
  );
};

export default SummeryPage;
