import React, { useContext } from "react";
export interface AuthContextType {
  currentUser: any;
  getCurrentUser: () => void;
  isSuperAdmin: () => boolean;
  isClient: () => boolean;
  isLoggedIn: () => boolean;
  // Customizable Area Start
  checkAuthorization: (
    forSuperAdmin: boolean,
    forWebAdmin: boolean,
    navigation: any
  ) => void;
  // Customizable Area End
  // Customizable Area Start
  logoutUser: () => boolean;
  // Customizable Area End
}
const defaultContext: AuthContextType = {
  currentUser: {},
  getCurrentUser: () => {},
  isSuperAdmin: () => {
    return true;
  },
  isClient: () => {
    return true;
  },
  isLoggedIn: () => {
    return true;
  },
  // Customizable Area Start
  checkAuthorization: (
    forSuperAdmin: boolean,
    forWebAdmin: boolean,
    navigation: any
  ) => {},
  // Customizable Area End
  // Customizable Area Start
  logoutUser: (): boolean => {
    return false;
  },
  // Customizable Area End
};

export const AuthContext = React.createContext<AuthContextType>(defaultContext);

export function useAuth() {
  return useContext(AuthContext);
}
