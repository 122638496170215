import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
export const configJSON = require("../config");
import {toast } from 'react-toastify';
import { AuthContext } from "../../../email-account-login/src/AuthContext/AuthContext.web";
import { Notification } from "../../../Notifications/src/Notification2Controller.web";

export interface Props {
  navigation: any;
  id: string;
}

export interface ApprovalResponse{
   response:{
     data:any[]
   },
   length:number,
   numOfItem:number,
}

interface S {
  approvalRequestList:ApprovalResponse,
  getApprovalLoading:boolean,
  currentPage:number,
  loadedApprovalPage:number,
  systemInfoModal:boolean,
  selectedApproval:any,
  approveModal:boolean,
  approveLoading:boolean,
  action:{
    alert:string,
    action:string
  },
  notifiedSystem:Notification|null
}

interface SS {
  id: any;
}

export default class UserController extends BlockComponent<Props, S, SS> {
  static contextType:any = AuthContext;
  apiResetGetAllClientCallId: string = "";
  apiGetSystemApprovalCallId: string = "";
  apiSystemApproveCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      approvalRequestList:{
        response:{
          data:[]
        },
        length:0,
        numOfItem:6,
      },
      getApprovalLoading:false,
      currentPage:1,
      loadedApprovalPage:1,
      systemInfoModal:false,
      selectedApproval:{},
      approveModal:false,
      approveLoading:false,
      action:{
        alert:"",
        action:""
      },
      notifiedSystem:null

    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: String, message: Message) {
    
        //<=====RECEIVE DATA FROM NOTIFICATION ===========>
        if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
          const notification = message.getData(
            getName(MessageEnum.FromNotificationDataMessage)
          );

          this.setState({
            notifiedSystem: notification
          });
        }

   //<===============response for get approval systems============>
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiGetSystemApprovalCallId &&
      this.apiGetSystemApprovalCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      //disable loading
      this.setState((prevState,props)=>{
            const nApprove:ApprovalResponse={
                response:{
                  data:prevState?.currentPage===1?[...responseJson?.system]:[...prevState.approvalRequestList.response.data,...responseJson?.system]
                },
                length:responseJson?.length,
                numOfItem:6,
            }
            return {
              approvalRequestList:nApprove,
              getApprovalLoading:false,
              }
            }
          );
    }
   //<===============response for approval systems============>
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiSystemApproveCallId &&
      this.apiSystemApproveCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
        if(responseJson?.status===200){
            this.setState({
                  approveLoading:false
                })
          this.changeApproveModal(false,{},{action:"",alert:""})
          this.getApproveSystemList();
          toast(responseJson?.message)
        }
    }
 
  }

  async componentDidMount() {
     this.getApproveSystemList();
     console.log({
       message:"Approval Screen",
       context:this?.context
     })
  }

  getApproveSystemList = async(page?:number) => {
    let callPage=1;
      if(page){
        callPage=page;
      }


    if(page!=null && page<=this.state.loadedApprovalPage){
      this.setState({
          currentPage:callPage,
      })
      return;
    }


      this.setState({
        loadedApprovalPage:callPage,
        getApprovalLoading:true,
        currentPage:callPage
      })
     const hitUrl=`${configJSON.getApprovalSystemApiUrl}?per_page=${this.state.approvalRequestList.numOfItem}&page=${callPage}`
    //const hitUrl=`${configJSON.getApprovalSystemApiUrl}`
    
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetSystemApprovalCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      hitUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  approveDeclineSystem=async() => {
    const {selectedApproval,action}=this.state;
      this.setState({
        approveLoading:true,
      })
    // const hitUrl=`${configJSON.getApprovalSystemApiUrl}?per_page=${this.clientPerPage}&page=${callPage}`
    const hitUrl=`bx_block_client/systems/${selectedApproval?.systems?.id}/approve_or_decline_system?status=${action?.action}`
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSystemApproveCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      hitUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

   changePage=(pageNumber: number) => {
       this.getApproveSystemList(pageNumber)
  };
   changeSystemInfoModal=(open: boolean,system:any) => {
      this.setState({
         systemInfoModal:open,
         selectedApproval:system
      })
  };
  changeApproveModal=(open: boolean,system:any,action:{alert:string,action:string}) => {
      this.setState({
         approveModal:open,
         selectedApproval:system,
         action:action
      })
  };
  



}
