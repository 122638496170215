import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
export const configJSON = require("../config");
import {} from "react-router-dom";
import { toast } from "react-toastify";
import { CreateApiRequestBody,SystemListType } from "./Reports.type";

export interface Props {
  navigation: any;
  id: string;
  classes?: any;
}

export interface S {
  edit:boolean;
  editReportId:string;
  activeStep: number;
  name: string;
  description: string;
  content: string;
  systemId: string | number;
  filter: boolean;
  allSystem: boolean;
  specificSystem: boolean;
  coverTitle: string;
  coverDescription: string;
  coverLogo: any;
  coverImage: any;
  chartEnergy: boolean;
  chartEnergyComparison: boolean;
  chartPeriodReportOfTime: boolean;
  chartPeriodYearDate: boolean;
  chartPRComparison: boolean;
  keyPerformanceIndicator: {
    production: {
      checked: boolean;
      title: string;
    };
    power: {
      checked: boolean;
      title: string;
    };
    availiability: {
      checked: boolean;
      title: string;
    };
    powerKWp: {
      checked: boolean;
      title: string;
    };
    productionKwp: {
      checked: boolean;
      title: string;
    };
    specificYield: {
      checked: boolean;
      title: string;
    };
    dataInput: {
      checked: boolean;
      title: string;
    };
    lastDataInput: {
      checked: boolean;
      title: string;
    };
  };
  ticketCreated: boolean;
  ticketCompleted: boolean;
  ticketDisplayOption: boolean;
  generalSettingErrors: any;
  contentErrors: any;
  summeryErrors: any;
  createLoading: boolean;
  updateLoading: boolean;
  getLoading: boolean;
  enable:{
    chart:boolean;
    keyPerformance:boolean;
    ticket:boolean;
  },
  open:{
    chart:boolean;
    keyPerformance:boolean;
    ticket:boolean;
    cover:boolean;
  },
  systemList:SystemListType
}

interface SS {
  id: any;
}

export default class NewPortfolioReportController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiCreateNewPortfolioMessageId: string = "";
  apiGetEditPortfolioMessageId: string = "";
  apiGetSystemListMessageId: string = "";
  apiEditPortfolioMessageId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      edit:false,
      editReportId:"",
      activeStep: 0,
      name: "",
      description: "",
      systemId: "",
      content: "",
      filter: false,
      allSystem: false,
      specificSystem: false,
      coverTitle: "",
      coverDescription: "",
      coverLogo: null,
      coverImage: null,
      chartEnergy: false,
      chartEnergyComparison: false,
      chartPeriodReportOfTime: false,
      chartPeriodYearDate: false,
      chartPRComparison: false,
      keyPerformanceIndicator: {
        production: {
          checked: false,
          title: "Production",
        },
        power: {
          checked: false,
          title: "Power",
        },
        availiability: {
          checked: false,
          title: "Availiability",
        },
        powerKWp: {
          checked: false,
          title: "Power [kWp]",
        },
        productionKwp: {
          checked: false,
          title: "Production [kWp]",
        },
        specificYield: {
          checked: false,
          title: "Specific yield",
        },
        dataInput: {
          checked: false,
          title: "Data input",
        },
        lastDataInput: {
          checked: false,
          title: "Last Data input",
        },
      },
      ticketCreated: false,
      ticketCompleted: false,
      ticketDisplayOption: false,
      generalSettingErrors: {},
      contentErrors: {},
      summeryErrors: {},
      createLoading: false,
      updateLoading: false,
      getLoading: false,
      enable:{
        chart:false,
        keyPerformance:false,
        ticket:false,
      },
      open:{
        chart:false,
        keyPerformance:false,
        ticket:false,
        cover:false,
      },
      systemList:{
        data:[]
      }
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
 

  async receive(from: String, message: Message) {


    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson === undefined) {
        return;
      } else if (
        responseJson &&
        responseJson.errors &&
        responseJson.errors[0].token
      ) {
        this.props.navigation.navigate("EmailAccountLoginBlock");
        return;
      }

       if( this.apiCreateNewPortfolioMessageId ===apiRequestCallId){
         this.setState({
              createLoading:false
          })  
          if(responseJson?.data?.id){
            toast.success("New Report Created.")
            const newInterval=setTimeout(()=>{
                this.props.navigation.goBack();
                clearTimeout(newInterval)
            },1000)


          }else{
            toast.error("Report Create Failed.")
          }


       } 
       if( this.apiEditPortfolioMessageId ===apiRequestCallId){
         this.setState({
              createLoading:false
          })  
          if(responseJson?.data?.id){
            toast.success("Report Updated.")
            const newInterval=setTimeout(()=>{
                this.props.navigation.goBack();
                clearTimeout(newInterval)
            },1000)


          }else{
            toast.error("Report Update Failed.")
          }
       } 
       if( this.apiGetEditPortfolioMessageId ===apiRequestCallId){
          if(responseJson?.data?.id){
            console.log("Edit-->",responseJson?.data?.attributes)
            const data=responseJson?.data?.attributes;

            const chartEnable=data?.chart_energy || data?.chart_energy_comparison || data?.chart_pr_comparison || data?.chart_report_period_of_time || data?.chart_year_to_date;
            const ticketEnable=data?.ticket_completed || data?.ticket_created || data?.ticket_available_pictures;
            const performanceEnable=data?.performance_availiability || data?.performance_power || data?.performance_production || data?.perofrmance_Power_kWp || data?.perofrmance_Production_kWp || data?.perofrmance_availiability || data?.perofrmance_data_input || data?.perofrmance_last_data_input || data?.perofrmance_specific_yield;

            this.setState({
                getLoading:false,
                name:data?.name,
                description:data?.description,
                specificSystem:data?.system?.id?true:false,
                allSystem:data?.system?.id?false:true,
                filter:false,
                systemId:data?.system?.id,
                coverTitle:data?.cover_title,
                coverDescription:data?.cover_description,
                coverLogo:data?.cover_logo,
                coverImage:data?.cover_image,
                chartEnergy:data?.chart_energy,
                chartEnergyComparison:data?.chart_energy_comparison,
                chartPRComparison:data?.chart_pr_comparison,
                chartPeriodYearDate:data?.chart_year_to_date,
                chartPeriodReportOfTime:data?.chart_report_period_of_time,
                ticketCompleted:data?.ticket_completed,
                ticketCreated:data?.ticket_created,
                ticketDisplayOption:data?.ticket_available_pictures,
                keyPerformanceIndicator:{
                   production: {
                      checked: data?.performance_production,
                      title: "Production",
                    },
                    power: {
                      checked: data?.performance_power,
                      title: "Power",
                    },
                    availiability: {
                      checked: data?.performance_availiability,
                      title: "Availiability",
                    },
                    powerKWp: {
                      checked: data?.perofrmance_Power_kWp,
                      title: "Power [kWp]",
                    },
                    productionKwp: {
                      checked: data?.perofrmance_Production_kWp,
                      title: "Production [kWp]",
                    },
                    specificYield: {
                      checked: data?.perofrmance_specific_yield,
                      title: "Specific yield",
                    },
                    dataInput: {
                      checked: data?.perofrmance_data_input,
                      title: "Data input",
                    },
                    lastDataInput: {
                      checked: data?.perofrmance_last_data_input,
                      title: "Last Data input",
                    },
                },
                enable:{
                  chart:chartEnable,
                  ticket:ticketEnable,
                  keyPerformance:performanceEnable
                }
              })
          }else{
            toast.error("Something Went Wrong!!");
            this.setState({
              getLoading:false,
            })
          }
       }
        if( this.apiGetSystemListMessageId ===apiRequestCallId){
          if(responseJson?.data?.length>0){
             this.setState({
              systemList:responseJson
             })   
          }else{
            toast.error("No System Found.");
          }
       }


    }

  }

 async componentDidMount() {
    const reportId=this.props.navigation.getParam("reportId");
   
    if(reportId){
       this.setState({
          edit:true,
          editReportId:reportId
        })
      this.getEditPortfolioInfo();
    }
    this.getSystemList();
    
  }


  // handle ui changes
  changeActiveStep = (step: number) => {
    if (step === 1) {
      this.checkGeneralSettingInput(step);
    }else if(step===2){
      const {enable:openCard}=this.state;

      let chartPassed=true,keyPerformancePassed=true,ticketPassed=true;
      const coverPassed=this.checkContentCoverInput();
      if(openCard.chart){
        chartPassed=this.checkChartInput();
      }
      if(openCard.keyPerformance){
        keyPerformancePassed=this.checkKeyPerformanceInput();
      }
      if(openCard.ticket){
        ticketPassed=this.checkTicketInput();
      }

      if(coverPassed && chartPassed && ticketPassed && keyPerformancePassed){
        this.setState({
          activeStep: step,
        });
      }

    }else {
      this.setState({
        activeStep: step,
      });
    }
  };

  getSystemList = () => {
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: userToken,
    };

    const requestMessage = this.createApiRequest({
      header,
      apiUrl: configJSON.getAllSystemListApiUrl,
      body: null,
      method: configJSON.getApiMethodType,
    });
    this.apiGetSystemListMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createNewPortfolioReport = () => {
    this.setState({
      createLoading: true,
    });

    const userToken = localStorage.getItem("authToken");
    const header = {
      token: userToken,
    };

    const {
      name,
      description,
      systemId,
      coverTitle,
      coverDescription,
      coverLogo,
      coverImage,
      chartEnergy,
      chartEnergyComparison,
      chartPeriodReportOfTime,
      chartPeriodYearDate,
      chartPRComparison,
      keyPerformanceIndicator: pI,
      ticketCompleted,
      ticketCreated,
      ticketDisplayOption,
      edit,
      editReportId
    } = this.state;
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("system_id", systemId.toString());
    formData.append("cover_title", coverTitle);
    formData.append("cover_description", coverDescription);
    if(coverLogo?.name){
       formData.append("cover_logo", coverLogo);
    }
    if(coverImage?.name){
      formData.append("cover_image", coverImage);
    }
    formData.append("chart_energy", chartEnergy.toString());
    formData.append(
      "chart_energy_comparison",
      chartEnergyComparison.toString()
    );
    formData.append(
      "chart_report_period_of_time",
      chartPeriodReportOfTime.toString()
    );
    formData.append("chart_year_to_date", chartPeriodYearDate.toString());
    formData.append("chart_pr_comparison", chartPRComparison.toString());
    formData.append("performance_production", pI.production.checked.toString());
    formData.append("performance_power", pI.power.checked.toString());
    formData.append(
      "performance_availiability",
      pI.availiability.checked.toString()
    );
    formData.append("perofrmance_Power_kWp", pI.powerKWp.checked.toString());
    formData.append(
      "perofrmance_Production_kWp",
      pI.productionKwp.checked.toString()
    );
    formData.append(
      "perofrmance_specific_yield",
      pI.specificYield.checked.toString()
    );
    formData.append("perofrmance_data_input", pI.dataInput.checked.toString());
    formData.append(
      "perofrmance_last_data_input",
      pI.lastDataInput.checked.toString()
    );
    formData.append(
      "perofrmance_specific_yield_second",
      pI.specificYield.checked.toString()
    );
    formData.append(
      "perofrmance_availiability",
      pI.availiability.checked.toString()
    );
    formData.append("ticket_created", ticketCreated.toString());
    formData.append("ticket_completed", ticketCompleted.toString());
    formData.append(
      "ticket_available_pictures",
      ticketDisplayOption.toString()
    );

    let hitUrl=configJSON.createPortfolioApiUrl;
    let method=configJSON.postApiMethodType;

    if(edit){
      hitUrl+=`/${editReportId}`;
      method=configJSON.patchApiMethodType;
    }

    const requestMessage = this.createApiRequest({
      header,
      apiUrl:hitUrl ,
      body: formData,
      method: method,
    });

    if(edit){
      this.apiEditPortfolioMessageId=requestMessage.messageId;
    }else{
       this.apiCreateNewPortfolioMessageId = requestMessage.messageId;
    }

  runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getEditPortfolioInfo = () => {
    this.setState({
      getLoading: true,
    });
    const reportId=this.props.navigation.getParam("reportId");
   
    const userToken = localStorage.getItem("authToken");
    const header = {
      token: userToken,
    };
    const requestMessage = this.createApiRequest({
      header,
      apiUrl: `${configJSON.getSinglePortfolioApiUrl}/${reportId}`,
      body: null,
      method: configJSON.getApiMethodType,
    });
    this.apiGetEditPortfolioMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  createApiRequest = ({
    header,
    apiUrl,
    body,
    method,
  }: CreateApiRequestBody) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    return requestMessage;
  };

  checkGeneralSettingInput = (step: number) => {
    const {
      name,
      description,
      allSystem,
      specificSystem,
      filter,
      generalSettingErrors,
      systemId,
    } = this.state;

    let newError: any = {};

    if (!name) {
      newError.name = "Please Enter Name";
    }
    if (!description) {
      newError.description = "Please Enter Description";
    }
    if (
      newError &&
      Object.keys(newError).length === 0 &&
      Object.getPrototypeOf(newError) === Object.prototype
    ) {
      // if (!allSystem && !specificSystem) {
      //   toast.error("Please select system type for this portfolio reports.");
      //   this.setState({
      //     generalSettingErrors: {},
      //   });
      //   return;
      // }
      if (!systemId) {
        toast.error("Please Select System.");
        this.setState({
          generalSettingErrors: {},
        });
        return;
      }
      this.setState({
        activeStep: step,
      });
    } else {
      this.setState({
        generalSettingErrors: newError,
      });
    }
  };

  checkContentCoverInput=():boolean=>{
    const {coverTitle,coverDescription,coverLogo,coverImage}=this.state;
    let newError:any={};

    if(!coverTitle){
      newError.coverTitle="Please Enter Title";
    }
    if(!coverDescription){
      newError.coverDescription="Please Enter Cover Description";
    }
    if(!coverLogo){
      newError.coverLogo="Please Choose  Logo";
    }
    if(!coverImage){
      newError.coverImage="Please Choose  Image"
    }
    let goNext=false;
    if (
      newError &&
      Object.keys(newError).length === 0 &&
      Object.getPrototypeOf(newError) === Object.prototype
    ){
      this.setState(prevState=>({
        open:{
          ...prevState.open,
          cover:false,
        }
      }))
      goNext=true;
    }else{
      goNext=false;
      this.setState(prevState=>({
        contentErrors:newError,
        open:{
          ...prevState.open,
          cover:true
        }
      }))
    }
  return goNext;
  }

  checkChartInput=():boolean=>{
    const {
      chartEnergy,
      chartEnergyComparison,
      chartPeriodReportOfTime,
      chartPeriodYearDate,
      chartPRComparison
    }=this.state;
    let goNext=false;

    if(!chartEnergy && !chartEnergyComparison && !chartPeriodReportOfTime && !chartPeriodYearDate && !chartPRComparison){
        goNext=false;
        toast.error("Pleae Select Atleast One Options In Chart.")
        this.setState(prevState=>({
          open:{
            ...prevState.open,
            chart:true,
          }
        }))
    }else{
      goNext=true;
      this.setState(prevState=>({
          open:{
            ...prevState.open,
            chart:false,
          }
      }))
    }

    return goNext;
  }
  checkKeyPerformanceInput=():boolean=>{
    let goNext=false;
    const {keyPerformanceIndicator}=this.state;

    Object.keys(keyPerformanceIndicator).forEach(key=>{
      // @ts-ignore
      const keyName = key as keyof typeof keyPerformanceIndicator;
      if(keyPerformanceIndicator[keyName].checked){
        goNext=true;
      }
    })

    if(goNext){
      this.setState(prevState=>({
        open:{
          ...prevState.open,
          keyPerformance:false
        }
      }))
    }else{
      toast.error("Please Select Atleast One Property in Key Performance Indicator")
      this.setState(prevState=>({
        open:{
          ...prevState.open,
          keyPerformance:true
        }
      }))
    }
    return goNext;
  }
  checkTicketInput=():boolean=>{
    const {
      ticketCompleted,
      ticketCreated,
      ticketDisplayOption,
    }=this.state;
    let goNext=false;

    if(!ticketCompleted && !ticketCreated && !ticketDisplayOption){
        goNext=false;
        toast.error("Pleae Select Atleast One Options In Tickets.")
        this.setState(prevState=>({
          open:{
            ...prevState.open,
            ticket:true,
          }
        }))
    }else{
      goNext=true;
      this.setState(prevState=>({
          open:{
            ...prevState.open,
            ticket:false,
          }
      }))
    }

    return goNext;
  }




changeEnable=(key:string,value:boolean)=>{
  // @ts-ignore
 const keyName = key as keyof typeof this.state.enable;

 if(keyName==="chart" && value===false){
    this.setState({
      chartEnergy:false,
      chartEnergyComparison:false,
      chartPeriodReportOfTime:false,
      chartPeriodYearDate:false,
      chartPRComparison:false,
    })
 }
 if(keyName==="keyPerformance" && value===false){
     this.setState({
      keyPerformanceIndicator: {
        production: {
          checked: false,
          title: "Production",
        },
        power: {
          checked: false,
          title: "Power",
        },
        availiability: {
          checked: false,
          title: "Availiability",
        },
        powerKWp: {
          checked: false,
          title: "Power [kWp]",
        },
        productionKwp: {
          checked: false,
          title: "Production [kWp]",
        },
        specificYield: {
          checked: false,
          title: "Specific yield",
        },
        dataInput: {
          checked: false,
          title: "Data input",
        },
        lastDataInput: {
          checked: false,
          title: "Last Data input",
        },
      }
     })
   
 }
 if(keyName==="ticket" && value===false){
  this.setState({
    ticketCompleted:false,
    ticketCreated:false,
    ticketDisplayOption:false,
  })
 }





  this.setState(prevState=>{
    return{
      enable:{
        ...prevState.enable,
        [keyName]:value
      }
    }
  })
}
changeOpen=(key:string,value:boolean)=>{
  // @ts-ignore
 const keyName = key as keyof typeof this.state.open;
  this.setState(prevState=>{
    return{
      open:{
        ...prevState.open,
        [keyName]:value
      }
    }
  })
}
  handleNameChange = (value: string) => {
    this.setState({
      name: value,
    });
  };
  handleDescriptionChange = (value: string) => {
    this.setState({
      description: value,
    });
  };
  handleContentChange = (value: string) => {
    console.log("Content--->0", value);
    this.setState({
      content: value,
    });
  };
  handleSystemSelected = (system: string | number) => {
    this.setState({
      systemId: system,
    });
  };
  handleGeneralSettingsItemChecked = (key: string, checked: boolean) => {
    // @ts-ignore
    const keyName = key as keyof typeof this.state;
    if (keyName === "allSystem") {
      this.setState({
        [keyName]: checked,
        specificSystem: false,
      });
    } else if (keyName === "specificSystem") {
      this.setState({
        [keyName]: checked,
        allSystem: false,
      });
    } else {
      this.setState({
        ...this.state,
        [keyName]: checked,
      });
    }
  };

  handleCoverTitleChange = (value: string) => {
    this.setState({
      coverTitle: value,
    });
  };
  handleCoverDescriptionChange = (value: string) => {
    this.setState({
      coverDescription: value,
    });
  };
  handleLogoChange = (value: any) => {
    this.setState({
      coverLogo: value,
    });
  };
  handleCoverImageChange = (value: any) => {
    this.setState({
      coverImage: value,
    });
  };

  handleChartsItemChecked = (key: string, checked: boolean) => {
    // @ts-ignore
    const keyName = key as keyof typeof this.state;
    this.setState({
      ...this.state,
      [keyName]: checked,
    });
  };
  handleTicketItemChecked = (key: string, checked: boolean) => {
    // @ts-ignore
    const keyName = key as keyof typeof this.state;
    this.setState({
      ...this.state,
      [keyName]: checked,
    });
  };
  handleKeyPerformanceIndicatorItemChecked = (
    key: string,
    checked: boolean
  ) => {
    // @ts-ignore
    const keyName = key as keyof typeof this.state.keyPerformanceIndicator;
    this.setState((prevState) => {
      return {
        ...prevState,
        keyPerformanceIndicator: {
          ...prevState.keyPerformanceIndicator,
          // @ts-ignore
          [keyName]: {
            // @ts-ignore
            title: prevState.keyPerformanceIndicator[keyName].title,
            checked: checked,
          },
        },
      };
    });
  };

  goTo = (path: string) => {
    this.props.navigation.navigate(path);
  };



}
