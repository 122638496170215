import {
  Grid,
  makeStyles,
  Typography,
  Paper,
  Theme,
  createStyles,
  Button
} from "@material-ui/core";
import React from "react";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import InverterDataHeatmapModal from "./InverterDataHeatmapModal.web";
import CustomLineChart from "../Graph/CustomLineChart.web";
import ChartPoint from "../Graph/ChartPoint.web";
import CustomSorting from "../Util/CustomSorting.web";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card__shadow__text_ep: {
      color: "#A19DAF",
      fontSize: ".85rem",
      fontWeight: 600,
      textAlign: "center",
    },
    card__bold__text_ep: {
      color: "black",
      fontSize: ".95rem",
      fontWeight: 600,
      maxWidth: "120px",
      textAlign: "center",
    },
    card__small__bold__text_ep: {
      color: "black",
      fontSize: ".70rem",
      fontWeight: 600,
      textAlign: "center",
    },
    heading__text_ep: {
      color: "#A19DAF",
      fontSize: "1rem",
      fontWeight: 600,
    },
    tab__container_ep: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      maxHeight: "600px",
      overflowX: "auto",
      borderTop: "1px solid #f9f9fa",
      borderRight: "1px solid #f9f9fa",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "row",
        gap: "20px",
      },
    },
    tab_item_ep: {
      transition: ".5s",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      cursor: "pointer",
      padding: "20px 0px",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        minWidth: "120px",
        textAlign: "center",
      },
    },
    tab__content_ep: {
      transition: ".5s",
      display: "flex",
      alignItems: "center",
      gap: "20px",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    tab__title_ep: {
      transition: ".5s",
      fontSize: "1rem",
      fontWeight: "bolder",
    },
    active__tab__title_ep: {
      transition: ".5s",
      color: "#407dd9 !important",
    },
    tab__selected__indicator_ep: {
      transition: ".5s",
      display: 'flex',
      marginLeft: "15px",
      justifyContent: 'center',
      [theme.breakpoints.down("sm")]: {
        transform: "rotate(90deg)",
        marginLeft: "0px",
      },
    },
    paperContainer_ep: {
      border: "none",
      boxShadow: "none",
      padding: "10px",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },
    buttonAsh_ep: {
      textTransform: "capitalize",
      background: "#F9F9FA",
      boxShadow: "none",
      borderRadius: "15px",
      padding: "10px 15px 10px 15px",
      color: "black",
      fontWeight: "normal",
      minWidth: "100px",
      "&:hover": {
        background: "#F9F9FA",
      },
    },
  })
);

const getTabList = (activeTab: number) => {
  const activeStyle = {
    color: "#407dd9",
  };
  const inActiveStyle = {
    color: "#8f92a1",
  };
  return [
    {
      id: 1,
      title: "Energy Generation",
      icon: (
        <CalendarTodayOutlinedIcon
          style={activeTab === 1 ? activeStyle : inActiveStyle}
        />
      ),
    },
    {
      id: 2,
      title: "Inverter Data",
      icon: (
        <CalendarTodayOutlinedIcon
          style={activeTab === 2 ? activeStyle : inActiveStyle}
        />
      ),
    },
    {
      id: 3,
      title: "Meter Data",
      icon: (
        <CalendarTodayOutlinedIcon
          style={activeTab === 3 ? activeStyle : inActiveStyle}
        />
      ),
    },
    {
      id: 4,
      title: "Sensor Data",
      icon: (
        <CalendarTodayOutlinedIcon
          style={activeTab === 4 ? activeStyle : inActiveStyle}
        />
      ),
    },
    {
      id: 5,
      title: "Other Devices Data",
      icon: (
        <CalendarTodayOutlinedIcon
          style={activeTab === 5 ? activeStyle : inActiveStyle}
        />
      ),
    },
    {
      id: 6,
      title: "Gallery",
      icon: (
        <CalendarTodayOutlinedIcon
          style={activeTab === 6 ? activeStyle : inActiveStyle}
        />
      ),
    },
    {
      id: 7,
      title: "System Efficiency",
      icon: (
        <CalendarTodayOutlinedIcon
          style={activeTab === 7 ? activeStyle : inActiveStyle}
        />
      ),
    },
  ];
};

const EvalutionPage = () => {
  const styles = useStyles();

  const [activeTab, setActiveTab] = React.useState(1);
 const [inverterModal, setInverterModal] = React.useState(false);


  return (
    <>
      <br />
      <br />
      <Grid container spacing={3} justifyContent="space-between">
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          lg={3}
          xl={3}
          style={{ borderRight: "1px solid #f9f9fa" }}
        >
          <Typography
            component="h3"
            variant="h5"
            className={styles.heading__text_ep}
          >
            MATRICES
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
            <CustomSorting/>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          <div className={styles.tab__container_ep}>
            {getTabList(activeTab).map((tabItem) => (
              <div
                key={tabItem.id}
                className={styles.tab_item_ep}
                onClick={(e) => setActiveTab(tabItem?.id)}
              >
                <div className={styles.tab__content_ep}>
                  {tabItem.icon}
                  <Typography
                    component="h4"
                    variant="h5"
                    className={`${styles.tab__title_ep} ${
                      tabItem?.id === activeTab ? styles.active__tab__title_ep : ""
                    }`}
                  >
                    {tabItem?.title}
                  </Typography>
                </div>
                <div className={styles.tab__selected__indicator_ep}>
                  {tabItem?.id === activeTab && <ArrowForwardIosOutlinedIcon />}
                </div>
              </div>
            ))}
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
          {/* tab pannels will be go here  */}
          <div style={{ width: "100%", height: "500px" }}>
             <CustomLineChart/>
          </div>
           <ChartPoint/>
          {
            activeTab===2 && <>
            <Grid container spacing={3} style={{marginTop:"15px",marginBottom:"15px"}}>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <Paper className={styles.paperContainer_ep}>
                  <Typography
                    component="h3"
                    variant="h6"
                    className={styles.card__shadow__text_ep}
                  >
                    MODEL NUMBER
                  </Typography>
                  <Typography
                    component="h3"
                    variant="h5"
                    className={styles.card__bold__text_ep}
                  >
                    AKDSDI24343
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <Paper className={styles.paperContainer_ep}>
                  <Typography
                    component="h3"
                    variant="h6"
                    className={styles.card__shadow__text_ep}
                  >
                    INVERTER CAPACITY
                  </Typography>
                  <Typography
                    component="h3"
                    variant="h5"
                    className={styles.card__bold__text_ep}
                  >
                  800VA
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <Paper className={styles.paperContainer_ep}>
                  <Typography
                    component="h3"
                    variant="h6"
                    className={styles.card__shadow__text_ep}
                  >
                    SYSTEM VOLTAGE
                  </Typography>
                  <Typography
                    component="h3"
                    variant="h5"
                    className={styles.card__bold__text_ep}
                  >
                    12VDC
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <Paper className={styles.paperContainer_ep}>
                  <Typography
                    component="h3"
                    variant="h6"
                    className={styles.card__shadow__text_ep}
                  >
                    BATTERY BANKS
                  </Typography>
                  <Typography
                    component="h3"
                    variant="h5"
                    className={styles.card__bold__text_ep}
                  >
                    200 X 1 no
                  </Typography>
                </Paper>
              </Grid>
              </Grid>
              <div style={{display:"flex",alignItems:"center",justifyContent:"flex-end"}}>
                <Button
                    onClick={() => setInverterModal(true)}
                    variant="contained"
                    className={styles.buttonAsh_ep}
                  >
                    View Heatmap
                  </Button>
              </div>
            </>
          }
          
        </Grid>
      </Grid>
       <InverterDataHeatmapModal
        open={inverterModal}
        setOpen={setInverterModal}
      />
    </>
  );
};

export default EvalutionPage;
