import React from "react";
import TransectionController, {
  Props,
  configJSON,
} from "./TransectionController.web";
import { TransectionContext } from "./TransectionContext.web";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BarChartOutlinedIcon from "@material-ui/icons/BarChartOutlined";
import KeyboardArrowRightOutlinedIcon from "@material-ui/icons/KeyboardArrowRightOutlined";
import { Typography, IconButton } from "@material-ui/core";
import "../assets/transection.css";
import FilterListIcon from "@material-ui/icons/FilterList";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import DescriptionIcon from "@material-ui/icons/Description";
import TransactionDetailsModal from "../../../components/src/TransactionComponents/TransactionDetailsModal.web";

class Transection extends TransectionController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { transactionModal } = this.state;
    return (
      <TransectionContext.Provider
        value={{
          ...this.state,
          ...this,
        }}
      >
        <div className="transection__container">
          <div className="transection__header__selector">
            <div>
              <BarChartOutlinedIcon style={{ color: "#FDCE32" }} />
            </div>
            <div>
              <KeyboardArrowRightOutlinedIcon style={{ color: "#8F92A1" }} />
            </div>
            <div>
              <h3 style={{ fontSize: "14px" }}>Payments</h3>
            </div>
          </div>
          <div className="transection__body__container">
            <div className="transection__body__heading">
              <div className="transection__body__heading__left">
                <Typography
                  component="h3"
                  variant="h5"
                  style={{ fontWeight: "bolder" }}
                >
                  Payments
                </Typography>
              </div>
              <div className="transection__body__heading__righ">
                <FilterListIcon />
                <Typography component="h6" style={{ marginLeft: "10px" }}>
                  {" "}
                  Sort by: Today
                </Typography>
              </div>
            </div>
            <div className="transection__table__container">
              <table>
                <thead>
                  <tr>
                    <th>
                      <h5 style={{ fontWeight: "bold" }}>CLIENTS</h5>
                    </th>
                    <th>
                      <h5 style={{ fontWeight: "bold" }}>
                        TRANSACTION DETAILS
                      </h5>
                    </th>
                    <th>
                      <h5 style={{ fontWeight: "bold" }}>STATUS</h5>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {[...new Array(6)].map((item, index) => (
                    <tr key={index}>
                      <td>
                        <div>
                          <h2 className="itemTxt">Client Name</h2>
                        </div>
                      </td>
                      <td>
                        <div
                          className="table__item__data__containier"
                          onClick={() => this.changeTransactionOpenModal(true)}
                        >
                          <DescriptionIcon
                            style={{ color: "#6c6c6c", marginRight: "7px" }}
                          />
                          <h2 className="itemTxt">Open</h2>
                        </div>
                      </td>
                      <td style={{ boxSizing: "border-box" }}>
                        {index % 2 === 0 ? (
                          <button className="paid__button">Paid</button>
                        ) : (
                          <button className="unpaid__button">Unpaid</button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="transection__pagination__container">
              <IconButton
                style={{
                  background: "#f1fofo",
                  marginRight: "15px",
                  borderRadius: "18px",
                  padding: "10px 12px"
                }}
              >
                <ArrowBackIosIcon />
              </IconButton>
              <div className="pagination__text__container">
                <Typography variant="h5" component="p" style={{fontSize:"17px"}}>
                  1/10
                </Typography>
              </div>

              <IconButton
                style={{
                  background: "#f9f9fa",
                  marginLeft: "15px",
                  borderRadius: "10px",
                }}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </div>
          </div>
        </div>
        <ToastContainer theme="dark" />
        {transactionModal && (
          <TransactionDetailsModal
            open={transactionModal}
            setOpen={this.changeTransactionOpenModal}
          />
        )}
      </TransectionContext.Provider>
    );
  }
}

export default Transection;
