import React from "react";

import ForgotPasswordController, {
  Props,
} from "./ForgotPasswordController.web";
import { backgroundCoverImage } from "./assets";

import "../assets/ForgotPassword.css";
import { StyleSheet, ImageBackground, Image } from "react-native";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

export default class NewPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    this.userSessionToken = window.location.href.split("/")[
      window.location.href.split("/").length - 1
    ];
  }

  render() {
    const { navigation } = this.props;

    return (
      <Grid
        container
        style={{
          height: "100vh",
        }}
      >
        <ImageBackground source={backgroundCoverImage} style={styles.bgImage}>
          <Button
            onClick={() => navigation.navigate("EmailAccountLoginBlock")}
            style={{
              boxShadow: "none",
              width: "100px",
              height: "40px",
              borderRadius: "15px",
              background: "#f9f9fa",
              textTransform: "capitalize",
              margin: "20px",
            }}
            className="backButton"
            variant="contained"
            startIcon={<ArrowBackIosIcon />}
          >
            Back
          </Button>

          <Box
            sx={{
              width: 360,
              height: 500,
              boxShadow: 1,
            }}
            className="forgot-password-box"
          >
            <Grid
              className="login-box-header"
              container
              direction="row"
              alignItems="center"
            >
              <Grid item>
                <Image
                  source={require("../assets/oriana_power-logo.png")}
                  style={styles.logoImage}
                />
              </Grid>
              <Grid item className="logo-text">
                ORIANA POWER
              </Grid>
            </Grid>

            <Grid className="login-form-header-grid">
              <h2 className="login-form-header">
                {this.labelTextIsAccountRecovery}
              </h2>
              <div className="login-form-sub-header">
                Welcome back, administrator
              </div>
            </Grid>

            <Grid className="login-form-grid">
              <form>
                <InputTextField
                  type="password"
                  label="Create new password"
                  variant="filled"
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                  }}
                  onChange={(e) => this.handleTxtInputNewPassword(e)}
                />

                <InputTextField
                  type="password"
                  label={this.placeholderIsReTypePassword}
                  variant="filled"
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                  }}
                  onChange={(e) => this.handleTxtInputConfirmNewPassword(e)}
                />
              </form>
              <ActionRoundButton
                variant="contained"
                disabled={
                  this.state.newPassword === "" ||
                  this.state.newPassword !== this.state.confirmNewPassword
                }
                onClick={() => this.doResetPassword()}
              >
                Save Password
              </ActionRoundButton>
            </Grid>
          </Box>
        </ImageBackground>
      </Grid>
    );
  }
}

const styles = StyleSheet.create({
  bgImage: {
    width: "100%",
    height: "100%",
  },
  logoImage: {
    height: "32px",
    width: "32px",
  },
});

const ActionRoundButton = withStyles({
  root: {
    background: "#ffc100",
    borderRadius: 20,
    height: 48,
    width: "100%",
    marginTop: 50,
    "&:hover": {
      background: "#ffc100",
    },
  },
  label: {
    textTransform: "capitalize",
  },
})(Button);

const InputTextField = withStyles({
  root: {
    marginBottom: 20,
    "& .MuiFilledInput-root": {
      borderRadius: `15px`,
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "#ffc100",
    },
  },
})(TextField);
