import React from "react";
import { imgImageLoader } from "../../../blocks/Chat9/src/assets";
import GetAppIcon from "@material-ui/icons/GetApp";
import RefreshIcon from "@material-ui/icons/RefreshRounded";
import { IconButton, Paper, Modal } from "@material-ui/core";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { saveAs } from "file-saver";

interface IframeProps extends React.IframeHTMLAttributes<HTMLIFrameElement> { }

const LazyFileViewer = ({ src, title }: IframeProps) => {
    const [fileSrc, setFileSrc] = React.useState(imgImageLoader || src);
    const [viewFileModal, setViewFileModal] = React.useState<boolean>(false);

    const downloadFile = async (url: string) => {
        saveAs(url);
    };

    console.log("<================Lazzy File Rendering==============>");

    return (
        <>
            <span onClick={() => setViewFileModal(true)}>{title}</span>
            {viewFileModal && (
                <div>
                    <Modal
                        style={{
                            height: "100vh",
                            width: "100vw",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                        open={viewFileModal}
                        onClose={() => setViewFileModal(false)}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >
                        <Paper style={{ width: "60%", padding: "20px", borderRadius: "20px" }}>
                            <div style={{ padding: "10px" }}>
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <IconButton onClick={(e) => downloadFile(src as string)}>
                                            <GetAppIcon />
                                        </IconButton>
                                        <IconButton onClick={(e) => setFileSrc(src as string)}>
                                            <RefreshIcon />
                                        </IconButton>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <IconButton onClick={(e) => setViewFileModal(false)}>
                                            <CloseOutlinedIcon />
                                        </IconButton>
                                    </div>
                                </div>

                                <div
                                    style={{
                                        marginTop: "10px",
                                        marginBottom: "15px",
                                        height: "2px",
                                        width: "100%",
                                        background: "#f9f9fa",
                                    }}
                                />
                                <iframe title={title} style={{ height: '50vh', width: '50vw' }} src={fileSrc} />
                            </div>
                        </Paper>
                    </Modal>
                </div>
            )}
        </>
    );
};

export default LazyFileViewer;
