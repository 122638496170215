import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Button, Checkbox, Paper, Typography } from "@material-ui/core";
import { CheckBox } from "@material-ui/icons";
import CheckItem from "./CheckItem.web";
import "./relience.css";
interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function SettingModal({ open, setOpen }: Props) {
  return (
    <div>
      <Modal
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Paper style={{ width: "400px" }}>
          <div style={{ padding: "10px" }}>
            <Typography
              variant="h5"
              style={{ fontWeight: "bolder", padding: "20px" }}
            >
              Performance Overview
            </Typography>

            <CheckItem value="Power [KWP]" />
            <CheckItem value="Production [KWP]" />
            <CheckItem value="Specific Yield" />
            <CheckItem value="Availability" />
            <CheckItem value="Data Input" />
            <CheckItem value="Last Data Input" />
            <CheckItem value="Specific Yield" />
            <CheckItem value="Availability" />
            <Button
              fullWidth
              variant="contained"
              style={{
                background: "#FFCE21",
                marginRight: "10x",
                borderRadius: "10px",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              Done
            </Button>
          </div>
        </Paper>
      </Modal>
    </div>
  );
}
