import {
  Button,
  Grid, Paper, Typography
} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import React from "react";
import { useChatContext } from '../../../blocks/Chat9/src/ChatContext.web';
import { UserType } from "../../../blocks/Chat9/src/Interfaces.web";
import GroupMemberItemWeb from "./GroupMemberItem.web";
import RemoveGroupMembersModal from "./RemoveGroupMembersModal";
interface Props{
    open:boolean,
    setOpen:(open:boolean)=>void;
}
const AddNewChatModal=({open,setOpen}:Props)=> {
  const [selectedMember,setSelectedMember]=React.useState<UserType|null>(null);
  const {
    changeAddGroupMembersModal,
    groupMemberList,
    getGroupMemberLoading,
  }=useChatContext();
  
  return (
    <div>
      <Modal
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Paper
          style={{ width: "550px", padding: "20px", borderRadius: "20px",overflowY:"auto" }}
        >
          <div style={{ padding: "10px" }}>
            <Typography
              variant="h5"
              style={{ fontWeight: 450, fontSize: "22px" }}
            >
              Group Members {getGroupMemberLoading || <span>Total Members: {groupMemberList?.length}</span>}
            </Typography>

            <div
              style={{
                marginTop: "10px",
                marginBottom: "15px",
                height: "2px",
                width: "100%",
                background: "#f9f9fa",
              }}
            />
            <div className="addnewchat__item__wraper">

                {
                  getGroupMemberLoading? <Typography variant="h5">Loading...</Typography>:
                  groupMemberList?.map(user=><GroupMemberItemWeb 
                                                key={user?.id}
                                                user={user}
                                                changeSelectedMember={setSelectedMember}
                                                />)
                }
            </div>
            

            <Grid container spacing={2} style={{ marginTop: "15px" }}>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <Button
                  onClick={() => setOpen(false)}
                  fullWidth
                  variant="contained"
                  style={{
                    background: "#f9f9fa",
                    marginRight: "10x",
                    borderRadius: "15px",
                    marginTop: "10px",
                    marginBottom: "10px",
                    boxShadow: "none",
                    textTransform: "capitalize",
                    fontWeight: 400,
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <Button
                  onClick={()=>changeAddGroupMembersModal(true)}
                  fullWidth
                  variant="contained"
                  style={{
                    background: "#FFCE21",
                    marginRight: "10x",
                    borderRadius: "15px",
                    marginTop: "10px",
                    marginBottom: "10px",
                    boxShadow: "none",
                    textTransform: "capitalize",
                    fontWeight: 400,
                  }}
                >
                   Add More Members
                  
                </Button>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Modal>
      {
        selectedMember && <RemoveGroupMembersModal user={selectedMember}/>
      }
      
    </div>
  );
}


export default React.memo(AddNewChatModal);