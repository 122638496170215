import {
  GoogleMap,
  InfoWindow,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";
import React from "react";
import { ISystem } from "../../../blocks/LandingPage2/src/interfaces.types";
import {Typography} from "@material-ui/core"

const styleForMap: any[] = [
  {
    featureType: "all",
    elementType: "geometry.fill",
    stylers: [
      {
        weight: "2.00",
      },
    ],
  },
  {
    featureType: "all",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#9c9c9c",
      },
    ],
  },
  {
    featureType: "all",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        color: "#f2f2f2",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "landscape.man_made",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "all",
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 45,
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#7b7b7b",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "all",
    stylers: [
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [
      {
        color: "#46bcec",
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#c8d7d4",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#070707",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
];

const mapContainerStyle = {
  height: "100%",
  width: "100%",
};
const options = {
  styles: styleForMap,
  disableDefaultUI: true,
  zoomControl: false,
};
const center = {
  lat: 43.6532,
  lng: -79.3832,
};

interface Props {
  zoom: number;
  systemList: ISystem[];
}

export default function MapWeb({ zoom, systemList }: Props) {
  
  const { isLoaded, loadError } = useLoadScript({
    // googleMapsApiKey: "AIzaSyD5myaBcSgGZOChinjLQsDZrjn0MBePg_0",
    googleMapsApiKey: "AIzaSyDUmjLxl_qEb35uInhGq4ncy_a5pz2YYxA",
  });
  const [selected, setSelected] = React.useState<ISystem | null>();
  const [_, setMap] = React.useState(null);

  const onMapLoad = React.useCallback((map: any) => {
    setMap(map);
  }, []);


  if (loadError) return <div>Error</div>;
  if (!isLoaded) return <div>Loading...</div>;

  return (
    <GoogleMap
      id="map"
      mapContainerStyle={mapContainerStyle}
      zoom={zoom}
      center={systemList?.length>0?{lat:systemList[0].attributes.latitude,lng:systemList[0].attributes.longitude}:center}
      options={options}
      onLoad={onMapLoad}
    >
      {systemList?.length>0&&systemList.map((system: ISystem) => (
        <Marker
          key={`${system.attributes.latitude}-${system.attributes.longitude}`}
          position={{
            lat: system.attributes.latitude,
            lng: system.attributes.longitude,
          }}
          onClick={() => {
            setSelected(system);
          }}
          icon={{
            url: `/pin.png`,
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(15, 15),
            scaledSize: new window.google.maps.Size(30, 30),
          }}
        />
      ))}

      {selected ? (
        <InfoWindow
          position={{
            lat: selected.attributes.latitude,
            lng: selected.attributes.longitude,
          }}
          onCloseClick={() => {
            setSelected(null);
          }}
        >
          <div>
            <Typography component="h1" variant="h5">
              {selected?.attributes?.designation}
            </Typography>
            <p>
               {selected?.attributes?.country}, <br/>
               {selected?.attributes?.postal_code},
               {selected?.attributes?.street}, <br/>
            </p>
          </div>
        </InfoWindow>
      ) : null}
    </GoogleMap>
  );
}
