import React from "react";

import { IconButton, Typography, Button } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import FilterListOutlinedIcon from "@material-ui/icons/FilterListOutlined";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import EditIcon from '@material-ui/icons/Edit';
import AddCircleOutlineSharpIcon from '@material-ui/icons/AddCircleOutlineSharp';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ClientUserListModal from "./ClientUserListModal";
import {useNetworkContext} from '../../../blocks/AdminConsole3/src/Network/NetworkContext.web'
import TableLoader from "../Util/TableLoader.web";
import AddNewClientModal from "./AddNewClientModal.web";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: "4px 0px",
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    ashIconButton:{
            width:"40px",
            height:"40px",
            background:"#f9f9fa",
            borderRadius:"15px"
    },
    capitalTextTransform: {
      textTransform: "capitalize"
    }
  }),
);

interface Props {
  clientErrors:any;
  userCreateErrors:any;
}

const ClientListTable = ({clientErrors,userCreateErrors}:Props) => {
  const classes = useStyles();
  const[numOfItems,_]=React.useState(6);
  const[selectedClient,setSelectedClient]=React.useState<object>({});
 
  const {
    clientPage:page,
    getClients,
    clientsData:clientLists,
    getClientsLoading:loading,
    openClientSystems,
    addNewClientSystem:addNewSystem,
    deleteClient:deleteClientAction,
    updateClientDialog:updateDialog,
    changeClientUpdateModal:changeUpdateDialog,
    getClientUser,
    clientUsersDialog:clientUserModal,
    changeClientUpdateModal:setOpen,
    setClientLoading:updateClientLoading,
    updateClients
  }=useNetworkContext();

  const changePage = (pageNumber: number) => {
      getClients(pageNumber)
  };
  const changeUpdateModal=(ob:object)=>{
    setSelectedClient(ob);
    changeUpdateDialog(true);
  }
  const openClientUserListModal=(ob:any)=>{
    setSelectedClient(ob);
    getClientUser(ob?.id)
  }


    
  return (
    <>
    <div className="userListTableContainer">
         <div className="client__table__wrapper">
            <table>
                  <thead>
                      <tr>
                           <th> <h5 style={{fontWeight:"bold"}}>CLIENTS</h5></th>  
                           <th>
                              <div style={{display:"flex",alignItems:"center"}}>
                                  <div>
                                      <h5 style={{fontWeight:"bold"}}>SYSTEMS</h5>
                                  </div>
                                  <div  style={{marginTop:"5px",marginLeft:"7px"}}>   
                                      <FilterListOutlinedIcon style={{ color: "#8F92A1" }} />
                                  </div>
                              </div>
                            </th>  
                            <th>
                              <div style={{display:"flex",alignItems:"center"}}>
                                  <div>
                                        <h5 style={{fontWeight:"bold"}}>TOTAL USERS</h5>
                                  </div>
                                  <div  style={{marginTop:"5px",marginLeft:"7px"}}>   
                                      <FilterListOutlinedIcon style={{ color: "#8F92A1" }} />
                                  </div>
                              </div>
                            </th>
                            <th>
                                 <div style={{display:"flex",alignItems:"center"}}>
                                    <div>
                                          <h5 style={{fontWeight:"bold"}}>ADD NEW SYSTEM</h5>
                                    </div>
                                    <div  style={{marginTop:"5px",marginLeft:"7px"}}>   
                                        <FilterListOutlinedIcon style={{ color: "#8F92A1" }} />
                                    </div>
                                </div>
                            </th>
                            <th>
                              <div style={{display:"flex",alignItems:"center"}}>
                                  <div>
                                        <h5 style={{fontWeight:"bold"}}>ACTIONS</h5>
                                  </div>
                                  <div  style={{marginTop:"5px",marginLeft:"7px"}}>   
                                      <FilterListOutlinedIcon style={{ color: "#8F92A1" }} />
                                  </div>
                             </div>
                            </th>
                       </tr>
                   </thead>
                   <tbody>
                          {
                            loading?
                            <TableLoader count={numOfItems} column={5}/>:
                            clientLists?.response?.data?.slice((page-1) * numOfItems, (page-1) * numOfItems + numOfItems)
                            .map((client,index)=>(
                                <tr key={index}>
                                  <td>
                                     <div>
                                         <Typography component="h5" className="itemTxt">{client?.attributes?.name}</Typography>
                                      </div>
                                    </td>
                                    <td>
                                        <div>
                                          <Typography component="h5" className="itemTxt">{client?.attributes?.systems} Plants
                                          <ArrowDropDownIcon style={{verticalAlign: "middle", marginLeft: "15px", color: "#8F92A1"}}
                                          onClick={() => openClientSystems(client.id)}
                                          />
                                          </Typography>
                                      </div>
                                    </td>
                                    <td>
                                         <div>
                                            <Typography component="h2" className="itemTxt"> {client?.attributes?.users} 
                                             <EditIcon onClick={()=>openClientUserListModal(client)} style={{verticalAlign: "middle", marginLeft: "15px", color: "#8F92A1"}}/>
                                            </Typography>
                                          </div>
                                    </td>
                                    <td>
                                        <div>
                                            <Button size="small" className={`${classes.margin} ${classes.capitalTextTransform}`}
                                            startIcon={<AddCircleOutlineSharpIcon style={{color: "#8F92A1"}}
                                            />} 
                                            onClick={() => addNewSystem(client.id)}>
                                                Add New System
                                            </Button>
                                        </div>
                                    </td>  
                                    <td>
                                        <div>
                                            <div style={{display:"flex",alignItems:"center"}}>
                                                <IconButton onClick={()=>changeUpdateModal(client)} style={{marginLeft:"10px",padding:"0"}}><EditOutlinedIcon/></IconButton>
                                                <IconButton style={{margin:"0",padding:"0"}} onClick={() => deleteClientAction(client.id)}><DeleteOutlineOutlinedIcon/></IconButton>
                                            </div>
                                        </div>
                                      </td>


                                </tr>




                              ))
                          }   
                    </tbody>
              
            </table>
        </div>
    </div>

    <div className="tablePagination">
            <IconButton
             className={classes.ashIconButton}
                onClick={() => {
                    if (page> 1) {
                       loading || changePage(page - 1);
                    }
                }}
                aria-label="delete"
            >
                <ArrowBackIosIcon />
            </IconButton>
          <div className="paginationContent">
            <h2 style={{fontSize:"14px"}}>
              {page }/{Math.ceil(clientLists.length / numOfItems)}
            </h2>
          </div>
           <IconButton
             className={classes.ashIconButton}
                onClick={() => {
                    if (
                        page<
                        Math.ceil(clientLists.length / numOfItems)
                    ) {
                       loading || changePage(page + 1);
                    }
                    }}
                aria-label="delete">
                <ArrowForwardIosIcon />
            </IconButton>
    </div>
    {
      updateDialog &&
        <AddNewClientModal
           edit={true}
           open={updateDialog}
           setOpen={setOpen}
           loading={updateClientLoading}
           actionHandler={updateClients}
           client={selectedClient}
           newErrors={clientErrors}
           />
    }
    {
      clientUserModal && <ClientUserListModal client={selectedClient} userCreateErrors={userCreateErrors}/>
    }
    </>
    

    
  );
};

export default ClientListTable;
