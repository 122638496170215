import React from "react";
import { ToastContainer } from "react-toastify";
import { Typography, IconButton, Button } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import "../../assets/report.css";
import AddIcon from "@material-ui/icons/Add";
import SystemUpdateAltOutlinedIcon from "@material-ui/icons/SystemUpdateAltOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import MailOutlineOutlinedIcon from "@material-ui/icons/MailOutlineOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import {
  makeStyles,
  Theme,
  useTheme,
  withStyles,
} from "@material-ui/core/styles";
import PortfolioReportController, {
  Props,
  configJSON,
} from "./PortfolioReportController.web";
import GeneratePortfolioReportModal from "../../../../components/src/ReportsComponents/GeneratePortfolioReportModal.web";
import TableLoader from "../../../../components/src/Util/TableLoader.web";
import ActionModal from "../../../../components/src/Util/ActionModal";
const styles = (theme: Theme) => ({
  action__buttons__item: {
    display: "flex",
    alignItems: "center",
  },
});

class PortfolioReport extends PortfolioReportController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    const { generatePortfolioReportModal, loading,reportList,currentPage,perPage,deleteLoading,deleteModal} = this.state;
    const {response,length:dataLength}=reportList || {};
    const {data}=response ||{};
    return (
      <>
        <div className="report__body__heading">
          <div className="report__body__heading__left">
            <Typography
              component="h3"
              variant="h5"
              style={{ fontWeight: "bolder" }}
            >
              Portfolio Reports
            </Typography>
          </div>
          <Button
            onClick={this.goToNewPortfolioReportPage}
            variant="contained"
            startIcon={<AddIcon />}
            style={{
              background: "#FFCE21",
              marginRight: "10x",
              borderRadius: "15px",
              marginTop: "10px",
              marginBottom: "10px",
              boxShadow: "none",
              textTransform: "capitalize",
              fontWeight: 400,
            }}
          >
            Create a portfolio report
          </Button>
        </div>
        <div className="report__container">
          <div className="report__body__container">
            <div className="report__table__container">
              <table>
                <thead>
                  <tr>
                    <th style={{ minWidth: "120px" }}>
                      <h5 style={{ fontWeight: "bold" }}>NAME</h5>
                    </th>
                    <th>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <h5 style={{ fontWeight: "bold" }}>DESCRIPTION</h5>
                        <IconButton>
                          <FilterListIcon />
                        </IconButton>
                      </div>
                    </th>
                    <th>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <h5 style={{ fontWeight: "bold" }}>TRANSMISSION</h5>
                        <IconButton>
                          <FilterListIcon />
                        </IconButton>
                      </div>
                    </th>
                    <th>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <h5 style={{ fontWeight: "bold" }}>LAST CHANGE</h5>
                        <IconButton>
                          <FilterListIcon />
                        </IconButton>
                      </div>
                    </th>
                    <th style={{ paddingLeft: "15p x" }}>
                      <h5 style={{ fontWeight: "bold" }}>ACTIONS</h5>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <TableLoader count={6} column={5} />
                  ) : (
                    data?.slice((currentPage-1) * perPage, (currentPage-1) * perPage + perPage)
                    .map((item, index) => (
                      <tr key={item?.id}>
                        <td>
                          <div>
                            <h2 className="itemTxt">{item?.attributes?.name}</h2>
                          </div>
                        </td>
                        <td>
                          <h2 className="itemTxt">{item?.attributes?.description}</h2>
                        </td>
                        <td>
                          <h2 className="itemTxt">__</h2>
                        </td>
                        <td>
                          <h2 className="itemTxt">
                             {new Date(item?.attributes?.updated_at).toISOString()}
                          </h2>
                        </td>
                        <td>
                          <div className={classes.action__buttons__item}>
                            <IconButton
                              onClick={() =>
                                this.changeGeneratePortfolioReport(true)
                              }
                            >
                              <SystemUpdateAltOutlinedIcon />
                            </IconButton>
                            <IconButton onClick={()=>this.openDeleteModal(item.id)}>
                              <DeleteOutlineOutlinedIcon />
                            </IconButton>
                            <IconButton>
                              <MailOutlineOutlinedIcon />
                            </IconButton>
                            <IconButton
                              onClick={()=>this.editPortfolioReport(item?.id)}
                             >
                              <svg
                                width="40px"
                                height="40px"
                                viewBox="0 0 40 40"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g
                                  id="Final-Designs"
                                  stroke="none"
                                  stroke-width="1"
                                  fill="none"
                                  fill-rule="evenodd"
                                >
                                  <g
                                    id="Mobile---Profile"
                                    transform="translate(-319.000000, -70.000000)"
                                  >
                                    <g
                                      id="Edit_2"
                                      transform="translate(319.000000, 70.000000)"
                                    >
                                      <rect
                                        id="🎨-Base"
                                        fill="#8F92A1"
                                        opacity="0.100000001"
                                        x="0"
                                        y="0"
                                        width="40"
                                        height="40"
                                        rx="16"
                                      />
                                      <g
                                        id="💚-Icon"
                                        transform="translate(10.000000, 10.000000)"
                                        fill="#171717"
                                      >
                                        <path
                                          d="M19,10.0057983 C18.4477153,10.0057983 18,10.4535136 18,11.0057983 L18,17.0057983 C18,17.5580831 17.5522847,18.0057983 17,18.0057983 L3,18.0057983 C2.44771525,18.0057983 2,17.5580831 2,17.0057983 L2,3.00579832 C2,2.45351357 2.44771525,2.00579832 3,2.00579832 L9,2.00579832 C9.55228475,2.00579832 10,1.55808307 10,1.00579832 C10,0.453513575 9.55228475,0.00579832434 9,0.00579832434 L3,0.00579832434 C1.34314575,0.00579832434 -1.77635684e-15,1.34894407 -1.77635684e-15,3.00579832 L-1.77635684e-15,17.0057983 C-1.77635684e-15,18.6626526 1.34314575,20.0057983 3,20.0057983 L17,20.0057983 C18.6568542,20.0057983 20,18.6626526 20,17.0057983 L20,11.0057983 C20,10.4535136 19.5522847,10.0057983 19,10.0057983 Z M3.99998326,10.7657983 L3.99998326,15.0057983 C3.99998326,15.5580831 4.44771525,16.0058151 5,16.0058151 L9.24,16.0058151 C9.50580298,16.0073351 9.76128117,15.9029848 9.95,15.7157983 L16.87,8.78579832 L16.87,8.78579832 L19.71,6.00579832 C19.8993127,5.81803172 20.0057983,5.56243586 20.0057983,5.29579832 C20.0057983,5.02916079 19.8993127,4.77356492 19.71,4.58579832 L15.47,0.295798324 C15.2822334,0.106485674 15.0266375,0 14.76,0 C14.4933625,0 14.2377666,0.106485674 14.05,0.295798324 L11.23,3.12579832 L11.23,3.12579832 L4.29,10.0557983 C4.10281349,10.2445172 3.99846324,10.5 3.99998326,10.7657983 Z M14.76,2.41579832 L17.59,5.24579832 L16.17,6.66579832 L13.34,3.83579832 L14.76,2.41579832 Z M6,11.1757983 L11.93,5.24579832 L14.76,8.07579832 L8.83,14.0057983 L6,14.0057983 L6,11.1757983 Z"
                                          id="Shape"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </IconButton>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
            <div
              className="report__pagination__container"
              style={{ marginRight: "10px", marginBottom: "15px" }}
            >
              <IconButton
                onClick={this.fetchPrevReports}
                style={{
                  background: "#f9f9fa",
                  marginRight: "15px",
                  borderRadius: "10px",
                }}
              >
                <ArrowBackIosIcon />
              </IconButton>
              <div className="pagination__text__container">
                <Typography
                  variant="h5"
                  component="p"
                  style={{ fontSize: "17px" }}
                >
                   {currentPage }/{Math.ceil(dataLength / perPage)}
                </Typography>
              </div>

              <IconButton
                onClick={this.fetchNextReports}
                style={{
                  background: "#f9f9fa",
                  marginLeft: "15px",
                  borderRadius: "10px",
                }}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </div>
          </div>
        </div>
        {generatePortfolioReportModal && (
          <GeneratePortfolioReportModal
            open={generatePortfolioReportModal}
            setOpen={this.changeGeneratePortfolioReport}
          />
        )}
        <ToastContainer theme="dark" />
        <ActionModal
          title="Delete Portfolio Report"
          message="Are You Sure You Want To Sure Delete This Reports?"
          open={deleteModal}
          setOpen={this.changeDeleteModal}
          actionHandler={this.deleteReports}
          actionLoading={deleteLoading}
          actionLoadingText="Deleting Report.."
        />
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(PortfolioReport);
