import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import FilterListIcon from '@material-ui/icons/FilterList';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {Typography,IconButton} from "@material-ui/core"
import { useChatContext } from '../../../blocks/Chat9/src/ChatContext.web';
import DeleteConversationsModal from './DeleteConversationsModal.web';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));
const ChatActions=() =>{
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [searchByDateModal, setSearchByDateModal] = React.useState<boolean>(false);
  const {
    changeDeleteConverstaionModal,
    currentChat,
    changeDeleteGroupModal,
    changeDeleteChatRoomModal,
    changeGroupMembersModal}=useChatContext();


  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
       <IconButton
           aria-controls="customized-menu"
           aria-haspopup="true"
        onClick={handleClick}
       >
                <MoreVertIcon/>
       </IconButton>
     
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{marginRight:"50px"}}
      >
         {
          currentChat?.type==="direct" && 
          <>
            <MenuItem>
                <div 
                onClick={()=>{
                  setAnchorEl(null)
                  changeDeleteChatRoomModal(true)
                }}
                  style={{width:"100%",display:"flex",justifyContent:"space-between"}}>
                    <div>Delete</div>
                  </div>
              </MenuItem>
             
          </>
        }
        <MenuItem>
          <div 
          onClick={()=>{
            setAnchorEl(null)
            changeDeleteConverstaionModal(true)
          }}
            style={{width:"100%",display:"flex",justifyContent:"space-between"}}>
              <div>Delete Conversations</div>
            </div>
        </MenuItem>
        {
          currentChat?.type==="group" && 
          <>
            <MenuItem>
                <div 
                onClick={()=>{
                  setAnchorEl(null)
                  changeGroupMembersModal(true)
                }}
                  style={{width:"100%",display:"flex",justifyContent:"space-between"}}>
                    <div>Group Members</div>
                  </div>
              </MenuItem>
              <MenuItem>
                <div 
                  onClick={()=>{
                    setAnchorEl(null)
                    changeDeleteGroupModal(true)
                  }}
                  style={{width:"100%",display:"flex",justifyContent:"space-between"}}>
                    <div>Delete Group</div>
                  </div>
              </MenuItem>
          </>
        }
        
      </StyledMenu>
      <DeleteConversationsModal/>
    </div>
  );
}


export default React.memo(ChatActions);