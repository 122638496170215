import {
  Button,
  createStyles,
  makeStyles,
  Paper,
  Select,
  Theme,
  Typography,
  MenuItem,
  Grid,
  Checkbox,
} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dividerStyle: {
      marginTop: "10px",
      marginBottom: "15px",
      height: "2px",
      width: "100%",
      background: "#f9f9fa",
    },
    ashBoldText: {
      color: "#8F92A1",
      fontSize: "15px",
      marginTop: "10px",
      marginBottom: "10px",
      fontWeight: "bolder",
    },
    selectContainer: {
      border: "none",
      fontSize: "1.2rem",
      fontWeight: "bolder",
      color: "black",
      "&:hover": {
        borderBottom: "0px",
      },
      "&::before": {
        borderBottom: "0px !important",
      },
      "&::after": {
        borderBottom: "0px !important",
      },
      "&$selected": {
        borderBottom: "0px !important",
      },
    },
    blackBoldText: {
      fontSize: "1.2rem",
      fontWeight: "bolder",
      color: "black",
    },
    row: {
      display: "flex",
      alignItems: "center",
      gap: "15px",
    },
    ashButton: {
      background: "#f9f9fa",
      borderRadius: "15px",
      boxShadow: "none",
      textTransform: "capitalize",
      fontWeight: 400,
      color: "black",
    },
    yellowButton: {
      background: "#FFCE21",
      borderRadius: "15px",
      boxShadow: "none",
      textTransform: "capitalize",
      fontWeight: 400,
      color: "black",
    },
  })
);

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function NewCustomAlarmModal({ open, setOpen }: Props) {
  const styles = useStyles();
  return (
    <div>
      <Modal
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Paper
          style={{
            width: "680px",
            padding: "20px",
            borderRadius: "20px",
            minHeight: "40vh",
          }}
        >
          <div
            style={{
              padding: "10px",
              height: "90vh",
              overflowY: "auto",
              overflowX: "hidden",
              maxHeight: "fit-content",
            }}
          >
            <Typography
              variant="h5"
              style={{ fontWeight: 600, fontSize: "22px" }}
            >
              New Custom Alarm
            </Typography>

            <div className={styles.dividerStyle} />

            <Typography
              style={{ fontSize: ".85rem" }}
              component="h5"
              className={styles.ashBoldText}
            >
              Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              Voluptates porro animi molestiae fugit aut non eaque pariatur
              dignissimos odit ullam ad, quis provident eos placeat quod, quo
              mollitia error ipsum!
            </Typography>

            <div className={styles.dividerStyle} />

            <div className="transection__details__modal__body__container">
              <Typography component="h5" className={styles.ashBoldText}>
                Type
              </Typography>
              <Select
                className={styles.selectContainer}
                fullWidth
                labelId="demo-simple-select-label"
                id="select"
                value="lorem ipsum sit ammited a................"
                //   onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                //     setSelectedSystem(event.target.value as string)
                //   }
              >
                <MenuItem value="lorem ipsum sit ammited a................">
                  lorem ipsum sit ammited a................
                </MenuItem>
                <MenuItem value="lorem ipsum sit ammited a................">
                  lorem ipsum sit ammited a................
                </MenuItem>
                <MenuItem value="lorem ipsum sit ammited a................">
                  lorem ipsum sit ammited a................
                </MenuItem>
                <MenuItem value="lorem ipsum sit ammited a................">
                  lorem ipsum sit ammited a................
                </MenuItem>
              </Select>
              <div className={styles.dividerStyle} />
              <Typography component="h5" className={styles.ashBoldText}>
                Value
              </Typography>
              <Select
                className={styles.selectContainer}
                fullWidth
                labelId="demo-simple-select-label"
                id="select"
                value="lorem ipsum sit ammited a................"
                //   onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                //     setSelectedSystem(event.target.value as string)
                //   }
              >
                <MenuItem value="lorem ipsum sit ammited a................">
                  lorem ipsum sit ammited a................
                </MenuItem>
                <MenuItem value="lorem ipsum sit ammited a................">
                  lorem ipsum sit ammited a................
                </MenuItem>
                <MenuItem value="lorem ipsum sit ammited a................">
                  lorem ipsum sit ammited a................
                </MenuItem>
                <MenuItem value="lorem ipsum sit ammited a................">
                  lorem ipsum sit ammited a................
                </MenuItem>
              </Select>
              <div className={styles.dividerStyle} />
              <Typography component="h5" className={styles.ashBoldText}>
                Trigger alarm if the value is
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                  <Select
                    className={styles.selectContainer}
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="select"
                    value="lorem ipsum sit ammited a................"
                    //   onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                    //     setSelectedSystem(event.target.value as string)
                    //   }
                  >
                    <MenuItem value="lorem ipsum sit ammited a................">
                      lorem ipsum sit ammited a................
                    </MenuItem>
                    <MenuItem value="lorem ipsum sit ammited a................">
                      lorem ipsum sit ammited a................
                    </MenuItem>
                    <MenuItem value="lorem ipsum sit ammited a................">
                      lorem ipsum sit ammited a................
                    </MenuItem>
                    <MenuItem value="lorem ipsum sit ammited a................">
                      lorem ipsum sit ammited a................
                    </MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Typography component="h5" className={styles.blackBoldText}>
                    500
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                  <Typography component="h5" className={styles.blackBoldText}>
                    kWh
                  </Typography>
                </Grid>
              </Grid>
              <div className={styles.dividerStyle} />

              <Typography component="h5" className={styles.ashBoldText}>
                Ignore missing values
              </Typography>

              <div className={styles.row}>
                <Checkbox
                  // checked={systems?.all}
                  // onChange={handleChecked}
                  color="primary"
                />
                <Select className={styles.selectContainer} value="Enabled">
                  <MenuItem value="Enabled">Enabled</MenuItem>
                  <MenuItem value="Disabled">Disabled</MenuItem>
                </Select>
              </div>
              <Typography component="h5" className={styles.ashBoldText}>
                Only take account of daily values
              </Typography>
              <div className={styles.row}>
                <Checkbox
                  // checked={systems?.all}
                  // onChange={handleChecked}
                  color="primary"
                />
                <Select className={styles.selectContainer} value="Enabled">
                  <MenuItem value="Enabled">Enabled</MenuItem>
                  <MenuItem value="Disabled">Disabled</MenuItem>
                </Select>
              </div>

              <Typography component="h5" className={styles.ashBoldText}>
                Trigger alarm if the value is
              </Typography>

              <div className={styles.row}>
                <Typography component="h5" className={styles.blackBoldText}>
                  500
                </Typography>
                <Typography component="h5" className={styles.blackBoldText}>
                  minutes
                </Typography>
              </div>
              <div className={styles.dividerStyle} />
            </div>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Button
                  onClick={() => setOpen(false)}
                  fullWidth
                  variant="contained"
                  className={styles.ashButton}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Button
                  onClick={() => setOpen(false)}
                  fullWidth
                  variant="contained"
                  className={styles.yellowButton}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Modal>
    </div>
  );
}
