import {
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import React from "react";
import EditorSelectItem from './EditorSelectItem.web';
import PrivilegeItem from './PrivilegeItem.web'
import { INewUserGroup } from "../../../blocks/AdminConsole3/src/user/UserController.web";
import CircularProgress from "@material-ui/core/CircularProgress";
const useStyles=makeStyles({
   inputPlaceholder:{
     color:"#8F92A1",
     fontWeight:"bolder"
   },
   headingText:{
     fontWeight:"bolder",
     fontSize:"22px"

   },
   divBorder:{
    marginTop: "10px",
    marginBottom: "15px",
    height: "2px",
    width: "100%",
    background: "#f9f9fa",
   },
   text:{
     fontWeight:"bold",
     color:"#8F92A1",
     fontSize:"14px"
   }
 
})

interface Props{
    edit?:boolean;
    open:boolean;
    setOpen:(open:boolean)=>void;
    editorList:any[];
    getEditorLoading:boolean;
    actionLoading:boolean;
    newGroup:INewUserGroup;
    changeGroupName:(name:string)=>void;
    changeGroupDesc:(desc:string)=>void;
    changeRootPrivileges:(id:number,allow:boolean)=>void;
    chngeSubPrivileges:(id:number,key:string,value:string)=>void;
    changeEditors:(editor:string)=>void;
    newErrors:any;
    actionHandler:()=>void;
}


export default function CreateNewUserGroup({
    edit,
    open,
    setOpen,
    editorList,
    getEditorLoading,
    actionLoading,
    changeGroupName,
    changeGroupDesc,
    changeRootPrivileges,
    newGroup,
    chngeSubPrivileges,
    changeEditors,
    newErrors,
    actionHandler
}:Props) {

  const [errors,setErrors]=React.useState<any>({});
  const styles=useStyles();


  React.useEffect(()=>{
      setErrors(newErrors)
  },[newErrors])

  const getButtonText=()=>{
     if(edit){
       return "Update"
     }
     return "Save"
  }

  return (
    <div>
      <Modal
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Paper
          style={{ width: "780px", padding: "20px", borderRadius: "20px" }}
        >
          <div style={{ padding: "10px",height:"85vh",overflow:"auto" }}>
            <Typography
              variant="h5"
              style={{ fontWeight: 600, fontSize: "22px" }}
            >
              {edit?"Update User Group":"Create User Group"}
            </Typography>
              
            <div className={styles.divBorder}
            ></div>

            <TextField 
                    onChange={(e)=>changeGroupName(e.target.value)}
                    value={newGroup.groupName}
                    fullWidth id="standard-basic"
                    label={<span className={styles.inputPlaceholder}>User Groups</span>}
                    error={errors?.userGroups===undefined || errors.userGroups===""?false:true}
                    helperText={errors?.userGroups}
               />
                <br/>
                <br/>
             <TextField 
                    onChange={(e)=>changeGroupDesc(e.target.value)}
                    value={newGroup.groupDescription}
                    fullWidth id="standard-basic"
                    label={<span className={styles.inputPlaceholder}>Description</span>}
                     error={errors?.description===undefined || errors.description===""?false:true}
                    helperText={errors?.description}
                
                />
                <br/>
                <br/>
            <Typography
              variant="h5"
              style={{ fontWeight: 600, fontSize: "22px" }}
             >
              Editors
            </Typography>
            {
              errors?.editors && <p style={{color:"red"}}>{errors?.editors}</p>
            }
              <div className={styles.divBorder}
            ></div>
            {
              getEditorLoading?<Typography variant="h6">Loading Editors..</Typography>:
              editorList?.map((editor:any,index)=>(
                <EditorSelectItem value={editor} key={editor?.id} changeEditors={changeEditors} editors={newGroup.editors}/>
              ))
            }
            <br/>
            <br/>
            <Typography
              variant="h5"
              style={{ fontWeight: 600, fontSize: "22px" }}
             >
              Modules and Privileges
            </Typography>

             <div className={styles.divBorder}
            ></div>
            <Grid container spacing={2} style={{ marginTop: "15px" }}>
              <Grid item xs={6} sm={4} md={4} xl={4}>
                  <Typography variant="h5" className={styles.text}>Modules</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={2} xl={2}>
                    <Typography variant="h5" className={styles.text}>Privileges</Typography>
             
              </Grid>
            </Grid>
            <div className={styles.divBorder}
            ></div>
            
             {
               newGroup.privileges?.map((privilege,index)=>(
                <React.Fragment key={privilege?.id}>
                  <PrivilegeItem  privilege={privilege} changeRootPrivileges={changeRootPrivileges} chngeSubPrivileges={chngeSubPrivileges}/>
                  <div className={styles.divBorder}
                  ></div>
                </React.Fragment>
               ))
            }

            <div className={styles.divBorder}
            ></div>
            <Grid container spacing={2} style={{ marginTop: "15px" }}>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <Button
                  onClick={() => setOpen(false)}
                  fullWidth
                  variant="contained"
                  style={{
                    background: "#f9f9fa",
                    marginRight: "10x",
                    borderRadius: "15px",
                    marginTop: "10px",
                    marginBottom: "10px",
                    boxShadow: "none",
                    textTransform: "capitalize",
                    fontWeight: 400,
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <Button
                  onClick={actionHandler}
                  disabled={actionLoading}
                  fullWidth
                  variant="contained"
                  style={{
                    background: "#FFCE21",
                    marginRight: "10x",
                    borderRadius: "15px",
                    marginTop: "10px",
                    marginBottom: "10px",
                    boxShadow: "none",
                    textTransform: "capitalize",
                    fontWeight: 400,
                  }}
                >
                  {actionLoading?<CircularProgress size={24} />:getButtonText()}
                </Button>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Modal>
    </div>
  );
}
