import React from "react";

import ForgotPasswordController, {
  Props,
} from "./NewForgotPasswordController.web";
import { backgroundCoverImage,imgPasswordInVisible, imgPasswordVisible } from "./assets";

import "../assets/ForgotPassword.css";
import { StyleSheet, ImageBackground, Image } from "react-native";
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ToastContainer } from "react-toastify";
import IconButton from "@material-ui/core/IconButton";
// Customizable Area Start
// Customizable Area End
export default class NewForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    this.userSessionToken = window.location.href.split("/")[
      window.location.href.split("/").length - 1
    ];
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    const { navigation } = this.props;
    const {errors,loading,newPassword,confirmNewPassword,showNewPassword,showConfirmNewPassword}=this.state;
    const device =this.props.navigation.getParam("device")
    return (
      <Grid
        container
        style={{
          height: "100vh",
        }}
      >
        <ImageBackground source={backgroundCoverImage} style={styles.bgImage}>
          {
            device!=="mb" &&  
             <Button
                onClick={() => navigation.navigate("EmailAccountLoginBlock")}
                style={{
                  boxShadow: "none",
                  width: "100px",
                  height: "40px",
                  borderRadius: "15px",
                  background: "#f9f9fa",
                  textTransform: "capitalize",
                  margin: "20px",
                }}
                className="backButton"
                variant="contained"
                startIcon={<ArrowBackIosIcon />}
              >
                Back
            </Button>
          }
        

          <Box
            sx={{
              width: 360,
              boxShadow: 1,
            }}
            className="forgot-password-box"
          >
            <Grid
              className="login-box-header"
              container
              direction="row"
              alignItems="center"
            >
              <Grid item>
                <Image
                  source={require("../assets/oriana_power-logo.png")}
                  style={styles.logoImage}
                />
              </Grid>
              <Grid item className="logo-text">
                ORIANA POWER
              </Grid>
            </Grid>

            <Grid className="login-form-header-grid">
              <h2 className="login-form-header">
                {this.labelTextIsAccountRecovery}
              </h2>
              <div className="login-form-sub-header">
                Welcome back, administrator
              </div>
            </Grid>

            <Grid className="login-form-grid">
              <form>
                <InputTextField
                  error={errors?.newPassword?true:false}
                  helperText={errors?.newPassword}
                  type={showNewPassword ? "text" : "password"}
                  label="Create new password"
                  variant="filled"
                  fullWidth
                  onChange={(e) => this.handleTxtInputNewPassword(e)}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          onClick={() => this.handleNewPasswordShow()}
                        >
                          <Image
                            style={{ height: 30, width: 30 }}
                            source={showNewPassword?imgPasswordInVisible:imgPasswordVisible}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              <InputTextField
                  error={errors?.confirmPassword?true:false}
                  helperText={errors?.confirmPassword}
                  type={showConfirmNewPassword ? "text" : "password"}
                  label="Re-enter new password"
                  variant="filled"
                  fullWidth
                  value={confirmNewPassword}
                  onChange={(e) => this.handleTxtInputConfirmNewPassword(e)}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          onClick={() => this.handleConfirmNewPasswordShow()}
                        >
                          <Image
                            style={{ height: 30, width: 30 }}
                            source={showConfirmNewPassword?imgPasswordInVisible:imgPasswordVisible}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </form>
              <ActionRoundButton
                variant="contained"
                disabled={
                  this.state.newPassword === "" ||
                  this.state.newPassword !== this.state.confirmNewPassword
                }
                onClick={() => this.doResetPassword()}
              >
                {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Save Password"
                  )}
                
              </ActionRoundButton>
            </Grid>
          </Box>
        </ImageBackground>
        <ToastContainer theme="dark" />
      </Grid>
    );
  }
}

const styles = StyleSheet.create({
  bgImage: {
    width: "100%",
    height: "100%",
  },
  logoImage: {
    height: "32px",
    width: "32px",
  },
});

const ActionRoundButton = withStyles({
  root: {
    background: "#ffc100",
    borderRadius: 20,
    height: 48,
    width: "100%",
    marginTop: 50,
    marginBottom:40,
    "&:hover": {
      background: "#ffc100",
    },
  },
  label: {
    textTransform: "capitalize",
  },
})(Button);

const InputTextField = withStyles({
  root: {
    marginBottom: 20,
    "& .MuiFilledInput-root": {
      borderRadius: `15px`,
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "#ffc100",
    },
  },
})(TextField);
// Customizable Area Start
 // Customizable Area End