import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
import { AuthContext } from "../../email-account-login/src/AuthContext/AuthContext.web";

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  transactionModal: boolean;
}

interface SS {
  id: any;
}

export default class TransectionController extends BlockComponent<
  Props,
  S,
  SS
> {
  static contextType: any = AuthContext;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      transactionModal: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: String, message: Message) {}

  async componentDidMount() {}

  changeTransactionOpenModal = (open: boolean) => {
    this.setState({
      transactionModal: open,
    });
  };
}
