import React from "react";

import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
} from "@material-ui/core";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import { useNetworkContext } from "../../../blocks/AdminConsole3/src/Network/NetworkContext.web";
import ActionModal from "../Util/ActionModal";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    systemName: {
      color: "black",
      fontWeight: "bold",
      fontSize: "14px",
    },
  })
);

interface Props {
  clientId: any;
}

const ClientsSystemList = ({ clientId }: Props) => {
  const classes = useStyles();
  const {
    clientSystemsModal: openModal,
    clientSystemDataLoading: loading,
    clientSystemsData: systemLists,
    deleteSystemLoading: deleteLoading,
    closeClientSystems: closeModal,
    deleteSystems: deleteSystem,
    addNewClientSystem: addNewSystem,
  } = useNetworkContext();
  const [scroll, setScroll] = React.useState<DialogProps["scroll"]>("paper");
  const [deleteModal, setDeleteModal] = React.useState<boolean>(false);
  const [selectedSystem, setSelectedSystem] = React.useState<any>(null);

  const openDeleteSystemModal = (system: any) => {
    setSelectedSystem(system);
    setDeleteModal(true);
  };

   const performDelete=()=>{
        const formData = new FormData();
        formData.append("id", selectedSystem?.id);
        deleteSystem(formData);
        setDeleteModal(false)
  }


  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xs"
        PaperProps={{
          style: { borderRadius: 16 },
        }}
        scroll={scroll}
        open={openModal}
      >
        <div
          style={{
            display: "flex",
            marginLeft: "15px",
            boxSizing: "border-box",
            marginRight: "15px",
            width: "98%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            <strong>List of Systems</strong>
          </div>
          <div>
            <Button
              onClick={() => addNewSystem(clientId)}
              variant="contained"
              disableElevation
              style={{
                margin: "20px 32px",
                borderRadius: "9px",
                height: "40px",
                background: "#FDCE32",
                fontWeight: 600,
                textTransform: "capitalize",
              }}
            >
              Add New System
            </Button>
          </div>
        </div>

        <DialogContent dividers={true}>
          <List dense={true}>
            {loading ? (
              <Typography variant="h6">Loading....</Typography>
            ) : (
              systemLists.map((system) => (
                <ListItem>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Typography
                          component="strong"
                          className={classes.systemName}
                        >
                          {system.attributes.designation}
                        </Typography>
                      </React.Fragment>
                    }
                    secondary={system.attributes.country}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      onClick={() => openDeleteSystemModal(system)}
                      edge="end"
                      aria-label="delete"
                    >
                      <DeleteOutlinedIcon />
                    </IconButton>
                    <IconButton edge="end" aria-label="detail">
                      <ArrowForwardIosOutlinedIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))
            )}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            disableElevation
            size="small"
            style={{
              borderRadius: "9px",
              height: "40px",
              width: "100%",
              fontWeight: 600,
              textTransform: "capitalize",
            }}
            onClick={() => closeModal()}
          >
            {deleteLoading ? "Deleting One System.." : "Close"}
          </Button>
        </DialogActions>
      </Dialog>
      {deleteModal && (
        <ActionModal
          title="Delete System?"
          message="Are you sure want to delete this System?"
          open={deleteModal}
          setOpen={setDeleteModal}
          actionLoading={deleteLoading}
          actionHandler={performDelete}
        />
      )}
    </>
  );
};

export default ClientsSystemList;
