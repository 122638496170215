import React, { ReactComponentElement } from "react";
import { ToastContainer } from "react-toastify";
import AlarmsController, { Props, configJSON } from "./AlarmsController.web";
import {
  createStyles,
  IconButton,
  Theme,
  withStyles,
  withTheme,
} from "@material-ui/core";

import { Button, Grid, MenuItem, Typography } from "@material-ui/core";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import AddCircleOutlineTwoToneIcon from "@material-ui/icons/AddCircleOutlineTwoTone";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import BarChartOutlinedIcon from "@material-ui/icons/BarChartOutlined";
import FilterListIcon from "@material-ui/icons/FilterList";
import FilterListOutlinedIcon from "@material-ui/icons/FilterListOutlined";
import GetAppIcon from "@material-ui/icons/GetApp";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ReplayTwoToneIcon from "@material-ui/icons/ReplayTwoTone";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import NewCustomAlarmModal from "../../../components/src/client-dashboard/NewCustomAlarmModal.web";

const StyledMenu = withStyles({
  paper: {
    background: "#fffcf2",
    padding: "10px 20px",
    borderRadius: "20px",
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

class Alarms extends AlarmsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    const { anchorEl, customAlarmModal } = this.state;

    return (
      <div style={{ margin: "35px" }}>
        <div className={classes.client__content__container}>
          <div className={classes.row}>
            <BarChartOutlinedIcon
              style={{ color: "#f2dd00", fontSize: "50px" }}
            />
            <ArrowForwardIosOutlinedIcon style={{ fontSize: "20px" }} />
            <Typography
              component="h4"
              variant="h5"
              className={classes.blackBoldText}
            >
              Reliance
            </Typography>
            <ArrowForwardIosOutlinedIcon style={{ fontSize: "20px" }} />
            <Typography
              component="h4"
              variant="h5"
              className={classes.blackBoldText}
            >
              Khalaya Power Plant
            </Typography>
            <ArrowForwardIosOutlinedIcon style={{ fontSize: "20px" }} />
            <Typography
              component="h4"
              variant="h5"
              className={classes.blackBoldText}
            >
              Alarms
            </Typography>
          </div>
          <div
            style={{
              background: "white",
              borderRadius: "15px",
              padding: "15px",
            }}
          >
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography
                  component="h4"
                  variant="h3"
                  className={classes.blackBoldText}
                  style={{ fontSize: "1.2rem" }}
                >
                  Alarms
                </Typography>
              </Grid>
              <Grid item>
                <div className={classes.actions__buttons}>
                  <div>
                    <Button
                      onClick={this.handleMenuOpen}
                      variant="contained"
                      className={classes.buttonDarkYellow}
                      startIcon={<MoreHorizIcon />}
                    >
                      Actions
                    </Button>
                    <StyledMenu
                      id="action__menu_1"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={this.handleMenuClose}
                      style={{ marginRight: "50px" }}
                    >
                      <MenuItem>
                        <div
                          onClick={() => {
                            this.handleMenuClose();
                            this.changeCustomAlarmModal(true);
                          }}
                          className={classes.optionMenuItem}
                        >
                          <div>
                            <AddCircleOutlineTwoToneIcon />
                          </div>
                          <div>Add</div>
                        </div>
                      </MenuItem>
                      <MenuItem>
                        <div
                          onClick={() => this.handleMenuClose()}
                          className={classes.optionMenuItem}
                        >
                          <div>
                            <ReplayTwoToneIcon />
                          </div>
                          <div>Refresh</div>
                        </div>
                      </MenuItem>
                      <MenuItem>
                        <div
                          onClick={() => this.handleMenuClose()}
                          className={classes.optionMenuItem}
                        >
                          <div>
                            <SettingsOutlinedIcon />
                          </div>
                          <div>Settings</div>
                        </div>
                      </MenuItem>
                      <MenuItem>
                        <div
                          onClick={() => this.handleMenuClose()}
                          className={classes.optionMenuItem}
                        >
                          <div>
                            <FilterListOutlinedIcon />
                          </div>
                          <div>Filter</div>
                        </div>
                      </MenuItem>
                    </StyledMenu>
                  </div>

                  <Button
                    variant="contained"
                    className={classes.buttonAsh}
                    startIcon={<GetAppIcon />}
                  >
                    CSV Export
                  </Button>
                </div>
              </Grid>
            </Grid>

            <div className={classes.table__container}>
              <table>
                <thead>
                  <tr>
                    <th>
                      <h5 style={{ fontWeight: "bold", paddingLeft: "20px" }}>
                        LAST CHANGE
                      </h5>
                    </th>
                    <th>
                      <div className={classes.row}>
                        <h5 style={{ fontWeight: "bold" }}>TICKET</h5>
                        <FilterListIcon />
                      </div>
                    </th>
                    <th>
                      <div className={classes.row}>
                        <h5 style={{ fontWeight: "bold" }}>ERROR TYPE</h5>
                        <FilterListIcon />
                      </div>
                    </th>
                    <th>
                      <div className={classes.row}>
                        <h5 style={{ fontWeight: "bold" }}>EMAIL</h5>
                        <FilterListIcon />
                      </div>
                    </th>
                    <th>
                      <h5 style={{ fontWeight: "bold" }}>ERROR STATUS</h5>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {[...new Array(6)].map((item, index) => (
                    <tr key={index}>
                      <td>
                        <div style={{ paddingLeft: "20px" }}>
                          <h2 className={classes.itemText}>First name last</h2>
                        </div>
                      </td>
                      <td>
                        <div>
                          <h2 className={classes.itemText}>usr_name</h2>
                        </div>
                      </td>{" "}
                      <td>
                        <div>
                          <h2 className={classes.itemText}>newusergroup</h2>
                        </div>
                      </td>
                      <td>
                        <div>
                          <h2 className={classes.itemText}>
                            email@address.com
                          </h2>
                        </div>
                      </td>
                      <td>
                        <div>
                          <h2 className={classes.itemText}>Open</h2>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={classes.pagination_container}>
              <IconButton
                style={{
                  background: "#f9f9fa",
                  marginRight: "15px",
                  borderRadius: "10px",
                }}
              >
                <ArrowBackIosIcon />
              </IconButton>
              <div className={classes.pagination__text__container}>
                <Typography
                  variant="h5"
                  component="p"
                  style={{ fontSize: "17px" }}
                >
                  1/10
                </Typography>
              </div>

              <IconButton
                style={{
                  background: "#f9f9fa",
                  marginLeft: "15px",
                  borderRadius: "10px",
                }}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </div>
            <NewCustomAlarmModal
              open={customAlarmModal}
              setOpen={this.changeCustomAlarmModal}
            />
          </div>
        </div>
        <ToastContainer theme="dark" />
      </div>
    );
  }
}

export default withTheme(
  withStyles((theme: Theme) =>
    createStyles({
      row: {
        display: "flex",
        alignItems: "center",
        gap: "15px",
        boxSizing: "border-box",
      },
      client__content__container: {
        background: "#f9f9fa",
        borderRadius: "15px",
        padding: "30px",
        marginTop: "25px",
      },
      blackBoldText: {
        fontSize: "1rem",
        color: "black",
        fontWeight: "bolder",
      },
      table__container: {
        height: "550px",
        boxSizing: "border-box",
        marginTop: "10px",
        overflowY: "auto",
        width: "100%",
        "& table": {
          width: "100%",
          minWidth: "800px",
          borderSpacing: "0px 15px",
          "& thead": {
            background: "#f9f9fa",
            borderRadius: "10px",
            height: "40px",
          },
          "& tr": {
            textAlign: "left",
            "& td": {
              textTransform: "capitalize",
              padding: "17px 0px",
              borderBottom: "2px solid #f9f9fa",
            },
            "&:hover": {
              backgroundColor: "var(--main-color)",
              color: "var(--txt-white)",
              cursor: "pointer",
            },
          },
        },
      },
      itemText: {
        fontSize: "16px",
        fontWeight: 500,
      },
      pagination_container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
      },
      pagination__text__container: {
        width: "120px",
        background: "#f9f9fa",
        borderRadius: "15px",
        padding: "10px",
        textAlign: "center",
      },
      table__item__data__containier: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
      },
      actions__buttons: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        gap: "20px",
      },
      buttonDarkYellow: {
        textTransform: "capitalize",
        background: "#FFCE21",
        boxShadow: "none",
        borderRadius: "15px",
        padding: "10px 15px 10px 15px",
        color: "black",
        fontWeight: "normal",
        minWidth: "120px",
        "&:hover": {
          background: "#FDCE32",
        },
      },
      buttonAsh: {
        textTransform: "capitalize",
        background: "#F9F9FA",
        boxShadow: "none",
        borderRadius: "15px",
        padding: "10px 15px 10px 15px",
        color: "black",
        fontWeight: "normal",
        minWidth: "100px",
        "&:hover": {
          background: "#F9F9FA",
        },
      },
      optionMenuItem: {
        width: "100%",
        display: "flex",
        // justifyContent: "space-around",
        gap: "20px",
      },
    })
  )(Alarms)
);
