import {
  Button,
  createStyles,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import React from "react";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from "@material-ui/core/CircularProgress";

import { useClientSettingContext } from "../../../blocks/ActivityLog/src/ClientSettingContext.web";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: "20px",
    },
    dividerStyle: {
      marginTop: "10px",
      marginBottom: "15px",
      height: "2px",
      width: "100%",
      background: "#f9f9fa",
    },
    ashBoldText: {
      color: "#8F92A1",
      fontSize: "15px",
      marginTop: "10px",
      marginBottom: "10px",
      fontWeight: "bolder",
    },
    inputPlaceholder: {
      color: "#8F92A1",
      fontWeight: "bolder",
      fontSize: ".85rem",
    },
    selectContainer: {
      border: "none",
      fontSize: "1.2rem",
      fontWeight: "bolder",
      color: "black",
      "&:hover": {
        borderBottom: "0px",
      },
      "&::before": {
        borderBottom: "0px !important",
      },
      "&::after": {
        borderBottom: "0px !important",
      },
      "&$selected": {
        borderBottom: "0px !important",
      },
    },
    blackBoldText: {
      fontSize: "1.2rem",
      fontWeight: "bolder",
      color: "black",
    },
    headingText: {
      fontSize: "2rem",
      fontWeight: 550,
      color: "black",
    },
    row: {
      display: "flex",
      alignItems: "center",
      gap: "15px",
    },
    ashButton: {
      background: "#f9f9fa",
      borderRadius: "15px",
      boxShadow: "none",
      textTransform: "capitalize",
      fontWeight: 400,
      color: "black",
    },
    yellowButton: {
      background: "#FFCE21",
      borderRadius: "15px",
      boxShadow: "none",
      textTransform: "capitalize",
      fontWeight: 400,
      color: "black",
    },
    textInput: {
      fontWeight: "bolder",
      color: "black",
    },
  })
);

const DataLogger = () => {
  const styles = useStyles();
  const {
    dataLogger,
    changeDataLoggerInput:cI,
    createDataLogger,
  }=useClientSettingContext();

  const {
    input,
    errors,
    createLoading,
    updateLoading,
    edit
  }=dataLogger || {};

  return (
    <div className={styles.container}>
      <Backdrop style={{zIndex:100,color:"#fff"}} open={updateLoading}>
          <CircularProgress color="inherit" />
      </Backdrop>
      <Typography component="h1" variant="h3" className={styles.headingText}>
        Data Logger Administration
      </Typography>
      <br />
      <div className={styles.dividerStyle} />
      <Typography
        component="h5"
        className={styles.ashBoldText}
        style={{ fontSize: ".70rem" }}
      >
        To register a data logger in the system , please enter the serial number
        and the name of the data logger on this page
      </Typography>
      <TextField
        value={input.name}
        onChange={(e)=>cI("name",e.target.value)}
        error={errors?.name===undefined || errors?.name===""?false:true}
        helperText={errors?.name}
        style={{ fontWeight: "bolder", fontSize: "1rem", width: "70%" }}
        className={styles.textInput}
        fullWidth
        id="standard-basic"
        label={
          <span className={styles.inputPlaceholder}>Data Logger name</span>
        }
      />
      <br />
      <br />
      <br />
      <TextField
        value={input.hardwareSerialNumber}
        onChange={(e)=>cI("hardwareSerialNumber",e.target.value)}
        error={errors?.hardwareSerialNumber===undefined || errors?.hardwareSerialNumber===""?false:true}
        helperText={errors?.hardwareSerialNumber}
        style={{ fontWeight: "bolder", fontSize: "1rem", width: "70%" }}
        className={styles.textInput}
        fullWidth
        id="standard-basic"
        label={
          <span className={styles.inputPlaceholder}>
            Hardware Serial Number
          </span>
        }
      />
      <br />
      <br />
      <br />
      <TextField
        value={input.interval}
        onChange={(e)=>cI("interval",e.target.value)}
        error={errors?.interval===undefined || errors?.interval===""?false:true}
        helperText={errors?.interval}
        style={{ fontWeight: "bolder", fontSize: "1rem", width: "70%" }}
        className={styles.textInput}
        fullWidth
        id="standard-basic"
        type="number"
        label={
          <span className={styles.inputPlaceholder}>
            Measuring Interval
          </span>
        }
      />
      <br />
      <br />
      <div className={styles.dividerStyle} />

      <Typography
        component="h5"
        className={styles.blackBoldText}
        style={{ fontSize: "1rem" }}
      >
        Api Configuration
      </Typography>
      <br />
      <br />
      <TextField
        value={input.ftpUserName}
        onChange={(e)=>cI("ftpUserName",e.target.value)}
        error={errors?.ftpUserName===undefined || errors?.ftpUserName===""?false:true}
        helperText={errors?.ftpUserName}
        style={{ fontWeight: "bolder", fontSize: "1rem", width: "70%" }}
        className={styles.textInput}
        fullWidth
        id="standard-basic"
        label={<span className={styles.inputPlaceholder}>Username</span>}
      />
      <br />
      <br /> 
      <TextField
        value={input.ftpPassword}
        onChange={(e)=>cI("ftpPassword",e.target.value)}
        error={errors?.ftpPassword===undefined || errors?.ftpPassword===""?false:true}
        helperText={errors?.ftpPassword}
        style={{ fontWeight: "bolder", fontSize: "1rem", width: "70%" }}
        className={styles.textInput}
        fullWidth
        id="standard-basic"
        label={<span className={styles.inputPlaceholder}>Password</span>}
      />
      <br />
      <br />
      <TextField
        value={input.ftpApiKey}
        onChange={(e)=>cI("ftpApiKey",e.target.value)}
        error={errors?.ftpApiKey===undefined || errors?.ftpApiKey===""?false:true}
        helperText={errors?.ftpApiKey}
        style={{ fontWeight: "bolder", fontSize: "1rem", width: "70%" }}
        className={styles.textInput}
        fullWidth
        id="standard-basic"
        label={<span className={styles.inputPlaceholder}>Api Key</span>}
      />
      <br />
      <br />
      <TextField
        value={input.ftpEntityId}
        onChange={(e)=>cI("ftpEntityId",e.target.value)}
        error={errors?.ftpEntityId===undefined || errors?.ftpEntityId===""?false:true}
        helperText={errors?.ftpEntityId}
        style={{ fontWeight: "bolder", fontSize: "1rem", width: "70%" }}
        className={styles.textInput}
        fullWidth
        id="standard-basic"
        label={<span className={styles.inputPlaceholder}>Entity ID</span>}
      />
      <br />
      <br />
      <br />
      <div className={styles.row}>
        <Button className={styles.ashButton} style={{ minWidth: "130px" }}>
          Cancel
        </Button>
        <Button disabled={createLoading} onClick={createDataLogger} className={styles.yellowButton} style={{ minWidth: "130px" }}>
        {createLoading ? (
            <CircularProgress size={24} />
          ) :edit?"Update":"Save"}
        </Button>
      </div>

      <br />
      <br />
      <br />
    </div>
  );
};

export default DataLogger;
