import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { AuthContext, AuthContextType } from "../../email-account-login/src/AuthContext/AuthContext.web";
import { ISystem } from "./interfaces.types";
import { toast } from "react-toastify";
import { imgDownArrow, imgUpArrow } from "./assets";

// Customizable Area End

export const configJSON = require("./config");
export interface DashboardDetailsType {
  message: string;
  total_energy_genrated: string;
  number_of_systems: any;
  total_installed_capacity: string;
  clients_count: string;
  plants_count: string;
  energy_up_down_number: string;
  is_energy_minus: boolean;
}
export interface ClientType {
  id: string;
  type: string;
  attributes: {
    name: string;
    users: number,
    systems: number,
    total_power: string;
  };
}




export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  forSuperAdmin: boolean;
  forWebAdmin: boolean;
  classes?: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  leftSideBarFullScreen: boolean;
  dashboardDetails: DashboardDetailsType;
  clientList: ClientType[];
  systemList: ISystem[],
  todaysGeneratedEnergy: string;
  todaysPerformance: string;
  todaysPerformanceUpDown: boolean;
  graffPoints: number[];
  loading: boolean;
  selectedYear: string,
  getClientLoading: boolean;
  getSystemsLoading: boolean;
  zoom: number;
  selectedClientId: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LandingPage2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  apiResetGetDashboardCallId: string = "";
  apiResetGetAllClientCallId: string = "";
  apiGetSystemListCallId: string = "";
  // Customizable Area Start
  static contextType: any = AuthContext;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      leftSideBarFullScreen: false,
      selectedYear: new Date().getFullYear().toString(),
      dashboardDetails: {
        message: "",
        total_energy_genrated: "",
        number_of_systems: "",
        total_installed_capacity: "",
        clients_count: "",
        energy_up_down_number: "",
        plants_count: "",
        is_energy_minus: false,
      },
      loading: true,
      clientList: [],
      zoom: 8,
      getClientLoading: false,
      getSystemsLoading: true,
      systemList: [],
      selectedClientId: "",
      todaysGeneratedEnergy: "",
      todaysPerformance: "",
      todaysPerformanceUpDown: true,
      graffPoints: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getDashboardDetails(new Date().getFullYear().toString());
    this.getClientList();
    const { checkAuthorization } = this.context as AuthContextType;
    const { forSuperAdmin, forWebAdmin, navigation } = this.props;
    checkAuthorization(forSuperAdmin, forWebAdmin, navigation)
  }

  getSystemForClient = (clientId: string) => {
    this.setState({
      getSystemsLoading: true,
      selectedClientId: clientId
    });
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetSystemListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSystemApiUrl}?id=${clientId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getClientList = () => {
    this.setState({
      getClientLoading: true,
    });
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiResetGetAllClientCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllClientAPIURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getDashboardDetails = (year: string) => {
    this.setState({
      loading: true,
    });

    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiResetGetDashboardCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getDashboardAPIURL}?year=${year}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  dashboardDetailsApiResponse = (responseJson: any) => {
    this.setState({
      dashboardDetails: {
        ...responseJson,
        energy_up_down_number: `${responseJson.energy_up_down_number}%`
      },
      loading: false,
    });
  }
  getAllClientApiResponse = (responseJson: any) => {
    this.setState({
      clientList: responseJson?.data ? responseJson.data : [],
      getClientLoading: false,
    }, () => {
      if (responseJson?.data?.length > 0) {
        this.getSystemForClient(responseJson.data[0].id);
      }
    });
  }
  getSystemListCallIdApiResponse = (responseJson: any) => {
    if (!responseJson?.data?.data?.length || responseJson?.data?.data?.length === 0) {
      toast.error("No System Found For This Client.")
    }
    this.setState({
      systemList: responseJson?.data?.data?.length > 0 ? responseJson.data.data : [],
      todaysGeneratedEnergy: responseJson?.todays_energy || "0 KWs",
      todaysPerformance: responseJson?.todays_performance || "0%",
      todaysPerformanceUpDown: responseJson?.todays_performance_up_down || true,
      graffPoints: responseJson?.graff_points || [],
      getSystemsLoading: false,
    });
  }
  receiveBlock = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.apiResetGetDashboardCallId) {
      this.dashboardDetailsApiResponse(responseJson);
    } else if (apiRequestCallId === this.apiResetGetAllClientCallId) {
      this.getAllClientApiResponse(responseJson);
    } else if (apiRequestCallId === this.apiGetSystemListCallId) {
      this.getSystemListCallIdApiResponse(responseJson);
    }
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson === undefined) {
        return;
      } else if (
        responseJson &&
        responseJson.errors &&
        responseJson.errors[0].token
      ) {
        this.props.navigation.navigate("EmailAccountLoginBlock");
        return;
      }
      this.receiveBlock(apiRequestCallId, responseJson);
    }
  }

  handleExtend = () => {
    this.setState(prevState => ({
      leftSideBarFullScreen: !prevState.leftSideBarFullScreen,
    }));
  };

  handleMapZoomIn = () => {
    this.setState(prevState => ({
      zoom: prevState.zoom + 1
    }))
  }
  handleMapZoomOut = () => {
    this.setState(prevState => ({
      zoom: prevState.zoom - 1
    }))
  }
  gotToClientDetailsPage = () => {
    const { selectedClientId } = this.state;
    if (selectedClientId) {
      this.props.navigation.navigate("ClientDetailsPage", {
        clientId: selectedClientId
      })
    }
  }
  handleOnchangeFilterbyValue = (value: any) => {
    this.setState({
      selectedYear: value
    });
    this.getDashboardDetails(value);
  }
  getColorCodeByBool = (value: boolean) =>
    value ? "#00875A" : "#ff0808"

  getUpDownArrowByBool = (value: boolean) =>
    value ? imgUpArrow : imgDownArrow

  getStyleForLeftFullTable = (value: boolean) =>
    value ? { transform: "rotate(180deg)" } : {}
}