import {
  Button,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import React from "react";
interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const useStyles = makeStyles({
  inputPlaceholder: {
    color: "#8F92A1",
    fontWeight: "bolder",
  },
  headingText: {
    fontWeight: "bolder",
    fontSize: "22px",
  },
  dropZone: {
    width: "100%",
    flexDirection: "column",
    height: "90px",
    boxSizing: "border-box",
    padding: "10px",
    border: "2px dashed #000",
    borderRadius: "10px",
    background: "#f9f9fa",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    marginBottom: "15px",
    marginTop: "10px",
  },
});
export default function CreateTaskModal({ open, setOpen }: Props) {
  const styles = useStyles();
  return (
    <div>
      <Modal
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Paper
          style={{ width: "680px", padding: "30px", borderRadius: "10px" }}
        >
          <div style={{ padding: "10px", overflow: "auto" }}>
            <Typography className={styles.headingText} variant="h5">
              Create New TAsk
            </Typography>

            <div
              style={{
                marginTop: "10px",
                marginBottom: "15px",
                height: "2px",
                width: "100%",
                background: "#f9f9fa",
              }}
            />
            <TextField
              type="text"
              fullWidth
              label={
                <span className={styles.inputPlaceholder}>
                  Name of the task
                </span>
              }
            />

            <TextField
              style={{ marginTop: "15px" }}
              fullWidth
              select
              label={
                <span className={styles.inputPlaceholder}>Allocation</span>
              }
            >
              <MenuItem value="23,street view, Amritsar, India">name</MenuItem>
            </TextField>

            <div
              style={{
                marginTop: "30px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <span className={styles.inputPlaceholder}>Completion Date</span>
              <TextField type="date" fullWidth id="standard-basic" />
            </div>
            <br />
            <br />
            <TextField
              type="text"
              fullWidth
              label={
                <span className={styles.inputPlaceholder}>
                  Description of the task
                </span>
              }
            />
            <Grid container spacing={2} style={{ marginTop: "15px" }}>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <Button
                  onClick={() => setOpen(false)}
                  fullWidth
                  variant="contained"
                  style={{
                    background: "#f9f9fa",
                    marginRight: "10x",
                    borderRadius: "15px",
                    marginTop: "10px",
                    marginBottom: "10px",
                    boxShadow: "none",
                    textTransform: "capitalize",
                    fontWeight: 400,
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <Button
                  fullWidth
                  variant="contained"
                  style={{
                    background: "#FFCE21",
                    marginRight: "10x",
                    borderRadius: "15px",
                    marginTop: "10px",
                    marginBottom: "10px",
                    boxShadow: "none",
                    textTransform: "capitalize",
                    fontWeight: 400,
                  }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Modal>
    </div>
  );
}
