import React, { useContext } from "react";
import { S } from "./ChatController.web";
import {
  DirectMessage,
  GroupItemType,
  IAllUser,
  UserType,
} from "./Interfaces.web";
interface ChatContextType extends S {
  getCurrentUserRole: () => string;
  getCurrentUserId: () => number;
  changeAddNewChatModal: (open: boolean) => void;
  getAllClients: () => void;
  getGroupMembers: () => void;
  deleteMessages: () => void;
  addNewDirectChat: (client: IAllUser) => void;
  removeFromDirectChatItem: (client: IAllUser) => void;
  createNewDirectMessage: () => void;
  changeCurrentChatHead: (dMessage: DirectMessage, chatType: string) => void;
  sendNewMessage: (message: string, attatchment?: any) => void;
  deleteChatRoom: () => void;
  changeDeleteConverstaionModal: (open: boolean) => void;
  //group conversations functions

  changeGroupCreateModal: (open: boolean) => void;
  changeDeleteGroupModal: (open: boolean) => void;
  changeDeleteChatRoomModal: (open: boolean) => void;
  changeGroupMemberRemoveModal: (open: boolean) => void;
  changeGroupMembersModal: (open: boolean) => void;
  changeAddGroupMembersModal: (open: boolean) => void;
  changeGroupUserSelectModal: (
    name: string,
    description: string,
    image: any | null,
    open: boolean
  ) => void;
  addNewGroupUser: (user: IAllUser) => void;
  removeFromGroupUser: (user: IAllUser) => void;
  removeGroupMember: (member: UserType) => void;
  createNewGroup: () => void;
  getAllUsers: () => void;
  addNewMembersToTheGroup: () => void;
  setGroupToChatHead: (group: GroupItemType, chatType: string) => void;
}

export const initialState: S = {
  getUserLoading: false,
  getMessageLoading: false,
  addNewChatModal: false,
  getClientLoading: false,
  createNewChatLoading: false,
  getDirectConversationsLoading: false,
  clientList: [],
  selectedDirectChat: [],
  directMessageList: [],
  userList: [],
  currentChat: {
    type: "",
    chat: null,
    group: null,
    messages: [],
  },
  sendMessageLoading: false,
  loggedInUser: {},
  deleteRoomLoading: false,
  deleteConversationModal: false,
  //group chat state initialization
  createGroupModal: false,
  newGroup: {
    groupName: "",
    groupDescription: "",
    image: null,
    selectedGroupUser: [],
  },
  selectGroupUserModal: false,
  createGroupLoading: false,
  groupList: [],
  getGroupLoading: false,
  groupMembersModal: false,
  addGroupMemberLoading: false,
  addGroupMembersModal: false,
  groupMemberList: [],
  getGroupMemberLoading: false,
  removeGroupMemberLoading: false,
  removeGroupMemberModal: false,
  deleteGroupModal: false,
  deleteChatRoomModal: false,
};

const defaultContext: ChatContextType = {
  //states initialization
  ...initialState,
  //function initialization
  getCurrentUserRole: () => {
    return "";
  },
  getCurrentUserId: () => {
    return 0;
  },
  changeAddNewChatModal: (open: boolean) => {},
  changeDeleteGroupModal: (open: boolean) => {},
  changeDeleteChatRoomModal: (open: boolean) => {},
  getAllClients: () => {},
  deleteMessages: () => {},
  getAllUsers: () => {},
  addNewDirectChat: (user: IAllUser) => {},
  removeFromDirectChatItem: (user: IAllUser) => {},
  createNewDirectMessage: () => {},
  getGroupMembers: () => {},
  changeCurrentChatHead: (dMessage: DirectMessage, chatType: string) => {},
  sendNewMessage: (message: string, attatchment?: any) => {},
  deleteChatRoom: () => {},
  changeDeleteConverstaionModal: (open: boolean) => {},
  //group conversation modals
  changeGroupCreateModal: (open: boolean) => {},
  removeGroupMember: (member: UserType) => {},
  changeGroupMemberRemoveModal: (open: boolean) => {},
  changeAddGroupMembersModal: (open: boolean) => {},
  changeGroupMembersModal: (open: boolean) => {},
  changeGroupUserSelectModal: (
    name: string,
    description: string,
    image: any | null,
    open: boolean
  ) => {},
  addNewGroupUser: (user: IAllUser) => {},
  removeFromGroupUser: (user: IAllUser) => {},
  createNewGroup: () => {},
  addNewMembersToTheGroup: () => {},
  setGroupToChatHead: (group: GroupItemType, chatType: string) => {},
};

export const ChatContext = React.createContext<ChatContextType>(defaultContext);

export function useChatContext() {
  return useContext(ChatContext);
}
