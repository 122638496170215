import {
  Button,
  createStyles,
  makeStyles,
  Paper,
  Select,
  Theme,
  Typography,
  MenuItem,
  Grid,
  Checkbox,
  Card,
  withStyles,
  Switch,
} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import React from "react";
import InverterDataItem from "./InverterDataItem.web";

const RED__ITEM_COLOR = "#e50000";
const YELLOW__ITEM_COLOR = "#f9d300";
const GREEN__ITEM_COLOR = "#009557";
const PURPLE__ITEM_COLOR = "#6B00B0";

const data: any[] = [
  {
    title: "TOTAL",
    value: 82.72,
  },
  {
    title: "AVERAGE",
    value: 22,
  },
  {
    title: "MINIMUM",
    value: 12,
  },
  {
    title: "MAXIMUM",
    value: 222,
  },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContainer: {
      height: "100vh",
      width: "100vw",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      boxSizing: "border-box",
    },
    modalCard: {
      width: "85vw",
      boxSizing: "border-box",
      height: "90vh",
      borderRadius: "20px",
      padding: "34px 30px",
      overflowY: "auto",
    },
    row: {
      display: "flex",
      alignItems: "center",
      gap: "15px",
    },
    blackBoldText: {
      color: "black",
      fontSize: "1.5rem",
      fontWeight: "bolder",
    },
    blackNormalBoldText: {
      color: "black",
      fontSize: "1rem",
      fontWeight: "bolder",
    },
    ashBoldText: {
      color: "#A19DAF",
      fontSize: "1rem",
      fontWeight: "bolder",
    },
    dividerStyle: {
      marginTop: "10px",
      marginBottom: "15px",
      height: "2px",
      width: "100%",
      background: "#f9f9fa",
    },
    ashContainer: {
      background: "#f9f9fa",
      borderRadius: "15px",
      padding: "30px",
      marginTop: "15px",
      marginBottom: "15px",
    },
  })
);

const StyledSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 50,
      height: 25,
      padding: 0,
      display: "flex",
      alignItems: "center",
    },
    switchBase: {
      padding: 3,
      color: "#fdce32",
      transform: "translateX(5px)",
      "&$checked": {
        transform: "translateX(28px)",
        color: "#fdce32",
        "& + $track": {
          opacity: 1,
          backgroundColor: "black",
        },
      },
    },
    thumb: {
      width: 10,
      height: 18,
      boxShadow: "none",
      borderRadius: "10px",
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: "15px",
      opacity: 1,
      backgroundColor: theme.palette.common.white,
      height: "90%",
    },
    checked: {},
  })
)(Switch);

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function InverterDataHeatmapModal({ open, setOpen }: Props) {
  const styles = useStyles();
  return (
    <Modal
      className={styles.modalContainer}
      open={open}
      onClose={() => setOpen(false)}
    >
      <Card className={styles.modalCard}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography component="h1" className={styles.blackBoldText}>
              Inverter Data Heatmap
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <div className={styles.row} style={{ justifyContent: "flex-end" }}>
              <Typography
                component="h1"
                style={{ fontSize: ".80rem", color: "black" }}
              >
                Energy
              </Typography>
              <StyledSwitch name="checkedC" />
              <Typography
                component="h1"
                style={{ fontSize: ".80rem", color: "black" }}
              >
                Power
              </Typography>
            </div>
          </Grid>
        </Grid>
        <br />
        <br />
        <Grid container spacing={5}>
          {data?.map((item) => (
            <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: "15px",
                }}
              >
                <Typography
                  component="h3"
                  variant="h5"
                  className={styles.ashBoldText}
                >
                  {item?.title}
                </Typography>
                <Typography
                  component="h3"
                  variant="h5"
                  className={styles.blackNormalBoldText}
                >
                  {item?.value} kW
                </Typography>
              </div>
            </Grid>
          ))}
        </Grid>
        <div className={styles.dividerStyle} />
        <div className={styles.ashContainer}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
              <InverterDataItem
                title="K-INVM (W:10)"
                value={34}
                color={RED__ITEM_COLOR}
              />
            </Grid>
          </Grid>
        </div>
        <div className={styles.ashContainer}>
          <Grid container spacing={4}>
            {[...new Array(6)].map((item, index) => (
              <Grid key={index} item xs={12} sm={12} md={4} lg={3} xl={3}>
                <InverterDataItem
                  title="K-INVM (W:10)"
                  value={34}
                  color={YELLOW__ITEM_COLOR}
                />
              </Grid>
            ))}
          </Grid>
        </div>
        <div className={styles.ashContainer}>
          <Grid container spacing={4}>
            {[...new Array(2)].map((item, index) => (
              <Grid key={index} item xs={12} sm={12} md={4} lg={3} xl={3}>
                <InverterDataItem
                  title="K-INVM (W:10)"
                  value={34}
                  color={GREEN__ITEM_COLOR}
                />
              </Grid>
            ))}
          </Grid>
        </div>
        <div className={styles.ashContainer}>
          <Grid container spacing={4}>
            {[...new Array(3)].map((item, index) => (
              <Grid key={index} item xs={12} sm={12} md={4} lg={3} xl={3}>
                <InverterDataItem
                  title="K-INVM (W:10)"
                  value={34}
                  color={PURPLE__ITEM_COLOR}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      </Card>
    </Modal>
  );
}
