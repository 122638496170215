import React from "react";
import { ToastContainer } from "react-toastify";
import {
  Typography,
  IconButton,
  Button,
  Select,
  MenuItem,
} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import AddIcon from "@material-ui/icons/Add";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";

import {
  withStyles,
  createStyles,
  Theme,
  withTheme,
} from "@material-ui/core/styles";
import ProjectMangementController, {
  Props,
  configJSON,
} from "./PorjectManagementController.web";
import GeneratePortfolioReportModal from "../../../components/src/ReportsComponents/GeneratePortfolioReportModal.web";
import CreateTaskModal from "../../../components/src/WebAdmin/CreateTaskModal.web";

class ProjectManagement extends ProjectMangementController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    const { createTaskModal } = this.state;
    return (
      <>
        <div className="report__body__heading">
          <div className="report__body__heading__left">
            <Typography
              component="h3"
              variant="h5"
              style={{ fontWeight: "bolder" }}
            >
              Project Management
            </Typography>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
            <IconButton
              className={classes.darkIconButton}
              onClick={() => this.props.navigation.navigate("taskCalendar")}
            >
              <CalendarTodayOutlinedIcon />
            </IconButton>
            <div className={classes.dropdown_container}>
              <Typography
                component="h5"
                variant="h6"
                className={classes.dropdown__title}
              >
                Show :
              </Typography>
              <Select
                style={{ borderBottom: "0px" }}
                className={classes.dropdown__main}
                defaultValue="Week"
              >
                <MenuItem value="Week">Week</MenuItem>
                <MenuItem value={20}>Month</MenuItem>
                <MenuItem value={30}>Year</MenuItem>
              </Select>
            </div>
            <Button
              onClick={() => this.changeCreateTaskModal(true)}
              variant="contained"
              startIcon={<AddIcon />}
              style={{
                background: "#FFCE21",
                marginRight: "10x",
                borderRadius: "15px",
                marginTop: "10px",
                marginBottom: "10px",
                boxShadow: "none",
                textTransform: "capitalize",
                fontWeight: 400,
              }}
            >
              Create Task
            </Button>
          </div>
        </div>
        <div className="report__container">
          <div className="report__body__container">
            <div className={classes.table__container}>
              <table>
                <thead>
                  <tr>
                    <th>
                      <h5 style={{ fontWeight: "bold", paddingLeft: "20px" }}>
                        TASKS NAME
                      </h5>
                    </th>
                    <th>
                      <div className={classes.row}>
                        <h5 style={{ fontWeight: "bold" }}>ALLOCATION</h5>
                      </div>
                    </th>
                    <th>
                      <div className={classes.row}>
                        <h5 style={{ fontWeight: "bold" }}>COMPLETION DATE</h5>
                      </div>
                    </th>
                    <th>
                      <div className={classes.row}>
                        <h5 style={{ fontWeight: "bold" }}>ACTION</h5>
                        <FilterListIcon />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {[...new Array(6)].map((item, index) => (
                    <tr key={index}>
                      <td>
                        <div style={{ paddingLeft: "20px" }}>
                          <h2 className={classes.itemText}>Task name</h2>
                        </div>
                      </td>
                      <td>
                        <div>
                          <h2 className={classes.itemText}>newusergroup</h2>
                        </div>
                      </td>{" "}
                      <td>
                        <div>
                          <h2 className={classes.itemText}>20/11/2021</h2>
                        </div>
                      </td>
                      <td>
                        <div className={classes.row}>
                          <IconButton style={{ padding: "0px" }}>
                            <DeleteOutlineOutlinedIcon />
                          </IconButton>
                          <IconButton style={{ padding: "0px" }}>
                            <EditOutlinedIcon />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div
              className="project__pagination__container"
              style={{ marginRight: "10px", marginBottom: "15px" }}
            >
              <IconButton
                style={{
                  background: "#f9f9fa",
                  marginRight: "15px",
                  borderRadius: "10px",
                }}
              >
                <ArrowBackIosIcon />
              </IconButton>
              <div className="pagination__text__container">
                <Typography
                  variant="h5"
                  component="p"
                  style={{ fontSize: "17px" }}
                >
                  1/10
                </Typography>
              </div>

              <IconButton
                style={{
                  background: "#f9f9fa",
                  marginLeft: "15px",
                  borderRadius: "10px",
                }}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </div>
          </div>
        </div>
        {createTaskModal && (
          <CreateTaskModal
            open={createTaskModal}
            setOpen={this.changeCreateTaskModal}
          />
        )}
        <ToastContainer theme="dark" />
      </>
    );
  }
}

export default withTheme(
  withStyles((theme: Theme) =>
    createStyles({
      darkIconButton: {
        background: "#f3f2f5",
      },
      action__buttons__item: {
        display: "flex",
        alignItems: "center",
      },
      dropdown_container: {
        display: "flex",
        background: "#f3f2f5",
        borderRadius: "20px",
        padding: "5px 20px",
        alignItems: "center",
      },
      dropdown__title: {
        fontSize: "12px",
        color: "#A19DAF",
        fontWeight: "bolder",
      },
      dropdown__main: {
        marginLeft: "10px",
        fontSize: "14px",
        fontWeight: "bolder",
        "&:hover": {
          borderBottom: "0px",
        },
        "&::before": {
          borderBottom: "0px !important",
        },
      },
      row: {
        display: "flex",
        alignItems: "center",
        gap: "15px",
      },
      table__container: {
        height: "550px",
        boxSizing: "border-box",
        marginTop: "10px",
        overflowY: "auto",
        width: "100%",
        "& table": {
          width: "100%",
          minWidth: "800px",
          borderSpacing: "0px 15px",
          "& thead": {
            background: "#f9f9fa",
            borderRadius: "10px",
            height: "40px",
          },
          "& tr": {
            textAlign: "left",
            "& td": {
              textTransform: "capitalize",
              padding: "17px 0px",
              borderBottom: "2px solid #f9f9fa",
            },
            "&:hover": {
              backgroundColor: "var(--main-color)",
              color: "var(--txt-white)",
              cursor: "pointer",
            },
          },
        },
      },
      itemText: {
        fontSize: "16px",
        fontWeight: 500,
      },
    })
  )(ProjectManagement)
);
