import React from "react";
import {
  imgImageLoader,
  imgErrorImage,
} from "../../../blocks/Chat9/src/assets";
import { useChatContext } from "../../../blocks/Chat9/src/ChatContext.web";
import ViewImageModal from "./ViewImageModal.web";

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {}
const LazyImage = ({ src, ...props }: ImageProps) => {
  const [imgSrc, setSrc] = React.useState(imgImageLoader || src);
  const [viewImageModal, setViewImageModal] = React.useState<boolean>(false);

  const onLoad = React.useCallback(() => {
    if (src) {
      setSrc(src);
    }
  }, [src]);

  const onError = React.useCallback(() => {
    setSrc(src || imgErrorImage);
  }, [imgErrorImage, imgImageLoader]);

  React.useEffect(() => {
    const img = new Image();
    img.src = src as string;
    img.addEventListener("load", onLoad);
    img.addEventListener("error", onError);
    return () => {
      img.removeEventListener("load", onLoad);
      img.removeEventListener("error", onError);
    };
  }, [src, onLoad]);

   console.log("<================Lazzy Image Rendering==============>");

  return (
    <>
      <img
        onClick={() => setViewImageModal(true)}
        {...props}
        alt=""
        src={imgSrc}
      />
      {viewImageModal && (
        <ViewImageModal
          src={src}
          open={viewImageModal}
          setOpen={setViewImageModal}
        />
      )}
    </>
  );
};

export default LazyImage;
