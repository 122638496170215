import React from 'react';
import { Checkbox, makeStyles, TableCell, TableRow, Typography } from "@material-ui/core";
import { ClientType } from './LandingPage2Controller.web';
interface Props {
  index: number;
    customer: ClientType;
    hover:string;
    navigateToClientDetails:(clientId:string)=>void;
}

interface State{
  check:boolean;
}

interface StyledType{
    hover:string
}

const useStyles = makeStyles({
  tableRow: {
    borderRadius: "10px",
    padding:"10px",
    marginTop:"20px",
    "&:hover": {
      background: (props: StyledType) => props.hover,
      cursor: "pointer",
    },
  },
});

const ClientTableRow = ({ index, customer, hover,navigateToClientDetails }: Props) => {
  
 //const [check, setCheck] = useState<boolean>(false);
  const styles = useStyles({ hover });
    return (
      <TableRow style={{height:"85px"}} className={styles.tableRow} onClick={()=>navigateToClientDetails(customer.id)}>
        <TableCell style={{ minWidth: 210,paddingRight:"10px" }} padding="checkbox">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}

          >
            <Checkbox
              
              color="primary"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
            <Typography
              style={{
                marginLeft: "10px",
                color: "#005AD1",
                fontWeight: "bolder",
              }}
            >
              {customer?.attributes?.name}
            </Typography>
          </div>
        </TableCell>
        <TableCell style={{ minWidth: 300 }} component="th" scope="row" padding="none">
          {/* {customer?.plantLocation} */}
          <p style={{margin:"0",padding:"0",color:"#888"}}>{customer?.attributes?.systems} Plants</p>
          <p style={{margin:"0",padding:"0",color:"black",fontWeight:"bold"}}>Delhi, New Youk,Mumbai,Jakarta, San Fransisco,London</p>
          
        </TableCell>
        <TableCell style={{ minWidth: 200,padding:"0"  }}>
          {/* {customer?.todayTotalPower} */}
           <p style={{margin:"0",padding:"0",color:"black",fontWeight:"bold"}}>21.2 Kwp</p>
          
     
          </TableCell>
        <TableCell  style={{ minWidth: 200,padding:"0" }}>
          {/* {customer?.status === "normal" ? (
            <button className="normalButton">Normal</button>
          ) : customer?.status === "critical" ? (
            <button className="criticalButton">Critical</button>
          ) : (
            <button className="warningsButton">Warnings</button>
          )} */}

          <button className="normalButton">
            Normal
          </button>

        </TableCell>
        <TableCell style={{ minWidth: 150,padding:"0"  }}>
          <div
            style={{
              display: "flex",
              width:"100%",
              flexDirection: "column",
            }}
          >
            <p style={{textAlign:"center",width:"120px"}}>
              {/* {customer?.prJoint} */}
               50%</p>
            <div className="bar">
              <span style={{ width: `${50}%` }}></span>
            </div>
          </div>
        </TableCell>
        <TableCell  style={{ minWidth: 120,padding:"0"  }}>
          {
            // <button
            //   className={`${
            //     customer?.communication === "Active"
            //       ? "normalButton"
            //       : customer?.communication === "Inactive"
            //       ? "warningsButton"
            //       : "criticalButton"
            //   }`}
            // >
            //   {customer?.communication}
            // </button>
          }
          <button className="normalButton">
            Active
          </button>
        </TableCell>
        <TableCell  style={{ minWidth: 120,padding:"0"  }}>
          {
            // <button
            //   className={`${
            //     customer?.onOff === "On"
            //       ? "normalButton"
            //       : customer?.onOff === "Off"
            //       ? "criticalButton"
            //       : "warningsButton"
            //   }`}
            // >
            //   {customer?.onOff}
            // </button>
          }
          <button className="normalButton">
            On
          </button>
        </TableCell>
      </TableRow>
    );


}


export default ClientTableRow;