import React from 'react';
import {Dialog,DialogTitle,DialogContent,DialogActions,Button} from '@material-ui/core';
import { User } from '../../../blocks/AdminConsole3/src/user/UserController.web';
import {useApprovalContext} from '../../../blocks/AdminConsole3/src/Approval/ApprovalContext.web'


const ApproveDeclineModal=()=>{

  const {
    approveModal:open,
    approveLoading,
    changeApproveModal:setOpen,
    selectedApproval,
    action,
    approveDeclineSystem
  }=useApprovalContext();
    return(
        <Dialog
        fullWidth
        maxWidth="xs"
        PaperProps={{style: {borderRadius: 16}}}
        open={open}
        onClose={() => setOpen(false,{},{alert:"",action:""})}
        >
          <DialogTitle id="max-width-dialog-title" style={{paddingBottom: 0}}>
            <strong>{action?.alert} System?</strong>
          </DialogTitle>
          <DialogContent>
            <p>Are you sure you want to {action?.alert} this system</p>
          </DialogContent>
          <DialogActions style={{margin: "15px"}}>
            <Button
                    variant="contained"
                    disableElevation
                    style={{borderRadius: "9px", height: "40px", width: "100%", fontWeight: 600, textTransform: "capitalize"}}
                    onClick={() => setOpen(false,{},{alert:"",action:""})}
                  >
              Cancel
            </Button>
            <Button
                disabled={approveLoading}
                    variant="contained"
                    disableElevation
                    style={{borderRadius: "9px", height: "40px", width: "100%", background: "#FDCE32", fontWeight: 600, textTransform: "capitalize"}}
                    onClick={async() =>{
                      approveDeclineSystem();
                    }}
                  >
              {approveLoading?`${action?.alert} System...`:"Yes"}
            </Button>
            </DialogActions>
        </Dialog>

    )
}

export default ApproveDeclineModal;