import {
  Button,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
  Modal
} from "@material-ui/core";
import React from "react";
import { ClientType, UserGroup } from "../../../blocks/AdminConsole3/src/user/Interfaces";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Yup from 'yup';
interface UserError{
  name?:string|null;
  clientName?:string|null;
  userName?:string|null;
  userGroup?:string|null;
  assignRole?:string|null;
  contact?:string|null;
  password?:string|null;
  confirmPassword?:string|null;
  email?:string|null;
  message?:string|null;
}

interface Props{
  edit?:boolean;
  editUser?:any;
  open:boolean;
  setOpen:(open:boolean)=>void;
  actionLoading:boolean;
  actionHandler:any;
  clientList?:ClientType[];
  userGroupList:UserGroup[];
  clientName?:string;
  clientId?:string;
  getUserGroupsLoading?:boolean;
  getClientsLoading?:boolean;
  userErrors?:any;
  forClient?:boolean;
}

export default function CreateNewUser({
      edit,
      editUser,
      open,
      setOpen,
      actionLoading,
      actionHandler,
      clientList,
      userGroupList,
      getClientsLoading,
      getUserGroupsLoading,
      clientId,
      clientName:prevClientName,
      userErrors,
      forClient
    }:Props) {
  const [name,setName]=React.useState<string>("");
  const [clientName,setClientName]=React.useState<string>("");
  const [userName,setUserName]=React.useState<string>("");
  const [userGroup,setUserGroup]=React.useState<string>("");
  const [contact,setContact]=React.useState<string>("");
  const [password,setPassword]=React.useState<string>("");
  const [confirmPassword,setConfirmPassword]=React.useState<string>("");
  const [email,setEmail]=React.useState<string>("");
  const [errors,setErrors]=React.useState<UserError>({})


  const getPassword=()=>{
     if(edit){
        if(password){
          return password
        }else{
          return "244739@Sk"
        }
     }else{
      return password;
     }
  }
  const getConfirmPassword=()=>{
     if(edit){
        if(password){
          return confirmPassword
        }else{
          return "244739@Sk"
        }
     }else{
      return confirmPassword;
     }
  }


  const validateInput=()=>{
     let userSchema = Yup.object({
          name: Yup.string().required("Please Enter Name").max(50,"Name is too long (maximum is 50 characters)"),
          clientName: Yup.string().required("Please Select Client"),
          userName: Yup.string().required("Please Enter User Name").max(50,"User Name is too long (maximum is 50 characters)"),
          userGroup: Yup.string().required("Please Select User Group"),
          contact:Yup.string().required("Please Enter Contact Number")
                              .min(10,"Contact is too short (minimum is 10 characters)")
                              .max(10,"Contact is too long (maximum is 10 characters)"),
          password:Yup.string().required("Please Enter Your Password").matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\_*])(?=.{6,})/,
                "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
              ), 
          confirmPassword: Yup.string().required("Please Confirm Your Password").oneOf([Yup.ref('password'), null], "Password Doesn't matched"),
          email:Yup.string().required("Please Enter Email.").email("Invalid Email Address"),
        });
        userSchema.validate({
           name,
           clientName:clientId?prevClientName:clientName,
           userName,
           userGroup,
           password:getPassword(),
           confirmPassword:getConfirmPassword(),
           contact,
           email,
        }, { abortEarly: false }).then((value)=>{
            const userData:any={
                user_name:userName,
                password:password,
                confirm_password:confirmPassword,
                email:email,
                user_group:userGroup,
                name:name,
                client_id:clientId?clientId:clientList?.find((item)=>item.attributes.name===clientName)?.id,
                contact:contact,
                client_name:clientId?prevClientName:clientName,
                role:"administration"
              }
              if(edit && !password){
                 delete userData.password
                 delete userData.confirm_password
              }

              if(forClient){
                delete userData.client_id
                delete userData.client_name
              }

              setErrors({})
            actionHandler(userData,editUser?.id,clientId);
        }).catch((err)=>{
          console.log(err,"err")
          let newError:any={};
          err.inner?.forEach((item:any)=>{
             newError[item.path]=item.message
          })
          setErrors(newError)
        })
  }
  React.useEffect(()=>{
    if(edit){
       const fillInput=()=>{
         setName(editUser?.attributes?.name);
         setClientName(editUser?.attributes?.client_name);
         setUserName(editUser?.attributes?.user_name);
         setUserGroup(editUser?.attributes?.user_group);
         setContact(editUser?.attributes?.contact);
         setEmail(editUser?.attributes?.email);
       }
       fillInput();
    }
  },[edit,editUser])

  React.useEffect(()=>{
      setErrors(userErrors)
  },[userErrors])



  const getActionButtonText=()=>{
    if(edit)
      return "Update"
    return "Save"
  }
  return (
    <div>
      {/* @ts-ignore */}
      <Modal
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Paper
          style={{ width: "680px", padding: "20px", borderRadius: "20px" }}
        >
          <div style={{ padding: "10px" }}>
            <Typography
              variant="h5"
              style={{ fontWeight: 450, fontSize: "22px" }}
            >
             {editUser?"Update User":"Add New User"}
            </Typography>
              
            <div
              style={{
                marginTop: "10px",
                marginBottom: "15px",
                height: "2px",
                width: "100%",
                background: "#f9f9fa",
              }}
            ></div>

            <Grid container spacing={2} style={{ marginTop: "15px" }}>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <TextField 
                    value={name}
                    onChange={e=>setName(e.target.value)}
                    fullWidth id="standard-basic"
                    label="Name"
                    error={errors?.name?true:false}
                    helperText={errors?.name}
                        />
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                {
                  clientId ?null:(<TextField
                      value={clientName} 
                      onChange={e=>setClientName(e.target.value)}
                      fullWidth
                      id="standard-select-currency"
                      select
                      label={editUser?`Client Name (${clientName})`:"Client Name"}
                      error={errors?.clientName?true:false}
                      helperText={errors?.clientName}
                     >
                       {
                         getClientsLoading? 
                           <p style={{marginLeft:"10px",fontWeight:200,fontSize:"12px"}}>Loading....</p>:
                           clientList?.map(client=>(
                             <MenuItem value={client?.attributes?.name}>{client?.attributes?.name}</MenuItem>
                           ))
                       }
                  </TextField>)
                }
                
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: "15px" }}>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <TextField 
                    value={userName}
                    onChange={e=>setUserName(e.target.value)}
                    fullWidth
                    id="standard-basic"
                    label="User Name"
                    error={errors?.userName?true:false}
                    helperText={errors?.userName}
                     />
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>

                  <TextField
                      value={userGroup} 
                      onChange={e=>setUserGroup(e.target.value)}
                      fullWidth
                      id="standard-select-currency"
                      select
                      label={editUser?`User Group (${userGroup})`:"User Group"}
                      error={errors?.userGroup===undefined || errors?.userGroup===""?false:true}
                      helperText={errors?.userGroup}
                     >
                       {
                         getUserGroupsLoading? 
                           <p style={{marginLeft:"10px",fontWeight:200,fontSize:"12px"}}>Loading....</p>:
                           userGroupList.map(group=>(
                             <MenuItem value={group?.attributes?.user_group}>{group?.attributes?.user_group}</MenuItem>
                           ))
                       }
                          
                  </TextField>
              </Grid>
            </Grid>
           

            <TextField
               value={contact} 
              onChange={e=>setContact(e.target.value)}
              style={{ marginTop: "15px" }}
              type="number"
              fullWidth
              id="standard-basic"
              label="Contact"
              error={errors?.contact?true:false}
              helperText={errors?.contact}
              onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
            />
            <Grid container spacing={2} style={{ marginTop: "15px" }}>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <TextField
                  value={password} 
                  onChange={e=>setPassword(e.target.value)}
                  fullWidth id="standard-basic"
                  label="Password"
                  error={errors?.password?true:false}
                  helperText={errors?.password}
                  />
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <TextField
                  value={confirmPassword} 
                  onChange={e=>setConfirmPassword(e.target.value)}
                  fullWidth
                  id="standard-basic"
                  label="Confirm Password"
                  error={errors?.confirmPassword?true:false}
                  helperText={errors?.confirmPassword}
                />
              </Grid>
            </Grid>
            <TextField
              value={email} 
              onChange={e=>setEmail(e.target.value)}
              style={{ marginTop: "15px" }}
              fullWidth
              id="standard-basic"
              label="Email"
              error={errors?.email?true:false}
              helperText={errors?.email}
            />
            {
              errors?.message&&<p style={{marginTop:"10px",marginBottom:"10px",color:"red"}}>{errors?.message}</p>
            }

            <Grid container spacing={2} style={{ marginTop: "15px" }}>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <Button
                  onClick={() => setOpen(false)}
                  fullWidth
                  variant="contained"
                  style={{
                    background: "#f9f9fa",
                    marginRight: "10x",
                    borderRadius: "15px",
                    marginTop: "10px",
                    marginBottom: "10px",
                    boxShadow: "none",
                    textTransform: "capitalize",
                    fontWeight: 400,
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <Button
                  disabled={actionLoading}
                  onClick={()=>validateInput()}
                  fullWidth
                  variant="contained"
                  style={{
                    background: "#FFCE21",
                    marginRight: "10x",
                    borderRadius: "15px",
                    marginTop: "10px",
                    marginBottom: "10px",
                    boxShadow: "none",
                    textTransform: "capitalize",
                    fontWeight: 400,
                  }}
                >
                  {actionLoading?<CircularProgress size={24} />:getActionButtonText()}
                </Button>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Modal>
    </div>
  );
}
