import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import {  toast } from 'react-toastify';
import { AuthContext, AuthContextType } from "../../../email-account-login/src/AuthContext/AuthContext.web";

export const configJSON = require("../config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  addSystemLoading:boolean;
  isSuccess:boolean;
}
interface SS {
  id: any;
}

export default class AddNewSystemController extends BlockComponent<Props, S, SS> {
  apiCreateNewSystemCallId: string = "";
    static contextType:any = AuthContext;
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      addSystemLoading:false,
      isSuccess:false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: String, message: Message) {
    //response for add new system
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiCreateNewSystemCallId &&
      this.apiCreateNewSystemCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      //disable loading
      if(responseJson?.data){
        this.setState({
          addSystemLoading:false,
          isSuccess:true,
        })
        const {isClient}=this.context as AuthContextType;
        if(isClient()){
          toast.success("New System Created Successful.Please Wait For Approval.")
          this.goBack()
        }else{ 
          toast.success("New System Created.")
        }
      }else{
        toast.error("System Create Failed.")
        this.setState({
          addSystemLoading:false,
          isSuccess:false,
        });
      }
    }
  }

  async componentDidMount() {
    document.getElementById("Clients")?.classList.add("active");
  }

   createNewSystem=async(body: any)=>{
    this.setState({
      addSystemLoading:true,
      isSuccess:false
    })
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCreateNewSystemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createSystemApiUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body.client_id = this.props.navigation.getParam("clientId");
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goBack=()=>{
    this.props.navigation.goBack();
  }


}
