import React from "react";
import { ToastContainer } from "react-toastify";
import {
  Typography,
  IconButton,
  Button,
  Select,
  MenuItem,
  Grid,
} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import AddIcon from "@material-ui/icons/Add";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";

import {
  withStyles,
  createStyles,
  Theme,
  withTheme,
} from "@material-ui/core/styles";
import ProjectTaskCalendarController, {
  Props,
  configJSON,
} from "./ProjectTaskCalendarController.web";
import { differenceInDays, endOfMonth, format, startOfMonth } from "date-fns";
import CalendarCell from "../../../components/src/WebAdmin/CalendarCell.web";
import TaskItem from "../../../components/src/WebAdmin/TaskItem.web";

const weeks = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export interface EventType {
  date: any;
  title: any;
}

const eventList: EventType[] = [
  {
    date: new Date("2022-10-31"),
    title: "Helllo",
  },
  {
    date: new Date("2022-10-31"),
    title: "Helllo",
  },
  {
    date: new Date("2022-10-31"),
    title: "Helllo",
  },
  {
    date: new Date("2022-11-4"),
    title: "Helllo",
  },
  {
    date: new Date("2022-11-4"),
    title: "Helllo",
  },
  {
    date: new Date("2022-11-7"),
    title: "Helllo",
  },
  {
    date: new Date("2022-11-7"),
    title: "Helllo",
  },
  {
    date: new Date("2022-11-7"),
    title: "Helllo",
  },
  {
    date: new Date("2022-11-8"),
    title: "Helllo",
  },
];

class ProjectTaskCalendar extends ProjectTaskCalendarController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    const { date } = this.state;
    const startDate = startOfMonth(date);
    const endDate = endOfMonth(date);
    const numDays = differenceInDays(endDate, startDate) + 1;

    const prefixDays = startDate.getDay();
    const suffixDays = 6 - endDate.getDay();

    return (
      <>
        <Grid
          container
          justifyContent="space-between"
          spacing={2}
          style={{
            marginLeft: "20px",
            marginRight: "20px",
            marginTop: "30px",
            width: "98%",
          }}
        >
          <Grid item>
            <Typography
              component="h3"
              variant="h5"
              style={{ fontWeight: "bolder" }}
            >
              Project Management
            </Typography>
          </Grid>
          <Grid item>
            <div className={classes.row}>
              <IconButton className={classes.darkIconButton}>
                <CalendarTodayOutlinedIcon />
              </IconButton>
              {/* area for show drop down  */}
              <div className={classes.dropdown_container}>
                <Typography
                  component="h5"
                  variant="h6"
                  className={classes.dropdown__title}
                >
                  Show :
                </Typography>
                <Select
                  style={{ borderBottom: "0px" }}
                  className={classes.dropdown__main}
                  defaultValue="Week"
                >
                  <MenuItem value="Week">Week</MenuItem>
                  <MenuItem value={20}>Month</MenuItem>
                  <MenuItem value={30}>Year</MenuItem>
                </Select>
              </div>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                style={{
                  background: "#FFCE21",
                  borderRadius: "15px",
                  boxShadow: "none",
                  textTransform: "capitalize",
                  fontWeight: 600,
                }}
              >
                Create Task
              </Button>
            </div>
          </Grid>
        </Grid>
        <div
          className={classes.dropdown_container}
          style={{ width: "fit-content", marginLeft: "20px" }}
        >
          <Typography
            component="h5"
            variant="h6"
            className={classes.dropdown__title}
          >
            Show :
          </Typography>
          <Select
            style={{ borderBottom: "0px" }}
            className={classes.dropdown__main}
            defaultValue="Week"
          >
            <MenuItem value="Week">All Schedule</MenuItem>
          </Select>
        </div>
        <Grid
          container
          spacing={3}
          style={{
            marginLeft: "20px",
            marginRight: "20px",
            marginTop: "30px",
            width: "98%",
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={9}
            className={classes.schedulePannelContainer}
          >
            <div className={classes.schedulePannelInsider}>
              <div className={classes.calendarControlPannel}>
                <div className={classes.calendarDateControl}>
                  <IconButton
                    onClick={this.prevMonth}
                    className={classes.prevButton}
                  >
                    <ArrowBackIosIcon />
                  </IconButton>
                  <IconButton
                    onClick={this.nextMonth}
                    className={classes.nextButton}
                  >
                    <ArrowForwardIosIcon />
                  </IconButton>
                  <div style={{ marginLeft: "25px" }}>
                    <Typography
                      component="h3"
                      variant="h4"
                      style={{ fontSize: "20px" }}
                    >
                      {format(date, "MMMM YYYY")}
                    </Typography>
                  </div>
                </div>
                <div className={classes.calendarViewControl}>
                  <div
                    className={classes.dropdown_container}
                    style={{ width: "fit-content" }}
                  >
                    <Typography
                      component="h5"
                      variant="h6"
                      className={classes.dropdown__title}
                    >
                      Show :
                    </Typography>
                    <Select
                      style={{ borderBottom: "0px" }}
                      className={classes.dropdown__main}
                      defaultValue="Week"
                    >
                      <MenuItem value="Month">Month</MenuItem>
                      <MenuItem value="Week">Week</MenuItem>
                    </Select>
                  </div>
                </div>
              </div>
              <div className={classes.calendar}>
                {weeks.map((week, index) => (
                  <CalendarCell
                    containerStyle={{ borderRight: "none" }}
                    key={index}
                    value={week}
                  />
                ))}
                {Array.from({ length: prefixDays }).map((_, index) => (
                  <CalendarCell key={index} value="" />
                ))}

                {Array.from({ length: numDays }).map((_, index) => {
                  const thisDate = index + 1;
                  const isCurrentDate = thisDate === date.getDate();
                  return (
                    <CalendarCell
                      key={thisDate}
                      value={thisDate}
                      currentDate={{
                        day: thisDate,
                        month: date.getMonth(),
                        year: date.getFullYear(),
                      }}
                      events={eventList}
                      isActive={isCurrentDate}
                      onClick={() => this.handleClickDate(thisDate)}
                    />
                  );
                })}

                {Array.from({ length: suffixDays }).map((_, index) => (
                  <CalendarCell key={index} value="" />
                ))}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
            <div style={{ overflow: "auto", height: "calc(100vh - 120px)" }}>
              {[...new Array(15)].map((item, index) => (
                <TaskItem key={index} completed={index % 2 === 0} />
              ))}
            </div>
          </Grid>
        </Grid>
        <ToastContainer theme="dark" />
      </>
    );
  }
}

export default withTheme(
  withStyles((theme: Theme) =>
    createStyles({
      calendar: {
        width: "101%",
        display: "grid",
        gridTemplateColumns: "repeat(7, 1fr)",
        gridTemplateRows: "repeat(7,130px)",
        borderTop: " 2px solid #b6b6b6",
      },
      schedulePannelContainer: {
        background: "#f9f9fa",
        borderRadius: "25px",
        padding: "30px !important",
        boxSizing: "border-box",
      },
      schedulePannelInsider: {
        background: "white",
        borderRadius: "25px",
        boxSizing: "border-box",
        overflowX: "hidden",
      },
      calendarControlPannel: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        maxWidth: "90%",
        margin: "0 auto",
        paddingTop: "20px",
        paddingBottom: "20px",
      },
      calendarDateControl: {
        display: "flex",
        alignItems: "center",
      },
      calendarViewControl: {
        display: "flex",
        alignItems: "center",
      },
      prevButton: {
        background: "#f9f9fa",
        borderRadius: "20px 0px 0px 20px",
        padding: "15px 20px",
        borderRight: "1px solid #b6b6b6",
      },
      nextButton: {
        background: "#f9f9fa",
        borderRadius: "0px 20px 20px 0px",
        padding: "15px 20px",
      },
      darkIconButton: {
        background: "#f3f2f5",
      },
      action__buttons__item: {
        display: "flex",
        alignItems: "center",
      },
      dropdown_container: {
        display: "flex",
        background: "#f3f2f5",
        borderRadius: "20px",
        padding: "5px 20px",
        alignItems: "center",
      },
      dropdown__title: {
        fontSize: "18px",
        color: "#A19DAF",
        fontWeight: "bolder",
      },
      dropdown__main: {
        marginLeft: "10px",
        fontSize: "18px",
        fontWeight: "bolder",
        "&:hover": {
          borderBottom: "0px",
        },
        "&::before": {
          borderBottom: "0px !important",
        },
        "&::after": {
          borderBottom: "0px !important",
        },
        "&:active": {
          borderBottom: "0px !important",
        },
      },
      row: {
        display: "flex",
        alignItems: "center",
        gap: "15px",
      },
    })
  )(ProjectTaskCalendar)
);
