import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
import {
  AuthContext,
  AuthContextType,
} from "../../email-account-login/src/AuthContext/AuthContext.web";

import {} from "react-router-dom";
import { toast } from "react-toastify";
import CreateRestApiMessage from "../../../components/src/Util/CreateRestApiMessage.web";

export interface UserGroup {
  id: string;
  type: string;
  attributes: {
    id: number;
    user_group: string;
    description: string;
  };
}
export interface IUser{
  id:string;
  type:string
  attributes:{
    user_name:string;
    email:string;
    user_group:string;
    name:string;
    client_id:string;
    client_name:string;
    contact:string;
  }
}

export interface Props {
  navigation: any;
  id: string;
  classes?: any;
}

interface S {
  createUserModal: boolean;
  createUserLoading: boolean;
  createUserError: any;
  userGroupList: UserGroup[];
  getUserGroupLoading: boolean;
  editUserModal:boolean;
  deleteUserModal:boolean;
  deleteLoading:boolean;
  updateLoading:boolean;
  getUserLoading:boolean;
  userList:IUser[];
  pagination:{
    currentPage:number;
    totalPage:number;
    perPage:number;
  },
  selectedUser?:IUser;
}

interface SS {
  id: any;
}

export default class AdministrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  static contextType: any = AuthContext;
  apiCreateUserCallId: string = "";
  apiGetAllUserGroupCallId: string = "";
  apiGetAllUserListCallId: string = "";
  apiDeleteUserCallId: string = "";
  apiUpdateUserCallId:string="";
  deleteUserId:string="";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      createUserModal: false,
      createUserError: {},
      createUserLoading: false,
      userGroupList: [],
      getUserGroupLoading: false,
      deleteUserModal:false,
      editUserModal:false,
      deleteLoading:false,
      updateLoading:false,
      getUserLoading:false,
      userList:[],
      pagination:{
        currentPage:1,
        totalPage:0,
        perPage:5
      }
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson === undefined) {
      return;
    } else if (
      responseJson &&
      responseJson.errors &&
      responseJson.errors[0].token
    ) {
      this.props.navigation.navigate("EmailAccountLoginBlock");
      return;
    }
    if (this.apiCreateUserCallId === apiRequestCallId) {
      this.handleCreateUserResponse(responseJson);
    }
    if (this.apiGetAllUserGroupCallId === apiRequestCallId) {
      this.handleGetAllUserGroupResponse(responseJson);
    }
    if(this.apiDeleteUserCallId === apiRequestCallId) {
      this.handleDeleteUserResponse(responseJson);
    }
    if(this.apiUpdateUserCallId === apiRequestCallId) {
      this.handleUpdateUserResponse(responseJson);
    }
    if (this.apiGetAllUserListCallId === apiRequestCallId) {
       this.setState(prevState=>({
          userList:responseJson?.data?.length>0?responseJson?.data:[],
          getUserLoading:false,
          pagination:{
            ...prevState.pagination,
            totalPage:responseJson?.data?.length,
            currentPage:1
          }
       }))
    }
  }


  handleCreateUserResponse = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({
        createUserLoading: false,
        createUserError: {},
        createUserModal: false,
      });
      this.getAllUserList();
      toast("User Created Successful");
    } else if (responseJson?.errors) {
      const newErrors: string[] = responseJson.errors[0]?.user?.map(
        (er: string) => {
          toast(er);
          return er;
        }
      );
      this.setState({
        createUserLoading: false,
        createUserError: {
          message: newErrors?.join(",\n"),
        },
      });
    } else if (responseJson?.message) {
      toast.error(responseJson.message);
      this.setState({
        createUserLoading: false,
        createUserError: {
          message: responseJson.message,
        },
      });
    }
  };
  handleGetAllUserGroupResponse = (responseJson: any) => {
    if (responseJson?.response?.data) {
      this.setState({
        getUserGroupLoading: false,
        userGroupList: responseJson?.response?.data,
      });
    } else {
      this.setState({
        getUserGroupLoading: false,
      });
    }
  };
  handleUpdateUserResponse=(responseJson:any)=>{
    if(responseJson?.data?.id){
        this.setState({
          updateLoading:false,
          editUserModal:false
        })
        this.getAllUserList();
        toast("User Updated Successful")
    }else if(responseJson?.errors){
        this.setState({
          createUserLoading:false
        })
        responseJson?.errors[0]?.user?.map((er:string)=>{
          toast.warn(er);
        })
   }
    
  }  
  handleDeleteUserResponse=(responseJson:any)=>{
     this.setState({
      deleteLoading:false,
      deleteUserModal:false,
     })
     this.getAllUserList();
    toast(responseJson?.message)
    
  }  


  async componentDidMount() {
    this.getAllUserList();
    this.getAllUserGroups();
  }
  handleNext=()=>{
    const {pagination:{currentPage,totalPage,perPage}}=this.state;
      if (currentPage<Math.ceil(totalPage / perPage)) {
        this.setState(prevState=>({
              pagination:{
                ...prevState.pagination,
                currentPage:prevState.pagination.currentPage+1
              }
          }))
       }
   
  }
  handlePrev=()=>{
  const {pagination:{currentPage,}}=this.state;
   if (currentPage> 1) {
    this.setState(prevState=>({
       pagination:{
         ...prevState.pagination,
         currentPage:prevState.pagination.currentPage-1
       }
    }))
   }
    
  }
  getAllUserGroups = () => {
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetAllUserGroupCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllUserGroupCallId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }; 
  getAllUserList = () => {
    this.setState({
      getUserLoading:true
    })
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };

    const requestMessage = CreateRestApiMessage({
      header:header,
      apiUrl:configJSON.getClientUserApiURl,
      body:null,
      method:configJSON.getApiMethodType
    })

    this.apiGetAllUserListCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createNewUser = (newUser: any) => {
    this.setState({
      createUserLoading: true,
    });
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };

    const httpBody = {
      data: newUser,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCreateUserCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createUserFromClientCallId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  deleteUser=()=>{
    this.setState({
      deleteLoading:true
    })
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };
    const requestMessage = CreateRestApiMessage({
        header:header,
        apiUrl:`${configJSON.deleteUserApiUrl}/${this.deleteUserId}`,
        body:null,
        method:configJSON.deleteApiMethodType
      })

    this.apiDeleteUserCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  updateUser=(newUser:any,userId:string)=>{
    this.setState({
      updateLoading:true
    })
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };

    const httpBody={
      data:newUser
    }

    const requestMessage = CreateRestApiMessage({
        header:header,
        apiUrl: `${configJSON.updateUserApiUrl}/${userId}`,
        body: JSON.stringify(httpBody),
        method:configJSON.putApiMethodType
      })
    this.apiUpdateUserCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // handle ui changes

  changeCreateUserModal = (open: boolean) => {
    this.setState({
      createUserModal: open,
    });
  };
  changeUpdateUserModal = (open: boolean,user?:IUser) => {
    if(user){
      this.setState({
        selectedUser:user,
        editUserModal:open
      })
    }else{
      this.setState({
        editUserModal: open,
      });
    }
    
  };
  changeDeleteUserModal = (open: boolean,id?:string) => {
    if(id){
      this.deleteUserId=id;
    }
   this.setState({
      deleteUserModal: open,
    });
  };
  

  getClientId = (): string => {
    const { currentUser } = this.context as AuthContextType;
    return currentUser?.account?.client_id;
  };
  getClientName = (): string => {
    const { currentUser } = this.context as AuthContextType;
    return currentUser?.account?.name;
  };

  addNewSystem = () => {
    const { currentUser } = this.context as AuthContextType;
    this.props.navigation.navigate("AddNewSystem", {
      clientId: currentUser?.account?.client_id,
    });
  };
}
