import React from "react";

import ForgotPasswordController, {
  Props,
} from "./ForgotPasswordController.web";
import { backgroundCoverImage } from "./assets";
import "../assets/ForgotPassword.css";
import { StyleSheet, ImageBackground, Image } from "react-native";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CircularProgress from "@material-ui/core/CircularProgress";

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { navigation } = this.props;
    const {requestLoading}=this.state;

    return (
      <Grid
        container
        style={{
          height: "100vh",
        }}
      >
        <ImageBackground source={backgroundCoverImage} style={styles.bgImage}>
          <Button
            onClick={() => navigation.navigate("EmailAccountLoginBlock")}
            style={{
              boxShadow: "none",
              width: "100px",
              height: "40px",
              borderRadius: "15px",
              background: "#f9f9fa",
              textTransform: "capitalize",
              margin: "20px",
            }}
            className="backButton"
            variant="contained"
            startIcon={<ArrowBackIosIcon />}
          >
            Back
          </Button>

          <Box
            sx={{
              width: 360,
              height: 400,
              boxShadow: 1,
            }}
            className="forgot-password-box"
          >
            <Grid
              className="login-box-header"
              container
              direction="row"
              alignItems="center"
            >
              <Grid item>
                <Image
                  source={require("../assets/oriana_power-logo.png")}
                  style={styles.logoImage}
                />
              </Grid>
              <Grid item className="logo-text">
                ORIANA POWER
              </Grid>
            </Grid>

            <Grid className="login-form-header-grid">
              <h2 className="login-form-header">Forgot Password</h2>
              <div className="login-form-sub-header">
                Welcome back, administrator
              </div>
            </Grid>

            <Grid className="login-form-grid">
              <form>
                <InputTextField
                  label="Enter Email"
                  variant="filled"
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                  }}
                  value={this.state.emailValue}
                  onChange={(e) => this.handleTxtInputEmail(e)}
                />
              </form>
              <ActionRoundButton
                style={{ fontWeight: "bold" }}
                variant="contained"
                onClick={() => this.doForgotPassword()}
                disabled={requestLoading}
              >
                {requestLoading? <CircularProgress size={24} />:"Send link" }
               
               
              </ActionRoundButton>
            </Grid>
          </Box>
        </ImageBackground>
      </Grid>
    );
  }
}

const styles = StyleSheet.create({
  bgImage: {
    width: "100%",
    height: "100%",
  },
  logoImage: {
    height: "32px",
    width: "32px",
  },
});

const ActionRoundButton = withStyles({
  root: {
    background: "#ffc100",
    borderRadius: 20,
    height: 48,
    width: "100%",
    marginTop: 50,
    "&:hover": {
      background: "#ffc100",
    },
  },
  label: {
    textTransform: "capitalize",
  },
})(Button);

const InputTextField = withStyles({
  root: {
    "& .MuiFilledInput-root": {
      borderRadius: `15px`,
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "#ffc100",
    },
  },
})(TextField);
