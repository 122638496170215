import React, { useContext } from "react";
import { ViewPagerAndroidComponent } from "react-native";
import { ClientType, UserGroup, UserGroupResponseType } from "./Interfaces";
import {
  ClientListResponseType,
  UserCreateType,
  UserListResponseType,
} from "./UserController.web";
interface ActionType {
  alert: string;
  action: string;
}
interface UserContextType {
  value: number;
  userModal: boolean;
  updateUserModal: boolean;
  userList: UserListResponseType;
  userGroupList: UserGroup[];
  allClientList: ClientType[];
  clientList: ClientListResponseType;
  getUserLoading: boolean;
  createUserLoading: boolean;
  getUserGroupsLoading: boolean;
  getClientsLoading: boolean;
  deleteUserLoading: boolean;
  loadedGroupPage: number;
  groupPage: number;
  getPaginatedGroupLoading: boolean;
  userPage: number;
  loadedUserPage: number;
  getAllClientLoading: boolean;
  addedClientPage: number;
  loadedAddedClientPage: number;
  pUserGroupList: UserGroupResponseType;
  editUserGroupModal: boolean;
  addUserGroupModal: boolean;
  updateUserGroupLoading: boolean;
  getEditorLoading: boolean;
  editorList: any[];
  getUserLists: (page?: number) => void;
  getUserGroups: () => void;
  getClientLists: (page?: number) => void;
  getAllClient: () => void;
  handleChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
  changeUserModal: (open: boolean) => void;
  changeUpdateUserModal: (open: boolean) => void;
  createNewUser: (newUser: UserCreateType) => void;
  updateUser: (newUser: UserCreateType, userId: string) => void;
  deleteUser: (userId?: string) => void;
  changeEditUserGroupModal: (open: boolean, group?: any) => void;
  changeAddUserGroupModal: (open: boolean) => void;
  updateUserGroup: (value: any, groupId: string) => void;
  createNewUserGroup: (value: any) => void;
  getPaginatedUserGroups: (page?: number) => void;
  getAllEditors: () => void;
}
const defaultContext: UserContextType = {
  value: 0,
  userModal: false,
  userList: {
    response: {
      data: [],
    },
    length: 0,
  },
  clientList: {
    response: {
      data: [],
    },
    length: 0,
  },
  pUserGroupList: {
    response: {
      data: [],
    },
    length: 0,
    numOfItem: 6,
  },
  userGroupList: [],
  getUserLoading: true,
  createUserLoading: false,
  getUserGroupsLoading: true,
  getClientsLoading: true,
  deleteUserLoading: false,
  updateUserModal: false,
  userPage: 1,
  loadedUserPage: 1,
  addedClientPage: 1,
  allClientList: [],
  getAllClientLoading: true,
  loadedAddedClientPage: 1,
  editUserGroupModal: false,
  updateUserGroupLoading: false,
  addUserGroupModal: false,
  getPaginatedGroupLoading: false,
  groupPage: 1,
  loadedGroupPage: 1,
  getEditorLoading: false,
  editorList: [],
  getUserLists: (page?: number) => {},
  getUserGroups: () => {},
  getClientLists: (page?: number) => {},
  getAllClient: () => {},
  handleChange: (event: React.ChangeEvent<{}>, newValue: number) => {},
  changeUserModal: (open: boolean) => {},
  changeUpdateUserModal: (open: boolean) => {},
  createNewUser: (newUser: UserCreateType) => {},
  updateUser: (newUser: UserCreateType, userId: string) => {},
  deleteUser: (userId?: string) => {},
  changeEditUserGroupModal: (open: boolean, group?: any) => {},
  changeAddUserGroupModal: (open: boolean) => {},
  updateUserGroup: (value: any, groupId: string) => {},
  createNewUserGroup: (value: any) => {},
  getPaginatedUserGroups: (page?: number) => {},
  getAllEditors: () => {},
};

export const UserContext = React.createContext<UserContextType>(defaultContext);

export function useUserContext() {
  return useContext(UserContext);
}
