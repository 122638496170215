import React from "react";
import {
  Grid,
  Typography,
  makeStyles,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { IPrivilege } from "../../../blocks/AdminConsole3/src/user/UserController.web";

const useStyles = makeStyles({
  text: {
    fontWeight: "bold",
    color: "#8F92A1",
    fontSize: "14px",
  },
});

interface Props {
  privilege: IPrivilege;
  changeRootPrivileges: (id: number, allow: boolean) => void;
  chngeSubPrivileges: (id: number, key: string, value: string) => void;
}

const PrivilegeItem = ({
  privilege,
  changeRootPrivileges,
  chngeSubPrivileges,
}: Props) => {
  const styles = useStyles();

  const getRootPriv = () => {
    const isSame = new Set(Object.values(privilege.permissions)).size === 1;
    if (isSame && Object.values(privilege.permissions)[0] === "allow") {
      return "Allow_All";
    } else if (isSame && Object.values(privilege.permissions)[0] === "deny") {
      return "Deny_All";
    }
  };

  const handleRootChange = (e: any) => {
    if (e.target.value === "Allow_All") {
      changeRootPrivileges(privilege.id, true);
    } else {
      changeRootPrivileges(privilege.id, false);
    }
  };
  const handleSubPrivChange = (key: string, value: string) => {
    chngeSubPrivileges(privilege.id, key, value);
  };

  const getPrivilegeName = (index: number) => {
    const arr = ["View", "Create", "Change", "Delete"];
    return arr[index];
  };

  return (
    <Grid container spacing={2} style={{ marginTop: "15px" }}>
      <Grid item xs={6} sm={4} md={4} xl={4}>
        <Typography variant="h5" className={styles.text}>
          {privilege.name}
        </Typography>
        <RadioGroup
          style={{ marginTop: "10px" }}
          aria-label="gender"
          name="gender1"
          value={getRootPriv()}
          onChange={handleRootChange}
        >
          <FormControlLabel
            value="Allow_All"
            control={<Radio color="primary" />}
            label="Allow all"
          />
          <FormControlLabel
            value="Deny_All"
            control={<Radio color="primary" />}
            label="Deny all"
          />
        </RadioGroup>
      </Grid>
      {Object.keys(privilege.permissions).map((permissionKey: any, index) => {
        //@ts-ignore
        const keyName = permissionKey as keyof typeof privilege.permissions;
        return (
          <Grid key={keyName + index} item xs={6} sm={2} md={2} xl={2}>
            <Typography variant="h5" className={styles.text}>
              {getPrivilegeName(index)}
            </Typography>
            <RadioGroup
              style={{ marginTop: "10px" }}
              aria-label="gender"
              name="gender1"
              value={privilege.permissions[keyName]}
              onChange={(e: any) =>
                handleSubPrivChange(permissionKey, e.target.value)
              }
            >
              <FormControlLabel
                value={"allow"}
                control={<Radio color="primary" />}
                label="Allow"
              />
              <FormControlLabel
                value={"deny"}
                control={<Radio color="primary" />}
                label="Deny"
              />
            </RadioGroup>
          </Grid>
        );
      })}
    </Grid>
  );
};
export default React.memo(PrivilegeItem);
