Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.getApiMethodType = "GET";
exports.postApiMethodType = "POST";
exports.putApiMethodType = "PUT";
exports.deleteApiMethodType = "DELETE";
exports.contentType = "application/json";

exports.getAccountProfileEndPoint = "bx_block_profile/profiles/profile_detail";
exports.getAccountUserListEndPoint =
  "account_block/account/display_super_admin_accounts";
exports.postAccountUserEndPoint = "bx_block_profile/create_account";
exports.deleteAccountUserEndPoint = "bx_block_profile/delete_account";
exports.updateAccountProfileEndPoint = "bx_block_profile/profiles/modify";
exports.uploadProfileAvatarEndPoint = "bx_block_profile/profiles/avatar";

exports.errorPasswordNotValid =
  "Password not valid, must have atleast 6 characters containing 1 upercase, 1 lowercase, 1 special symbol and 1 number.";
exports.errorUsernameNotValid = "Username not valid";
exports.errorEmailNotValid = "Email is not valid";
exports.errorContactNotValid = "Contact not valid";
exports.emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
exports.passwordRegExp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/;
exports.phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
   