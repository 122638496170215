import {
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import CustomBarChart from "../Graph/CustomBarChart.web";
import ChartPoint from "../Graph/ChartPoint.web";
import { ISystemInverterInfo, ISystemMeterInfo } from "../../../blocks/LandingPage2/src/interfaces.types";
import CustomSorting from "../Util/CustomSorting.web";

const useStyles = makeStyles({
  root__container: {
    marginTop: "10px",
  },
  paperContainer: {
    border: "none",
    boxShadow: "none",
    padding: "10px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  card__shadow__text: {
    color: "#A19DAF",
    fontSize: ".85rem",
    fontWeight: 600,
    textAlign: "center",
  },
  card__bold__text: {
    color: "black",
    fontSize: ".95rem",
    fontWeight: 600,
    maxWidth: "120px",
    textAlign: "center",
  },
  card__small__bold__text: {
    color: "black",
    fontSize: ".70rem",
    fontWeight: 600,
    textAlign: "center",
  },
  heading__text: {
    color: "black",
    fontSize: "1rem",
    fontWeight: 600,
  },
  dropdown_container: {
    display: "flex",
    background: "#f3f2f5",
    borderRadius: "20px",
    padding: "10px 20px",
    alignItems: "center",
  },
  dropdown__title: {
    fontSize: "12px",
    color: "#A19DAF",
    fontWeight: "bolder",
  },
  dropdown__main: {
    marginLeft: "10px",
    fontSize: "14px",
    fontWeight: "bolder",
    "&:hover": {
      borderBottom: "0px",
    },
    "&::before": {
      borderBottom: "0px !important",
    },
  },
  darkIconButton: {
    background: "#f3f2f5",
  },
  row: {
    display: "flex",
    alignItems: "center",
    gap: "15px",
    boxSizing: "border-box",
    marginTop: "-30px",
  },
});

interface Props{
  getMeterInfoLoading:boolean,
  getEnergyGraphLoading:boolean,
  energyGraphData:any[],
  inverterDetails:ISystemInverterInfo,
  meterDetails:ISystemMeterInfo,
  onEnergyGraphFilterChange:(filterBy:string,date:{
     day:number;
     month:number;
     year:number;
   })=>void;
}

const CockpitPage = ({
  getMeterInfoLoading,
  getEnergyGraphLoading,
  inverterDetails,
  meterDetails,
  energyGraphData,
  onEnergyGraphFilterChange
}:Props) => {
  const styles = useStyles();

  return (
    <>
      <Grid
        justifyContent="space-between"
        container
        spacing={10}
        className={styles.root__container}
      >
        <Grid container spacing={3} item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Paper className={styles.paperContainer}>
              <Typography
                component="h3"
                variant="h6"
                className={styles.card__shadow__text}
              >
                INSTALLED POWER
              </Typography>
              <Typography
                component="h3"
                variant="h5"
                className={styles.card__bold__text}
              >
                100.30 kWp
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Paper className={styles.paperContainer}>
              <Typography
                component="h3"
                variant="h6"
                className={styles.card__shadow__text}
              >
                INVERTERS
              </Typography>
              <Typography
                component="h3"
                variant="h5"
                className={styles.card__bold__text}
              >
                2 X 20000UE
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Paper className={styles.paperContainer}>
              <Typography
                component="h3"
                variant="h6"
                className={styles.card__shadow__text}
              >
                MODULES
              </Typography>
              <Typography
                component="h3"
                variant="h5"
                className={styles.card__bold__text}
              >
                264 x Navitas Solar Navitas 380
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Paper className={styles.paperContainer}>
              <Typography
                component="h3"
                variant="h6"
                className={styles.card__shadow__text}
              >
                COMMISSIONING
              </Typography>
              <Typography
                component="h3"
                variant="h5"
                className={styles.card__bold__text}
              >
                {meterDetails?.commisioning}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Paper className={styles.paperContainer}>
              <Typography
                component="h3"
                variant="h6"
                className={styles.card__shadow__text}
              >
                OPERATOR
              </Typography>
              <Typography
                component="h3"
                variant="h5"
                className={styles.card__bold__text}
              >
                {meterDetails?.operated_by}
              </Typography>
              <Typography
                component="h3"
                variant="h5"
                className={styles.card__small__bold__text}
              >
                {meterDetails?.operated_email}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Paper className={styles.paperContainer}>
              <Typography
                component="h3"
                variant="h6"
                className={styles.card__shadow__text}
              >
                MONITORING
              </Typography>
              <Typography
                component="h3"
                variant="h5"
                className={styles.card__bold__text}
              >
                {meterDetails?.monitered_by}
              </Typography>
              <Typography
                component="h3"
                variant="h5"
                className={styles.card__small__bold__text}
              >
                {meterDetails?.monitered_email}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Paper className={styles.paperContainer}>
              <Typography
                component="h3"
                variant="h6"
                className={styles.card__shadow__text}
              >
                LAST DATA IMPORT
              </Typography>
              <Typography
                component="h3"
                variant="h5"
                className={styles.card__bold__text}
              >
                    {new Date(meterDetails?.data_date_time)?.toDateString()} at {new Date(meterDetails?.data_date_time)?.toLocaleTimeString()}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          {/* right side header part */}
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
              <Typography
                component="h3"
                variant="h5"
                className={styles.heading__text}
              >
                Energy Generation
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={7} lg={8} xl={8}>
              <div className={`${styles.row}`}>
                  <CustomSorting
                    onChange={onEnergyGraphFilterChange}
                    />
              </div>
            </Grid>
          </Grid>
          {/* chart section  */}

          <div style={{ width: "100%", height: "500px", marginTop: "20px",display:"flex",alignItems:"center",justifyContent:"center" }}>
             {
              getEnergyGraphLoading && <CircularProgress size={50} />
             }
             {
                !getEnergyGraphLoading && energyGraphData?.length===0 && <Typography component="h4" variant="h4">No Data Available</Typography>
             }
              {
                !getEnergyGraphLoading && energyGraphData?.length>0 && (
                  <CustomBarChart 
                    data={energyGraphData}
                  />
                )
              }
           
          </div>
          <ChartPoint />
        </Grid>
      </Grid>
    </>
  );
};

export default CockpitPage;
