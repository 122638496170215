import React, { ReactComponentElement } from "react";
import { ToastContainer } from "react-toastify";
import {
  Typography,
  IconButton,
  Button,
  Stepper,
  Step,
  StepLabel,
} from "@material-ui/core";
import "../../assets/report.css";
import SettingsIcon from "@material-ui/icons/Settings";
import { Theme } from "@material-ui/core/styles";
import NewPortfolioReportController, {
  Props,
  configJSON,
} from "./NewPortfolioReportController.web";
import GeneralSettingPage from "../../../../components/src/ReportsComponents/GeneralSettingPage.web";
import ContentPage from "../../../../components/src/ReportsComponents/ContentPage.web";
import SummeryPage from "../../../../components/src/ReportsComponents/SummeryPage.web";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {NewPortfolioContext} from './NewPortfolioReportContext.web'
import ListAltIcon from '@material-ui/icons/ListAlt';
import BallotOutlinedIcon from '@material-ui/icons/BallotOutlined';
const styles = (theme: Theme) => ({
  action__buttons__item: {
    display: "flex",
    alignItems: "center",
  },
});
const steps: any[] = [
  {
    title: "General Settings",
    icon: <SettingsIcon style={{color:"red"}}/>,
  },
  {
    title: "Content",
    icon: <SettingsIcon />,
  },
  {
    title: "Summary",
    icon: <BallotOutlinedIcon />,
  },
];

const getStepIcon=(index:number,active:boolean)=>{
    if(index===0){
      return <SettingsIcon style={active ? {color:"black"}:{color:"#b6b6b6"}}/>
    }if(index===1){
      return <ListAltIcon style={active ? {color:"black"}:{color:"#b6b6b6"}}/>
    }if(index===2){
      return <BallotOutlinedIcon style={active ? {color:"black"}:{color:"#b6b6b6"}}/>
    }
}


class NewPortfolioReport extends NewPortfolioReportController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { activeStep ,getLoading} = this.state;
    return (
      <NewPortfolioContext.Provider value={{...this,...this.state}}>
        <Backdrop style={{zIndex:100,color:"#fff"}} open={getLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="new__portfolio__report__body__heading">
          <div className="new__portfolio__report__body__heading__left">
            <Typography
              component="h3"
              variant="h5"
              style={{ fontWeight: "bolder",marginTop:"15px",marginBottom:"15px",fontSize:"30px" }}
            >
              New Portfolio Report
            </Typography>
          </div>
        </div>
        <div className="new__portfolio__report__container">
          <div className="new__portfolio__report__body__container">
            <div className="new__portfolio__report__wrapper">
              <div className="new__portfolio__reports__tabs">
                {/* hello */}
                {
                  steps?.map((item,index)=>
                    <div className="new__portfolio__reports__tab__item" >
                       <div onClick={()=>this.changeActiveStep(index)} className={`tab__content ${index===activeStep && `portfolio__active__tab`}`}>
                          {getStepIcon(index,index<=activeStep)}
                          <Typography 
                              style={
                                  index<=activeStep?
                                  {fontWeight:600,color:"black",width:"max-content"}
                                  :{fontWeight:300,color:"#b6b6b6",width:"max-content"}} 
                                  component="h5">
                                    {item?.title}
                          </Typography>
                      </div>
                      {
                        index+1<steps?.length && <div className="tab__stepper" />
                      }

                      
                   </div>
                )
                }
              </div>
              <div className="portfolio__report__content">
                {
                  activeStep===0 ?
                      <GeneralSettingPage/> :
                      activeStep===1?
                       <ContentPage/>:
                       <SummeryPage/>
                }
              </div>




            </div>
          </div>
        </div>
        <ToastContainer theme="dark" />
      </NewPortfolioContext.Provider>
    );
  }
}

export default NewPortfolioReport;
