import React from "react";
import LandingPage2Controller from "./LandingPage2Controller.web";
import {
  Grid,
  Paper,
  Typography,
  Card,
  Button,
  IconButton,
  Select,
  MenuItem
} from "@material-ui/core";
import '../assets/dashboard.css'
import {
  createStyles,
  Theme,
  withStyles,
  withTheme,
} from "@material-ui/core/styles";
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined';
import { ArrowForwardIosOutlined } from "@material-ui/icons";
import { CartesianGrid, Line, LineChart, ResponsiveContainer } from "recharts";
import Skeleton from "@material-ui/lab/Skeleton";
import TableLoader from "../../../components/src/Util/TableLoader.web";
import MapWeb from "../../../components/src/Util/MapWeb";
import { ToastContainer } from "react-toastify";

const COLOR_HIGHT_CONTRAST = "#8884d8";
const PARENT___HEIGHT = 700;
export class LandingPage2 extends LandingPage2Controller {
  render() {
    const { leftSideBarFullScreen: leftFull, clientList, dashboardDetails, loading, getClientLoading, getSystemsLoading, systemList, selectedClientId, todaysGeneratedEnergy, todaysPerformance, todaysPerformanceUpDown, graffPoints } = this.state;
    const { classes } = this.props;

    return (
      <div className="dashboardContainer">
        <div className="dashboard_top">
          <Typography variant="h3" style={{ fontSize: "34px", marginTop: "10px", fontWeight: "bold" }}>Dashboard</Typography>

          {
            loading ?
              (<Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={3}>
                  <Skeleton variant="rect" width="100%" height={230} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Skeleton variant="rect" width="100%" height={230} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Skeleton variant="rect" width="100%" height={230} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Skeleton variant="rect" width="100%" height={230} />
                </Grid>
              </Grid>) :
              (<Grid style={{ marginTop: "0px" }} container spacing={4}>
                <Grid item xs={12} sm={6} md={3}>
                  {/* some comments */}
                  <Paper
                    className="paperContainer"
                    style={{ background: "#ffc100", borderRadius: "15px", boxSizing: "border-box", height: "202px", border: "1px solid #CFCFCF" }}
                    elevation={10}
                  >
                    <Typography
                      variant="h5"
                      className="cardText"
                      style={{
                        color: "#0052CC",
                        textAlign: "center",
                        fontWeight: "bolder",
                        fontSize: "30px"
                      }}
                    >
                      <> {dashboardDetails?.clients_count}</> Clients
                    </Typography>
                    <Typography style={{ textAlign: "center", fontSize: "12px", fontWeight: "bold", marginBottom: "5px" }}>WITH</Typography>
                    <Typography
                      style={{
                        fontWeight: "bolder",
                        fontSize: "30px",
                        color: "#0052CC"
                      }}
                      variant="h5"
                      className="cardText"
                    >
                      <>{dashboardDetails?.plants_count}</> Plants
                    </Typography>
                    <Typography style={{ textAlign: "center", fontSize: "12px", fontWeight: "bold", marginBottom: "5px" }}>
                      IN TOTAL
                    </Typography>
                    <br />
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Paper
                    elevation={0}
                    style={{ borderRadius: "15px" }}
                    className="paperContainer"
                  >
                    <div style={{ display: "flex", alignItems: "center", backgroundColor: "white" }}>
                      {/* TODO: change arrow RRR*/}
                      <img style={{ width: "12px", height: "18px", marginRight: "5px" }} src={this.getUpDownArrowByBool(!dashboardDetails.is_energy_minus)} alt="up arrow img" />
                      <p style={{
                        textAlign: "center", color: `${this.getColorCodeByBool(!dashboardDetails.is_energy_minus)}`,
                        fontSize: "14px", fontWeight: "bold"
                      }}>{dashboardDetails?.energy_up_down_number}</p>
                    </div>

                    <Typography
                      variant="h5"
                      className="cardText"
                      style={{
                        color: "#0052CC",
                        textAlign: "center",
                        fontWeight: "bolder",
                        fontSize: "30px",
                      }}
                    >
                      {dashboardDetails?.total_energy_genrated}
                    </Typography>

                    <Typography style={{ textAlign: "center", fontSize: "12px", fontWeight: "bold", marginBottom: "5px" }}>TOTAL ENERGY GENERATED</Typography>
                    <div className={classes.dropdown_container}>
                      <Typography
                        component="h5"
                        variant="h6"
                        className={classes.dropdown__title}
                      >
                        Filter By :
                      </Typography>
                      <Select
                        style={{ borderBottom: "0px" }}
                        className={classes.dropdown__main}
                        id="filterby"
                        onChange={(e) => {
                          this.handleOnchangeFilterbyValue(e.target.value)
                        }}
                        value={this.state.selectedYear}
                      >
                        <MenuItem value={new Date().getFullYear().toString()}>This Year</MenuItem>
                        <MenuItem value={(new Date().getFullYear() - 1).toString()}>Previous Year</MenuItem>
                        <MenuItem value={(new Date().getFullYear() - 2).toString()}>{(new Date().getFullYear() - 2).toString()}</MenuItem>
                      </Select>
                    </div>
                    <br />
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Paper
                    elevation={0}
                    style={{ borderRadius: "15px" }}
                    className="paperContainer"
                  >
                    <div className="thirdPaperContent">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="h5"
                          className="cardText"
                          style={{
                            color: "#0052CC",
                            textAlign: "center",
                            fontWeight: "bolder",
                            fontSize: "30px",
                          }}
                        >
                          {dashboardDetails?.number_of_systems?.toString()}
                        </Typography>
                      </div>
                      <p style={{ textAlign: "center", marginTop: "13px", fontSize: "12px", fontWeight: "bold" }}>
                        NUMBER OF SYSTEMS
                      </p>
                    </div>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Paper
                    elevation={0}
                    style={{ borderRadius: "15px" }}
                    className="paperContainer"
                  >
                    <div className="thirdPaperContent">
                      <Typography
                        variant="h5"
                        className="cardText"
                        style={{
                          color: "#0052CC",
                          textAlign: "center",
                          fontWeight: "bolder",
                          fontSize: "30px"
                        }}
                      >
                        {dashboardDetails?.total_installed_capacity}
                      </Typography>
                      <p style={{ textAlign: "center", marginTop: "13px", fontSize: "12px", fontWeight: "bold" }}>
                        TOTAL INSTALLED CAPACITY
                      </p>
                    </div>
                  </Paper>
                </Grid>
              </Grid>)
          }
        </div>
        <div className={classes.mainBottomContainer}>
          <Typography
            component="h3"
            variant="h5"
            style={{
              fontSize: "18px",
              fontWeight: "bolder",
              marginBottom: "10px",
            }}
          >
            All Clients
          </Typography>
          <Grid container className={classes.bottomContainerInsider} spacing={4}>
            <Grid
              item
              xs={12}
              sm={12}
              md={leftFull ? 11 : 4}
              style={{ height: "100%", transition: ".5s" }}
              className="dashboard_divider"
            >
              <div className="dashboard_client_table">
                <table>
                  <thead>
                    <th style={{ justifyContent: 'center',display: 'flex',height: 'inherit',alignItems: 'center'}}>Perf.</th>
                    <th>Client</th>
                    <th>Plants</th>
                    <th>Total Power[KWp]</th>
                  </thead>
                  <tbody>
                    {getClientLoading ? <TableLoader count={6} column={4} /> : clientList?.map((client, index) => (
                      <tr key={client.id} id={`client_${client.id}`} style={{ cursor: "pointer" }} className={`border_bottom ${selectedClientId === client.id?'active':''}`} onClick={() => this.getSystemForClient(client.id)}>
                        <td style={{ width: "7%" }}>
                          <p style={{ color: "#71717b", display:'flex',justifyContent:'center' }}>{index + 1}. </p>
                        </td>
                        <td>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <div className="client_circle"></div>
                            <h4>{!leftFull ? `${client?.attributes?.name?.substring(0, 14)}..` : client?.attributes?.name}</h4>
                          </div>
                        </td>
                        <td>{client?.attributes?.systems} Plants</td>
                        <td>{client?.attributes?.total_power}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className={classes.dividerContainer}>
              <IconButton
                id="left_full"
                onClick={this.handleExtend}
                style={{ background: "rgb(236, 236, 236)", borderRadius: "7px" }}
              >
                <ArrowForwardIosOutlined
                  style={this.getStyleForLeftFullTable(leftFull)}
                />
              </IconButton>
            </div>
            </Grid>
            {!leftFull && getSystemsLoading ? (<Grid
              item
              xs={12}
              sm={12}
              md={8}
              lg={8}
              xl={8}
              style={{ marginTop: "14px" }}
            >
              <Grid container spacing={7}>
                <Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
                  <Skeleton variant="rect" width="100%" height={117} />
                </Grid>
                <Grid item xs={12} sm={8} md={8} lg={9} xl={10}>
                  <Skeleton variant="rect" width="100%" height={117} />
                </Grid>
              </Grid>
              <div className={classes.homeMapContainer}>
                <Skeleton variant="rect" width="100%" height="100%" />
              </div>
            </Grid>) : !leftFull && (
              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                style={{ marginTop: "14px" }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
                    <Card
                      elevation={0}
                      style={{
                        border: "1px solid #CFCFCF",
                        padding: "15px 10px",
                        borderRadius: "15px",
                        minHeight: "117px",
                        height:'100%',
                        alignItems:'center',
                        display: 'flex',
                        flexDirection: 'column',
                        alignContent: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <Typography
                        style={{
                          textAlign: "center",
                          fontSize: "22px",
                          fontWeight: "bolder",
                        }}
                        variant="h4"
                      >
                        {todaysGeneratedEnergy}
                      </Typography>
                      <h5
                        style={{
                          color: "rgb(171 175 177)",
                          textAlign: "center",
                          marginTop: "10px",
                          fontSize: "14px",
                          fontWeight: "bolder",
                        }}
                      >
                        Total Energy Generated (Today)
                      </h5>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={8} md={8} lg={9} xl={10}>
                    <Card elevation={0} className={classes.bottomRightCard}>
                      <div className={classes.bottomRightCardLeftContainer}>
                        <div style={{ display: "flex", alignItems: "center", backgroundColor: "white" }}>
                          <img
                            // TODO: Change arrow if false RRR 
                            src={this.getUpDownArrowByBool(todaysPerformanceUpDown)}
                            style={{
                              width: "12px",
                              height: "18px",
                              objectFit: "cover",
                              marginRight: "10px",
                            }}
                          />
                          <Typography
                            style={{
                              textAlign: "center",
                              color: `${this.getColorCodeByBool(todaysPerformanceUpDown)}`,
                              fontSize: "14px",
                              fontWeight: "bold",
                            }}
                            variant="h4"
                          >
                            {todaysPerformance}
                          </Typography>
                        </div>

                        <h5
                          style={{
                            color: "#8F92A1",
                            textAlign: "center",
                            marginTop: "10px",
                            fontSize: "12px",
                          }}
                        >
                          24 Hour Performance
                        </h5>
                      </div>
                      <div className={classes.bottomRightCardChartContainer}>
                        <ResponsiveContainer width="100%" height="100%">
                          <LineChart
                            data={graffPoints.map(item => { return { uv: item } })}
                            margin={{ top: 5, right: 30, bottom: 5 }}
                          >
                            <CartesianGrid
                              strokeDasharray="0 0"
                              strokeWidth={2}
                              horizontal={false}
                            />
                            <Line
                              type="monotone"
                              dataKey="uv"
                              stroke={COLOR_HIGHT_CONTRAST}
                              strokeWidth={3}
                            />
                          </LineChart>
                        </ResponsiveContainer>
                      </div>
                    </Card>
                  </Grid>
                </Grid>
                <div className={classes.homeMapContainer}>
                  <div className={classes.mapImg}>
                    <MapWeb zoom={this.state.zoom} systemList={systemList} />
                  </div>
                  <div className={classes.mapLeftBottom}>
                    <Button className={classes.viewDetailsButton} onClick={this.gotToClientDetailsPage}>
                      View Details
                    </Button>
                  </div>
                  <div className={classes.mapHandleButtons}>
                    <IconButton
                      onClick={this.handleMapZoomOut}
                      style={{
                        width: "50px",
                        height: "40px",
                        background: "#F3F4F5",
                        borderRadius: "15px",
                      }}
                    >
                      <RemoveOutlinedIcon />
                    </IconButton>
                    <IconButton
                      onClick={this.handleMapZoomIn}
                      style={{
                        width: "50px",
                        height: "40px",
                        background: "#F3F4F5",
                        borderRadius: "15px",
                      }}
                    >
                      <AddOutlinedIcon />
                    </IconButton>
                  </div>
                </div>
              </Grid>
            )}
          </Grid>
        </div>
        <ToastContainer theme="dark" />
      </div>
    )
  }
}
export default withTheme(
  withStyles((theme: Theme) =>
    createStyles({
      mainBottomContainer: {
        width: "100%",
        boxSizing: "border-box",
        backgroundColor: "#F2F3F5",
        borderRadius: "15px",
        padding: "20px",
        height:"100vh"
      },
      bottomContainerInsider: {
        background: "white",
        borderRadius: "10px",
        padding: "20px",
        marginTop: "10px",
        margin: "0px",
        width: "100%",
        [theme.breakpoints.up("sm")]: {
          height: "calc(100% - 47px)",
        },
      },
      itemText: {
        fontSize: "16px",
        fontWeight: 500,
      },
      bottomRightCard: {
        border: "1px solid #CFCFCF",
        padding: "15px 10px",
        borderRadius: "15px",
        minHeight: "117px",
        display: "flex",
        alignItems: "center",
        gap: "20px",
      },
      bottomRightCardLeftContainer: {
        flex: "1",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      },
      dividerContainer: {
        background: "rgb(236, 236, 236)",
        width: "6px",
        maxWidth: '3%',
        marginLeft: "10px",
        marginRight: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      },
      bottomRightCardChartContainer: {
        flex: "8",
        height: "100px",
      },
      homeMapContainer: {
        marginTop: "10px",
        position: "relative",
        [theme.breakpoints.up("sm")]: {
          height: "calc(100% - 143px)",
        },
        [theme.breakpoints.down("sm")]: {
          height: "385px",
        },
      },
      mapImg: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
      mapHandleButtons: {
        position: "absolute",
        bottom: "10px",
        right: "10px",
        display: "flex",
        alignItems: "center",
        gap: "15px",
      },
      mapLeftBottom: {
        position: "absolute",
        bottom: "10px",
        left: "10px",
      },
      viewDetailsButton: {
        background: "#fcbe00",
        padding: "10px 15px",
        borderRadius: "15px",
        textTransform: "capitalize",
        fontWeight: "bolder",
        "&:hover": {
          background: "#fcbe00",
        },
      },
      dropdown_container: {
        display: "flex",
        background: "#f3f2f5",
        borderRadius: "20px",
        padding: "10px 20px",
        alignItems: "center",
      },
      dropdown__title: {
        fontSize: "12px",
        color: "#A19DAF",
        fontWeight: "bolder",
      },
      dropdown__main: {
        marginLeft: "10px",
        fontSize: "14px",
        fontWeight: "bolder",
        "&:hover": {
          borderBottom: "0px",
        },
        "&::before": {
          borderBottom: "0px !important",
        },
      },
    })
  )(LandingPage2)
);
