import React from 'react';

import GetAppIcon from '@material-ui/icons/GetApp';
import {IconButton} from '@material-ui/core';
import { MessageItemType } from '../../../blocks/Chat9/src/Interfaces.web';
import { useChatContext } from '../../../blocks/Chat9/src/ChatContext.web';
import {saveAs} from 'file-saver'
const urlConfig=require("../../../framework/src/config")
import LazyImage from './LazyImage.web'
import LazyFileViewer from './LazyFileViewer.web';
interface Props{
    message:MessageItemType,
    showImage:boolean,
    showDate:boolean,

}
const profileImage=`https://images.pexels.com/photos/1043474/pexels-photo-1043474.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500`;

const getFileName=(url:string)=>{
       if (url)
       {
        let extension= url.substring(url.lastIndexOf(".") + 1);
          let m = url.toString().match(/\/([^\/]+?)\./);
          if (m && m.length > 1)
          {
             return m[1]+"."+extension;
          }
       }
       return "";
}
const getSenderName=(message:MessageItemType)=>{
        let name="";
        if(message?.attributes?.account_id?.first_name){
            name=message?.attributes?.account_id?.first_name+message?.attributes?.account_id?.last_name
        }else if(message?.attributes?.account_id?.user_name){
            name=message?.attributes?.account_id?.user_name;
       }else if(message?.attributes?.account_id?.email){
             name=message?.attributes?.account_id?.email;
        }
        return name;
}
const MessageItem=React.forwardRef(({message,showImage,showDate}:Props,ref:any)=>{
    
    const {currentChat,getCurrentUserId}=useChatContext();
    const baseUrl=urlConfig?.baseURL;
  
    React.useEffect(()=>{
        ref?.current?.scrollIntoView({behavior:"auto"})
    },[message])

    const isSenderLoggedInUser=React.useCallback(():boolean=>{
        let check:boolean=false;
        const loggedInUserId=getCurrentUserId();
        if(message?.attributes?.account_id?.id==loggedInUserId){
            check=true;
        }
        return check;
    },[message]);

    const getTimeOnly=(d:any)=>{
        const date=new Date(d);
       return date.toLocaleString('en-US', { hour: 'numeric',minute:'2-digit', hour12: true })

    }  
     const getDateOnly=(d:any)=>{
        const date=new Date(d);
        return `${date.toLocaleString('en-US', { year: 'numeric',day: '2-digit',month:'2-digit'})}, ${date.toLocaleString('en-US', { weekday:'long'})}`
    }
    const attatchmentIsImage=(url:string)=> {
            return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
    }

  
    const downloadFile=async(url:string)=>{
       saveAs(url)
    }

   
    return(
       <>
            {
                showDate && 
                    <div className="message__item__date">
                        <h4>{getDateOnly(message?.attributes?.created_at)}</h4>
                    </div>
            }
        <div ref={ref} className={`message__item_container ${isSenderLoggedInUser()?`me`:`another`}`}>
                <div className="message__row"> 
                    {
                        !isSenderLoggedInUser() && currentChat?.type!=="group" &&
                        <div className='message__row__image'>
                             {showImage && <img src={profileImage} alt='profileImg'/>}
                       </div>
                    }
                   
                    <div className="message__body__container" style={{width:"100%"}}>
                        {showImage && currentChat?.type==="group" && !isSenderLoggedInUser() && <h4 style={{fontSize:"18px",fontWeight:"normal",marginBottom:"5px"}}>{getSenderName(message)}</h4> }
                         {message?.attributes?.content && <h5>{message?.attributes?.content}</h5>}
                            {
                                message?.attributes?.attachments && message?.attributes?.attachments?.length>0 && 
                                message?.attributes?.attachments?.map(attatchment=>
                                    attatchmentIsImage(attatchment?.url)?
                                    <div className='message__item__image'>
                                        <LazyImage 
                                            src={`${baseUrl}${attatchment?.url}`}
                                             alt=""/>
                                        <div className='message__image__download'>
                                                <IconButton 
                                                        onClick={e=>downloadFile(`${baseUrl}${attatchment?.url}`)}
                                                    >
                                                        <GetAppIcon/>
                                                </IconButton>
                                        </div>
                                    </div>:<div className='message__item__file'>
                                        <LazyFileViewer src={`${baseUrl}${attatchment?.url}`} title={getFileName(attatchment?.url)}/>
                                        <div className='message__image__download'>
                                                        <IconButton
                                                          onClick={e=>downloadFile(`${baseUrl}${attatchment?.url}`)}
                                                        >
                                                                <GetAppIcon/>
                                                        </IconButton>
                                                </div>
                                        </div>
                            )
                        }
                        <p className={`${isSenderLoggedInUser()? 'message__item__time_me':'message__item__time_another'}`}>
                             {message?.attributes?.isSending?"sending....": getTimeOnly(message?.attributes?.created_at)}
                         </p>
                    </div>
               </div>
        </div>
    </>
    )
})
export default React.memo(MessageItem);