import React from "react";
import { ToastContainer } from "react-toastify";
import { Typography, IconButton, Button, Grid, Card } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import "../../assets/report.css";
import AddIcon from "@material-ui/icons/Add";
import SystemUpdateAltOutlinedIcon from "@material-ui/icons/SystemUpdateAltOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import MailOutlineOutlinedIcon from "@material-ui/icons/MailOutlineOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import BarChartOutlinedIcon from "@material-ui/icons/BarChartOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import {
  makeStyles,
  Theme,
  useTheme,
  withStyles,
} from "@material-ui/core/styles";
import ReportPageController, {
  Props,
  configJSON,
} from "./ReportPageController.web";
import FlashOnOutlinedIcon from "@material-ui/icons/FlashOnOutlined";

const styles = (theme: Theme) => ({
  action__buttons__item: {
    display: "flex",
    alignItems: "center",
  },
});

class ReportPage extends ReportPageController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <div className="report__body__heading">
          <div className="report__body__heading__left">
            <Typography
              component="h3"
              variant="h5"
              style={{ fontWeight: "bolder" }}
            >
              Reports
            </Typography>
          </div>
        </div>
        <div className="report__container">
          <div className="report__body__container">
            <Grid container spacing={4} style={{ marginBottom: "15px" }}>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                xl={6}
                style={{ maxWidth: "80%" }}
              >
                <Card
                  style={{
                    borderRadius: "20px",
                    background: "#FFFFFF",
                    padding: "20px",
                    height: "200px",
                    border: "1px solid #CFCFCF",
                  }}
                  elevation={0}
                >
                  <div className="report__item__Content">
                    <div className="report__item__Left">
                      <div className="report__item__Logo">
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            height: "100%",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <FlashOnOutlinedIcon
                            style={{
                              color: "white",
                              fontSize: "25px",
                              paddingTop: "5px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="report__item__Right">
                      <div>
                        <div>
                          <h2 style={{ fontSize: "18px" }}>
                            Portfolio Reports
                          </h2>
                        </div>
                        <div style={{ marginTop: "10px" }}>
                          <p style={{ color: "#C9C3D3", fontSize: "13px" }}>
                            Create reports for the entire portfolio with just a
                            few clicks by using the intuitive drag & drop
                            editor.
                          </p>
                        </div>
                      </div>

                      <div>
                        <Button
                          onClick={this.goToPortfolioReportPage}
                          style={{
                            background: "#FFCE21",
                            borderRadius: "15px",
                            marginTop: "12px",
                            height: "40px",
                            textTransform: "capitalize",
                            fontWeight: "bold",
                            paddingLeft: "15px",
                            paddingRight: "15px",
                          }}
                          startIcon={<BarChartOutlinedIcon />}
                        >
                          Portfolio reports
                        </Button>
                      </div>
                    </div>
                  </div>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} xl={6}>
                <Card
                  style={{
                    borderRadius: "20px",
                    background: "#FFFFFF",
                    padding: "20px",
                    height: "200px",
                    border: "1px solid #CFCFCF",
                  }}
                  elevation={0}
                >
                  <div className="report__item__Content">
                    <div className="report__item__Left">
                      <div className="report__item__Logo">
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            height: "100%",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <FlashOnOutlinedIcon
                            style={{
                              color: "white",
                              fontSize: "25px",
                              paddingTop: "5px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="report__item__Right">
                      <div>
                        <div>
                          <h2 style={{ fontSize: "18px" }}>CSV Exports</h2>
                        </div>
                        <div style={{ marginTop: "10px" }}>
                          <p style={{ color: "#C9C3D3", fontSize: "13px" }}>
                            With the CSV export, you can transfer the data of
                            your systems and your portfolio in a high resolution
                            and for longer periods of time.
                          </p>
                        </div>
                      </div>

                      <div>
                        <Button
                          style={{
                            background: "#FFCE21",
                            borderRadius: "15px",
                            marginTop: "12px",
                            paddingLeft: "15px",
                            paddingRight: "15px",
                            height: "40px",
                            textTransform: "capitalize",
                            fontWeight: "bold",
                          }}
                          startIcon={<DescriptionOutlinedIcon />}
                        >
                          CSV exports
                        </Button>
                      </div>
                    </div>
                  </div>
                </Card>
              </Grid>
            </Grid>
          </div>
        </div>
        <ToastContainer theme="dark" />
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ReportPage);
