import {
  Button,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { DeleteOutlined } from "@material-ui/icons";
import React from "react";
import {DataLoggerItemI,DataLoggerListI,IDataLoggerDevice} from '../../../blocks/ActivityLog/src/ClientSetting.types'
import { useAuth } from "../../../blocks/email-account-login/src/AuthContext/AuthContext.web";
import ActionModal from "../Util/ActionModal";
import TableLoader from "../Util/TableLoader.web";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: "20px",
    },
    dividerStyle: {
      marginTop: "10px",
      marginBottom: "15px",
      height: "2px",
      width: "100%",
      background: "#f9f9fa",
    },
    ashBoldText: {
      color: "#8F92A1",
      fontSize: "15px",
      marginTop: "10px",
      marginBottom: "10px",
      fontWeight: "bolder",
    },
    selectContainer: {
      border: "none",
      fontSize: "1.2rem",
      fontWeight: "bolder",
      color: "black",
      "&:hover": {
        borderBottom: "0px",
      },
      "&::before": {
        borderBottom: "0px !important",
      },
      "&::after": {
        borderBottom: "0px !important",
      },
      "&$selected": {
        borderBottom: "0px !important",
      },
    },
    blackBoldText: {
      fontSize: "1.2rem",
      fontWeight: "bolder",
      color: "black",
    },
    headingText: {
      fontSize: "2rem",
      fontWeight: 550,
      color: "black",
    },
    row: {
      display: "flex",
      alignItems: "center",
      gap: "15px",
    },
    ashButton: {
      background: "#f9f9fa",
      borderRadius: "15px",
      boxShadow: "none",
      textTransform: "capitalize",
      fontWeight: 400,
      color: "black",
    },
    yellowButton: {
      background: "#FFCE21",
      borderRadius: "15px",
      boxShadow: "none",
      textTransform: "capitalize",
      fontWeight: 400,
      color: "black",
    },
    table__container: {
      boxSizing: "border-box",
      marginTop: "10px",
      overflowY: "auto",
      width: "100%",
      "& table": {
        width: "100%",
        minWidth: "800px",
        borderSpacing: "0px 15px",
        "& thead": {
          background: "#f9f9fa",
          borderRadius: "10px",
          height: "40px",
        },
        "& tr": {
          textAlign: "center",
          "& td": {
            textAlign: "center",
            textTransform: "capitalize",
            padding: "5px 0px",
            borderBottom: "2px solid #f9f9fa",
          },
          "&:hover": {
            backgroundColor: "var(--main-color)",
            color: "var(--txt-white)",
            cursor: "pointer",
          },
        },
      },
    },
    itemText: {
      fontSize: "16px",
      fontWeight: 500,
    },
  })
);


interface Props{
   title:string;
   selectedDataLogger:DataLoggerItemI|null;
   dataLoggers:DataLoggerListI;
   deviceList:IDataLoggerDevice[];
   filterBy:string;
   loader:boolean;
   handleDeleteModal:(open:boolean)=>void;
   deleteDevice:(deviceId:string)=>void;
   deleteLoading:boolean;
   deleteModal:boolean;
}

const MeterTable = ({
    selectedDataLogger,
    dataLoggers,
    title,
    deviceList,
    filterBy,
    loader,
    deleteLoading,
    deleteDevice,
    handleDeleteModal,
    deleteModal
  }:Props) => {
  const styles = useStyles();
  const { isSuperAdmin } = useAuth();
  const [selectedDevice,setSelectedDevice]=React.useState("");

  const handleSelectDevice=(id:string)=>{
    setSelectedDevice(id);
    handleDeleteModal(true)
  }
  return (
    <div className={styles.container}>
      {/* demo */}
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography
            component="h1"
            variant="h3"
            className={styles.headingText}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item>
          {isSuperAdmin() && (
            <Button className={styles.yellowButton}>Add</Button>
          )}
        </Grid>
      </Grid>

      <Typography component="h1" variant="h3" className={styles.ashBoldText}>
        Manage the data devices subordinate to the Weblog.
      </Typography>
      <Typography component="h1" variant="h3" className={styles.ashBoldText}>
        Data logger name: {selectedDataLogger?.attributes?.data_logger_name}, Hardware serial number : {selectedDataLogger?.attributes?.hardware_s_no}
      </Typography>
      <div className={styles.table__container}>
        <table>
          <thead>
            <tr>
              <th>
                <h5 style={{ fontWeight: "bold" }}>IDENTITY NO</h5>
              </th>
              <th>
                <h5 style={{ fontWeight: "bold" }}>UID</h5>
              </th>
              <th>
                <h5 style={{ fontWeight: "bold" }}>DESIGNATION</h5>
              </th>
              <th>
                <h5 style={{ fontWeight: "bold" }}>TYPE</h5>
              </th>
              <th>
                <h5 style={{ fontWeight: "bold" }}>ACTIONS</h5>
              </th>
            </tr>
          </thead>
          <tbody>
            {loader?<TableLoader count={4} column={5}/>:
                deviceList.filter(it=>it?.attributes.device_type===filterBy)
                .map((item, index) => (
                <tr key={item?.id}>
                <td>
                  <div>
                    <h2 className={styles.itemText}>{index + 1}</h2>
                  </div>
                </td>
                <td>
                  <div>
                    <h2 className={styles.itemText}>{item?.id}</h2>
                  </div>
                </td>{" "}
                <td>
                  <div>
                    <h2 className={styles.itemText}>{item?.attributes?.device_type}-{item?.attributes?.device_Address}</h2>
                  </div>
                </td>
                <td>
                  <div>
                    <h2 className={styles.itemText}>{item?.attributes?.device_type}</h2>
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "15px",
                      justifyContent: "center",
                    }}
                  >
                    <IconButton onClick={()=>handleSelectDevice(item?.id)}>
                      <DeleteOutlined />
                    </IconButton>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "15px",
        }}
      >
        <Button className={styles.yellowButton}>Save</Button>
      </div>
      <br />
      <br />
      <br />
      {
         selectedDevice && deleteModal && (
          <ActionModal
            open={deleteModal}
            setOpen={handleDeleteModal}
            title={`Delete ${title} Device`}
            message="Are You Want To Delete This Device"
            actionHandler={()=>deleteDevice(selectedDevice)}
            actionLoading={deleteLoading}
            actionLoadingText="Deleting Device.."
          />
         )
      }
    </div>
  );
};

export default MeterTable;
