import React, { useState, useRef, useEffect } from "react";
import {
  IconButton,
  makeStyles,
  MenuItem,
  Typography,
  Menu,
} from "@material-ui/core";
import ArrowBackIosOutlinedIcon from "@material-ui/icons/ArrowBackIosOutlined";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useOutsideClick from "./useOutsideClick";
import { addDays,addMonths,addYears,subDays,subMonths,subYears } from "date-fns";

const useStyles = makeStyles({
  dropdown_container_cs: {
    display: "flex",
    background: "#f3f2f5",
    borderRadius: "20px",
    padding: "13px 20px",
    alignItems: "center",
    cursor: "pointer",
    position: "relative",
  },
  dropdown__title_cs: {
    fontSize: "12px",
    color: "#A19DAF",
    fontWeight: "bolder",
  },
  show_text_cs: {
    fontWeight: "bolder",
    color: "black",
    fontSize: "16px",
    marginLeft: "7px",
  },
  responsive_text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  dropdown__main_cs: {
    marginLeft: "10px",
    fontSize: "14px",
    fontWeight: "bolder",
    "&:hover": {
      borderBottom: "0px",
    },
    "&::before": {
      borderBottom: "0px !important",
    },
  },
  darkIconButton_cs: {
    background: "#f3f2f5",
    position: "relative",
  },
  row_cs: {
    display: "flex",
    alignItems: "center",
    gap: "15px",
    boxSizing: "border-box",
  },
  date_picker__container_cs: {
    position: "relative",
  },
  date__picker: {
    position: "absolute",
    top: "50px",
    right: "0px",
    zIndex: 99,
  },
});

interface Props{
   onChange?:(filterBy:string,date:{
     day:number;
     month:number;
     year:number;
   })=>void;
}


const CustomSorting = ({onChange}:Props) => {
  const styles = useStyles();
  const [filterBy, setFilterBy] = useState<string>("year");
  const [showText, setShowText] = useState<string>("Year");
  const [filterDate, setFilterDate] = useState<string>(new Date().toString());
  const [optionMenu, setOptionMenu] = useState<null | HTMLElement>(null);
  const datePickerContainerRef = useRef<null | HTMLDivElement>(null);
  const yearPickerContainerRef = useRef<null | HTMLDivElement>(null);
  const monthPickerContainerRef = useRef<null | HTMLDivElement>(null);

  const {
    open: openDatePicker,
    handleOpen: setOpenDatePicker,
  } = useOutsideClick(datePickerContainerRef);
  const {
    open: openYearPicker,
    handleOpen: setOpenYearPicker,
  } = useOutsideClick(yearPickerContainerRef);

  const {
    open: openMonthPicker,
    handleOpen: setOpenMonthPicker,
  } = useOutsideClick(monthPickerContainerRef);

  const handleDateChange = (date: any) => {
    setFilterDate(date);
  };

  const handleOptionMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOptionMenu(event.currentTarget);
  };
  const handleOptionMenuClose = () => {
    setOptionMenu(null);
  };
  const handleStopPropagetion = (e: any) => {
    e.stopPropagation();
  };
  const handlePicFullDate=(e:any)=>{
    setFilterBy("day");
    handleDateChange(e);
  }
  const handleSelectFilterOption = (filter: number) => {
    if (filter === 0) {
      setFilterBy("day");
      setOpenDatePicker(2);
      setOpenMonthPicker(1);
      setOpenYearPicker(1);
      setOptionMenu(null);
    }
    if (filter === 1) {
      setFilterBy("month");
      setOpenDatePicker(1);
      setOpenMonthPicker(2);
      setOpenYearPicker(1);
      setOptionMenu(null);
    }
    if (filter === 2) {
      setFilterBy("year");
      setOpenDatePicker(1);
      setOpenMonthPicker(1);
      setOpenYearPicker(2);
      setOptionMenu(null);
    }
  };
  const handleNext=()=>{
    if(filterBy==="day"){
      setFilterDate((date)=>addDays(new Date(date), 1).toString())
    }else if(filterBy==="month"){
        setFilterDate((date)=>addMonths(new Date(date), 1).toString())
    }else if(filterBy==="year"){
        setFilterDate((date)=>addYears(new Date(date), 1).toString())
    }
  } 
  const handlePrev=()=>{
    if(filterBy==="day"){
      setFilterDate((date)=>subDays(new Date(date), 1).toString())
    }else if(filterBy==="month"){
        setFilterDate((date)=>subMonths(new Date(date), 1).toString())
    }else if(filterBy==="year"){
        setFilterDate((date)=>subYears(new Date(date), 1).toString())
    }
  }


  useEffect(()=>{
    const newDate=new Date(filterDate);
    if(filterBy==="day"){
      setShowText(newDate?.toDateString());
      setOpenDatePicker(1);
    }else if(filterBy==="month"){
      const monthName=newDate?.toLocaleString('default', { month: 'long' });
      setShowText(`${monthName} , ${newDate?.getFullYear()}`)
      setOpenMonthPicker(1)
    }else if(filterBy==="year"){
       setShowText(`Year, ${newDate?.getFullYear()}`)
       setOpenYearPicker(1)
    }
    if(onChange){
      onChange(filterBy,{
          day:newDate?.getDate(),
          month:newDate?.getMonth()+1,
          year:newDate?.getFullYear()
      });
    }
  },[filterDate])


  return (
    <div className={`${styles.row_cs}`}>
      <div className={styles.date_picker__container_cs}>
        <IconButton
          onClick={() => setOpenDatePicker()}
          className={styles.darkIconButton_cs}
        >
          <CalendarTodayOutlinedIcon />
        </IconButton>

        <div ref={datePickerContainerRef} className={styles.date__picker}>
          {openDatePicker && (
            <DatePicker selected={null} onChange={(e:any)=>handlePicFullDate(e)} inline />
          )}
        </div>
      </div>
      <IconButton 
        onClick={handlePrev}
        className={styles.darkIconButton_cs}>
        <ArrowBackIosOutlinedIcon />
      </IconButton>

      {/* area for show drop down  */}
      <div>
        <div
          onClick={handleOptionMenuOpen}
          className={styles.dropdown_container_cs}
        >
          <Typography
            component="h5"
            variant="h6"
            className={`${styles.dropdown__title_cs} ${styles.responsive_text}`}
          >
            Show :
          </Typography>
          <Typography
            component="h5"
            variant="h5"
            className={`${styles.show_text_cs} ${styles.responsive_text}`}
          >
            {showText}
          </Typography>
          <div
            onClick={handleStopPropagetion}
            className={styles.date__picker}
            ref={yearPickerContainerRef}
          >
            {openYearPicker && (
              <DatePicker
                selected={null}
                onChange={handleDateChange}
                inline
                dateFormat="yyyy"
                showYearDropdown
                showYearPicker
              />
            )}
          </div>
          <div
            onClick={handleStopPropagetion}
            className={styles.date__picker}
            ref={monthPickerContainerRef}
          >
            {openMonthPicker && (
              <DatePicker
                selected={null}
                onChange={handleDateChange}
                inline
                dateFormat="MM/yyyy"
                showMonthYearPicker
                showMonthDropdown
              />
            )}
          </div>
        </div>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={optionMenu}
          open={Boolean(optionMenu)}
          onClose={handleOptionMenuClose}
         
        >
          <MenuItem onClick={() => handleSelectFilterOption(0)}>Day</MenuItem>
          <MenuItem onClick={() => handleSelectFilterOption(1)}>Month</MenuItem>
          <MenuItem onClick={() => handleSelectFilterOption(2)}>Year</MenuItem>
        </Menu>
      </div>

      <IconButton 
        onClick={()=>handleNext()}
        className={styles.darkIconButton_cs}>
        <ArrowForwardIosOutlinedIcon />
      </IconButton>
    </div>
  );
};

export default CustomSorting;
