import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { EventType } from "../../../blocks/LandingPage2/src/ProjectTaskCalendar.web";
import React from 'react';
const useStyle = makeStyles({
  calendarCell: {
    padding: "15px",
    textAlign: "center",
    borderBottom: "2px solid #b6b6b6",
    borderRight: "2px solid #b6b6b6",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    cursor: "pointer",
    "&:hover": {
      background: "#fff9e5",
      //   "& div.hoverContent": {
      //     visibility: "visible",
      //   },
    },
  },
  text: {
    fontSize: "20px",
  },
  eventList: {
    display: "flex",
    flexDirection: "row",
    gap: "5px",
  },
  eventItem: {
    background: "#0052cc",
    width: "10px",
    height: "10px",
    borderRadius: "50%",
  },
  hoverContent: {
    background: "#f9f9fa",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    borderRadius: "15px",
    padding: "10px 20px",
    right: "0px",
    position: "absolute",
    top: "0px",
    visibility: "hidden",
  },
  activeCell: {
    background: "#fff9e5",
    height: "100%",
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "20px",
  },
});
interface Props {
  value: number | string;
  containerStyle?: any;
  textStyle?: any;
  currentDate?: {
    day: number;
    month: number;
    year: number;
  };
  events?: EventType[];
  isActive?: boolean;
  onClick?: () => void;
}
const CalendarCell = ({
  value,
  containerStyle,
  textStyle,
  currentDate,
  events,
  isActive,
  onClick,
}: Props) => {
  const styles = useStyle();

  const eventList = events?.filter(({ date }) => {
    return (
      date.getDate() === currentDate?.day &&
      date.getMonth() === currentDate?.month &&
      date.getFullYear() === currentDate?.year
    );
  });

  return (
    <div
      className={`${styles.calendarCell}`}
      style={containerStyle}
      onClick={isActive ? undefined : onClick}
    >
      <Typography
        variant="h4"
        component="h4"
        style={textStyle}
        className={`${styles.text} ${isActive && styles.activeCell}`}
      >
        {value}
      </Typography>
      <div className={styles.eventList}>
        {eventList?.map((item) => (
          <div className={styles.eventItem}></div>
        ))}
      </div>
      {/* <div className={styles.hoverContent}>
        {eventList?.map((item) => (
          <Typography variant="h5" className={styles.text}>
            {item?.title}
          </Typography>
        ))}
      </div> */}
    </div>
  );
};

export default CalendarCell;
