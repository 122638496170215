import React from "react";
import { ToastContainer } from "react-toastify";
import {
  Typography,
  IconButton,
  Button,
  Select,
  Grid,
  MenuItem,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import {
  withStyles,
  createStyles,
  Theme,
  withTheme,
} from "@material-ui/core/styles";
import TransectionWAController, {
  Props,
  configJSON,
} from "./TransectionWAController.web";
import TransactionDetailsModal from "../../../components/src/TransactionComponents/TransactionDetailsModal.web";
import DescriptionIcon from "@material-ui/icons/Description";
class TransectionWa extends TransectionWAController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    const { transactionModal } = this.state;
    return (
      <>
        <div className="report__body__heading">
          <div className="report__body__heading__left">
            <Typography
              component="h3"
              variant="h5"
              style={{ fontWeight: "bolder" }}
            >
              Payments
            </Typography>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
            <div className={classes.dropdown_container}>
              <Typography
                component="h5"
                variant="h6"
                className={classes.dropdown__title}
              >
                Show :
              </Typography>
              <Select
                style={{ borderBottom: "0px" }}
                className={classes.dropdown__main}
                defaultValue="Week"
              >
                <MenuItem value="Week">Week</MenuItem>
                <MenuItem value={20}>Month</MenuItem>
                <MenuItem value={30}>Year</MenuItem>
              </Select>
            </div>
          </div>
        </div>
        <div className="report__container">
          <div className="report__body__container">
            <div className={classes.table__container}>
              <table>
                <thead>
                  <tr>
                    <th>
                      <h5 style={{ fontWeight: "bold", paddingLeft: "20px" }}>
                        PAYMENT DATE
                      </h5>
                    </th>
                    <th>
                      <div className={classes.row}>
                        <h5 style={{ fontWeight: "bold" }}>
                          TRANSACTION DETAILS
                        </h5>
                      </div>
                    </th>
                    <th>
                      <div className={classes.row}>
                        <h5 style={{ fontWeight: "bold" }}>STATUS</h5>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {[...new Array(6)].map((item, index) => (
                    <tr key={index}>
                      <td>
                        <div style={{ paddingLeft: "20px" }}>
                          <h2 className={classes.itemText}>13 JUNE 2021</h2>
                        </div>
                      </td>
                      <td>
                        <div
                          className={classes.row}
                          onClick={() => this.changeTransactionOpenModal(true)}
                        >
                          <DescriptionIcon
                            style={{ color: "#6c6c6c", marginRight: "7px" }}
                          />
                          <h2 className="itemTxt">Open</h2>
                        </div>
                      </td>
                      <td>
                        {index % 2 === 0 ? (
                          <button className={classes.paidButton}>Paid</button>
                        ) : (
                          <button className={classes.unpaidButton}>
                            Unpaid
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div
              className="report__pagination__container"
              style={{ marginRight: "10px", marginBottom: "15px" }}
            >
              <IconButton
                style={{
                  background: "#f9f9fa",
                  marginRight: "15px",
                  borderRadius: "10px",
                }}
              >
                <ArrowBackIosIcon />
              </IconButton>
              <div className="pagination__text__container">
                <Typography
                  variant="h5"
                  component="p"
                  style={{ fontSize: "17px" }}
                >
                  1/10
                </Typography>
              </div>

              <IconButton
                style={{
                  background: "#f9f9fa",
                  marginLeft: "15px",
                  borderRadius: "10px",
                }}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </div>
          </div>
        </div>
        {transactionModal && (
          <TransactionDetailsModal
            open={transactionModal}
            setOpen={this.changeTransactionOpenModal}
          />
        )}
        <ToastContainer theme="dark" />
      </>
    );
  }
}

export default withTheme(
  withStyles((theme: Theme) =>
    createStyles({
      darkIconButton: {
        background: "#f3f2f5",
      },
      action__buttons__item: {
        display: "flex",
        alignItems: "center",
      },
      paidButton: {
        background: "#c5e8de",
        width: "90px",
        textAlign: "center",
        color: "#f42f00",
        fontWeight: "bold",
        cursor: "pointer",
        borderRadius: "10px",
        padding: "5px 5px",
        border: "none",
        outline: "none",
      },
      unpaidButton: {
        background: "#ffd6cd",
        width: "90px",
        textAlign: "center",
        color: "#f42f00",
        fontWeight: "bold",
        cursor: "pointer",
        borderRadius: "10px",
        padding: "5px 5px",
        border: "none",
        outline: "none",
      },
      dropdown_container: {
        display: "flex",
        background: "#f3f2f5",
        borderRadius: "20px",
        padding: "5px 20px",
        alignItems: "center",
      },
      dropdown__title: {
        fontSize: "12px",
        color: "#A19DAF",
        fontWeight: "bolder",
      },
      dropdown__main: {
        marginLeft: "10px",
        fontSize: "14px",
        fontWeight: "bolder",
        "&:hover": {
          borderBottom: "0px",
        },
        "&::before": {
          borderBottom: "0px !important",
        },
      },
      row: {
        display: "flex",
        alignItems: "center",
        gap: "15px",
      },
      table__container: {
        height: "550px",
        boxSizing: "border-box",
        marginTop: "10px",
        overflowY: "auto",
        width: "100%",
        "& table": {
          width: "100%",
          minWidth: "800px",
          borderSpacing: "0px 15px",
          "& thead": {
            background: "#f9f9fa",
            borderRadius: "10px",
            height: "40px",
          },
          "& tr": {
            textAlign: "left",
            "& td": {
              textTransform: "capitalize",
              padding: "10px 0px",
              borderBottom: "2px solid #f9f9fa",
            },
            "&:hover": {
              backgroundColor: "var(--main-color)",
              color: "var(--txt-white)",
              cursor: "pointer",
            },
          },
        },
      },
      itemText: {
        fontSize: "16px",
        fontWeight: 500,
      },
    })
  )(TransectionWa)
);
