import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
import {} from "react-router-dom";

export interface Props {
  navigation: any;
  id: string;
  classes?: any;
}

interface S {
  generatePortfolioReportModal: boolean;
  createTaskModal: boolean;
}

interface SS {
  id: any;
}

export default class ProjectMangementController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      generatePortfolioReportModal: false,
      createTaskModal: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {}

  async receive(from: String, message: Message) {}

  // handle ui changes
  changeCreateTaskModal = (open: boolean) => {
    this.setState({
      createTaskModal: open,
    });
  };
  goToNewPortfolioReportPage = () => {
    this.props.navigation.navigate("newPortfolioReport");
  };
}
