export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgDashboardSmallChart = require("../assets/chart1.png");
export const imgUpArrow = require("../assets/up_arrow.png");
export const imgDownArrow = require("../assets/down-arrow.png");
export const imgMap = require("../assets/maph.jpeg");
export const imgMap2 = require("../assets/map.png");
export const imgRlLogo = require("../assets/rl_logo.png");
export const imgChartImg = require("../assets/chart_img.png");
export const imgCoverImg = require("../assets/cover_img.png");
export const imgChartEdit = require("../assets/blue_edit_icon.png");
export const imgCover = require("../assets/cover_img.png");
export const imgCoverEdit = require("../assets/edit_img.png");
export const imgKeyPerformance = require("../assets/key_performance_img.png");
export const imgKeyPerformanceEdit = require("../assets/key_performance_edit.png");
export const imgTicketEdit = require("../assets/ticket_edit.png");
export const imgTicket = require("../assets/ticket_img.png");
