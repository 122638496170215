import {
  Button,
  Paper,
  Typography,
  makeStyles,
  IconButton
} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import React from "react";
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import {useNetworkContext} from '../../../blocks/AdminConsole3/src/Network/NetworkContext.web'
import CreateNewUser from "../UserComponents/CreateNewUser.web";
import ActionModal from "../Util/ActionModal";


const useStyles=makeStyles({
   inputPlaceholder:{
     color:"#8F92A1",
     fontWeight:"bolder"
   },
   headingText:{
     fontWeight:"bolder",
     fontSize:"22px"

   },
   dropZone:{
     width:"100%",
     flexDirection:"column",
     height:"90px",
     boxSizing:"border-box",
     padding:"10px",
     border:"2px dashed #000",
     borderRadius:"10px",
     background:"#f9f9fa",
     display:"flex",
     justifyContent:"center",
     alignItems:"center",
     cursor:"pointer",
     marginBottom:"15px",
     marginTop:"10px"
   }
})

interface Props{
  client:any;
  userCreateErrors:any
}


export default function ClientUserListModal({client,userCreateErrors}:Props) {
  const styles=useStyles();
 

  const {
    clientUsersList,
    getClientUserLoading:getUserLoading,
    changeClientUserModal:setOpen,
    clientUsersDialog:open,
    createUserModal,
    changeCreateUserModal,
    updateUserModal,
    changeUpdateUserModal,
    changeDeleteUserModal,
    deleteUserModal,
    createUserLoading,
    userGroupList,
    getUserGroupsLoading,
    createNewUser,
    updateUser,
    updateUserLoading,
    deleteUser,
    deleteUserLoading
  }=useNetworkContext();

  const [selectedUser,setSelectedUser]=React.useState<any>({});

  const openUpdateUserModal=(us:any)=>{
     setSelectedUser(us);
      changeUpdateUserModal(true);
  }
  const openDeleteUserModal=(us:any)=>{
      setSelectedUser(us);
      changeDeleteUserModal(true);
  }
  return (
    <div>
      <Modal
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Paper
          style={{ width: "70vw", padding: "20px", borderRadius: "10px" }}
        >
          <div style={{ padding: "10px",height:"65vh",overflow:"auto",display:"flex",flexDirection:"column" }}>
            <div style={{flex:"1",display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                  <div> 
                    <Typography
                        className={styles.headingText}
                        variant="h5"
                        >
                      List Of Users
                    </Typography>
                  </div>
               <div>
                  <Button
                    variant="contained"
                    disableElevation
                     onClick={()=>changeCreateUserModal(true)}
                    style={{
                      margin: "0px 32px",
                      borderRadius: "9px",
                      height: "40px",
                      background: "#FDCE32",
                      fontWeight: 600,
                      textTransform: "capitalize",
                    }}
                  >
                    Add New User
                  </Button>
               </div>
            </div>
            <div
              style={{
                marginTop: "10px",
                marginBottom: "15px",
                height: "2px",
                width: "100%",
                background: "#f9f9fa",
              }}
            ></div>
              <div style={{flex:"5"}} className="client__user__table__wrapper">
                 <table>
                  <thead>
                      <tr>
                           <th><Typography style={{fontWeight:"bold"}}>Name of the users</Typography></th>  
                           <th>
                              <Typography style={{fontWeight:"bold"}}>E-mail</Typography>
                            </th>  
                            <th>
                              <Typography style={{fontWeight:"bold"}}>Roles</Typography>
                            </th>
                            <th>
                              <Typography style={{fontWeight:"bold"}}>Actions</Typography>
                            </th>
                       </tr>
                   </thead>
                   <tbody>
                          {
                             getUserLoading ? <Typography variant="h6">Loading..</Typography>:
                             clientUsersList
                             ?.map((user,index)=>(
                                <tr key={index}>
                                    <td>
                                         <Typography className="itemTxt">{user?.attributes?.user_name}</Typography>
                                    
                                    </td>
                                    <td>
                                     <div>
                                         <Typography className="itemTxt">{user?.attributes?.email}</Typography>
                                      </div>
                                    </td>
                                    <td>
                                     <div>
                                         <Typography className="itemTxt">{user?.attributes?.user_group}</Typography>
                                      </div>
                                    </td>
                                    <td>
                                        <div>
                                            <div style={{display:"flex",alignItems:"center"}}>
                                                <IconButton onClick={()=>openUpdateUserModal(user)}  style={{marginLeft:"10px",padding:"0"}}><EditOutlinedIcon/></IconButton>
                                                <IconButton onClick={()=>openDeleteUserModal(user)} style={{margin:"0",padding:"0"}}><DeleteOutlineOutlinedIcon/></IconButton>
                                            </div>
                                        </div>
                                      </td>
                                </tr>
                              ))
                          }   
                    </tbody>
              
            </table>
            </div> 
            <div style={{flex:"1",width:"100%",display:"flex",justifyContent:"center"}}>
               <Button
                  onClick={() => setOpen(false)}
                  fullWidth
                  variant="contained"
                  style={{
                    background: "#f9f9fa",
                    marginRight: "10x",
                    borderRadius: "15px",
                    marginTop: "10px",
                    marginBottom: "10px",
                    boxShadow: "none",
                    textTransform: "capitalize",
                    fontWeight: 400,
                  }}
                >
                  Cancel
                </Button>    
            </div>
          </div>
        </Paper>
      </Modal>
      {
        createUserModal && <CreateNewUser 
                clientId={client?.id}
                clientName={client?.attributes?.name}
                open={createUserModal}
                setOpen={changeCreateUserModal}
                actionHandler={createNewUser}
                actionLoading={createUserLoading}
                userGroupList={userGroupList}
                getUserGroupsLoading={getUserGroupsLoading}
                userErrors={userCreateErrors}
              />
      }
      {
        updateUserModal  &&
         <CreateNewUser 
                edit={true}
                editUser={selectedUser}
                clientId={client?.id}
                clientName={client?.attributes?.name}
                open={updateUserModal}
                setOpen={changeUpdateUserModal}
                actionHandler={updateUser}
                actionLoading={updateUserLoading}
                userGroupList={userGroupList}
                getUserGroupsLoading={getUserGroupsLoading}
                userErrors={userCreateErrors}
              />
      }
      {
        deleteUserModal  && (
        <ActionModal
            title="Delete User?"
            message={`Are you sure want to delete this user? (${selectedUser?.attributes?.user_name})`}
            open={deleteUserModal}
            setOpen={changeDeleteUserModal}
            actionHandler={()=>deleteUser(selectedUser?.id,client?.id)}
            actionLoading={deleteUserLoading}
            actionLoadingText="Deleting User..."
          />)
      }
    </div>
  );
}
