import React from 'react'
import {Notification} from '../../../blocks/Notifications/src/Notification2Controller.web';
import {format} from 'timeago.js'
import BusinessSharpIcon from "@material-ui/icons/BusinessSharp";
import ChatBubbleOutlineRoundedIcon from '@material-ui/icons/ChatBubbleOutlineRounded';
import GroupRoundedIcon from '@material-ui/icons/GroupRounded';
import WatchRoundedIcon from '@material-ui/icons/WatchRounded';
import {IconButton} from '@material-ui/core'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
const image="https://thumbs.dreamstime.com/b/profile-anonymous-face-icon-gray-silhouette-person-male-default-avatar-photo-placeholder-isolated-white-background-profile-107327860.jpg";

const getNotificationIcon=(from:string|null)=>{
   
    let icon=<BusinessSharpIcon style={{color:"#0052cc",fontSize:"35px"}}/>;
    if(from==="system_notification"){
        return <BusinessSharpIcon style={{color:"#0052cc",fontSize:"35px"}}/>;
    }else if(from ==="chat_message"){
        return <ChatBubbleOutlineRoundedIcon style={{color:"#00875a",fontSize:"35px"}}/>;
    }else if(from ==="user_notification"){
        return <GroupRoundedIcon style={{color:"#0052cc",fontSize:"35px"}}/>;
    }else{
        return icon;
    }
}

interface Props{
    notification:Notification,
    clickNotification:(notification:Notification)=>void,
    changeDeleteNotificationModal:(open:boolean,id?:string)=>void,
}
const NotificationDetailsItem=({notification,clickNotification,changeDeleteNotificationModal}:Props)=>{
    
    return(
        <div  className='notification__details__item_container'>
            <div className="notification__details__item_left" onClick={()=>clickNotification(notification)}>
                <div className="notification__details__images" >
                    <div className="centerImage">
                        {getNotificationIcon(notification?.attributes?.event)}
                        </div>
                    <img className="bottomImg" src={image} alt="hello"/>
                </div>
                <div className="notification__details__item__content">
                    <div>
                        <h6 onClick={()=>clickNotification(notification)} style={notification?.attributes?.is_read?{}:{color:"black",fontWeight:"bolder"}}><span style={{color:"black",fontWeight:"bolder",fontSize:"15px"}}></span> {notification?.attributes?.contents}</h6>
                    </div>
                    <div>
                        <p>{format(notification?.attributes?.created_at)}</p>
                    </div>
                </div>

            </div>
            <div className="notification__details__item_right">
               <IconButton onClick={()=>changeDeleteNotificationModal(true,notification?.id)}>
                    <DeleteOutlineIcon/>
                </IconButton>
            </div>


            {/* <div onClick={()=>clickNotification(notification)} className='notification__details__item_left'>
                <div className="centerImage">
                    {getNotificationIcon(notification?.attributes?.event)}
                    </div>
                <img className="bottomImg" src={image} alt="hello"/>
            </div>
            <div className="notification__details__item_right">
                <div>
                    <h6 onClick={()=>clickNotification(notification)} style={notification?.attributes?.is_read?{}:{color:"black",fontWeight:"bolder"}}><span style={{color:"black",fontWeight:"bolder",fontSize:"15px"}}></span> {notification?.attributes?.contents}</h6>
                </div>
                <div>
                    <p>{format(notification?.attributes?.created_at)}</p>
                </div>
            </div>
            <div className="notification__details__item_delete">
                <IconButton onClick={()=>changeDeleteNotificationModal(true,notification?.id)}>
                    <DeleteOutlineIcon/>
                </IconButton>
            </div> */}
        </div>
    )
}

export default NotificationDetailsItem;