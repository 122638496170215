// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';

import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import FullTeamVisibility2 from '../../blocks/FullTeamVisibility2/src/FullTeamVisibility2';
import ActivityLog from '../../blocks/ActivityLog/src/ActivityLog';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import NewForgotPassword from '../../blocks/forgot-password/src/NewForgotPassword.web';
import TeamBuilder from '../../blocks/TeamBuilder/src/TeamBuilder';
import Contactus from '../../blocks/contactus/src/Contactus';
import AddContactus from '../../blocks/contactus/src/AddContactus';
import StoreLocator from '../../blocks/StoreLocator/src/StoreLocator';
import MobileAccountLoginBlock from '../../blocks/mobile-account-login/src/MobileAccountLoginBlock';
import Prioritise from '../../blocks/Prioritise/src/Prioritise';
import AdvancedSearch from '../../blocks/AdvancedSearch/src/AdvancedSearch';
import TaskAllocator from '../../blocks/TaskAllocator/src/TaskAllocator';
import RecommendationEngine4 from '../../blocks/RecommendationEngine4/src/RecommendationEngine4';
import AddAppointment from '../../blocks/AppointmentManagement/src/AddAppointment';
import Appointments from '../../blocks/AppointmentManagement/src/Appointments';
import TwilioIntegration2 from '../../blocks/TwilioIntegration2/src/TwilioIntegration2';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock';
import ContextTagging from '../../blocks/ContextTagging/src/ContextTagging';
import FormApprovalWorkflow from '../../blocks/FormApprovalWorkflow/src/FormApprovalWorkflow';
import FingerPrintLogin2 from '../../blocks/FingerPrintLogin2/src/FingerPrintLogin2';
import CustomForm2 from '../../blocks/CustomForm2/src/CustomForm2';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import PhotoLibrary3 from '../../blocks/PhotoLibrary3/src/PhotoLibrary3';
import TaskList from '../../blocks/TaskList/src/TaskList';
import Analytic2 from '../../blocks/Analytic2/src/Analytic2';
import FacialRecognitionLogin2 from '../../blocks/FacialRecognitionLogin2/src/FacialRecognitionLogin2';
import FileAttachment from '../../blocks/FileAttachment/src/FileAttachment';
import CameraAccess from '../../blocks/CameraAccess/src/CameraAccess';
import UserGroups from '../../blocks/UserGroups/src/UserGroups';
import Scheduling from '../../blocks/Scheduling/src/Scheduling';
import WeatherApi22 from '../../blocks/WeatherApi22/src/WeatherApi22';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import PaymentAdmin2 from '../../blocks/PaymentAdmin2/src/PaymentAdmin2';
import FilterSms2 from '../../blocks/FilterSms2/src/FilterSms2';
import ReceiptCustomisation from '../../blocks/ReceiptCustomisation/src/ReceiptCustomisation';
import Filteritems from '../../blocks/filteritems/src/Filteritems';
import Filteroptions from '../../blocks/filteritems/src/Filteroptions';
import LandingPage2 from '../../blocks/LandingPage2/src/LandingPage2';
import InvoiceBilling from '../../blocks/InvoiceBilling/src/InvoiceBilling';
import RefundManagement from '../../blocks/RefundManagement/src/RefundManagement';
import EmailNotifications from '../../blocks/EmailNotifications/src/EmailNotifications';
import Sorting from '../../blocks/sorting/src/Sorting';
import WeatherApi12 from '../../blocks/WeatherApi12/src/WeatherApi12';
import TaskViews from '../../blocks/TaskViews/src/TaskViews';
import AdminConsole3 from '../../blocks/AdminConsole3/src/AdminConsole3';
import DataImportexportcsv from '../../blocks/DataImportexportcsv/src/DataImportexportcsv';
import Analytics3 from '../../blocks/Analytics3/src/Analytics3';
import Sms2 from '../../blocks/Sms2/src/Sms2';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import CentralDbServerApi2 from '../../blocks/CentralDbServerApi2/src/CentralDbServerApi2';
import Notifications from '../../blocks/Notifications/src/Notifications';
import TaskGroups from '../../blocks/TaskGroups/src/TaskGroups';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import Groups from '../../blocks/Groups/src/Groups';
import RolesPermissions2 from '../../blocks/RolesPermissions2/src/RolesPermissions2';
import Calendar from '../../blocks/Calendar/src/Calendar';
import AdHocReporting from '../../blocks/AdHocReporting/src/AdHocReporting';
import WeatherApi32 from '../../blocks/WeatherApi32/src/WeatherApi32';
import PhoneNumberInput from '../../blocks/mobile-account-registration/src/PhoneNumberInput';
import AdditionalDetailForm from '../../blocks/mobile-account-registration/src/AdditionalDetailForm';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import TextOrExcelFileImport2 from '../../blocks/TextOrExcelFileImport2/src/TextOrExcelFileImport2';
import RepeatingTasks from '../../blocks/RepeatingTasks/src/RepeatingTasks';
import Pushnotifications from '../../blocks/PushNotifications/src/Pushnotifications';
import ApiIntegration8 from '../../blocks/ApiIntegration8/src/ApiIntegration8';
import Customform from '../../blocks/customform/src/Customform';
import ApiIntegration from '../../blocks/apiintegration/src/ApiIntegration';
import Analytics from '../../blocks/analytics/src/Analytics';
import LandingPage from '../../blocks/landingpage/src/LandingPage';
import UserProfileBasicBlock from '../../blocks/user-profile-basic/src/UserProfileBasicBlock';
import EditProfile from '../../blocks/user-profile-basic/src/EditProfile.web';
import ClientPage from '../../blocks/LandingPage2/src/ClientPage.web';
import ClientDetailsPage from '../../blocks/LandingPage2/src/ClientDetailsPage.web';
import ReportPage from '../../blocks/LandingPage2/src/Reports/ReportPage.web';
import PortfolioReportPage from '../../blocks/LandingPage2/src/Reports/PortfolioReport.web';
import NewPortfolioReportPage from '../../blocks/LandingPage2/src/Reports/NewPortfolioReport.web';
import AdminDataPage from '../../blocks/AdminConsole3/src/AdminData/AdminData.web';
import User from '../../blocks/AdminConsole3/src/user/User.web';
import Clients from '../../blocks/AdminConsole3/src/Network/Clients.web';
import AddNewSystem from '../../blocks/AdminConsole3/src/Network/AddNewSystem.web';
import Chat from '../../blocks/Chat9/src/Chat.web';
import ApprovalPage from '../../blocks/AdminConsole3/src/Approval/Approval.web';
import NotificationDetails from '../../blocks/Notifications/src/NotificationDetails.web';
import Transection from '../../blocks/PaymentAdmin2/src/Transection.web';
import ClientComparison from '../../blocks/Analytics3/src/ClientComparison.web';
import SelectClientToCompare from '../../blocks/Analytics3/src/SelectClientToCompare.web';

import ClientDashboardWeb from '../../blocks/ActivityLog/src/ClientDashboard.web';
import AlarmsWeb from '../../blocks/ActivityLog/src/Alarms.web';
import ClientSettings from '../../blocks/ActivityLog/src/ClientSetting.web';
import ProjectManagement from '../../blocks/LandingPage2/src/ProjectManagement.web';
import Administration from '../../blocks/LandingPage2/src/Administration.web';
import TransectionWA from '../../blocks/PaymentAdmin2/src/TransectionWA.web';
import ProjectTaskCalendar from '../../blocks/LandingPage2/src/ProjectTaskCalendar.web';
import { useAuth } from '../../blocks/email-account-login/src/AuthContext/AuthContext.web';
const routeMap = {
  taskCalendar: {
    component: ProjectTaskCalendar,
    path: '/taskCalendar'
  },
  webClientLandingPage: {
    component: ClientDetailsPage,
    path: '/webClientLandingPage'
  },
  transectionWeb: {
    component: TransectionWA,
    path: '/transectionWeb'
  },
  Administration: {
    component: Administration,
    path: '/Administration',
    forSuperAdmin: true,
    forWebAdmin: false
  },
  Alarms: {
    component: AlarmsWeb,
    path: '/Alarms',
    forSuperAdmin: true,
    forWebAdmin: false
  },
  projectManagement: {
    component: ProjectManagement,
    path: '/projectManagement',
    forSuperAdmin: false,
    forWebAdmin: true
  },
  clientDashboard: {
    component: ClientDashboardWeb,
    path: '/clientDashboard/:clientId/:plantId'
  },
  clientSetting: {
    component: ClientSettings,
    path: '/clientSetting/:clientId/:plantId'
  },
  selectClientToCompare: {
    component: SelectClientToCompare,
    path: '/selectClientToCompare'
  },
  clientComparison: {
    component: ClientComparison,
    path: '/clientComparison'
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: '/UserProfileBasicBlock'
  },
  Customform: {
    component: Customform,
    path: '/Customform'
  },
  ApiIntegration: {
    component: ApiIntegration,
    path: '/ApiIntegration'
  },
  Analytics: {
    component: Analytics,
    path: '/Analytics'
  },
  LandingPage: {
    component: LandingPage,
    path: '/LandingPage'
  },

  FullTeamVisibility2: {
    component: FullTeamVisibility2,
    path: '/FullTeamVisibility2'
  },
  ActivityLog: {
    component: ActivityLog,
    path: '/ActivityLog'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword',
    isLayoutRequired: false
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/reset-password',
    isLayoutRequired: false
  },
  resetPassword: {
    component: NewForgotPassword,
    path: '/resetPassword/:token/:device',
    isLayoutRequired: false
  },
  TeamBuilder: {
    component: TeamBuilder,
    path: '/TeamBuilder'
  },
  Contactus: {
    component: Contactus,
    path: '/Contactus'
  },
  AddContactus: {
    component: AddContactus,
    path: '/AddContactus'
  },
  StoreLocator: {
    component: StoreLocator,
    path: '/StoreLocator'
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: '/MobileAccountLoginBlock'
  },
  Prioritise: {
    component: Prioritise,
    path: '/Prioritise'
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: '/AdvancedSearch'
  },
  TaskAllocator: {
    component: TaskAllocator,
    path: '/TaskAllocator'
  },
  RecommendationEngine4: {
    component: RecommendationEngine4,
    path: '/RecommendationEngine4'
  },
  AddAppointment: {
    component: AddAppointment,
    path: '/AddAppointment'
  },
  Appointments: {
    component: Appointments,
    path: '/Appointments'
  },
  TwilioIntegration2: {
    component: TwilioIntegration2,
    path: '/TwilioIntegration2'
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLoginBlock',
    isLayoutRequired: false
  },
  ContextTagging: {
    component: ContextTagging,
    path: '/ContextTagging'
  },
  FormApprovalWorkflow: {
    component: FormApprovalWorkflow,
    path: '/FormApprovalWorkflow'
  },
  FingerPrintLogin2: {
    component: FingerPrintLogin2,
    path: '/FingerPrintLogin2'
  },
  CustomForm2: {
    component: CustomForm2,
    path: '/CustomForm2'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  PhotoLibrary3: {
    component: PhotoLibrary3,
    path: '/PhotoLibrary3'
  },
  TaskList: {
    component: TaskList,
    path: '/TaskList'
  },
  Analytic2: {
    component: Analytic2,
    path: '/Analytic2'
  },
  FacialRecognitionLogin2: {
    component: FacialRecognitionLogin2,
    path: '/FacialRecognitionLogin2'
  },
  FileAttachment: {
    component: FileAttachment,
    path: '/FileAttachment'
  },
  CameraAccess: {
    component: CameraAccess,
    path: '/CameraAccess'
  },
  UserGroups: {
    component: UserGroups,
    path: '/UserGroups'
  },
  Scheduling: {
    component: Scheduling,
    path: '/Scheduling'
  },
  WeatherApi22: {
    component: WeatherApi22,
    path: '/WeatherApi22'
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  PaymentAdmin2: {
    component: PaymentAdmin2,
    path: '/PaymentAdmin2'
  },
  FilterSms2: {
    component: FilterSms2,
    path: '/FilterSms2'
  },
  ReceiptCustomisation: {
    component: ReceiptCustomisation,
    path: '/ReceiptCustomisation'
  },
  Filteritems: {
    component: Filteritems,
    path: '/Filteritems'
  },
  Filteroptions: {
    component: Filteroptions,
    path: '/Filteroptions'
  },
  LandingPage2: {
    component: LandingPage2,
    path: '/LandingPage2',
    forSuperAdmin: true,
    forWebAdmin: false
  },
  InvoiceBilling: {
    component: InvoiceBilling,
    path: '/InvoiceBilling'
  },
  RefundManagement: {
    component: RefundManagement,
    path: '/RefundManagement'
  },
  EmailNotifications: {
    component: EmailNotifications,
    path: '/EmailNotifications'
  },
  Sorting: {
    component: Sorting,
    path: '/Sorting'
  },
  WeatherApi12: {
    component: WeatherApi12,
    path: '/WeatherApi12'
  },
  TaskViews: {
    component: TaskViews,
    path: '/TaskViews'
  },
  AdminConsole3: {
    component: AdminConsole3,
    path: '/AdminConsole3'
  },
  DataImportexportcsv: {
    component: DataImportexportcsv,
    path: '/DataImportexportcsv'
  },
  Analytics3: {
    component: Analytics3,
    path: '/Analytics3'
  },
  Sms2: {
    component: Sms2,
    path: '/Sms2'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  CentralDbServerApi2: {
    component: CentralDbServerApi2,
    path: '/CentralDbServerApi2'
  },
  Notifications: {
    component: Notifications,
    path: '/Notifications'
  },
  TaskGroups: {
    component: TaskGroups,
    path: '/TaskGroups'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  Groups: {
    component: Groups,
    path: '/Groups'
  },
  RolesPermissions2: {
    component: RolesPermissions2,
    path: '/RolesPermissions2'
  },
  Calendar: {
    component: Calendar,
    path: '/Calendar'
  },
  AdHocReporting: {
    component: AdHocReporting,
    path: '/AdHocReporting'
  },
  WeatherApi32: {
    component: WeatherApi32,
    path: '/WeatherApi32'
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: '/PhoneNumberInput'
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: '/AdditionalDetailForm'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  TextOrExcelFileImport2: {
    component: TextOrExcelFileImport2,
    path: '/TextOrExcelFileImport2'
  },
  RepeatingTasks: {
    component: RepeatingTasks,
    path: '/RepeatingTasks'
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: '/Pushnotifications'
  },
  ApiIntegration8: {
    component: ApiIntegration8,
    path: '/ApiIntegration8'
  },

  Home: {
    component: EmailAccountLoginBlock,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },

  EditProfile: {
    component: EditProfile,
    path: '/EditProfile'
  },
  ClientPage: {
    component: ClientPage,
    path: '/ClientPage'
  },
  ClientDetailsPage: {
    component: ClientDetailsPage,
    path: '/ClientDetailsPage/:clientId'
  },
  Reports: {
    component: ReportPage,
    path: '/Reports'
  },
  portfolioReport: {
    component: PortfolioReportPage,
    path: '/portfolioReport'
  },
  newPortfolioReport: {
    component: NewPortfolioReportPage,
    path: '/newPortfolioReport',
    needSearchBar: false
  },
  editReport: {
    component: NewPortfolioReportPage,
    path: '/editReport/:reportId',
    needSearchBar: false
  },
  adminData: {
    component: AdminDataPage,
    path: '/AdminData'
  },
  Users: {
    component: User,
    path: '/Users'
  },
  Clients: {
    component: Clients,
    path: '/Clients'
  },
  AddNewSystem: {
    component: AddNewSystem,
    path: '/AddNewSystem/:clientId'
  },
  ChatPage: {
    component: Chat,
    path: '/ChatPage'
  },
  Approval: {
    component: ApprovalPage,
    path: '/Approval'
  },
  NotificationDetails: {
    component: NotificationDetails,
    path: '/NotificationDetails'
  },
  Transactions: {
    component: Transection,
    path: '/Transactions'
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});
const App = () => {
  const defaultAnalytics = firebaseAPI.analytics();
  defaultAnalytics.logEvent('APP_Loaded');
  return (
    <View style={{ height: '100vh', width: '100vw' }}>
      {/* <TopNav /> */}
      {WebRoutesGenerator({ routeMap })}
      <ModalContainer />
    </View>
  );
};

export default App;
