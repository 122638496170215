import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
import {initialState} from "./ClientSettingContext.web";
import {DataLoggerI,DataLoggerErrorI,CreateApiRequestBody,DataLoggerListI, DataLoggerItemI,IDataLoggerDevice} from './ClientSetting.types'
import { toast } from "react-toastify";
export interface Props {
  navigation: any;
  id: string;
  classes?: any;
}

export interface S {
  activeTab: number;
  secondActiveTab: number;
  thirdActiveTab: number;
  addNewDeviceModal: boolean;
  dataLogger:DataLoggerI;
  allDataLogggerList:DataLoggerListI;
  selectedDataLogger:DataLoggerItemI|null;
  createDataLoggerDeviceLoading:boolean;
  updateDataLoggerDeviceLoading:boolean;
  getDataLoggerDeviceLoading:boolean;
  deleteDataLoggerDeiveLoading:boolean;
  deviceCreateError:any;
  deviceList:IDataLoggerDevice[];
  deleteDeviceModal:boolean;
}

interface SS {
  id: any;
}

export default class ClientSettingController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiCreateDataLoggerMessageId:string="";
  apiGetSingleDataLoggerMessageId:string="";
  apiGetAllDataLoggerMessageId:string="";
  apiUpdateDataLoggerMessageId:string="";
  apiGetAllExistsDataLoggerMessageId:string="";
  apiCreateDataLoggerDeviceApiMessageId:string="";
  apiGetDataLoggerDeviceApiMessageId:string="";
  apideleteDataLoggerDeviceApiMessageID:string="";
  fetchFtpDataApi:string="";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = initialState;
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }



  async receive(from: string, message: Message) {


    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleApiRequestResponse(apiRequestCallId,responseJson);
    }

  }

  handleApiRequestResponse=(apiRequestCallId:string,responseJson:any)=>{
      if (responseJson === undefined) {
        return;
      } else if (
        responseJson &&
        responseJson.errors &&
        responseJson.errors[0].token
      ) {
        this.props.navigation.navigate("EmailAccountLoginBlock");
        return;
      }
       if( this.apiCreateDataLoggerMessageId ===apiRequestCallId){
         this.handleCreateDataLoggerResponse(responseJson);
       } 
       if( this.apiUpdateDataLoggerMessageId ===apiRequestCallId){
         this.handleUpdateDataLoggerResponse(responseJson);
       }
       if( this.apiGetSingleDataLoggerMessageId ===apiRequestCallId){
         this.handleGetSingleDataLogger(responseJson);
       } 
       if( this.apiGetAllDataLoggerMessageId ===apiRequestCallId){
         this.handleGetSystemDataLoggerResponse(responseJson)
       } 
       if(this.apiGetAllExistsDataLoggerMessageId===apiRequestCallId){
         this.handleGetAllExistsDataLoggerResponse(responseJson)
       } 
       if(this.apiCreateDataLoggerDeviceApiMessageId===apiRequestCallId){
         this.handleCreateDataLoggerDeviceResponse(responseJson)
       }
       if(this.apiGetDataLoggerDeviceApiMessageId===apiRequestCallId){
         this.setState({
           getDataLoggerDeviceLoading:false,
           deviceList:responseJson?.data?.length>0?responseJson?.data:[]
         })
       }
        if(this.apideleteDataLoggerDeviceApiMessageID===apiRequestCallId){
          this.handleDeleteDataLoggerDeviceResponse(responseJson)
       }
  }

  handleGetSystemDataLoggerResponse=(responseJson:any)=>{
    if(responseJson?.response?.data?.length>0){
          this.setState((prevState)=>{
            const {dataLogger:{currentPage}}=prevState;
            const nDataLogger:DataLoggerListI={
                response:{
                    data:currentPage===1?[...responseJson.response.data]:[...prevState.dataLogger.dataLogggerList.response.data,...responseJson.response.data]
                  },
                length:responseJson?.length
              }
              return{
                 dataLogger:{
                    ...prevState.dataLogger,
                    dataLogggerList:nDataLogger,
                    getLoading:false,
                 }
              }
            }
           );
        }else{
          this.setState(prevState=>({
             dataLogger:{
              ...prevState.dataLogger,
              getLoading:false,
             }
          }))
        }
  }
  handleCreateDataLoggerResponse=(responseJson:any)=>{
     if(responseJson?.data?.id){
            toast.success("New Data Logger Created.")
            this.setState(prevState=>({
              dataLogger:{
                ...prevState.dataLogger,
                input:{
                  name:"",
                  hardwareSerialNumber:"",
                  interval:"",
                  ftpUserName:"",
                  ftpPassword:"",
                  ftpApiKey:"",
                  ftpEntityId:"",
                },
                createLoading:false,
              },
              secondActiveTab:0
            }),()=>{
               this.getDataLoggers();
               this.getAllExistsDataLoggers();
               this.fetchFtpData();
            })
          }else{
            toast.error("Data Logger Create Failed.")
            this.setState(prevState=>({
              dataLogger:{
                ...prevState.dataLogger,
                createLoading:false,
              }
            }))
          }
  }
  handleUpdateDataLoggerResponse=(responseJson:any)=>{
     if(responseJson?.data?.id){
            toast.success("Data Logger Updated.")
            this.setState(prevState=>({
              dataLogger:{
                ...prevState.dataLogger,
                input:{
                  name:"",
                  hardwareSerialNumber:"",
                  interval:"",
                  ftpUserName:"",
                  ftpPassword:"",
                  ftpApiKey:"",
                  ftpEntityId:"",
                },
                createLoading:false,
                edit:false,
                editId:""
              },
              secondActiveTab:0
            }),()=>{
               this.getDataLoggers();
               this.getAllExistsDataLoggers();
               this.fetchFtpData();
            })
          }else{
            toast.error("Data Logger Update Failed.")
            this.setState(prevState=>({
              dataLogger:{
                ...prevState.dataLogger,
                createLoading:false,
              }
            }))
          }
  }
  handleGetSingleDataLogger=(responseJson:any)=>{
      if(responseJson?.data?.id){
            this.setState(prevState=>({
              dataLogger:{
                ...prevState.dataLogger,
                input:{
                  name:responseJson?.data?.data_logger_name,
                  hardwareSerialNumber:responseJson?.data?.hardware_s_no,
                  interval:"",
                  ftpUserName:responseJson?.data?.ftp_username,
                  ftpPassword:responseJson?.data?.ftp_password,
                  ftpApiKey:responseJson?.data?.ftp_host,
                  ftpEntityId:responseJson?.data?.ftp_port,
                },
                updateLoading:false,
              },
            }),()=>{
               this.getDataLoggers();
            })
          }else{
            toast.error("Data Logger Not Found")
            this.setState(prevState=>({
              dataLogger:{
                ...prevState.dataLogger,
                updateLoading:false,
              },
              secondActiveTab:0
            }))
          }
  }
  handleGetAllExistsDataLoggerResponse=(responseJson:any)=>{
    if(responseJson?.response?.data?.length>0){
          this.setState((prevState)=>{
            const nDataLogger:DataLoggerListI={
                response:{
                    data:[...responseJson.response.data]
                  },
                length:responseJson?.length
              }
              return{
                 allDataLogggerList:nDataLogger,
                 selectedDataLogger:responseJson.response.data[0]
              }
            },()=>{
               this.getAllDataLoggerDevice();
            }
           );
        }
  }

  handleCreateDataLoggerDeviceResponse=(responseJson:any)=>{
    if(responseJson?.error){
      this.setState({
         createDataLoggerDeviceLoading:false,
         deviceCreateError:{
          message:responseJson?.exception
         }
      })
    }
    if(responseJson?.data?.id){
      toast.success("Device Created.")
      this.setState({
        createDataLoggerDeviceLoading:false,
        addNewDeviceModal:false,
      })
      this.getAllDataLoggerDevice();
    }
    
  }
  handleDeleteDataLoggerDeviceResponse=(responseJson:any)=>{
     this.setState({
      deleteDataLoggerDeiveLoading:false,
      deleteDeviceModal:false,
     })
     if(responseJson?.Message){
      toast.success(""+responseJson?.Message);
      this.getAllDataLoggerDevice()
     }
  }


  async componentDidMount() {
    this.getDataLoggers();
    this.getAllExistsDataLoggers();
  }

  //data fetching methods
  getDataLoggers = (page?: number) => {
    const { dataLogger:{perPage,loadedPage} } = this.state;
     const plantId=this.props.navigation.getParam("plantId");
    let callPage = 1;
    if (page) {
      callPage = page;
    }

    if (page != null && page <= loadedPage) {
      this.setState(prevState=>({
         dataLogger:{
          ...prevState.dataLogger,
          currentPage: callPage,
         }
      }));
      return;
    }

    this.setState(prevState=>({
      dataLogger:{
       ...prevState.dataLogger,
       currentPage: callPage,
       getLoading: true,
       loadedPage: callPage,
      }
   }));
    const hitUrl = `${
      configJSON.getDataLoggerApiURL
    }?system_id=${plantId}&per_page=${perPage}&page=${callPage}`;
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: userToken,
    };

    const requestMessage = this.createApiRequest({
      header,
      apiUrl: hitUrl,
      body: null,
      method: configJSON.getApiMethodType,
    });
    this.apiGetAllDataLoggerMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllExistsDataLoggers=()=>{
    const plantId=this.props.navigation.getParam("plantId");
    const hitUrl=`${configJSON.getDataLoggerApiURL}?system_id=${plantId}`;
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: userToken,
    };

    const requestMessage = this.createApiRequest({
      header,
      apiUrl: hitUrl,
      body: null,
      method: configJSON.getApiMethodType,
    });
    this.apiGetAllExistsDataLoggerMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  } 
  fetchFtpData=()=>{
    const plantId=this.props.navigation.getParam("plantId");
    const clientId=this.props.navigation.getParam("clientId");
    const hitUrl=`${configJSON.fetchFtpDataApi}?system_id=${plantId}&client_id=${clientId}`;
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: userToken,
    };

    const requestMessage = this.createApiRequest({
      header,
      apiUrl: hitUrl,
      body: null,
      method: configJSON.getApiMethodType,
    });
    this.fetchFtpDataApi = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  } 
  getAllDataLoggerDevice=()=>{
    this.setState({
      getDataLoggerDeviceLoading:true
    })
    const plantId=this.props.navigation.getParam("plantId");
    const {selectedDataLogger}=this.state;
    const dataLoggerId=selectedDataLogger?.attributes.id.toString() as string;

    const hitUrl=`${configJSON.getDataLoggerDeviceApiURL}?system_id=${plantId}&data_logger_id=${dataLoggerId}`;
    const userToken = localStorage.getItem("authToken");
    const header = {
      token: userToken,
    };

    const requestMessage = this.createApiRequest({
      header,
      apiUrl: hitUrl,
      body: null,
      method: configJSON.getApiMethodType,
    });
    this.apiGetDataLoggerDeviceApiMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getSingleDataLogger = (id: string) => {
    const hitUrl = `${
      configJSON.getSingleDataLoggerApiURL
    }/${id}`;
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: userToken,
    };

    const requestMessage = this.createApiRequest({
      header,
      apiUrl: hitUrl,
      body: null,
      method: configJSON.getApiMethodType,
    });
    this.apiGetSingleDataLoggerMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  fetchNextDataLogger=() => {
   const {dataLogger:{currentPage,dataLogggerList,perPage,getLoading}}=this.state;
      if (currentPage<Math.ceil(dataLogggerList.length / perPage)) {
           getLoading || this.getDataLoggers(currentPage + 1);
       }
  }
 fetchPrevDataLogger=()=>{
   const {dataLogger:{currentPage,getLoading}}=this.state;
   if (currentPage> 1) {
      getLoading || this.getDataLoggers(currentPage - 1);
   }
 }
  // handle ui changes\
  //@ts-ignore
  changeDataLoggerInput=(key:string,value:string)=>{
        this.setState(prevState=>({
           dataLogger:{
             ...prevState.dataLogger,
             input:{
               ...prevState.dataLogger.input,
               [key]:value
             }
           }
        }))
  }

  createDataLogger=()=>{
     const {dataLogger}=this.state;
     const {input,edit,editId}=dataLogger || {};

     let newError:DataLoggerErrorI={};
     if(!input.name){
        newError.name="Please Enter Data Logger Name"
     }
     if(!input.hardwareSerialNumber){
      newError.hardwareSerialNumber="Please Enter Hardware Serial Number"
     }
     if(!input.interval){
      newError.interval="Please Enter Measuring Interval (second)"
     }
     if(!input.ftpUserName){
      newError.ftpUserName="Please Enter User Name."
     }
     if(!input.ftpPassword){
      newError.ftpPassword="Please Enter Password."
     }
     if(!input.ftpEntityId){
       newError.ftpEntityId="Please Enter Entity ID"
     }
     if(!input.ftpApiKey){
      newError.ftpApiKey="Please Enter Api Key"
     }

     if( newError &&
      Object.keys(newError).length === 0 &&
      Object.getPrototypeOf(newError) === Object.prototype){
        //no errors all input enered correctly
        this.setState(prevState=>({
          dataLogger:{
            ...prevState.dataLogger,
            errors:{},
            createLoading:true,
          }
       }));

       //make request

       const userToken = localStorage.getItem("authToken");
       const clientId=this.props.navigation.getParam("clientId");
       const plantId=this.props.navigation.getParam("plantId");
       const header = {
         token: userToken,
       };
       const formData = new FormData();
       formData.append("data_logger_name", input.name);
       formData.append("client_id",clientId);
       formData.append("system_id",plantId);
       formData.append("hardware_s_no", input.hardwareSerialNumber);
       formData.append("ftp_port", input.ftpEntityId);
       formData.append("ftp_host", input.ftpApiKey);
       formData.append("ftp_password", input.ftpPassword);
       formData.append("ftp_username", input.ftpUserName);

       let hitUrl=configJSON.createDataLoggerApiURl;
       let method=configJSON.postApiMethodType;

       if(edit && editId){
         hitUrl=configJSON.createDataLoggerApiURl+"/"+editId;
         method=configJSON.patchApiMethodType;
       }

       const requestMessage = this.createApiRequest({
          header,
          apiUrl:hitUrl,
          body: formData,
          method: method,
        });
        if(edit && editId){
          this.apiUpdateDataLoggerMessageId = requestMessage.messageId;
        }else{
          this.apiCreateDataLoggerMessageId = requestMessage.messageId;
        }
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }else{
          //some input are missing

          this.setState(prevState=>({
             dataLogger:{
               ...prevState.dataLogger,
               errors:newError
             }
          }))


      }



  }
  editDataLogger=(id:string)=>{
    this.setState((prevState)=>({
      dataLogger:{
        ...prevState.dataLogger,
        edit:true,
        editId:id,
        updateLoading:true,
      },
      secondActiveTab:1
    }),()=>{
       this.getSingleDataLogger(id);
    })
  }


  handleDeleteDeviceModal=(open:boolean)=>{
    this.setState({
       deleteDeviceModal:open
    })
  }
 handleSelectDataLogger=(id:number)=>{
  const dataLogger=this.state.allDataLogggerList?.response?.data?.filter(it=>it?.attributes?.id===id);
  if(dataLogger?.length>0){
    this.setState({
      selectedDataLogger:dataLogger[0]
    },()=>{
       this.getAllDataLoggerDevice()
    })
  }

 }

  //@ts-ignore
  changeDataLoggerInput=(key:string,value:string)=>{
        this.setState(prevState=>({
           dataLogger:{
             ...prevState.dataLogger,
             input:{
               ...prevState.dataLogger.input,
               [key]:value
             }
           }
        }))
  }
  deleteDataLoggerDevice=(deviceId:string)=>{
      this.setState({
        deleteDataLoggerDeiveLoading:true,
      })

       let hitUrl=configJSON.createDataLoggerDeviceApiURL+"/"+deviceId;
       let method=configJSON.deleteApiMethod;
       const userToken = localStorage.getItem("authToken");
       const header = {
         token: userToken,
       };
       const requestMessage = this.createApiRequest({
          header,
          apiUrl:hitUrl,
          body: null,
          method: method,
        });
        this.apideleteDataLoggerDeviceApiMessageID = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  createDataLoggerDevice=(body:any)=>{
       this.setState({
         createDataLoggerDeviceLoading:true
       })
       const userToken = localStorage.getItem("authToken");
       const plantId=this.props.navigation.getParam("plantId");
       const header = {
         token: userToken,
         "Content-Type":configJSON.exampleApiContentType
       };
       body.data.attributes.system_id=plantId;
       let hitUrl=configJSON.createDataLoggerDeviceApiURL;
       let method=configJSON.postApiMethodType;
       const requestMessage = this.createApiRequest({
          header,
          apiUrl:hitUrl,
          body: JSON.stringify(body),
          method: method,
        });
        this.apiCreateDataLoggerDeviceApiMessageId=requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);

  } 
  changeTab = (value: number) => {
    this.setState({
      activeTab: value,
    });
  };
  changeSecondTab = (value: number) => {
    const {dataLogger:{edit}}=this.state;

    if(value!==1 && edit){
      this.setState(prevState=>({
        dataLogger:{
          ...prevState.dataLogger,
          input:{
            name:"",
            hardwareSerialNumber:"",
            interval:"",
            ftpUserName:"",
            ftpPassword:"",
            ftpApiKey:"",
            ftpEntityId:"",
          },
          edit:false,
          editId:"",
        },
        secondActiveTab:value
      }))
    }else{
      this.setState({
        secondActiveTab: value,
      })
    }
  };
  changeThirdTab = (value: number) => {
    this.setState({
      thirdActiveTab: value,
    });
  };
  changeAddNewDeviceModal = (open: boolean) => {
    this.setState({
      addNewDeviceModal: open,
    });
  };
  createApiRequest = ({
    header,
    apiUrl,
    body,
    method,
  }: CreateApiRequestBody) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    return requestMessage;
  };
}
