import React from "react";
import NotificationDetailsController, { Props, configJSON } from "./NotificationDetailsController.web";
import {Typography,IconButton} from "@material-ui/core"
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import CloseIcon from '@material-ui/icons/Close';
import NotificationDetailsItem from "../../../components/src/NotificationDropdown/NotificationDetailsItem.web";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../assets/notificationdetails.css"
import DeleteNotificationModal from "../../../components/src/NotificationDropdown/DeleteNotificationModal";
import FilterNotification from "../../../components/src/NotificationDropdown/FilterNotification.web";

class NotificationDetails extends NotificationDetailsController {
  
  render() {
      const {notificationList,getNotificationLoading,unread:unreadNotification,deleteLoading,filteredNotificationList}=this.state;
    return (
        <div className="notification__details__container">
        <div className="notification__details__header">
            <h2>Notifications</h2>
            <div style={{marginRight:"10px"}}>
                <FilterNotification
                    filterNotification={this.filterNotification}
                />
            </div>
        </div>
        <div className="notification__details__body">
            <div className="notification__details__content">
            {
                        getNotificationLoading?<Typography style={{color:"black"}} variant="h5">Loading...</Typography>:
                        notificationList?.length===0?<Typography style={{color:"black"}} variant="h5">No Notification Available For You</Typography>:
                        filteredNotificationList?.length>0?
                        filteredNotificationList.map((item,index)=>(
                            <NotificationDetailsItem 
                              notification={item}
                              key={item?.id}
                              clickNotification={this.navigateToActionPage}
                              changeDeleteNotificationModal={this.changeDeleteModal}
                              />
                        )):
                        notificationList?.map((item,index)=>(
                            <NotificationDetailsItem 
                              notification={item}
                              key={item?.id}
                              clickNotification={this.navigateToActionPage}
                              changeDeleteNotificationModal={this.changeDeleteModal}
                              />
                        ))
                    }
            </div>
        </div>
        <DeleteNotificationModal
            deleteLoading={this.state.deleteLoading}
            deleteModal={this.state.deleteModal}
            deleteNotification={this.deleteNotification}
            changeDeleteModal={this.changeDeleteModal}
        />
        <ToastContainer theme="dark" />
    </div>  
  )
  }
}

export default NotificationDetails;
