import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { AuthContext } from "../../email-account-login/src/AuthContext/AuthContext.web";
import { ToastContainer, toast } from "react-toastify";
export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
}

export interface Notification{
    id:string;
    type:string;
   
    attributes:{
      contents:string,
      created_at:string,
      id:number,
      is_read:boolean,
      read_at:string|null,
       event:string|null,
      account:{
        activated:boolean,
        admin:boolean,
        role:string
      }
    }
}


interface S {
  getNotificationLoading:boolean,
  notificationList:Notification[],
  lastCalled:number,
  unread:number,
  unreadNotificationsId:number[],
  deleteLoading:boolean,
  deleteModal:boolean,
  filteredNotificationList:Notification[],
  filterByDateError:string
}

interface SS {
  id: any;
}

export default class NotificationDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  static contextType: any = AuthContext;

  apiGetUserNotificationCallId:string="";
  apiMakeAsReadNotificationCallId:string="";
  apiDeleteNotificationCallId:string="";
  deleteNotificationId:string="";
  apiFilterNotificationCallId:string="";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      getNotificationLoading:true,
      notificationList:[],
      lastCalled:0,
      unread:0,
      unreadNotificationsId:[],
      deleteLoading:false,
      deleteModal:false,
      filteredNotificationList:[],
      filterByDateError:""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: String, message: Message) {
    //<=========Response For Get All Notification===========>
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiGetUserNotificationCallId &&
      this.apiGetUserNotificationCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson === undefined) {
        return;
      }
      this.setState({
        getNotificationLoading:false,
        notificationList:responseJson?.data,
        lastCalled:Date.now(),
      });
     
      this.calculateUnreadNotification();



    }
    //<=========Response For Make Notification as Read===========>
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiMakeAsReadNotificationCallId &&
      this.apiMakeAsReadNotificationCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.setState({
        unread:0,
        unreadNotificationsId:[]
      })
   

    } 
     //<=========Response For Delete Notification===========>
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiDeleteNotificationCallId &&
      this.apiDeleteNotificationCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson?.errors){
          toast.error(JSON.stringify(responseJson?.errors))
          this.setState({
            deleteLoading:false,
            deleteModal:false,
            filteredNotificationList:[]
          })
      }
      if(responseJson?.message){
        this.setState(prevState=>{
        
          const nNotificationList=prevState.notificationList.filter(nItem=>nItem?.id!==this.deleteNotificationId)
          const filteredNotificationList=prevState.filteredNotificationList.filter(nItem=>nItem?.id!==this.deleteNotificationId)
        
          return{
            notificationList:nNotificationList,
            deleteLoading:false,
            deleteModal:false,
            filteredNotificationList:filteredNotificationList
          }
        })
        toast("Notification Deleted",{autoClose: 500})
      }
      

    } 
    //<=========Response For Filter Notification===========>
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiFilterNotificationCallId &&
      this.apiFilterNotificationCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({
        getNotificationLoading:false,
        filteredNotificationList:responseJson?.data
      })

    }
  }

  async componentDidMount() {
    this.getAllNotifications();
  }
 getAllNotifications = async() => {
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetUserNotificationCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getNotificationEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  makeAsReadNotification=()=>{
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: userToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiMakeAsReadNotificationCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.makeReadNotificationEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({notification_ids:this.state.unreadNotificationsId})
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.markAsReadMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  } 
  changeDeleteModal=(open:boolean,id?:string)=>{
    if(id){
      this.deleteNotificationId=id;
    }
    this.setState({
      deleteModal:open
    })
  
  }
   deleteNotification=()=>{
     this.setState({
       deleteLoading:true
     })
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: userToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteNotificationCallId = requestMessage.messageId;
   
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteNotificationEndPoint}/${this.deleteNotificationId}`
    );

  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({})
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  calculateUnreadNotification=()=>{
      const {notificationList}=this.state;
      let totalCount:number=0;
      let ids:number[]=[]
  
      if(notificationList?.length>0){
        for(let i=0; i<notificationList.length; i++){
          const notificationItem=notificationList[i];
          if(notificationItem?.attributes?.is_read===false){
            totalCount+=1;
            ids.push(parseInt(notificationItem?.id))
        }
      }
        this.setState({
          unread:totalCount,
          unreadNotificationsId:ids
        })
      }
  }

  navigateToActionPage=(notification:Notification)=>{

      // const msg: Message = new Message(getName(MessageEnum.FromNotificationNavigationMessage));
      // msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      // msg.addData(getName(MessageEnum.NavigationRaiseMessage),notification); 
      if(notification?.attributes?.event==="system_notification"){
        //sent to approval page
       // msg.addData(getName(MessageEnum.NavigationTargetMessage),"Approval");
        this.props.navigation?.navigate("Approval")
      }else if(notification?.attributes?.event==="user_notification"){
        //sent to user page
       // msg.addData(getName(MessageEnum.NavigationTargetMessage),"Users");
        this.props.navigation?.navigate("Users")
      }else if(notification?.attributes?.event==="chat_message"){
        //sent to user page
       // msg.addData(getName(MessageEnum.NavigationTargetMessage),"Users");
        this.props.navigation?.navigate("ChatPage")
      }
      
      //this.send(msg);
  }
  // filterNotification=(filterBy:string)=>{
  //    const {notificationList}=this.state;
  //    this.filterNotificationFromApi(filterBy)
  //     if(filterBy==="all"){
  //       this.setState({
  //         filteredNotificationList:[]
  //       })
  //     }else{
  //       const filteredNotification=notificationList?.filter(notificationItem=>{
  //        return notificationItem?.attributes?.event===filterBy
  //       })
  //       this.setState({
  //         filteredNotificationList:filteredNotification
  //       })
  //     }
  // }

  // filterByDate=(startDate:string,endDate:string)=>{
  //   this.filterNotificationFromApi("date",startDate,endDate)
  //   const {notificationList}=this.state;
  //   const  stDate = new Date(startDate);
  //   const  edDate = new Date(endDate);
  //   const sd=new Date(`${stDate?.getFullYear()}-${stDate?.getMonth()+1}-${stDate?.getDate()}`).getTime();
  //   const ed=new Date(`${edDate?.getFullYear()}-${edDate?.getMonth()+1}-${edDate?.getDate()}`).getTime();
   
  //   const result = notificationList.filter(notificationItem => {
  //                           // const createdTime = new Date(notificationItem?.attributes?.created_at).getTime();
  //                           const cD=new Date(notificationItem?.attributes?.created_at);
                            
  //                           const createdTime=new Date(`${cD?.getFullYear()}-${cD?.getMonth()+1}-${cD?.getDate()}`).getTime();
  //                           return (sd <= createdTime && createdTime <= ed);
  //                           });                   
  //   if(result?.length>0){
  //       this.setState({
  //         filteredNotificationList:result
  //       })
  //   }else{
  //     this.setState({
  //       filterByDateError:"No Notification Found Between Two Dates.",
  //       filteredNotificationList:[]
  //     })
  //     toast("No Notification Found Between Two Dates.")
  //   }

  // }


  filterNotification=(filterBy:string,startDate?:string,endDate?:string)=>{
    if(filterBy==="all"){
      this.setState({
        filteredNotificationList:[]
      })
      return;
    }
    
    this.setState({
      getNotificationLoading:true,
    })

    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiFilterNotificationCallId = requestMessage.messageId;

    let filterNotificationHitUrl=`${configJSON.filterNotificationEndPoint}`

    if(filterBy==="system_notification"){
      filterNotificationHitUrl+=`?type=system&event=system_notification`;
    }else if(filterBy==="user_notification"){
      filterNotificationHitUrl+=`?type=user&event=user_notification`;
    }else if(filterBy==="chat_message"){
      filterNotificationHitUrl+=`?type=chat&event=chat_message`;
    }else if(filterBy==="date"){
      if(startDate && endDate){
      const  stDate = new Date(startDate);
      const  edDate = new Date(endDate);
      const sd=`${stDate?.getFullYear()}-${stDate?.getMonth()+1}-${stDate?.getDate()}`;
      const ed=`${edDate?.getFullYear()}-${edDate?.getMonth()+1}-${edDate?.getDate()}`;
       filterNotificationHitUrl+=`?type=date&event=date&from_date=${sd}&to_date=${ed}`;
      }
    }
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      filterNotificationHitUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }




}
