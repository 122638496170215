import {
  Button,
  Checkbox,
  createStyles,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useAuth } from "../../../blocks/email-account-login/src/AuthContext/AuthContext.web";
import RenderTable from "../Util/RenderTable";
import { ITableHeader } from "../Util/Table.types";
import {
  DataLoggerItemI,
  DataLoggerListI,
} from "../../../blocks/ActivityLog/src/ClientSetting.types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: "20px",
    },
    dividerStyle: {
      marginTop: "10px",
      marginBottom: "15px",
      height: "2px",
      width: "100%",
      background: "#f9f9fa",
    },
    ashBoldText: {
      color: "#8F92A1",
      fontSize: "15px",
      marginTop: "10px",
      marginBottom: "10px",
      fontWeight: "bolder",
    },
    selectContainer: {
      border: "none",
      fontSize: "1.2rem",
      fontWeight: "bolder",
      color: "black",
      background: "#f9f9fa",
      paddingLeft: "10px",
      paddingRight: "10px",
      borderRadius: "15px",
      "&:hover": {
        borderBottom: "0px",
      },
      "&::before": {
        borderBottom: "0px !important",
      },
      "&::after": {
        borderBottom: "0px !important",
      },
      "&$selected": {
        borderBottom: "0px !important",
      },
    },
    blackBoldText: {
      fontSize: "1.2rem",
      fontWeight: "bolder",
      color: "black",
    },
    headingText: {
      fontSize: "1.5rem",
      fontWeight: 550,
      color: "black",
    },
    row: {
      display: "flex",
      alignItems: "center",
      gap: "15px",
    },
    ashButton: {
      background: "#f9f9fa",
      borderRadius: "15px",
      boxShadow: "none",
      textTransform: "capitalize",
      fontWeight: 400,
      color: "black",
    },
    yellowButton: {
      background: "#FFCE21",
      borderRadius: "15px",
      boxShadow: "none",
      textTransform: "capitalize",
      fontWeight: 400,
      color: "black",
    },
    table__container: {
      boxSizing: "border-box",
      marginTop: "10px",
      overflowY: "auto",
      width: "100%",
      "& table": {
        width: "100%",
        minWidth: "1200px",
        borderSpacing: "0px 15px",
        "& thead": {
          background: "#f9f9fa",
          borderRadius: "10px",
          height: "40px",
        },
        "& tr": {
          textAlign: "left",
          "& td": {
            textTransform: "capitalize",
            padding: "5px 0px",
            borderBottom: "2px solid #f9f9fa",
          },
          "&:hover": {
            backgroundColor: "var(--main-color)",
            color: "var(--txt-white)",
            cursor: "pointer",
          },
        },
      },
    },
    itemText: {
      fontSize: "16px",
      fontWeight: 500,
    },
  })
);
interface Props {
  changeAddNewDeviceModal: (open: boolean) => void;
  selectedDataLogger: DataLoggerItemI | null;
  dataLoggers: DataLoggerListI;
}

const inverterTableHeaders: ITableHeader[] = [
  {
    id: 1,
    name: "IDENTITY NO",
    enableFilter: false,
  },
  {
    id: 2,
    name: "UID",
    enableFilter: false,
  },
  {
    id: 3,
    name: "DESIGNATION",
    enableFilter: false,
  },
  {
    id: 4,
    name: "ADDRESS",
    enableFilter: false,
  },
  {
    id: 5,
    name: "SERIAL NUMBER",
    enableFilter: false,
  },
  {
    id: 6,
    name: "SUBSYSTEM",
    enableFilter: false,
  },
  {
    id: 7,
    name: "SCALE FACTYOR",
    enableFilter: false,
  },
  {
    id: 8,
    name: "REPLACE",
    enableFilter: false,
  },
];

const renderTableItem = (data: any, styles: any) => {
  return (
    <tr>
      <td>
        <div
          style={{
            paddingLeft: "20px",
            display: "flex",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <Checkbox color="primary" />
          <h2 className={styles.itemText} />
        </div>
      </td>
      <td>
        <div>
          <h2 className={styles.itemText}>SN65578</h2>
        </div>
      </td>{" "}
      <td>
        <div>
          <h2 className={styles.itemText}>Inverter__</h2>
        </div>
      </td>
      <td>
        <div>
          <h2 className={styles.itemText} />
        </div>
      </td>
      <td>
        <div>
          <h2 className={styles.itemText}>Inverter__</h2>
        </div>
      </td>
      <td>
        <div>
          <h2 className={styles.itemText}>No assignment</h2>
        </div>
      </td>
      <td>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <Select className={styles.selectContainer} value="X">
            <MenuItem value="X">X</MenuItem>
            <MenuItem value="Y">Y</MenuItem>
          </Select>
          <Select className={styles.selectContainer} value="5">
            <MenuItem value="5">5</MenuItem>
            <MenuItem value="5">6</MenuItem>
          </Select>
          <h2 className={styles.itemText}>kWp</h2>
        </div>
      </td>
      <td>
        <div>
          <h2 className={styles.itemText}>____</h2>
        </div>
      </td>
    </tr>
  );
};

const Inverter = ({
  changeAddNewDeviceModal,
  dataLoggers,
  selectedDataLogger,
}: Props) => {
  const styles = useStyles();
  const { isSuperAdmin } = useAuth();
  return (
    <div className={styles.container}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography
            component="h1"
            variant="h3"
            className={styles.headingText}
          >
            Inverter Configuration
          </Typography>
        </Grid>
        <Grid item>
          {isSuperAdmin() && (
            <Button
              onClick={() => changeAddNewDeviceModal(true)}
              className={styles.yellowButton}
            >
              Add
            </Button>
          )}
        </Grid>
      </Grid>

      <br />
      <div className={styles.dividerStyle} />

      <RenderTable
        totalPage={47}
        perPage={2}
        currentPage={1}
        nextFetcher={() => {}}
        prevFetcher={() => {}}
        headers={inverterTableHeaders}
        data={[...new Array(100)]}
        renderRow={renderTableItem}
      />

      {/* <div className={styles.table__container}>
        <table>
          <thead>
            <tr>
              <th>
                <h5 style={{ fontWeight: "bold", paddingLeft: "20px" }}>
                  IDENTITY NO
                </h5>
              </th>
              <th>
                <h5 style={{ fontWeight: "bold" }}>UID</h5>
              </th>
              <th>
                <h5 style={{ fontWeight: "bold" }}>DESIGNATION</h5>
              </th>
              <th>
                <h5 style={{ fontWeight: "bold" }}>ADDRESS</h5>
              </th>
              <th>
                <h5 style={{ fontWeight: "bold" }}>SERIAL NUMBER</h5>
              </th>
              <th>
                <h5 style={{ fontWeight: "bold" }}>SUBSYSTEM</h5>
              </th>
              <th>
                <h5 style={{ fontWeight: "bold" }}>SCALE FACTYOR</h5>
              </th>
              <th>
                <h5 style={{ fontWeight: "bold" }}>REPLACE</h5>
              </th>
            </tr>
          </thead>
          <tbody>
            {[...new Array(2)].map((item, index) => (
             
            ))}
          </tbody>
        </table>
      </div> */}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "15px",
          gap: "18px",
        }}
      >
        <Button className={styles.ashButton}>Change inverter</Button>
        <Button className={styles.yellowButton}>Save</Button>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
};

export default Inverter;
