import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./customConfig");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  userProfileInfo: any;
  userAccountList: any[];
  openUpdateAccountConfirmation: boolean;
}

interface SS {
  id: any;
}

export default class EditProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiResetUpdateProfileCallId: string = "";
  apiResetUploadAvatarProfileCallId: string = "";
  selectedFile: any = null;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      userProfileInfo: null,
      userAccountList: [],
      openUpdateAccountConfirmation: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: String, message: Message) {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const profileDetailsData = message.getData(
        getName(MessageEnum.EditProfileDetailMessage)
      );

      this.setState({
        userProfileInfo: JSON.parse(profileDetailsData),
      });
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiResetUpdateProfileCallId &&
      this.apiResetUpdateProfileCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson === undefined) {
        return;
      } else if (
        responseJson &&
        responseJson.errors &&
        responseJson.errors[0].token
      ) {
        this.props.navigation.navigate("EmailAccountLoginBlock");
        return;
      }
      this.redirectToProfileView();
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiResetUploadAvatarProfileCallId &&
      this.apiResetUploadAvatarProfileCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson === undefined) {
        return;
      } else if (
        responseJson &&
        responseJson.errors &&
        responseJson.errors[0].token
      ) {
        this.props.navigation.navigate("EmailAccountLoginBlock");
        return;
      }
    }
  }

  handelNameChange(event: any) {
    const profileData = this.state.userProfileInfo;
    profileData.name = event.target.value;
    this.setState({ userProfileInfo: profileData });
  }

  handelPhoneNumberChange(event: any) {
    const profileData = this.state.userProfileInfo;
    profileData.phone_number = event.target.value;
    this.setState({ userProfileInfo: profileData });
  }

  handelPasswordChange(event: any) {
    const profileData = this.state.userProfileInfo;
    profileData.password = event.target.value;
    this.setState({ userProfileInfo: profileData });
  }

  openUpdateProfileConfirmation() {
    this.setState({openUpdateAccountConfirmation: true});
  }

  handleClose() {
    this.setState({openUpdateAccountConfirmation: false});
  }

  redirectToProfileView() {
    this.props.navigation.navigate("UserProfileBasicBlock");
  }

  handleUpdateProfile() {
    this.openUpdateProfileConfirmation();
  }

  updateProfile() {
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };

    const attrs = {
      name: this.state.userProfileInfo.name,
      phone_number: this.state.userProfileInfo.phone_number,
    };

    const data = {
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiResetUpdateProfileCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateAccountProfileEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onFileChange(event: any) {
    this.selectedFile = event.target.files[0];
    const profileData = this.state.userProfileInfo;

    var reader = new FileReader();
    reader.onload = (e: any) => {
      profileData.image = e?.target?.result;
      this.setState({userProfileInfo:profileData});
    };
    reader.readAsDataURL(this.selectedFile);

    this.onFileUpload();
  }

  onFileUpload() {
    const formData = new FormData();
    formData.append("image", this.selectedFile);

    const userToken = localStorage.getItem("authToken");
    const header = {
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiResetUploadAvatarProfileCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.uploadProfileAvatarEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
