import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes?: any;
}

export interface S {
  filterModal: null | HTMLElement;
  activeStep: number;
}

interface SS {
  id: any;
}

export default class SelectClientToCompareController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      activeStep: 0,
      filterModal: null,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {}

  async receive(from: String, message: Message) {}

  // handle ui changes

  handleFilterOpen = (event: React.MouseEvent<HTMLElement> | null) => {
    this.setState({
      filterModal: event ? event.currentTarget : null,
    });
  };
}
