import {
  Button,
  Grid, makeStyles,
  Paper,
  TextField,
  Typography
} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined';
import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Yup from 'yup';
const useStyles=makeStyles({
   inputPlaceholder:{
     color:"#8F92A1",
     fontWeight:"bolder"
  },
   headingText:{
     fontWeight:"bolder",
     fontSize:"22px"

  },
   dropZone:{
     width:"100%",
     flexDirection:"column",
     height:"90px",
     boxSizing:"border-box",
     padding:"10px",
     border:"2px dashed #000",
     borderRadius:"10px",
     background:"#f9f9fa",
     display:"flex",
     justifyContent:"center",
     alignItems:"center",
     cursor:"pointer",
     marginBottom:"15px",
     marginTop:"10px"
  }
})
interface UserError {
  clientName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  userName?: string | null;
  password?: string | null;
  primaryAddress?: string | null;
  contact?: string | null;
  moniteredBy?: string | null;
  moniteredEmail?: string | null;
  operatedBy?: string | null;
  operatedEmail?: string | null;
  commisioning?: string | null;
  logo?: string | null;
}
//component props type
interface Props{
   edit:boolean;
   client?:any;
   open:boolean;
   setOpen:(open:boolean)=>void;
   loading:boolean;
   actionHandler:any;
   isSuccess?:boolean;
   newErrors?:any;
}

export default function AddNewClientModal({ open, setOpen, actionHandler, client, edit, loading, isSuccess = false, newErrors }: Props) {
  const styles = useStyles();
  const [clientName, setClientName] = React.useState<string>("");
  const [firstName, setFirstName] = React.useState<string>("");
  const [lastName, setLastName] = React.useState<string>("");
  const [userName, setUserName] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [primaryAddress, setPrimaryAddress] = React.useState<string>("");
  const [contact, setContact] = React.useState<string>("");
  const [moniteredBy, setMoniteredBy] = React.useState<string>("");
  const [moniteredEmail, setMoniteredEmail] = React.useState<string>("");
  const [operatedBy, setOperatedBy] = React.useState<string>("");
  const [operatedEmail, setOperatedEmail] = React.useState<string>("");
  const [commisioning, setCommisioning] = React.useState<string>("");
  const [logo, setLogo] = React.useState<any>(null);
  const [viewImage, setViewImage] = React.useState<any>(null);
  const [errors, setErrors] = React.useState<UserError>(newErrors)

  const validateInput = () => {
    let userSchema = Yup.object({
      clientName: Yup.string().required("Please Enter Client Name").max(20, "Client Name is too long (maximum is 20 characters)"),
      firstName: Yup.string().required("Please Enter First Name").max(10, "First Name is too long (maximum is 10 characters)"),
      lastName: Yup.string().required("Please Enter Last Name").max(10, "Last Name is too long (maximum is 10 characters)"),
      userName: Yup.string().required("Please Enter Email.").email("Invalid Email Address"),
      password: Yup.string().required("Please Enter Your Password").matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\_*])(?=.{6,})/,
        "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
      primaryAddress: Yup.string().required("Please Enter Primary Address.").max(300, "Address is too long(maximum is 300 characters)"),
      contact: Yup.string().required("Please Enter Contact Number")
        .min(10, "Contact is too short (minimum is 10 characters)")
        .max(10, "Contact is too long (maximum is 10 characters)"),
      moniteredBy: Yup.string().required("Please Enter Monitered By"),
      moniteredEmail: Yup.string().required("Please Enter Monitered Email.").email("Invalid Email Address"),
      operatedBy: Yup.string().required("Please Enter Operated By"),
      operatedEmail: Yup.string().required("Please Enter Operated Email.").email("Invalid Email Address"),
      commisioning: Yup.string().required("Please Enter Commisioning."),
      logo: Yup.string().required("Please Please Select Logo"),
    });
    userSchema.validate({
      clientName,
      firstName,
      lastName,
      userName,
      password,
      primaryAddress,
      contact,
      moniteredBy,
      moniteredEmail,
      operatedBy,
      operatedEmail,
      commisioning,
           logo:edit?"edit":logo?.name
        }, { abortEarly: false }).then((value)=>{
      //check commisioning
      let newError: any = {}
      let isValidYear = true;
      if (commisioning) {
        const dateParts = commisioning.split("-");
        const commisiongYearLength = new Date(Number(dateParts[0]), Number(dateParts[1]) - 1, Number(dateParts[2])).getFullYear().toString().length;
        if (commisiongYearLength > 4) {
          isValidYear = false;
          newError.commisioning = "Please Enter Valid Year";
        }
      }
            if(isValidYear){
        setErrors({})
        const formData = new FormData();
        formData.append("image", logo);
        formData.append("name", clientName);
        formData.append("first_name", firstName);
        formData.append("last_name", lastName);
        formData.append("password", password);
        formData.append("user_name", userName);
        formData.append("primary_address", primaryAddress);
        formData.append("contact", contact);
        formData.append("monitered_by", moniteredBy);
        formData.append("operated_by", operatedBy);
        formData.append("monitered_email", moniteredEmail);
        formData.append("operated_email", operatedEmail);
        formData.append("commisioning", commisioning);
        actionHandler(formData, client?.id);
      } else {
        setErrors(newError)
      }


        }).catch((err)=>{
          let newError:any={};
          err.inner.forEach((item:any)=>{
             newError[item.path]=item.message
      })
      setErrors(newError)
    })
  }
  const onFileChange=(event: any) =>{
    const selectedFile = event.target.files[0];
    setLogo(selectedFile);
    const reader = new FileReader();
    reader.onload = (e: any) => {
      setViewImage(e?.target?.result);
    };
    reader.readAsDataURL(selectedFile);
    const newErros={
      ...errors,
      logo:""
    }
    setErrors(newErros);
  }
  React.useEffect(()=>{
    if(isSuccess){
      const clearInput=()=>{
        setLogo(null)
        setViewImage(null)
        setClientName("")
        setFirstName("")
        setLastName("")
        setUserName("")
        setPassword("")
        setPrimaryAddress("")
        setContact("")
        setMoniteredBy("")
        setMoniteredEmail("")
        setOperatedBy("")
        setOperatedEmail("")
        setCommisioning("")
      }

      clearInput()
    }
  },[isSuccess])
  React.useEffect(()=>{
    setErrors(newErrors)
  },[newErrors])
  React.useEffect(()=>{
    if(edit){

      const fillInput=()=>{
        setLogo(null)
        setClientName(client?.attributes?.name)
        setFirstName(client?.attributes?.first_name)
        setLastName(client?.attributes?.last_name)
        setUserName(client?.attributes?.user_name)
        setPassword(client?.attributes?.password)
        setPrimaryAddress(client?.attributes?.primary_address)
        setContact(client?.attributes?.contact)
        setMoniteredBy(client?.attributes?.monitered_by)
        setMoniteredEmail(client?.attributes?.monitered_email)
        setOperatedBy(client?.attributes?.operated_by)
        setOperatedEmail(client?.attributes?.operated_email)
        setCommisioning(client?.attributes?.commisioning)
        setViewImage(client?.attributes?.image)
      }


      fillInput()
    }
  },[edit])

  const handleCommisioningChange=(e:any)=>{
    setCommisioning(e.target.value)
  }

  const getButtonValue=()=>{
     if(edit && !loading){
      return "Update"
    }
     if(!edit && !loading){
      return "Save"
    }

  }

  return (
    <div>
      <Modal
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Paper
          style={{ width: "680px", padding: "20px", borderRadius: "10px" }}
        >
          <div style={{ padding: "10px",height:"85vh",overflow:"auto" }}>
            <Typography
              className={styles.headingText}
              variant="h5"
            >
              {edit?"Update Client":" Add New Client"}
            </Typography>


            <div
              style={{
                marginTop: "10px",
                marginBottom: "15px",
                height: "2px",
                width: "100%",
                background: "#f9f9fa",
              }}
            ></div>

            <input accept="image/*" id="upload"
              type="file" style={{ display: 'none' }}
              onChange={(e) => onFileChange(e)}
            />
            <span  style={{marginLeft:"7px"}} className={styles.inputPlaceholder}>Logo</span>

            <label htmlFor="upload" className={styles.dropZone}>
              {
                viewImage === null ? <>
                  <label htmlFor="upload" style={{ background: "#f1f0f0", borderRadius: "50%", cursor: "pointer", padding: "10px" }}>
                    <PublishOutlinedIcon />
                  </label>
                  <div>
                    <h3>{logo?.name}</h3>

                  </div>
                          </>:<img style={{width:"100%",height:"100%",objectFit:"cover"}} src={viewImage} alt=""/>
              }


            </label>
            <span style={{color:"red",fontSize:"12px",fontWeight:250}}>{errors?.logo}</span>

            <TextField
              value={clientName}
                onChange={(e)=>setClientName(e.target.value)}
              fullWidth id="standard-basic"
              label={<span className={styles.inputPlaceholder}>Name of the client</span>}
                 error={errors?.clientName?true:false}
              helperText={errors?.clientName}
            />
            <Grid container spacing={2} style={{ marginTop: "15px" }}>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <TextField
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  fullWidth id="standard-basic"
                  label={<span className={styles.inputPlaceholder}>First Name</span>}
                  error={errors?.firstName ? true : false}
                  helperText={errors?.firstName}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <TextField
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  fullWidth id="standard-basic"
                  label={<span className={styles.inputPlaceholder}>Last Name</span>}
                  error={errors?.lastName ? true : false}
                  helperText={errors?.lastName}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: "15px" }}>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <TextField
                  value={userName}
                    onChange={(e)=>setUserName(e.target.value)}
                  fullWidth id="standard-basic"
                  label={<span className={styles.inputPlaceholder}>Email</span>}
                     error={errors?.userName?true:false}
                  helperText={errors?.userName}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>

                <TextField
                  type="password"
                      onChange={(e)=>setPassword(e.target.value)}
                  fullWidth id="standard-basic"
                  label={<span className={styles.inputPlaceholder}>Password</span>}
                      error={errors?.password?true:false}
                  helperText={errors?.password}
                />
              </Grid>
            </Grid>
            <TextField
              type="text"
              value={primaryAddress}
                      onChange={(e)=>setPrimaryAddress(e.target.value)}
              style={{ marginTop: "15px" }}
              fullWidth
              id="standard-select-currency"
              label={<span className={styles.inputPlaceholder}>Primary Address</span>}
                      error={errors?.primaryAddress?true:false}
              helperText={errors?.primaryAddress}
            />
            <TextField
              type="number"
              value={contact}
                    onChange={(e)=>setContact(e.target.value)}
              style={{ marginTop: "15px" }}
              fullWidth
              id="standard-basic"
              label={<span className={styles.inputPlaceholder}>Contact</span>}
                      error={errors?.contact?true:false}
              helperText={errors?.contact}
                      onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
            />
            <Grid container spacing={2} style={{ marginTop: "15px" }}>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <TextField
                  value={moniteredBy}
                      onChange={(e)=>setMoniteredBy(e.target.value)}
                  fullWidth
                  id="standard-basic"
                  label={<span className={styles.inputPlaceholder}>Monitered By</span>}
                      error={errors?.moniteredBy?true:false}
                  helperText={errors?.moniteredBy}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <TextField
                  value={moniteredEmail}
                      onChange={(e)=>setMoniteredEmail(e.target.value)}
                  fullWidth
                  id="standard-basic"
                  label={<span className={styles.inputPlaceholder}>Monitered Email</span>}
                      error={errors?.moniteredEmail?true:false}
                  helperText={errors?.moniteredEmail}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: "15px" }}>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <TextField
                  value={operatedBy}
                      onChange={(e)=>setOperatedBy(e.target.value)}
                  fullWidth
                  id="standard-basic"
                  label={<span className={styles.inputPlaceholder}>Operated By</span>}
                      error={errors?.operatedBy?true:false}
                  helperText={errors?.operatedBy}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <TextField
                  value={operatedEmail}
                      onChange={(e)=>setOperatedEmail(e.target.value)}
                  fullWidth
                  id="standard-basic"
                  label={<span className={styles.inputPlaceholder}>Operated Email</span>}
                      error={errors?.operatedEmail?true:false}
                  helperText={errors?.operatedEmail}
                />
              </Grid>
            </Grid>

              <div style={{marginTop:"30px",display:"flex",flexDirection:"column",gap:"10px"}}>
                 <span  className={styles.inputPlaceholder}>Commisioning</span>
              <TextField
                value={commisioning}
                onChange={handleCommisioningChange}
                type="date"
                inputProps={{
                        max:"9999-12-31"
                }}
                fullWidth
                id="standard-basic"
                      error={errors?.commisioning?true:false}
                helperText={errors?.commisioning}
              />
            </div>


            <Grid container spacing={2} style={{ marginTop: "15px" }}>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <Button
                  onClick={() => setOpen(false)}
                  fullWidth
                  variant="contained"
                  style={{
                    background: "#f9f9fa",
                    marginRight: "10x",
                    borderRadius: "15px",
                    marginTop: "10px",
                    marginBottom: "10px",
                    boxShadow: "none",
                    textTransform: "capitalize",
                    fontWeight: 400,
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <Button
                  disabled={loading}
                  onClick={()=>validateInput()}
                  fullWidth
                  variant="contained"
                  style={{
                    background: "#FFCE21",
                    marginRight: "10x",
                    borderRadius: "15px",
                    marginTop: "10px",
                    marginBottom: "10px",
                    boxShadow: "none",
                    textTransform: "capitalize",
                    fontWeight: 400,
                  }}
                >
                  {loading? <CircularProgress size={24} />: getButtonValue()}
                </Button>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Modal>
    </div>
  );
}
