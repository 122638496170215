import { Button, Paper, Typography } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import React from "react";

const dividerStyle = {
  marginTop: "10px",
  marginBottom: "15px",
  height: "2px",
  width: "100%",
  background: "#f9f9fa",
};

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}
export default function TransactionDetailsModal({ open, setOpen }: Props) {
  return (
    <div>
      <Modal
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Paper
          style={{ width: "680px", padding: "20px", borderRadius: "20px" }}
        >
          <div style={{ padding: "10px" }}>
            <Typography
              variant="h5"
              style={{ fontWeight: 600, fontSize: "22px" }}
            >
              Transaction Details
            </Typography>

            <div style={dividerStyle} />
            <div className="transection__details__modal__body__container">
              <Typography
                component="h5"
                style={{
                  color: "#8F92A1",
                  fontSize: "15px",
                  marginTop: "10px",
                  marginBottom: "10px",
                  fontWeight: "bolder",
                }}
              >
                To:
              </Typography>
              <Typography
                component="h4"
                style={{ fontSize: "16px", fontWeight: "bolder" }}
              >
                transactionname
              </Typography>
              <div style={dividerStyle} />
              {/* <=========From Details=======+> */}
              <Typography
                component="h5"
                style={{
                  color: "#8F92A1",
                  fontSize: "15px",
                  marginTop: "10px",
                  marginBottom: "10px",
                  fontWeight: "bolder",
                }}
              >
                From:
              </Typography>
              <Typography
                component="h4"
                style={{ fontSize: "16px", fontWeight: "bolder" }}
              >
                transactionname
              </Typography>
              <div style={dividerStyle} />
              {/* <=========Transaction ID=======+> */}
              <Typography
                component="h5"
                style={{
                  color: "#8F92A1",
                  fontSize: "15px",
                  marginTop: "10px",
                  marginBottom: "10px",
                  fontWeight: "bolder",
                }}
              >
                Transaction ID:
              </Typography>
              <Typography
                component="h4"
                style={{ fontSize: "16px", fontWeight: "bolder" }}
              >
                457889454878989854
              </Typography>
              <div style={dividerStyle} />
              {/* <=========Amount=======+> */}
              <Typography
                component="h5"
                style={{
                  color: "#8F92A1",
                  fontSize: "15px",
                  marginTop: "10px",
                  marginBottom: "10px",
                  fontWeight: "bolder",
                }}
              >
                Amount:
              </Typography>
              <Typography
                component="h4"
                style={{ fontSize: "16px", fontWeight: "bolder" }}
              >
                23k
              </Typography>
              <div style={dividerStyle} />
              {/* <=========Date=======+> */}
              <Typography
                component="h5"
                style={{
                  color: "#8F92A1",
                  fontSize: "15px",
                  marginTop: "10px",
                  marginBottom: "10px",
                  fontWeight: "bolder",
                }}
              >
                Completed on:
              </Typography>
              <Typography
                component="h4"
                style={{ fontSize: "16px", fontWeight: "bolder" }}
              >
                November 1,5:50 PM
              </Typography>
              <div style={dividerStyle} />
            </div>

            <Button
              onClick={() => setOpen(false)}
              fullWidth
              variant="contained"
              style={{
                background: "#f9f9fa",
                marginRight: "10x",
                borderRadius: "15px",
                marginTop: "10px",
                marginBottom: "10px",
                boxShadow: "none",
                textTransform: "capitalize",
                fontWeight: 400,
              }}
            >
              Close
            </Button>
          </div>
        </Paper>
      </Modal>
    </div>
  );
}
