import React, { ReactComponentElement } from "react";
import { ToastContainer } from "react-toastify";
import { Typography, Grid } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import ClientComparisonItem from "../../../components/src/ClientScreenscomponents/ClientComparisonItem.web";
import ClientComparisonController, {
  Props,
  configJSON,
} from "./ClientComparisonController.web";
const styles = (theme: Theme) => ({
  action__buttons__item: {
    display: "flex",
    alignItems: "center",
  },
});

class ClientComparison extends ClientComparisonController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        <div className="new__portfolio__report__body__heading">
          <div className="new__portfolio__report__body__heading__left">
            <Typography
              component="h3"
              variant="h5"
              style={{ fontWeight: "bolder" }}
            >
              Comparison
            </Typography>
          </div>
        </div>
        <div className="new__portfolio__report__container">
          <div className="new__portfolio__report__body__container">
            <Grid container spacing={7} style={{ paddingTop: "20px" }}>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                style={{ borderRight: "4px solid #b6b6b6" }}
              >
                <ClientComparisonItem />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <ClientComparisonItem />
              </Grid>
            </Grid>
          </div>
        </div>
        <ToastContainer theme="dark" />
      </>
    );
  }
}

export default ClientComparison;
