import React from "react";

import ClientsController, { Props } from "./ClientsController.web";
import {
  Container,
  Box,
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import "../../assets/network.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Icons
import BarChartOutlinedIcon from "@material-ui/icons/BarChartOutlined";
import KeyboardArrowRightOutlinedIcon from "@material-ui/icons/KeyboardArrowRightOutlined";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import ClientListTable from "../../../../components/src/NetworkComponents/ClientTableComponent";
import AddNewClientModal from "../../../../components/src/NetworkComponents/AddNewClientModal.web";
import ClientsSystemList from "../../../../components/src/NetworkComponents/SystemListModal.web";
import { NetworkContext } from "./NetworkContext.web";
import ActionModal from "../../../../components/src/Util/ActionModal";
export default class Clients extends ClientsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      deleteClientConfimation,
      addClientModal,
      setClientLoading,
      clientCreated,
    } = this.state;
    return (
      <NetworkContext.Provider
        value={{
          ...this.state,
          ...this,
        }}
      >
        <main style={{ overflowX: "auto" }}>
          <Container
            maxWidth="xl"
            style={{ marginBottom: "20px", marginTop: "50px" }}
          >
            <Box
              style={{
                backgroundColor: "#f9f9fa",
                borderRadius: "24px",
                padding: "20px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className="networkHeader">
                <div>
                  <BarChartOutlinedIcon style={{ color: "#FDCE32" }} />
                </div>
                <div>
                  <KeyboardArrowRightOutlinedIcon
                    style={{ color: "#8F92A1" }}
                  />
                </div>
                <div>
                  <h3 style={{ fontSize: "14px" }}>Network</h3>
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: "24px",
                  width: "100%",
                }}
              >
                <div style={{ display: "flex", marginTop: "15px" }}>
                  <Typography
                    component="h2"
                    style={{
                      margin: "0px 32px",
                      fontSize: "22px",
                      fontWeight: "bold",
                      flex: 1,
                    }}
                  >
                    Clients
                  </Typography>

                  <Button
                    onClick={() => this.changeClientModal(true)}
                    variant="contained"
                    disableElevation
                    style={{
                      margin: "0px 32px",
                      borderRadius: "9px",
                      height: "40px",
                      background: "#FDCE32",
                      fontWeight: 600,
                      textTransform: "capitalize",
                    }}
                    startIcon={<AccountCircleOutlinedIcon />}
                  >
                    Add New Client
                  </Button>
                </div>

                <Paper elevation={0} style={{ margin: "0px 32px" }}>
                  <ClientListTable
                    clientErrors={this.state.clientErrors}
                    userCreateErrors={this.state.userCreateError}
                  />
                </Paper>
              </div>
            </Box>
          </Container>

          <AddNewClientModal
            edit={false}
            open={addClientModal}
            setOpen={this.changeClientModal}
            loading={setClientLoading}
            actionHandler={this.setNewClients}
            isSuccess={clientCreated}
            newErrors={this.state.clientErrors}
          />

          <ClientsSystemList clientId={this.idSystemClients} />
          <ActionModal
            open={deleteClientConfimation}
            title="Delete Client?"
            message="Are you sure want to delete client?"
            setOpen={(open: boolean) => this.cancelDeleteClient()}
            positiveButtonText="Delete"
            actionHandler={this.confirmDeleteClient}
            actionLoading={this.state.deleteClientLoading}
            actionLoadingText="Deleting.."
          />
        </main>
        <ToastContainer theme="dark" />
      </NetworkContext.Provider>
    );
  }
}
