import React from "react";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import MoodIcon from "@material-ui/icons/Mood";
import { Typography, IconButton } from "@material-ui/core";
import {useChatContext} from '../../../blocks/Chat9/src/ChatContext.web'
import Picker from 'emoji-picker-react';
import CloseIcon from '@material-ui/icons/Close';
import {
  imgCloudFileImage,
} from "../../../blocks/Chat9/src/assets";
import { CurrentChatType } from "../../../blocks/Chat9/src/Interfaces.web";
const image =
  "https://images.pexels.com/photos/1043474/pexels-photo-1043474.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500";

interface Props {
  currentChat: CurrentChatType
}

const ChatInput = ({ currentChat }:Props) => {
  const [message,setMessage]=React.useState<string>("");
  const [emojiModal,setEmojiModal]=React.useState<boolean>(false)
  const [attatchmentViewModal,setAttatchmentViewModal]=React.useState<boolean>(false)
  const [attatchment,setAttatchment]=React.useState<any>(null)
  const emojiRef=React.useRef<HTMLDivElement>(null)
  const inputRef=React.useRef<HTMLInputElement>(null);
  const inputFileRef=React.useRef<HTMLInputElement>(null);
  const {sendNewMessage}=useChatContext();

  const handleKeyDown=(e:React.KeyboardEvent<HTMLInputElement>)=>{
     if(e?.key==="Enter" && (message.trim()!="" || attatchment)){
       sendNewMessage(message,attatchment)
       setMessage("")
       setAttatchmentViewModal(false);
       setAttatchment(null);
       if(inputFileRef!=null && inputFileRef.current!==null){
          inputFileRef.current.value="";
       }
      
     }
  }

  React.useEffect(() => {
    setMessage('')
    setAttatchmentViewModal(false);
    setAttatchment(null)
  }, [currentChat]);

  React.useEffect(() => {
    function handleClickOutside(event:any) {
      if (emojiRef?.current && !emojiRef?.current?.contains(event?.target)) {
        setEmojiModal(false)
      }
    }
    // Bind the event listener
    document.addEventListener("click", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("click", handleClickOutside);
    };
  }, [emojiRef]);


  const handleFileChange=(e:any)=>{
      setEmojiModal(false)
      setAttatchmentViewModal(true);
      setAttatchment(e?.target?.files[0])
     if(inputRef?.current){inputRef?.current?.focus()}
  }


 console.log("<================Chat Input Rendering==============>");

  return (
    <div className="chat__input">
      <div className="chat__input__left">
        <div className="chat_input_sender_image">
          <img src={image} alt="" />
        </div>
        {
          attatchmentViewModal && 
              <div className="chat__input__file__attatchment">
              <img 
                 onError={(e:React.SyntheticEvent<HTMLImageElement, Event>)=>{
                           e.currentTarget.onerror=null;
                           e.currentTarget.src=imgCloudFileImage;
                          }}
                  src={URL.createObjectURL(attatchment)}
                 alt="" />
              <p className="chat__input__file__attatchment__name">
                 {attatchment?.name}
              </p>
              <div className="attatchment__close__button">
                  <IconButton onClick={e=>{setAttatchmentViewModal(false);setAttatchment(null)}}>
                      <CloseIcon />
                  </IconButton>
              </div>
            </div>
        }
        
        <div style={{ width: "85%" }}>
          <input
            ref={inputRef}
            type="text" 
            onKeyDown={e=>handleKeyDown(e)}
            onChange={e=>setMessage(e.target.value)}
            value={message}
            placeholder="Write a comment.." />
        </div>
      </div>
      <div className="chat__input__right">
        <div  ref={emojiRef} className="emoji__container">
          <IconButton onClick={e=>{setEmojiModal(!emojiModal);setAttatchmentViewModal(false)}}>
            <MoodIcon />
          </IconButton>
          {
            emojiModal &&<div className="emoji__modal">
                             <Picker
                               onEmojiClick={(e:any,emojiObject:any)=>{
                                           setMessage(message+emojiObject?.emoji)
                                       }}
                                />
                        </div>
          }
            

        </div>
        <div className="chatInput__AttatchmentContainer">
          <input
            ref={inputFileRef}
            accept="/*"
            id="uploadAttachment"
            type="file"
            style={{ display: "none" }}
            onChange={e=>handleFileChange(e)}
          />
          <label htmlFor="uploadAttachment">
            <AttachFileIcon style={{ color: "#767676", cursor: "pointer" }} />
          </label>
         
         
        </div>
        
        
      </div>
    </div>
  );
};

export default React.memo(ChatInput);
