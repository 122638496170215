import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from "yup";
export const customConfigJSON = require("./customConfig");
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
}

interface S {
  accountType: string;
  accountStatus: any;
  passwordRules: any;
  emailValue: any;
  phoneValue: any;
  countryCodeSelected: any;
  token: any;
  enablePasswordField: Boolean;
  btnConfirmPasswordShowHide: Boolean;
  newPassword: string;
  confirmNewPassword: string;
  requestLoading: boolean;
}

interface SS {
  navigation: any;
}

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const passwordInvisibleImage = require("../assets/ic_password_invisible.png");
const passwordVisibleImage = require("../assets/ic_password_visible.png");

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  validationAPICallId: any;
  requestEmailOtpCallId: any;
  requestPhoneOtpCallId: any;
  requestChangePasswordCallId: any;
  requestGoToConfirmationCallId: any;
  otpToken: any;
  isChangePassword: boolean;
  apiForgotPasswordCallId: string = "";
  apiResetPasswordCallId: string = "";
  userSessionToken: string = "";

  //Properties from config
  labelTextIsAccountRecovery: string = configJSON.labelTextIsAccountRecoveryNew;
  secondLabelText: string = configJSON.secondLabelText;
  thirdLabelText: string = configJSON.thirdLabelText;
  forthLabelText: string = configJSON.forthLabelText;
  fifthLabelText: string = configJSON.fifthLabelText;
  sixthLabelText: string = configJSON.sixthLabelText;
  firstInputAutoCompleteType: any = configJSON.firstInputAutoCompleteType;
  firstInputKeyboardStyle: any = configJSON.firstInputKeyboardStyle;
  firstInputPlaceholder: string = configJSON.firstInputPlaceholder;
  firstInputErrorColor: any = configJSON.firstInputErrorColor;
  buttonTextIsNext: string = configJSON.buttonTextIsNext;
  buttonColorForNextButton: any = configJSON.buttonColorForNextButton;
  secondInputAutoCompleteType: any = configJSON.secondInputAutoCompleteType;
  secondInputKeyboardType: any = configJSON.secondInputKeyboardType;
  secondInputPlaceholder: string = configJSON.secondInputPlaceholder;
  secondInputErrorColor: any = configJSON.secondInputErrorColor;
  thirdInputPlaceholder: string = configJSON.thirdInputPlaceholder;
  thirdInputErrorColor: any = configJSON.thirdInputErrorColor;
  buttonTitleIsSMSPhoneAccount: string =
    configJSON.buttonTitleIsSMSPhoneAccount;
  buttonTitleIsEmailAccount: string = configJSON.buttonTitleIsEmailAccount;
  labelTextIsPleaseEnterYourNewPassword: string =
    configJSON.labelTextIsPleaseEnterYourNewPassword;
  labelTextIsYourPasswordHasBeenSuccessfullyChanged: string =
    configJSON.labelTextIsYourPasswordHasBeenSuccessfullyChanged;
  placeholderIsPassword: string = configJSON.placeholderIsPassword;
  passwordVisibleImage: any = passwordVisibleImage;
  passwordInvisibleImage: any = passwordInvisibleImage;
  placeholderIsReTypePassword: string =
    configJSON.placeholderIsReTypePasswordNew;
  buttonTitleIsOk: string = configJSON.buttonTitleIsOk;
  buttonColorForOkButton: any = configJSON.buttonColorForOkButton;
  countryCodeSelectorPlaceholder: string =
    configJSON.countryCodeSelectorPlaceholder;

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      accountType: "sms",
      accountStatus: "ChooseAccountType",
      emailValue: "",
      phoneValue: "",
      countryCodeSelected: "",
      passwordRules: "",
      token: "",
      enablePasswordField: true,
      btnConfirmPasswordShowHide: true,
      newPassword: "",
      confirmNewPassword: "",
      requestLoading: false,
    };
  }

  async componentDidMount() {
    super.componentDidMount();
    // this.validationRulesRequest();
  }

  handleTxtInputEmail(event: any) {
    this.setState({ emailValue: event.target.value });
  }

  handleTxtInputNewPassword(event: any) {
    this.setState({ newPassword: event.target.value });
  }

  handleTxtInputConfirmNewPassword(event: any) {
    this.setState({ confirmNewPassword: event.target.value });
  }

  doForgotPassword = () => {
    const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (
      this.state.emailValue === null ||
      this.state.emailValue.length === 0 ||
      !this.state.emailValue.match(emailRegExp)
    ) {
      this.showAlert("Error", customConfigJSON.errorEmailNotValid);
      return false;
    }

    this.setState({
      requestLoading: true,
    });
    const header = {
      "Content-Type": customConfigJSON.forgotPasswordAPiContentType,
    };

    const attrs = {
      email: this.state.emailValue,
    };

    const httpBody = {
      data: attrs,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiForgotPasswordCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      customConfigJSON.sendRecoveryPasswordLinkAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      customConfigJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  doResetPassword() {
    const passwordRegExp = customConfigJSON.passwordRegExp;
    if (
      this.state.newPassword === null ||
      this.state.newPassword.length === 0 ||
      !this.state.newPassword.match(passwordRegExp)
    ) {
      this.showAlert("Error", customConfigJSON.errorPasswordNotValid);
      return false;
    }
    if (
      this.state.newPassword === null ||
      this.state.newPassword.length === 0 ||
      this.state.newPassword !== this.state.confirmNewPassword
    ) {
      this.showAlert("Error", configJSON.passwordsMustMatch);
      return false;
    }

    const header = {
      "Content-Type": customConfigJSON.forgotPasswordAPiContentType,
    };

    const attrs = {
      password: this.state.newPassword,
      confirm_password: this.state.confirmNewPassword,
    };

    const httpBody = {
      data: attrs,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiResetPasswordCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${customConfigJSON.resetPasswordAPIEndPoint}?token=${
        this.userSessionToken
      }`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      customConfigJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  validationRulesRequest = () => {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileValidationSettingsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiForgotPasswordCallId &&
      this.apiForgotPasswordCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson === undefined) {
        return;
      }
      this.setState({ emailValue: "", requestLoading: false });
      this.showAlert("", responseJson.message);
      return false;
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiResetPasswordCallId &&
      this.apiResetPasswordCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson === undefined) {
        return;
      }
      this.showAlert("Error", responseJson.message);
      this.goToLogin();
      return false;
    }
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id)
      if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
        const otpAuthTkn = message.getData(
          getName(MessageEnum.AuthTokenDataMessage)
        );

        if (otpAuthTkn && otpAuthTkn.length > 0) {
          this.setState({ token: otpAuthTkn });
          if (this.isChangePassword) {
            this.setState({ accountStatus: "ChangePassword" });
          }
          this.otpToken = this.state.token;
          // runEngine.debugLog("otpAuthTkn", this.state.token);
        } else {
          const accountType = message.getData(
            getName(MessageEnum.NavigationForgotPasswordPageInfo)
          );
          if (accountType) {
            this.startForgotPassword(accountType);
          }
        }
      } else if (
        getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.apiForgotPasswordCallId &&
        this.apiForgotPasswordCallId ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      ) {
        var responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson === undefined || responseJson.data === undefined) {
          return;
        }
      } else if (
        getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.validationAPICallId &&
        this.validationAPICallId ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      ) {
        var responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (responseJson === undefined || responseJson.data === undefined) {
          return;
        }

        if (responseJson && responseJson.data[0]) {
          const passRegex = RegExp(
            responseJson.data[0].password_validation_regexp
          );
          const emailRegex = RegExp(
            responseJson.data[0].email_validation_regexp
          );
          const passwordRulesFromValidation =
            responseJson.data[0].password_validation_rules;
        }
      } else if (
        getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.requestEmailOtpCallId !== null &&
        this.requestEmailOtpCallId ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      ) {
        // console.log("entered email!!!!");
        var responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (
          responseJson !== undefined &&
          responseJson.meta &&
          responseJson.meta.token
        ) {
          this.otpToken = responseJson.meta.token;

          this.setState({ token: this.otpToken });

          //navigate to OTP page
          const msg: Message = new Message(
            getName(MessageEnum.NavigationMobilePhoneOTPMessage)
          );

          msg.addData(
            getName(MessageEnum.AuthTokenDataMessage),
            this.state.token
          );

          msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

          msg.addData(
            getName(MessageEnum.AuthTokenEmailMessage),
            this.state.emailValue
          );

          msg.addData(
            getName(MessageEnum.EnterOTPAsForgotPasswordMessage),
            true
          );

          this.send(msg);
        }
        //error handling
        else if (responseJson && responseJson.errors) {
          this.parseApiErrorResponse(responseJson);
        } else {
          var errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );

          this.parseApiCatchErrorResponse(errorReponse);
        }
      } else if (
        getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.requestPhoneOtpCallId !== null &&
        this.requestPhoneOtpCallId ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      ) {
        // console.log("entered phone!!!!");
        var responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (
          responseJson !== undefined &&
          responseJson.meta &&
          responseJson.meta.token
        ) {
          this.otpToken = responseJson.meta.token;
          this.setState({ token: this.otpToken });

          const msg: Message = new Message(
            getName(MessageEnum.NavigationMobilePhoneOTPMessage)
          );
          msg.addData(
            getName(MessageEnum.AuthTokenDataMessage),
            this.state.token
          );

          msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

          msg.addData(
            getName(MessageEnum.AuthTokenPhoneNumberMessage),
            this.state.phoneValue
          );

          msg.addData(
            getName(MessageEnum.EnterOTPAsForgotPasswordMessage),
            true
          );

          this.send(msg);
        }
        //error handling
        else if (responseJson && responseJson.errors) {
          this.parseApiErrorResponse(responseJson);
        } else {
          var errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );

          this.parseApiCatchErrorResponse(errorReponse);
        }
      } else if (
        getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.requestGoToConfirmationCallId !== null &&
        this.requestGoToConfirmationCallId ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      ) {
        // console.log("entered 3");
        var responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson !== undefined && responseJson.data) {
          this.setState({
            accountStatus: "Confirmation",
          });
        } else if (responseJson !== undefined && responseJson.errors) {
          this.parseApiErrorResponse(responseJson);
        } else {
          var errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );

          this.parseApiCatchErrorResponse(errorReponse);
        }
      } else if (getName(MessageEnum.CountryCodeMessage) === message.id) {
        var selectedCode = message.getData(
          getName(MessageEnum.CountyCodeDataMessage)
        );

        if (selectedCode !== undefined) {
          this.setState({
            countryCodeSelected:
              selectedCode.indexOf("+") > 0
                ? selectedCode.split("+")[1]
                : selectedCode,
          });
        }
      }
  }

  startForgotPassword(accountType: String) {
    this.setState({
      accountStatus: accountType === "sms" ? "EnterPhone" : "EnterEmail",
    });
  }

  goToOtpAfterEmailValidation(values: { accountType: string; email: string }) {
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestEmailOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryStartOtpAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({
      emailValue: values.email ? values.email : "",
    });

    const data = {
      type: values.accountType ? values.accountType : "email_account",
      attributes: {
        email: values.email ? values.email : "",
      },
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToOtpAfterPhoneValidation(values: { phone: string }) {
    // console.log("entered phone validation code");
    if (
      !this.state.countryCodeSelected ||
      this.state.countryCodeSelected.length === 0
    ) {
      this.showAlert(
        configJSON.goToOtpAfterPhoneValidationErrorTitle,
        configJSON.goToOtpAfterPhoneValidationErrorBody
      );
      return;
    }
    console.log(this.state.countryCodeSelected);
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestPhoneOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryStartOtpAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({
      phoneValue:
        this.state.countryCodeSelected && values.phone
          ? this.state.countryCodeSelected + values.phone
          : "",
    });

    const data = {
      type: "sms_account",
      attributes: {
        full_phone_number: this.state.phoneValue,
      },
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToChangePasswordAfterOtp(values: { otpCode: string }) {
    //change status to change password
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestChangePasswordCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryConfirmOtpAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
      token: this.otpToken ? this.otpToken : "",
      otp_code: values.otpCode ? values.otpCode : "",
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToConfirmationAfterPasswordChange(values: {
    password: any;
    confirmPassword: any;
  }) {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestGoToConfirmationCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryChangePasswordAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
      token: this.otpToken ? this.otpToken : "",
      new_password: values.password,
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
}
