import { Button, IconButton, Typography } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import DehazeIcon from '@material-ui/icons/Dehaze';
import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddGroupMembersModalWeb from "../../../components/src/ChatComponents/AddGroupMembersModal.web";
import AddNewChatModal from "../../../components/src/ChatComponents/AddNewChatModal.web";
import ChatHeader from "../../../components/src/ChatComponents/ChatHeader.web";
import ChatInput from "../../../components/src/ChatComponents/ChatInput.web";
import CreateGroupModal from "../../../components/src/ChatComponents/CreateGroupModal";
import DeleteChatRoomModal from "../../../components/src/ChatComponents/DeleteChatRoomModal.web";
import DeleteGroupModal from "../../../components/src/ChatComponents/DeleteGroupModal.web";
import DirectMessageItem from "../../../components/src/ChatComponents/DirectMessageItem.web";
import GroupMembersModalWeb from "../../../components/src/ChatComponents/GroupMembersModal.web";
import GroupMessageItem from "../../../components/src/ChatComponents/GroupMessageItem.web";
import MessageItem from "../../../components/src/ChatComponents/MessageItem.web";
import SelectGroupUserModalWeb from "../../../components/src/ChatComponents/SelectGroupUserModal.web";
import "../assets/chat.css";
import { ChatContext } from './ChatContext.web';
import ChatController, { Props } from "./ChatController.web";
import { MessageItemType } from "./Interfaces.web";

class Chat extends ChatController {
  scroll_ref:any=null;
  direct__message__ref:any=null;
  group__message__ref:any=null;
  constructor(props: Props) {
    super(props);
    this.scroll_ref=React.createRef()
    this.direct__message__ref=React.createRef()
    this.group__message__ref=React.createRef()
  }

  isAnother=(msg:MessageItemType):boolean=>{
    let check:boolean=true;
        if(msg?.attributes?.account_id?.id==this.state?.loggedInUser?.account?.account_id){
            check=false;
        }
    return check;
  }
  //test change

  needShowDate=(prevMsgDate:any,currentMsgDate:any):boolean=>{
      let needShow=true;
        const pDate=new Date(prevMsgDate);
        const cDate=new Date(currentMsgDate);
        if(pDate?.getFullYear()===cDate.getFullYear() && pDate?.getMonth()===cDate?.getMonth() && pDate?.getDate()===cDate?.getDate()){
            needShow=false;
        }

      return needShow;
  }


  toggleChatOpen=()=>{
    console.log("Ref",this.direct__message__ref.current)
    this.direct__message__ref?.current?.classList?.toggle("open__chat__list");
    this.group__message__ref?.current?.classList?.toggle("open__chat__list");
  }




  render() {

    const {addNewChatModal,
        directMessageList,
        currentChat,
        getMessageLoading,
        getDirectConversationsLoading,
        createGroupModal,
        userList,
        selectGroupUserModal,
        groupList,
        getGroupLoading,
        groupMembersModal,
        addGroupMembersModal,
        deleteGroupModal,
        deleteChatRoomModal
    }=this.state;
    return (
        <ChatContext.Provider
            value={{
                ...this.state,
                ...this,
             }}
        >
         <div className="chat__page_container">
        <div className="chat__page_title">
                <h2>Chats</h2>
        </div>
        <div className="chat__page_body">
            <div className="chat__container">
                <div className="direct__message" ref={this.direct__message__ref}>
                     <div className="chat__close__button" style={{display:"flex",alignItems:"center",justifyContent:"flex-end"}}>
                        <IconButton onClick={e=>this.toggleChatOpen()} style={{margin:"0px",padding:"0px"}}>
                            <CloseIcon/>
                        </IconButton>
                     </div>
                    <div className="direct__message__wrapper">
                        <div className="direct_message__header">
                            <div>
                                <p>Direct Messages 
                                    {getDirectConversationsLoading && <span style={{fontSize:"10px",marginLeft:"2px",color:"green",textTransform:"lowercase"}}>(loading..)</span>  }
                                </p>
                            </div>
                            <div>
                            <IconButton onClick={(e)=>this.changeAddNewChatModal(true)}>
                                <AddIcon/>
                            </IconButton>
                            </div>
                        </div>
                        <div className="direct__message__body">
                            {
                            directMessageList?.map((dMessage,index)=>(
                                <DirectMessageItem  key={dMessage?.id} message={dMessage} index={index}/>
                            ))
                            }
                        </div>
                    </div>
                    
                </div>
                <div className="groups__message" ref={this.group__message__ref}>
                    <div className="group__message__wrapper">
                        <div className="group_message__header">
                            <div>
                                <p>Groups</p>
                                {getGroupLoading && <span style={{fontSize:"10px",marginLeft:"2px",color:"green",textTransform:"lowercase"}}>(loading..)</span>  }
                              
                            </div>
                            <div>
                                <IconButton onClick={(e)=>this.changeGroupCreateModal(true)}>
                                <AddIcon/>
                            </IconButton>
                            </div>
                        </div>
                        <div className="group__message__body">
                            {
                            groupList?.map((group,index)=>(
                                <GroupMessageItem key={index} group={group} />
                            ))
                            }
                        </div>
                    </div>
                </div>
                <div className="chat__header">
                            {
                              currentChat?.type!=="" && (currentChat?.chat!==null || currentChat?.group!==null) ?
                              <ChatHeader
                                 getMessageLoading={getMessageLoading}
                                currentChat={currentChat}
                                toggleChatOpen={this.toggleChatOpen}
                              />:<div style={{display:"flex",alignItems:"center",gap:"15px"}}>
                                    <div className='chat__header__toggle__icon'>
                                        <IconButton>
                                            <DehazeIcon/>
                                        </IconButton>
                                    </div>
                                <Typography style={{margin:"0 auto",color:"#8f92a1"}} variant="h6">Please Select A Chat To Continue</Typography>
                              </div>
                            }

                  
                </div>
                <div className="chat__body">
                    <div className="chat__body__wrapper">
                            {
                                currentChat?.type!=="" && currentChat?.chat!==null &&
                                currentChat?.messages?.length===0 && !getMessageLoading?
                                <Typography variant="h6" style={{margin:"0 auto",color:"#8f92a1"}}>No Conversations</Typography>:
                                currentChat?.messages?.map((msg,index)=>(
                                    <>
                                        <MessageItem 
                                            ref={this.scroll_ref}
                                            key={msg?.id}
                                            message={msg}
                                            showImage={currentChat?.messages[index-1]?.attributes?.account_id?.id===msg?.attributes?.account_id?.id?false:true} 
                                            showDate={this.needShowDate(currentChat?.messages[index-1]?.attributes?.created_at,msg?.attributes?.created_at)}
                                        />
                                    </>
                                ))
                            }     

                                {/* <MessageItem 
                                    key={index}
                                    message={item}
                                    showImage={messsages[index-1]?.senderId===item?.senderId?false:true} 
                                    /> */}

                    </div>
                </div>
                <div className="chat__footer">
                   <ChatInput currentChat={currentChat}/>
                </div>
                <div className="chat__new__message">
                       <Button 
                           startIcon={<AddIcon/>}
                           fullWidth
                           style={{background:"#f9f9fa",borderRadius:"15px",textTransform:"capitalize"}}>
                                New Message
                       </Button>
                </div>
            </div>
        </div>
        { addNewChatModal && <AddNewChatModal open={addNewChatModal} setOpen={this.changeAddNewChatModal}/>}
        { createGroupModal && <CreateGroupModal open={createGroupModal} setOpen={this.changeGroupCreateModal}/>}
        { selectGroupUserModal && <SelectGroupUserModalWeb open={selectGroupUserModal}/>}
        { groupMembersModal && <GroupMembersModalWeb open={groupMembersModal} setOpen={this.changeGroupMembersModal}/>}
        { addGroupMembersModal && <AddGroupMembersModalWeb open={addGroupMembersModal} setOpen={this.changeAddGroupMembersModal}/>}
        { deleteGroupModal && <DeleteGroupModal/>}
        { deleteChatRoomModal && <DeleteChatRoomModal/>}
         </div>
         <ToastContainer theme="dark" />
         </ChatContext.Provider>
        );
  }
}

export default Chat;
