import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { AuthContext } from "../../email-account-login/src/AuthContext/AuthContext.web";
import { ToastContainer, toast } from "react-toastify";
export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
}

export interface Notification{
    id:string;
    type:string;
   
    attributes:{
      contents:string,
      created_at:string,
      id:number,
      is_read:boolean,
      read_at:string|null,
       event:string|null,
      account:{
        activated:boolean,
        admin:boolean,
        role:string
      }
    }
}


interface S {
  getNotificationLoading:boolean,
  notificationList:Notification[],
  lastCalled:number,
  unread:number,
  unreadNotificationsId:number[],
  deleteLoading:boolean,
  deleteModal:boolean
}

interface SS {
  id: any;
}

export default class Notification2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  static contextType: any = AuthContext;

  apiGetUserNotificationCallId:string="";
  apiMakeAsReadNotificationCallId:string="";
  apiDeleteNotificationCallId:string="";
  deleteNotificationId:string="";
  intervel:any=null;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      getNotificationLoading:true,
      notificationList:[],
      lastCalled:0,
      unread:0,
      unreadNotificationsId:[],
      deleteLoading:false,
      deleteModal:false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: String, message: Message) {
    //<=========Response For Get All Notification===========>
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiGetUserNotificationCallId &&
      this.apiGetUserNotificationCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson === undefined) {
        return;
      }
      this.setState({
        getNotificationLoading:false,
        notificationList:responseJson?.data,
        lastCalled:Date.now(),
      });
     
      this.calculateUnreadNotification();



    }
    //<=========Response For Make Notification as Read===========>
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiMakeAsReadNotificationCallId &&
      this.apiMakeAsReadNotificationCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.setState({
        unread:0,
        unreadNotificationsId:[]
      })
   

    }  //<=========Response For Delete Notification===========>
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiDeleteNotificationCallId &&
      this.apiDeleteNotificationCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson?.errors){
          toast.error(JSON.stringify(responseJson?.errors))
          this.setState({
            deleteLoading:false,
            deleteModal:false
          })
      }
      if(responseJson?.message){
        this.setState(prevState=>{
        
          const filteredNotificationList=prevState.notificationList.filter(nItem=>nItem?.id!==this.deleteNotificationId)
        
          return{
            notificationList:filteredNotificationList,
            deleteLoading:false,
            deleteModal:false
          }
        })
        toast("Notification Deleted",{autoClose: 500})
      }
      

    }
  }

  async componentDidMount() {
   this.getAllNotifications();
    this.intervel=setInterval(()=>{
      this.getAllNotifications();
    },30000)
    
  }

async componentWillUnmount() {
  console.log(this.intervel)
   if(this.intervel){
     clearInterval(this.intervel)
   }
   
}

 getAllNotifications = async() => {
  console.log({
    message:"Getting Notifiation"
  })
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetUserNotificationCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getNotificationEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
};

  makeAsReadNotification=()=>{
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: userToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiMakeAsReadNotificationCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.makeReadNotificationEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({notification_ids:this.state.unreadNotificationsId})
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.markAsReadMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  } 
  changeDeleteModal=(open:boolean,id?:string)=>{
    if(id){
      this.deleteNotificationId=id;
    }
    this.setState({
      deleteModal:open
    })
  
  }
   deleteNotification=()=>{
     this.setState({
       deleteLoading:true
     })
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: userToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteNotificationCallId = requestMessage.messageId;
   
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteNotificationEndPoint}/${this.deleteNotificationId}`
    );

  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({})
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  calculateUnreadNotification=()=>{
      const {notificationList}=this.state;
      let totalCount:number=0;
      let ids:number[]=[]
  
      if(notificationList?.length>0){
        for(let i=0; i<notificationList.length; i++){
          const notificationItem=notificationList[i];
          if(notificationItem?.attributes?.is_read===false){
            totalCount+=1;
            ids.push(parseInt(notificationItem?.id))
        }
      }
        this.setState({
          unread:totalCount,
          unreadNotificationsId:ids
        })
      }
      
  }

  navigateToActionPage=(notification:Notification)=>{

      // const msg: Message = new Message(getName(MessageEnum.FromNotificationNavigationMessage));
      // msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      // msg.addData(getName(MessageEnum.NavigationRaiseMessage),notification); 
      if(notification?.attributes?.event==="system_notification"){
        //sent to approval page
       // msg.addData(getName(MessageEnum.NavigationTargetMessage),"Approval");
        this.props.navigation?.navigate("Approval")
      }else if(notification?.attributes?.event==="user_notification"){
        //sent to user page
       // msg.addData(getName(MessageEnum.NavigationTargetMessage),"Users");
        this.props.navigation?.navigate("Users")
      }else if(notification?.attributes?.event==="chat_message"){
        //sent to user page
       // msg.addData(getName(MessageEnum.NavigationTargetMessage),"Users");
        this.props.navigation?.navigate("ChatPage")
      }
      
      //this.send(msg);
  }

}
