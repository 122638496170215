import React, { useContext } from "react";
import { S } from "./ClientSettingController.web";

interface ClientSettingI extends S {
  changeDataLoggerInput: (key: string, value: string) => void;
  createDataLogger: () => void;
  fetchNextDataLogger: () => void;
  fetchPrevDataLogger: () => void;
  editDataLogger: (id: string) => void;
}

export const initialState: S = {
  activeTab: 0,
  secondActiveTab: 0,
  thirdActiveTab: 0,
  addNewDeviceModal: false,
  dataLogger: {
    input: {
      name: "",
      hardwareSerialNumber: "",
      interval: "",
      ftpUserName: "",
      ftpPassword: "",
      ftpApiKey: "",
      ftpEntityId: "",
    },
    errors: {},
    createLoading: false,
    updateLoading: false,
    getLoading: false,
    edit: false,
    editId: "",
    loadedPage: 0,
    currentPage: 1,
    perPage: 4,
    dataLogggerList: {
      response: {
        data: [],
      },
      length: 0,
    },
  },
  allDataLogggerList: {
    response: {
      data: [],
    },
    length: 0,
  },
  selectedDataLogger: null,
  createDataLoggerDeviceLoading: false,
  updateDataLoggerDeviceLoading: false,
  getDataLoggerDeviceLoading: false,
  deleteDataLoggerDeiveLoading: false,
  deviceCreateError: {},
  deviceList: [],
  deleteDeviceModal: false,
};

const defaultContext: ClientSettingI = {
  ...initialState,
  changeDataLoggerInput: (key: string, value: string) => {},
  createDataLogger: () => {},
  fetchNextDataLogger: () => {},
  fetchPrevDataLogger: () => {},
  editDataLogger: (id: string) => {},
};

export const ClientSettingContext = React.createContext<ClientSettingI>(
  defaultContext
);

export function useClientSettingContext() {
  return useContext(ClientSettingContext);
}
