Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ActivityLog";
exports.labelBodyText = "ActivityLog Body";

exports.btnExampleTitle = "CLICK ME";

exports.createDataLoggerApiURl = "bx_block_ftp_data/data_loggers";
exports.getDataLoggerApiURL = "bx_block_ftp_data/get_data_logger_by_system_id";
exports.getSingleDataLoggerApiURL = "bx_block_ftp_data/data_loggers";
exports.fetchFtpDataApi = "bx_block_ftp_data/get_ftp_data_by_system";
exports.postApiMethodType = "POST";
exports.getApiMethodType = "GET";
exports.patchApiMethodType = "PATCH";
exports.deleteApiMethod = "DELETE";
exports.createDataLoggerDeviceApiURL = "bx_block_ftp_data/device_tables";
exports.getDataLoggerDeviceApiURL =
  "bx_block_ftp_data/get_device_by_client_and_data_logger_id";

exports.getFtpInverterDataApiUrl = "bx_block_ftp_data/get_inverter_data";
exports.getFtpMeterDataApiUrl = "bx_block_ftp_data/get_meter_data";
exports.getMainGraphDataURL = "bx_block_ftp_data/get_graph_data";
// Customizable Area End
