import { IconButton, makeStyles, Typography ,Button} from '@material-ui/core';
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import FilterListOutlinedIcon from "@material-ui/icons/FilterListOutlined";
import React from 'react';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import {useUserContext} from '../../../blocks/AdminConsole3/src/user/UserContext.web'
import { INewUserGroup } from '../../../blocks/AdminConsole3/src/user/UserController.web';
import CreateNewUserGroup from './CreateNewUserGroup.web';

const useStyles=makeStyles({
    ashIconButton:{
        width:"40px",
        height:"40px",
        background:"#f9f9fa",
        borderRadius:"15px"
    },
      buttonDarkYellow:{
        textTransform:"capitalize",
        background:"#FDCE32",
        boxShadow:"none",
        borderRadius:"15px",
        marginRight:"10px",
        padding:"0px 15px 0px 15px",
        height:"40px",
        color:"black",
        fontWeight:"normal",
        '&:hover': { 
             background:"#FDCE32",
        },
    },
})

interface Props{
    newGroup:INewUserGroup;
    changeGroupName:(name:string)=>void;
    changeGroupDesc:(desc:string)=>void;
    changeRootPrivileges:(id:number,allow:boolean)=>void;
    chngeSubPrivileges:(id:number,key:string,value:string)=>void;
    changeEditors:(editor:string)=>void;
    groupCreateErrors:any;
    checkGroupCreateInput:(groupId?:string)=>void;
}


const UserRoles=({
    newGroup,
    changeGroupName,
    changeGroupDesc,
    changeRootPrivileges,
    chngeSubPrivileges,
    changeEditors,
    groupCreateErrors,
    checkGroupCreateInput
}:Props)=>{
    const {
        pUserGroupList:userGroupList,
        getPaginatedGroupLoading:loading,
        getPaginatedUserGroups,
        editUserGroupModal:editModal,
        changeEditUserGroupModal:openModal,
        addUserGroupModal:createModal,
        changeAddUserGroupModal:openCreateModal,
        groupPage:currentPage,
        updateUserGroupLoading,
        editorList,
        getEditorLoading
    }=useUserContext();

    const styles=useStyles();
    const [currentGroup,setCurrentGroup]=React.useState<any>({});

    const changePage = (pageNumber: number) => {
        getPaginatedUserGroups(pageNumber)
    };

    const openEditUserGroupModal=(group:any)=>{
        setCurrentGroup(group);
        openModal(true,group);
    }
    const handleNext=()=>{
        if (currentPage<Math.ceil(userGroupList.length / userGroupList.numOfItem)) {
          loading || changePage(currentPage + 1);
        }
    }
    const handlePrev=()=>{
        if (currentPage> 1) {
        loading || changePage(currentPage - 1);
        }
    }



  
    return(
        <div className="rolesContainer">
            <div className="roles__header">
                <div>
                    <Typography variant="h4" style={{ fontSize: "22px",fontWeight:"bolder" }}>
                        Roles
                    </Typography>
                </div>
                <div>
                     <Button 
                        onClick={()=>openCreateModal(true)}
                        className={styles.buttonDarkYellow}
                        startIcon={<AccountCircleOutlinedIcon/>}
                    >
                            Add New Roles
                    </Button>
                </div>
              
            </div>
            <div className="roles__table">
                <table>
                  <thead>
                      <tr>
                           <th> <Typography style={{fontWeight:"bold"}}>USER GROUP</Typography></th>  
                           <th>
                               <div style={{display:"flex",alignItems:"center",width:"100%",justifyContent:"center"}}>
                                   <div> <Typography style={{fontWeight:"bold"}}>DESCRIPTION</Typography></div>

                                   <div><FilterListOutlinedIcon/></div>
                               </div>
                             
                            </th>  
                            <th>
                              <Typography style={{fontWeight:"bold"}}>ACTIONS</Typography>
                            </th>
                       </tr>
                   </thead>
                   <tbody>
                          { 
                          loading?<Typography style={{margin:"10px"}} variant="h6">Loading..</Typography>:
                 
                            userGroupList?.response?.data?.slice((currentPage-1) * userGroupList.numOfItem, (currentPage-1) * userGroupList.numOfItem + userGroupList.numOfItem)
                            .map((group,index)=>(
                                <tr key={index}>
                                  <td>
                                     <div>
                                         <Typography style={{textAlign:"center"}} className="itemTxt">{group?.attributes?.user_group}</Typography>
                                      </div>
                                    </td>
                                    <td>
                                        <div>
                                          <Typography style={{textAlign:"center"}} className="itemTxt">{group?.attributes?.description}</Typography>
                                      </div>
                                    </td>
                                    <td>
                                        <div>
                                            <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                                                <IconButton
                                                 onClick={()=>openEditUserGroupModal(group)}
                                                 style={{padding:"0",margin:"0"}}><EditOutlinedIcon/></IconButton>
                                            </div>
                                        </div>
                                      </td>


                                </tr>
                              ))
                          }   
                    </tbody>
              
                 </table>
        
            </div>
            <div className="roles__pagination">
                <div className="tablePagination">
                        <IconButton
                                 className="ashIconButton"
                                  onClick={handlePrev}
                                  aria-label="delete"
                              >
                                  <ArrowBackIosIcon />
                              </IconButton>
                            <div className="paginationContent">
                              <h2 style={{fontSize:"14px"}}>
                                {currentPage }/{Math.ceil(userGroupList.length / userGroupList.numOfItem)}
                              </h2>
                            </div>
                            <IconButton
                               className="ashIconButton"
                               onClick={handleNext}
                               aria-label="delete">
                                  <ArrowForwardIosIcon />
                             </IconButton>
                      </div>
            </div>
            {
                createModal && <CreateNewUserGroup
                         open={createModal}
                         setOpen={openCreateModal}
                         actionLoading={updateUserGroupLoading}
                         editorList={editorList}
                         getEditorLoading={getEditorLoading}
                         changeGroupDesc={changeGroupDesc}
                         changeGroupName={changeGroupName}
                         changeRootPrivileges={changeRootPrivileges}
                         newGroup={newGroup}
                         chngeSubPrivileges={chngeSubPrivileges}
                         changeEditors={changeEditors}
                         newErrors={groupCreateErrors}
                         actionHandler={()=>checkGroupCreateInput("")}
                    />
            }
            {
              editModal && <CreateNewUserGroup
                         edit={true}
                         open={editModal}
                         setOpen={openModal}
                         actionLoading={updateUserGroupLoading}
                         editorList={editorList}
                         getEditorLoading={getEditorLoading}
                         changeGroupDesc={changeGroupDesc}
                         changeGroupName={changeGroupName}
                         changeRootPrivileges={changeRootPrivileges}
                         newGroup={newGroup}
                         chngeSubPrivileges={chngeSubPrivileges}
                         changeEditors={changeEditors}
                         newErrors={groupCreateErrors}
                         actionHandler={()=>checkGroupCreateInput(currentGroup?.id)}
                    />
            }
           
          </div>
    )
}

export default UserRoles;