import React from "react";
import { AppHeader } from "../../../components/src/AppHeader";
import EditProfileController, { Props } from "./EditProfileController.web";

import {imgEdit, imgAdd, imgDelete} from "./assets";

import { withStyles } from "@material-ui/core/styles";
import { Image } from "react-native";
import {
  Container,
  Typography,
  Box,
  Paper,
  Avatar,
  TextField,
  CircularProgress,
  Button,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  DialogProps,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";

export default class EditProfile extends EditProfileController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        <main style={{ marginTop: "48px", overflowX: "auto" }}>
          <Container maxWidth="xl" style={{marginBottom: "20px"}}>
            <Typography
              component="h1"
              style={{ margin: "20px 0", fontSize: "34px", fontWeight: "bold" }}
            >
              Edit Profile Details
            </Typography>

            <Box
              style={{
                backgroundColor: "#f9f9fa",
                borderRadius: "24px",
                padding: "20px",
                display: "flex",
              }}
            >
              <div
                style={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: "24px",
                  width: "100%",
                }}
              >
              <div style={{display: "flex"}}>
                <Typography
                  component="h2"
                  style={{
                    margin: "20px 32px",
                    fontSize: "22px",
                    fontWeight: "bold",
                    flex: 1
                  }}
                >
                  Profile Details
                </Typography>
              </div>
                {this.state.userProfileInfo === null && (
                  <LoadingCirculeProgress size="100" />
                )}
                {
                  this.state.userProfileInfo && 
                  <Paper elevation={0} style={{ margin: "20px 32px", width: "350px" }}>
                  <Typography
                    component="span"
                    style={{
                      fontSize: "14px",
                      fontWeight: 600,
                      color: "#8F92A1",
                    }}
                  >
                    Profile Picture
                  </Typography>

                  <input accept="image/*" id="icon-button-file"
                    type="file" style={{ display: 'none' }} 
                    onChange={(e) => this.onFileChange(e)}  
                  />
                  <label htmlFor="icon-button-file">
                    <Avatar src={this.state.userProfileInfo?.image}>OP</Avatar>
                  </label>

                  <InputTextField
                    id="standard-basic"
                    label="Name"
                    value={this.state.userProfileInfo?.name}
                    onChange={(e) => this.handelNameChange(e)}
                  />

                  <InputTextField
                    id="standard-basic"
                    label="Contact"
                    value={this.state.userProfileInfo?.phone_number}
                    onChange={(e) => this.handelPhoneNumberChange(e)}
                  />

                  <InputTextField
                    id="standard-basic"
                    label="Email address"
                    value={this.state.userProfileInfo?.email}
                    disabled
                  />

                  <InputTextField
                    id="standard-basic"
                    label="Password"
                    value={this.state.userProfileInfo?.password}
                    type="password"
                    onChange={(e) => this.handelPasswordChange(e)}
                    disabled
                  />

                  <div style={{textAlign: "right"}}>
                  <Button
                        variant="contained"
                        disableElevation
                        style={{marginRight: "20px",borderRadius: "9px", height: "40px", width: "100px", fontWeight: 600, textTransform: "capitalize"}}
                        onClick={() => this.redirectToProfileView()}
                    >
                        Cancel
                    </Button>

                    <Button
                        variant="contained"
                        disableElevation
                        style={{borderRadius: "9px", height: "40px", width: "100px", background: "#FDCE32", fontWeight: 600, textTransform: "capitalize"}}
                        onClick={() => this.handleUpdateProfile()}
                    >
                        Save
                    </Button>
                  </div>
                </Paper>
                }    
              </div>
            </Box>
          </Container>
        </main>

        <Dialog
        fullWidth
        maxWidth="xs"
        PaperProps={{style: {borderRadius: 16}}}
        open={this.state.openUpdateAccountConfirmation}
        onClose={() => this.handleClose()}
        >
          <DialogTitle id="max-width-dialog-title" style={{paddingBottom: 0}}>
            <strong>Save changes?</strong>
          </DialogTitle>
          <DialogContent>
            <p>Do you want to save the changes that you've made in your profile?</p>
          </DialogContent>
          <DialogActions style={{margin: "15px"}}>
            <Button
                    variant="contained"
                    disableElevation
                    style={{borderRadius: "9px", height: "40px", width: "100%", fontWeight: 600, textTransform: "capitalize"}}
                    onClick={() => this.handleClose()}
                  >
              Cancel
            </Button>
            <Button
                    variant="contained"
                    disableElevation
                    style={{borderRadius: "9px", height: "40px", width: "100%", background: "#FDCE32", fontWeight: 600, textTransform: "capitalize"}}
                    onClick={() => this.updateProfile()}
                  >
              Save
            </Button>
            </DialogActions>
        </Dialog>

      </>
    );
  }
}

const InputTextField = withStyles({
    root: {
      display: "block",
      margin: "20px 0",
      "& .MuiFormLabel-root": {
        color: "#8F92A1",
        fontSize: "16px",
        fontWeight: 600,
      },
      "& .MuiInput-root": {
        width: "350px",
        color: "#171717",
      },
      "& .MuiFormLabel-root.Mui-focused": {
        color: "#8F92A1",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#bdbdbd",
      },
    },
  })(TextField);
  
  const LoadingCirculeProgress = withStyles({
    root: {
      width: "100px",
      height: "100px",
      color: "#FDCE32",
      marginLeft: "25%",
    },
  })(CircularProgress);
