import { Button, Checkbox, Typography } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { makeStyles } from "@material-ui/styles";
import React from "react";
const useStyles = makeStyles({
  taskItemContainer: {
    display: "flex",
    gap: "14px",
    marginBottom: "20px",
  },
  taskContent: {
    display: "flex",
    flexDirection: "column",
    gap: "14px",
  },
});
interface Props {
  completed: boolean;
}
const TaskItem = ({ completed }: Props) => {
  const styles = useStyles();
  return (
    <div className={styles.taskItemContainer}>
      <div>
        <Checkbox
          checked={completed}
          style={{ accentColor: "#f9f9fa" }}
          color="primary"
        />
      </div>
      <div className={styles.taskContent}>
        <Typography
          component="h3"
          variant="h5"
          color="primary"
          style={{ fontSize: "17px", fontWeight: "bolder" }}
        >
          @linghguyn
        </Typography>
        <Typography
          component="h3"
          variant="h5"
          style={
            completed
              ? {
                  fontSize: "19px",
                  fontWeight: "bolder",
                  color: "black",
                  textDecorationLine: "line-through",
                }
              : { fontSize: "19px", fontWeight: "bolder", color: "black" }
          }
        >
          Task Name
        </Typography>
        <Typography
          component="h3"
          variant="h5"
          style={{ fontSize: "17px", color: "black" }}
        >
          Name Of The User
        </Typography>
        {completed ? (
          <Button
            startIcon={<CheckIcon style={{ color: "#02885b" }} />}
            style={{
              background: "#cce7de",
              borderRadius: "15px",
              padding: "5px 20px",
              color: "#02885b",
              textTransform: "capitalize",
              fontWeight: "bolder",
            }}
          >
            Completed
          </Button>
        ) : (
          <Button
            startIcon={<ScheduleIcon />}
            style={{
              background: "#f9f9fa",
              borderRadius: "15px",
              padding: "5px 20px",
              color: "black",
              textTransform: "capitalize",
              fontWeight: "bolder",
            }}
          >
            Task Time
          </Button>
        )}
      </div>
    </div>
  );
};

export default TaskItem;
