import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ISystemInverterInfo, ISystemMeterInfo } from "../../LandingPage2/src/interfaces.types";
import CreateRestApiMessage from "../../../components/src/Util/CreateRestApiMessage.web";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes?: any;
}

export interface S {
  activeTab: number;
  invertarDetails:ISystemInverterInfo;
  meterDetails:ISystemMeterInfo;
  energyGraphData:any[];
  getEnergyGraphDataLoading:boolean;
  getMeterDetailsLoading:boolean;
  getInverterDetailsLoading:boolean;
}

interface SS {
  id: any;
}

export default class ClientDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetPlantMeterData:string="";
  apiGetPlantInverterData:string="";
  apiGetEnergyGraphData:string="";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      activeTab: 0,
      meterDetails:{
        power:"0",
        data_date_time:"",
        id:0,
        operated_by:"",
        operated_email:"",
        monitered_by:"",
        monitered_email:"",
        commisioning:""
      },
      invertarDetails:{
        data_date_time:"",
        energy_total:0,
        id:0,
        production:0
      },
      energyGraphData:[],
      getEnergyGraphDataLoading:false,
      getMeterDetailsLoading:false,
      getInverterDetailsLoading:false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    this.getPlantInverterInfo();
    this.getPlantMeterInfo();
  }

  async receive(from: string, message: Message) {

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleApiResponse(responseJson,apiRequestCallId);
    }
  }
  //action receivers
    handleApiResponse=(responseJson:any,apiRequestCallId:string)=>{
    if (responseJson === undefined) {
        return;
      } else if (
        responseJson &&
        responseJson.errors &&
        responseJson.errors[0].token
      ) {
        this.props.navigation.navigate("EmailAccountLoginBlock");
        return;
      }
      if(this.apiGetEnergyGraphData===apiRequestCallId){
         this.handleEnergyGraphResponse(responseJson);
      }
      if(this.apiGetPlantInverterData===apiRequestCallId){
         this.handleGetSystemInverterDataResponse(responseJson);
      }
      if(this.apiGetPlantMeterData===apiRequestCallId){
         this.handleGetSystemMeterDataResponse(responseJson);
      }
  }
  handleGetEnergyGraphDataResponse=(responseJson:any)=>{

  }
  handleGetSystemMeterDataResponse=(responseJson:any)=>{
      if(responseJson?.response?.data?.attributes?.id){
        this.setState({
          meterDetails:responseJson?.response?.data?.attributes,
          getMeterDetailsLoading:false
        })
      }else{
        this.setState({
          meterDetails:{
              monitered_email:"",
              commisioning:"",
              data_date_time:"",
              id:0,
              power:"0",
              monitered_by:"",
              operated_by:"",
              operated_email:"",
          },
          getMeterDetailsLoading:false
        })
      }
  }
  handleGetSystemInverterDataResponse=(responseJson:any)=>{
     if(responseJson?.response?.data?.attributes?.id){
      this.setState({
        invertarDetails:responseJson?.response?.data?.attributes,
        getInverterDetailsLoading:false
      })
    }else{
      this.setState({
         invertarDetails:{
          data_date_time:"",
          energy_total:0,
          id:0,
          production:0
        },
        getInverterDetailsLoading:false
      })
    }
  }
  handleEnergyGraphResponse=(responseJson:any)=>{
    if(responseJson?.response){
     if(Object.keys(responseJson?.response)?.length>0){
        const energyGraphREsponse=Object.keys(responseJson?.response).map((keys:string)=>{
          return{
            name:keys,
            pv:Number(responseJson?.response[keys]?.power),
            uv:responseJson?.response[keys]?.avg_voltage
          }
        })
        this.setState({
          energyGraphData:energyGraphREsponse,
          getEnergyGraphDataLoading:false
        })
     }else{
       this.setState({
         getEnergyGraphDataLoading:false,
         energyGraphData:[]
       })
     }
    }else{
      this.setState({
         getEnergyGraphDataLoading:false,
         energyGraphData:[]
       })
    }
  }

  //api calling section

  getPlantInverterInfo=()=>{
    this.setState({
      getInverterDetailsLoading:true
    })
    const plantId = this.props.navigation.getParam("plantId");
    const hitUrl = `${
      configJSON.getFtpInverterDataApiUrl
    }?system_id=${plantId}`;
     const userToken = localStorage.getItem("authToken");
     const header = {
       "Content-Type": configJSON.validationApiContentType,
       token: userToken,
     };
       const requestMessage = CreateRestApiMessage({
         header,
         apiUrl: hitUrl,
         body: null,
         method: configJSON.getApiMethodType,
       });
       this.apiGetPlantInverterData = requestMessage.messageId;
       runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getPlantMeterInfo=()=>{
    this.setState({
      getMeterDetailsLoading:true
    })
    const plantId = this.props.navigation.getParam("plantId");
    const hitUrl = `${configJSON.getFtpMeterDataApiUrl}?system_id=${plantId}`;
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: userToken,
    };
    const requestMessage = CreateRestApiMessage({
      header,
      apiUrl: hitUrl,
      body: null,
      method: configJSON.getApiMethodType,
    });
    this.apiGetPlantMeterData = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getEnergyDataGraphData=(queryParams:string)=>{
    this.setState({
      getEnergyGraphDataLoading:true
    })
     const hitUrl = `${configJSON.getMainGraphDataURL}?${queryParams}`;
     const userToken = localStorage.getItem("authToken");
     const header = {
       "Content-Type": configJSON.validationApiContentType,
       token: userToken,
     };

     const requestMessage = CreateRestApiMessage({
       header,
       apiUrl: hitUrl,
       body: null,
       method: configJSON.getApiMethodType,
     });
     this.apiGetEnergyGraphData = requestMessage.messageId;
     runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleEnergyGraphFilterChange=(filterBy:string,date:{
     day:number;
     month:number;
     year:number;
   })=>{
    const systemId = this.props.navigation.getParam("plantId");
    let endUrl="";
     if(filterBy==="day"){
        endUrl=`system_id=${systemId}&filter=0&by_date=${date?.day}&by_month=${date?.month}&by_year=${date?.year}`;
     }else if(filterBy==="month"){
      endUrl=`system_id=${systemId}&filter=3&by_month=${date?.month}&by_year=${date?.year}`;
     }
     else if(filterBy==="year"){
         endUrl=`system_id=${systemId}&filter=2&by_year=${date?.year}`;
     }
     if(!this.state.getEnergyGraphDataLoading){
      this.getEnergyDataGraphData(endUrl);
     }
  }

  // handle ui changes

  changeTab = (value: number) => {
    this.setState({
      activeTab: value,
    });
  };

  navigateToAlarmsPage = () => {
    this.props.navigation.navigate("Alarms");
  };
  navigateToClientSetting = () => {
    const clientId=this.props.navigation.getParam("clientId");
    const plantId=this.props.navigation.getParam("plantId");

    this.props.navigation.navigate("clientSetting",{
      clientId:clientId,
      plantId:plantId
    })
  };
}
