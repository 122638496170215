import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
export const configJSON = require("../config");
import {toast } from 'react-toastify';
import { AuthContext } from "../../../email-account-login/src/AuthContext/AuthContext.web";
import { Notification } from "../../../Notifications/src/Notification2Controller.web";

export interface Props {
  navigation: any;
  id: string;
  theme: any;
}

interface S {
  value: number;
  showImportSurvey: boolean;
  importSurveyData: any[];
  todayContacts: any[];

  getApprovalLoading:boolean,
  currentPage:number,
  loadedApprovalPage:number,
  systemInfoModal:boolean,
  selectedApproval:any,
  approveModal:boolean,
  approveLoading:boolean,
  action:{
    alert:string,
    action:string
  },
  notifiedSystem:Notification|null
}

interface SS {
  id: any;
}

export default class AdminDataController extends BlockComponent<Props, S, SS> {
  static contextType:any = AuthContext;
  apiResetGetAllClientCallId: string = "";
  apiGetSystemApprovalCallId: string = "";
  apiSystemApproveCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      value: 0,
      showImportSurvey: false,
      importSurveyData: [],
      todayContacts : [],

      getApprovalLoading:false,
      currentPage:1,
      loadedApprovalPage:1,
      systemInfoModal:false,
      selectedApproval:{},
      approveModal:false,
      approveLoading:false,
      action:{
        alert:"",
        action:""
      },
      notifiedSystem:null

    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: String, message: Message) {
    
        //<=====RECEIVE DATA FROM NOTIFICATION ===========>
        if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
          const notification = message.getData(
            getName(MessageEnum.FromNotificationDataMessage)
          );

          this.setState({
            notifiedSystem: notification
          });
        }

   //<===============response for get approval systems============>
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiGetSystemApprovalCallId &&
      this.apiGetSystemApprovalCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      //disable loading
      this.setState((prevState,props)=>{
            return {
              getApprovalLoading:false,
              }
            }
          );
    }
   //<===============response for approval systems============>
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiSystemApproveCallId &&
      this.apiSystemApproveCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
        if(responseJson?.status===200){
            this.setState({
                  approveLoading:false
                });
          toast(responseJson?.message)
        }
    }
 
  }

  async componentDidMount() {
     console.log({
       message:"Approval Screen",
       context:this?.context
     });
     this.getImportSurveyData();
  }

  getImportSurveyData = () => {
    this.setState({
        importSurveyData: [
            {dataLogger: 'CHIRAG ENCLAVE_KSKV', renfenceNo: '235764746', dataSource: 'Inverter', lastFailure: '25/10/2021',
            status: 'Open', firstNo: 100, secondNo: 100},
            {dataLogger: 'CHIRAG ENCLAVE_KSKV', renfenceNo: '235764746', dataSource: 'Inverter', lastFailure: '25/10/2021',
            status: 'Open', firstNo: 80, secondNo: 80},
            {dataLogger: 'CHIRAG ENCLAVE_KSKV', renfenceNo: '235764746', dataSource: 'Inverter', lastFailure: '25/10/2021',
            status: 'Open', firstNo: 70, secondNo: 70},
            {dataLogger: 'CHIRAG ENCLAVE_KSKV', renfenceNo: '235764746', dataSource: 'Inverter', lastFailure: '25/10/2021',
            status: 'Open', firstNo: 40, secondNo: 40},
            {dataLogger: 'CHIRAG ENCLAVE_KSKV', renfenceNo: '235764746', dataSource: 'Inverter', lastFailure: '25/10/2021',
            status: 'Open', firstNo: 60, secondNo: 60},
        ]
    });

    this.setState({todayContacts: [
        {serialNo: '23343587', connection: 'importv2', contact: '20/10/2021 07:09:41'},
        {serialNo: '23343587', connection: 'importv2', contact: '20/10/2021 07:09:41'},
        {serialNo: '23343587', connection: 'importv2', contact: '20/10/2021 07:09:41'},
        {serialNo: '23343587', connection: 'importv2', contact: '20/10/2021 07:09:41'},
        {serialNo: '23343587', connection: 'importv2', contact: '20/10/2021 07:09:41'},
        {serialNo: '23343587', connection: 'importv2', contact: '20/10/2021 07:09:41'},
    ]});
  }

  handleChange=(event: React.ChangeEvent<{}>, newValue: number)=> { 
    this.setState({
      value: newValue,
    });
  }

  searchDataLogger = () => {
    this.setState({showImportSurvey: true});
  }
}
