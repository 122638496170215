import React, { useContext } from "react";
import { UserGroup } from "../user/Interfaces";
import { ClientListResponseType } from "./ClientsController.web";

interface NetworkContextType {
  clientsData: ClientListResponseType;
  getClientsLoading: boolean;
  addClientModal: boolean;
  setClientLoading: boolean;
  clientSystemsModal: boolean;
  clientSystemsData: any[];
  clientSystemDataLoading: boolean;
  deleteClientConfimation: boolean;
  clientId: any;
  deleteSystemLoading: boolean;
  updateClientDialog: boolean;
  clientPage: number;
  loadedClientPage: number;
  clientUsersDialog: boolean;
  clientUsersList: any[];
  getClientUserLoading: boolean;
  createUserModal: boolean;
  updateUserModal: boolean;
  deleteUserModal: boolean;
  createUserLoading: boolean;
  updateUserLoading: boolean;
  deleteUserLoading: boolean;
  userGroupList: UserGroup[];
  getUserGroupsLoading: boolean;
  clientCreated: boolean;
  //functions

  getClients: (page?: number) => void;
  openClientSystems: (clientId: number) => void;
  addNewClientSystem: (clientId: any) => void;
  deleteClient: (clientId: number) => void;
  updateClients: (value: any, clientId: string) => void;
  changeClientUpdateModal: (open: boolean) => void;
  getClientUser: (clientId: string) => void;
  changeClientUserModal: (open: boolean) => void;
  changeClientModal: (open: boolean) => void;
  setNewClients: (value: any) => void;
  closeClientSystems: () => void;
  deleteSystems: (value: any) => void;
  createNewUser: (newUser: any) => void;
  updateUser: (newUser: any, userId: string, clientId: string) => void;
  deleteUser: (userId: string, clientId: string) => void;
  changeCreateUserModal: (open: boolean) => void;
  changeUpdateUserModal: (open: boolean) => void;
  changeDeleteUserModal: (open: boolean) => void;
}

const defaultContext: NetworkContextType = {
  clientsData: {
    response: {
      data: [],
    },
    length: 0,
  },
  getClientsLoading: true,
  addClientModal: false,
  setClientLoading: false,
  clientSystemsModal: false,
  clientSystemsData: [],
  clientSystemDataLoading: false,
  deleteClientConfimation: false,
  clientId: 0,
  deleteSystemLoading: false,
  updateClientDialog: false,
  clientPage: 1,
  loadedClientPage: 1,
  clientUsersDialog: false,
  getClientUserLoading: false,
  clientUsersList: [],
  createUserModal: false,
  updateUserModal: false,
  deleteUserModal: false,
  createUserLoading: false,
  updateUserLoading: false,
  deleteUserLoading: false,
  userGroupList: [],
  getUserGroupsLoading: false,
  clientCreated: false,

  //function initialize
  getClients: (page?: number) => {},
  openClientSystems: (clientId: number) => {},
  addNewClientSystem: (clientId: any) => {},
  deleteClient: (clientId: number) => {},
  updateClients: (value: any, clientId: string) => {},
  changeClientUpdateModal: (open: boolean) => {},
  getClientUser: (clientId: string) => {},
  changeClientUserModal: (open: boolean) => {},
  changeClientModal: (open: boolean) => {},
  setNewClients: (value: any) => {},
  closeClientSystems: () => {},
  deleteSystems: (value: any) => {},
  createNewUser: (newUser: any) => {},
  updateUser: (newUser: any, userId: string, clientId: string) => {},
  deleteUser: (userId: string, clientId: string) => {},
  changeCreateUserModal: (open: boolean) => {},
  changeUpdateUserModal: (open: boolean) => {},
  changeDeleteUserModal: (open: boolean) => {},
};

export const NetworkContext = React.createContext<NetworkContextType>(
  defaultContext
);

export function useNetworkContext() {
  return useContext(NetworkContext);
}
