Object.defineProperty(exports, "__esModule", {
  value: true,
});

//APi Methods
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";

exports.sendRecoveryPasswordLinkAPIEndPoint =
  "bx_block_forgot_password/passwords/send_forgot_password_email";
exports.resetPasswordAPIEndPoint =
  "bx_block_forgot_password/passwords/reset_password";
exports.forgotPasswordAPiContentType = "application/json";

exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid =
  "Password not valid, must have atleast 6 characters containing 1 upercase, 1 lowercase, 1 special symbol and 1 number.";

exports.passwordRegExp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/;
