import React from "react";

import ClientDetailsPageController from "./ClientDetailsPageController.web";
import { Button, Typography,Grid,  MenuItem,Select} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import PlantOverview from "./PlantOverview.web";
import {imgRlLogo} from './assets';
import "../assets/railence.css"
import SettingModal from "../../../components/src/ClientDetailsComponents/SettingModal.web";
import AddNewProtletModal from "../../../components/src/ClientDetailsComponents/AddNewProtletModal.web";
import GenerateReportDialog from "../../../components/src/ClientDetailsComponents/GenerateReportDialog.web";
import {
  withStyles,
  createStyles,
  withTheme,
  Theme,
} from "@material-ui/core/styles";
import { AuthContextType } from "../../email-account-login/src/AuthContext/AuthContext.web";
class ClientDetailsPage extends ClientDetailsPageController{
   render() {
    const { settingModalOpen,addProtletModal,generateReportModal,systemList,selectedSystem} = this.state;
    const { classes } = this.props;
    const {isClient}=this.context as AuthContextType;
    return (
      <>
      <div className="railenceContainer">
        <div className="railenceHeader">
          <div>
            <div style={{display:"flex",alignItems:"center"}}>
              <div>
                 <img style={{width:"47px"}} src={imgRlLogo} alt=""/>
              </div>
              <div>
                  <Typography style={{ fontSize: "34px",marginLeft:"10px",fontWeight:"bold" }} variant="h3">
                  Railence Industry
                </Typography>
              </div>
            </div>
           
          </div>
          <div>
            {
              isClient() ||  
               <Button
                  onClick={()=>this.props.navigation.goBack()}
                  style={{boxShadow:"none",borderRadius:"15px",background:"#f9f9fa",textTransform:"capitalize"}}
                  className="backButton"
                  variant="contained"
                  startIcon={<ArrowBackIosIcon />}
                >
              Back
            </Button>
            }
          
          </div>
        </div>
        <div className="railenceBody">

          <Grid container justifyContent="space-between" style={{marginBottom:"10px"}}>
            <Grid item>
             <Typography component="h2" style={{ fontSize: "16px",fontWeight:"bolder" }}>Plants</Typography>
            </Grid>
            <Grid item style={{display:"flex",gap:"15px"}}>
                <Grid item>
                   <Button
                      onClick={()=>this.changeGenerateReportModal(true)}
                      variant="contained"
                      style={{
                        background: "#FDCE32",
                        borderRadius: "15px",
                        textTransform:"capitalize",
                        boxShadow:"none"
                      }}
                      startIcon={<GetAppRoundedIcon />}
                    >
                      Generate Report
                    </Button>
                </Grid>
                <Grid item>
                    <div className={classes.dropdown_container}>
                        <Typography
                          component="h5"
                          variant="h6"
                          className={classes.dropdown__title}
                        >
                          Show :
                        </Typography>
                        <Select
                          style={{ borderBottom: "0px" }}
                          className={classes.dropdown__main}
                          defaultValue="Week"
                        >
                          <MenuItem value="Week">Week</MenuItem>
                          <MenuItem value={20}>Month</MenuItem>
                          <MenuItem value={30}>Year</MenuItem>
                        </Select>
                    </div>
                </Grid>
            </Grid>
          </Grid>
          <div className="railenceMainContent">
            <div className="performanceOverView">
              <div>
                <h2 style={{ fontSize: "16px" }}>Performance Overview</h2>
              </div>
              <div style={{cursor:"pointer"}} onClick={()=>this.changeSettingModalOpen(!settingModalOpen)}>
                <SettingsOutlinedIcon />
              </div>
            </div>
            <div className="plant">
                <h4>Plant</h4>
            </div>
            <Grid container spacing={2}>
               <Grid item xs={12} sm={12} md={3} lg={2} xl={2}  className={classes.plantList}>
                   {systemList.length===0?<Typography>No Plant Found</Typography>:systemList.map((system) => (
                      <div
                        key={system.id}
                        onClick={() => this.changeCurrentPlant(system)}
                        className={`${classes.plantListItem} ${system?.id === selectedSystem?.id? classes.activePlantItem:''}`}
                      >
                        <div>
                          <h3 className={classes.plantItemHeader}>{system?.attributes.designation}</h3>
                        </div>
                        <div>
                          <h5 className={classes.plantItemDesc}>{system?.attributes.country}</h5>
                        </div>
                      </div>
                    ))} 
                </Grid>         
               <Grid item xs={12} sm={12} md={9} lg={10} xl={10}>
                 {selectedSystem!=null && (
                    <PlantOverview 
                        mainGraphData={this.state.mainGraphData}
                        mainGraphLoading={this.state.getMainGraphDataLoading}
                        onChangeGraphFilter={this.handleGraphFilterChange}
                        isClient={isClient()} 
                        plant={selectedSystem} 
                        changeAddNewProtletDialog={this.changeAddProtletModal} 
                        navigateToClientDashboard={this.navigateToClientDashboard} 
                        goBack={this.goBack}
                        inverterInfo={this.state.inverterInfo}
                        meterInfo={this.state.meterInfo}
                        getPlantInfoLoading={this.state.getPlantInfoLoading}
                      />
                  )}
                </Grid>         
            </Grid>  
          </div>
          
        </div>
      </div>
        <SettingModal open={settingModalOpen} setOpen={this.changeSettingModalOpen}/>
        <AddNewProtletModal open={addProtletModal} setOpen={this.changeAddProtletModal}/>
        <GenerateReportDialog 
            open={generateReportModal} 
            setOpen={this.changeGenerateReportModal}
            navigate={this.props.navigation.navigate}
            />
     </>
    );
  }
}


export default withTheme(
  withStyles((theme: Theme) =>
    createStyles({
      row: {
        display: "flex",
        alignItems: "center",
        gap: "8px",
        boxSizing: "border-box",
      },
      dropdown_container: {
        display: "flex",
        background: "#f3f2f5",
        borderRadius: "20px",
        padding: "5px 10px",
        alignItems: "center",
      },
      dropdown__title: {
        fontSize: "12px",
        color: "#A19DAF",
        fontWeight: "bolder",
      },
      dropdown__main: {
        marginLeft: "10px",
        fontSize: "14px",
        fontWeight: "bolder",
        "&:hover": {
          borderBottom: "0px",
        },
        "&::before": {
          borderBottom: "0px !important",
        },
        "&$selected": {
          borderBottom: "0px !important",
        },
      },
      plantList:{
        display: "flex",
        flexDirection: "column",
        gap:"10px",
        [theme.breakpoints.down("sm")]: {
          flexDirection: "row",
          gap: "20px",
          overflowY:"auto",
        },
      },
      plantListItem:{
        width:"100%",
        boxSizing: "border-box",
        padding: "15px 20px",
        [theme.breakpoints.down("sm")]: {
          minWidth:"120px",
        },
        "&:hover":{
          backgroundColor: "#fff8e0",
          cursor: "pointer",
          borderRadius: "20px 0px 0px 20px"
        }
      },
      activePlantItem:{
        backgroundColor: "#fff8e0",
        cursor: "pointer",
        borderRadius: "20px 0px 0px 20px",
      },
      plantItemHeader:{
        color: "#0052cc",
        fontWeight: "bolder",
        fontSize: "14px",
      },
      plantItemDesc:{
        color: "#8f92a1",
        fontWeight: "bold",
        fontSize: ".85rem",
      }
    })
  )(ClientDetailsPage)
);
