import React from "react";
import { ToastContainer } from "react-toastify";
import { Typography, IconButton, Button } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import AddIcon from "@material-ui/icons/Add";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import {
  withStyles,
  createStyles,
  Theme,
} from "@material-ui/core/styles";
import AdministrationController, {
  Props,
} from "./AdministrationController.web";
import CreateNewUser from "../../../components/src/UserComponents/CreateNewUser.web";
import TableLoader from "../../../components/src/Util/TableLoader.web";
import PaginationController from "../../../components/src/Util/PaginationController";
import ActionModal from "../../../components/src/Util/ActionModal";
class Administration extends AdministrationController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    const { 
      createUserModal,
      userList,
      pagination:{perPage,currentPage},
      getUserLoading,
      deleteLoading,
      deleteUserModal,
      updateLoading,
      editUserModal
    } = this.state;
    return (
      <>
        <div className="report__body__heading">
          <div className="report__body__heading__left">
            <Typography
              component="h3"
              variant="h5"
              style={{ fontWeight: "bolder" }}
            >
              Administration
            </Typography>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
            <Button
              onClick={() => this.addNewSystem()}
              variant="contained"
              startIcon={<AddIcon />}
              style={{
                background: "#FFCE21",
                marginRight: "10x",
                borderRadius: "15px",
                marginTop: "10px",
                marginBottom: "10px",
                boxShadow: "none",
                textTransform: "capitalize",
                fontWeight: 400,
              }}
            >
              Add New System
            </Button>
            <Button
              data-testid="createNewUserButton"
              onClick={() => this.changeCreateUserModal(true)}
              variant="contained"
              startIcon={<AddIcon />}
              style={{
                background: "#FFCE21",
                marginRight: "10x",
                borderRadius: "15px",
                marginTop: "10px",
                marginBottom: "10px",
                boxShadow: "none",
                textTransform: "capitalize",
                fontWeight: 400,
              }}
            >
              Add New User
            </Button>
          </div>
        </div>
        <div className="report__container">
          <div className="report__body__container">
            <div className={classes.table__container}>
              <table>
                <thead>
                  <tr>
                    <th>
                      <h5 style={{ fontWeight: "bold", paddingLeft: "20px" }}>
                        USERS
                      </h5>
                    </th>
                    <th>
                      <div className={classes.row}>
                        <h5 style={{ fontWeight: "bold" }}>USER ROLE</h5>
                      </div>
                    </th>
                    <th>
                      <div className={classes.row}>
                        <h5 style={{ fontWeight: "bold" }}>ACTION</h5>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    getUserLoading?<TableLoader count={perPage} column={3}/>:
                    userList?.slice((currentPage-1) * perPage, (currentPage-1) * perPage + perPage)
                    .map((user,index)=>(
                      <tr key={user.id}>
                      <td>
                        <div style={{ paddingLeft: "20px" }}>
                          <h2 className={classes.itemText}>{user?.attributes?.user_name}</h2>
                        </div>
                      </td>
                      <td>
                        <div>
                          <h2 className={classes.itemText}>{user?.attributes?.user_group}</h2>
                        </div>
                      </td>
                      <td>
                        <div className={classes.row}>
                          <IconButton style={{ padding: "0px" }} onClick={()=>this.changeDeleteUserModal(true,user.id)}>
                            <DeleteOutlineOutlinedIcon />
                          </IconButton>
                          <IconButton 
                              onClick={()=>this.changeUpdateUserModal(true,user)}
                              style={{ padding: "0px" }}>
                            <EditOutlinedIcon />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                   ))
                  }
                </tbody>
              </table>
            </div>
            <PaginationController
              currentPage={currentPage}
              next={this.handleNext}
              prev={this.handlePrev}
              totalPage={Math.ceil(userList.length / perPage)}
            />
          </div>
        </div>
        {createUserModal && (
          <CreateNewUser
            forClient={true}
            clientId={"434"}
            clientName={"test"}
            open={createUserModal}
            setOpen={this.changeCreateUserModal}
            actionHandler={this.createNewUser}
            actionLoading={this.state.createUserLoading}
            userGroupList={this.state.userGroupList}
            getUserGroupsLoading={this.state.getUserGroupLoading}
            userErrors={this.state.createUserError}
          />
        )}
        {
          deleteUserModal && <ActionModal
            actionHandler={this.deleteUser}
            message="Are you want to delete this user?"
            title="Delete User"
            open={deleteUserModal}
            setOpen={this.changeDeleteUserModal}
            actionLoading={deleteLoading}
            actionLoadingText="Deleting User.."
          />
        }
         {
          editUserModal  &&
          <CreateNewUser 
                  edit={true}
                  editUser={this.state.selectedUser}
                  clientId={this.state.selectedUser?.attributes.client_id}
                  clientName={this.state.selectedUser?.attributes.client_name}
                  open={editUserModal}
                  setOpen={this.changeUpdateUserModal}
                  actionHandler={this.updateUser}
                  actionLoading={updateLoading}
                  userGroupList={this.state.userGroupList}
                  getUserGroupsLoading={false}
                  userErrors={this.state.createUserError}
                />
         }
        <ToastContainer theme="dark" />
      </>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    darkIconButton: {
      background: "#f3f2f5",
    },
    action__buttons__item: {
      display: "flex",
      alignItems: "center",
    },
    dropdown_container: {
      display: "flex",
      background: "#f3f2f5",
      borderRadius: "20px",
      padding: "5px 20px",
      alignItems: "center",
    },
    dropdown__title: {
      fontSize: "12px",
      color: "#A19DAF",
      fontWeight: "bolder",
    },
    dropdown__main: {
      marginLeft: "10px",
      fontSize: "14px",
      fontWeight: "bolder",
      "&:hover": {
        borderBottom: "0px",
      },
      "&::before": {
        borderBottom: "0px !important",
      },
    },
    row: {
      display: "flex",
      alignItems: "center",
      gap: "15px",
    },
    table__container: {
      height: "550px",
      boxSizing: "border-box",
      marginTop: "10px",
      overflowY: "auto",
      width: "100%",
      "& table": {
        width: "100%",
        minWidth: "800px",
        borderSpacing: "0px 15px",
        "& thead": {
          background: "#f9f9fa",
          borderRadius: "10px",
          height: "40px",
        },
        "& tr": {
          textAlign: "left",
          "& td": {
            textTransform: "capitalize",
            padding: "17px 0px",
            borderBottom: "2px solid #f9f9fa",
          },
          "&:hover": {
            backgroundColor: "var(--main-color)",
            color: "var(--txt-white)",
            cursor: "pointer",
          },
        },
      },
    },
    itemText: {
      fontSize: "16px",
      fontWeight: 500,
    },
  });
export default withStyles(styles, { withTheme: true })(Administration);
