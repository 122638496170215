import React from "react";
import {
  Button,
  makeStyles,
  TextField,
  IconButton,
  Divider,
} from "@material-ui/core";
import ReplayIcon from "@material-ui/icons/Replay";
import FilterListIcon from "@material-ui/icons/FilterList";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

const useStyles = makeStyles({
  buttonDarkYellow: {
    textTransform: "capitalize",
    background: "#FDCE32",
    boxShadow: "none",
    borderRadius: "15px",
    marginRight: "10px",
    padding: "0px 15px 0px 15px",
    height: "40px",
    color: "black",
    fontWeight: "normal",
    minWidth: "120px",
    "&:hover": {
      background: "#FDCE32",
    },
  },
  buttonAsh: {
    textTransform: "capitalize",
    background: "#F9F9FA",
    boxShadow: "none",
    borderRadius: "15px",
    marginRight: "10px",
    padding: "0px 15px 0px 15px",
    height: "40px",
    color: "black",
    fontWeight: "normal",
    minWidth: "100px",
    "&:hover": {
      background: "#F9F9FA",
    },
  },
  ashIconButton: {
    width: "40px",
    height: "40px",
    background: "#f9f9fa",
    borderRadius: "15px",
    margin: "0px 10px",
  },
  formFieldInput: {
    minWidth: "450px",
  },
});
const DataLoggerContact = (props: any) => {
  const styles = useStyles();
  return (
    <div className="wrapper-container">
      <div className="page-inner-header">
        <h3>Data Logger Server Contact</h3>
      </div>

      <div className="margin-botton-20">
        <p className="guide-information-text">
          By entering the hardware serial number of a data logger or its
          relevent ID, you can check if a data logger has communicated with the
          portal and when. Therefore, the seraial number has to be entered
          carefully, including space characters. Upper and lower case will be
          ignored, You can search retrospectively for the last seven days.
        </p>

        <form noValidate autoComplete="off" className="form-container">
          <TextField
            id="EnterSerialNumber"
            label="Enter Serial Number"
            className={styles.formFieldInput}
          />
        </form>

        <Button
          className={styles.buttonDarkYellow}
          onClick={() => props.handleSearchDataLogger()}
        >
          Search
        </Button>
      </div>

      <Divider />

      <div className="today-contacts margin-top-20 page-inner-header">
        <h3>Today - 71 contacts</h3>
        <div>
          <Button className={styles.buttonAsh} startIcon={<FilterListIcon />}>
            Sort by : Today
          </Button>
        </div>
      </div>

      <div>
        <div className="client_list_table">
          <table>
            <thead>
              <tr>
                <th>
                  <h5 style={{ fontWeight:800, marginLeft: "10px" }}>
                    SERIAL NUMBER
                  </h5>
                </th>
                <th>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div>
                      {" "}
                      <h5 style={{ fontWeight: "bold" }}>CONNECTION</h5>
                    </div>

                    <div />
                  </div>
                </th>
                <th>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div>
                      {" "}
                      <h5 style={{ fontWeight: "bold" }}>CONTACT</h5>
                    </div>

                    <div />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {props.contactsData.map((data: any, index: number) => (
                <tr key={index}>
                  <td>
                    <div>
                      <h2 style={{ marginLeft: "10px" }} className="itemTxt">
                        {data.serialNo}
                      </h2>
                    </div>
                  </td>
                  <td>
                    <div>
                      <h2 className="itemTxt">{data.connection}</h2>
                    </div>
                  </td>
                  <td>
                    <div>
                      <h2 className="itemTxt">{data.contact}</h2>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="tablePagination">
          <IconButton className={styles.ashIconButton} aria-label="delete">
            <ArrowBackIosIcon />
          </IconButton>
          <div className="paginationContent">
            <h2 style={{ fontSize: "14px" }}>1 / 10</h2>
          </div>
          <IconButton className={styles.ashIconButton} aria-label="delete">
            <ArrowForwardIosIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default DataLoggerContact;
