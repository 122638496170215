import {
  Button,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import React from "react";
import FilterListIcon from "@material-ui/icons/FilterList";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import DescriptionIcon from "@material-ui/icons/Description";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import GetAppIcon from "@material-ui/icons/GetApp";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import AddCircleOutlineTwoToneIcon from "@material-ui/icons/AddCircleOutlineTwoTone";
import ReplayTwoToneIcon from "@material-ui/icons/ReplayTwoTone";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import FilterListOutlinedIcon from "@material-ui/icons/FilterListOutlined";
import CropFreeOutlinedIcon from "@material-ui/icons/CropFreeOutlined";
import NewCustomAlarmModal from "./NewCustomAlarmModal.web";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table__container: {
      height: "550px",
      boxSizing: "border-box",
      marginTop: "10px",
      overflowY: "auto",
      width: "100%",
      "& table": {
        width: "100%",
        minWidth: "800px",
        borderSpacing: "0px 15px",
        "& thead": {
          background: "#f9f9fa",
          borderRadius: "10px",
          height: "40px",
        },
        "& tr": {
          textAlign: "left",
          "& td": {
            textTransform: "capitalize",
            padding: "17px 0px",
            borderBottom: "2px solid #f9f9fa",
          },
          "&:hover": {
            backgroundColor: "var(--main-color)",
            color: "var(--txt-white)",
            cursor: "pointer",
          },
        },
      },
    },
    itemText: {
      fontSize: "16px",
      fontWeight: 500,
    },
    pagination_container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    pagination__text__container: {
      width: "120px",
      background: "#f9f9fa",
      borderRadius: "15px",
      padding: "10px",
      textAlign: "center",
    },
    table__item__data__containier: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
    row: {
      display: "flex",
      alignItems: "center",
      gap: "15px",
    },
    actions__buttons: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      marginTop: "-40px",
      gap: "20px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "20px",
      },
    },
    buttonDarkYellow: {
      textTransform: "capitalize",
      background: "#FFCE21",
      boxShadow: "none",
      borderRadius: "15px",
      padding: "10px 15px 10px 15px",
      color: "black",
      fontWeight: "normal",
      minWidth: "120px",
      "&:hover": {
        background: "#FDCE32",
      },
    },
    buttonAsh: {
      textTransform: "capitalize",
      background: "#F9F9FA",
      boxShadow: "none",
      borderRadius: "15px",
      padding: "10px 15px 10px 15px",
      color: "black",
      fontWeight: "normal",
      minWidth: "100px",
      "&:hover": {
        background: "#F9F9FA",
      },
    },
    optionMenuItem: {
      width: "100%",
      display: "flex",
      // justifyContent: "space-around",
      gap: "20px",
    },
  })
);

const StyledMenu = withStyles({
  paper: {
    background: "#fffcf2",
    padding: "10px 20px",
    borderRadius: "20px",
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

interface Props {
  navigateToAlarmsPage: () => void;
}

const AlarmsAndTickets = ({ navigateToAlarmsPage }: Props) => {
  const styles = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [customAlarmModal, setCustomAlarmModal] = React.useState(false);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div className={styles.actions__buttons}>
        <div>
          <Button
            onClick={handleClick}
            variant="contained"
            className={styles.buttonDarkYellow}
            startIcon={<MoreHorizIcon />}
          >
            Actions
          </Button>
          {/* need to update sgsg */}
          <StyledMenu
            id="action__menu_1"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            style={{ marginRight: "50px" }}
          >
            <MenuItem>
              <div
                onClick={() => {
                  setAnchorEl(null);
                  setCustomAlarmModal(true);
                }}
                className={styles.optionMenuItem}
              >
                <div>
                  <AddCircleOutlineTwoToneIcon />
                </div>
                <div>Add</div>
              </div>
            </MenuItem>
            <MenuItem>
              <div
                onClick={() => setAnchorEl(null)}
                className={styles.optionMenuItem}
              >
                <div>
                  <ReplayTwoToneIcon />
                </div>
                <div>Refresh</div>
              </div>
            </MenuItem>
            <MenuItem>
              <div
                onClick={() => setAnchorEl(null)}
                className={styles.optionMenuItem}
              >
                <div>
                  <SettingsOutlinedIcon />
                </div>
                <div>Settings</div>
              </div>
            </MenuItem>
            <MenuItem>
              <div
                onClick={() => setAnchorEl(null)}
                className={styles.optionMenuItem}
              >
                <div>
                  <FilterListOutlinedIcon />
                </div>
                <div>Filter</div>
              </div>
            </MenuItem>
            <MenuItem>
              <div
                onClick={() => {
                  navigateToAlarmsPage();
                }}
                className={styles.optionMenuItem}
              >
                <div>
                  <CropFreeOutlinedIcon />
                </div>
                <div>Maximize</div>
              </div>
            </MenuItem>
          </StyledMenu>
        </div>

        <Button
          variant="contained"
          className={styles.buttonAsh}
          startIcon={<GetAppIcon />}
        >
          CSV Export
        </Button>
      </div>
      <div className={styles.table__container}>
        <table>
          <thead>
            <tr>
              <th>
                <h5 style={{ fontWeight: "bold", paddingLeft: "20px" }}>
                  LAST CHANGE
                </h5>
              </th>
              <th>
                <div className={styles.row}>
                  <h5 style={{ fontWeight: "bold" }}>TICKET</h5>
                  <FilterListIcon />
                </div>
              </th>
              <th>
                <div className={styles.row}>
                  <h5 style={{ fontWeight: "bold" }}>ERROR TYPE</h5>
                  <FilterListIcon />
                </div>
              </th>
              <th>
                <div className={styles.row}>
                  <h5 style={{ fontWeight: "bold" }}>EMAIL</h5>
                  <FilterListIcon />
                </div>
              </th>
              <th>
                <h5 style={{ fontWeight: "bold" }}>ERROR STATUS</h5>
              </th>
            </tr>
          </thead>
          <tbody>
            {[...new Array(6)].map((item, index) => (
              <tr key={index}>
                <td>
                  <div style={{ paddingLeft: "20px" }}>
                    <h2 className={styles.itemText}>First name last</h2>
                  </div>
                </td>
                <td>
                  <div>
                    <h2 className={styles.itemText}>usr_name</h2>
                  </div>
                </td>{" "}
                <td>
                  <div>
                    <h2 className={styles.itemText}>newusergroup</h2>
                  </div>
                </td>
                <td>
                  <div>
                    <h2 className={styles.itemText}>email@address.com</h2>
                  </div>
                </td>
                <td>
                  <div>
                    <h2 className={styles.itemText}>Open</h2>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className={styles.pagination_container}>
        <IconButton
          style={{
            background: "#f9f9fa",
            marginRight: "15px",
            borderRadius: "10px",
          }}
        >
          <ArrowBackIosIcon />
        </IconButton>
        <div className={styles.pagination__text__container}>
          <Typography variant="h5" component="p" style={{ fontSize: "17px" }}>
            1/10
          </Typography>
        </div>

        <IconButton
          style={{
            background: "#f9f9fa",
            marginLeft: "15px",
            borderRadius: "10px",
          }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </div>
      <NewCustomAlarmModal
        open={customAlarmModal}
        setOpen={setCustomAlarmModal}
      />
    </div>
  );
};

export default AlarmsAndTickets;
