import React from 'react'
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Typography, IconButton, Button } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

interface Props {
  next: () => void;
  prev: () => void;
  totalPage: number;
  currentPage: number;
  containerStyle?: any;
  className?: string;
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paginationContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end"
    },
    paginationTextContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "120px",
      background: "#f9f9fa",
      borderRadius: "15px",
      padding: "10px",
      textAalign: "center"
    }
  })
);



const PaginationController = ({ next, prev, currentPage, totalPage, containerStyle = {}, className }: Props) => {
  const styles = useStyles();
  return (
    <div className={className ? className : styles.paginationContainer} style={containerStyle}>
      <IconButton
        onClick={prev}
        style={{
          background: "#f9f9fa",
          marginRight: "15px",
          borderRadius: "15px",
          padding: "10px 12px"
        }}
      >
        <ArrowBackIosIcon />
      </IconButton>
      <div className={styles.paginationTextContainer}>
        <Typography
          variant="h5"
          component="p"
          style={{ fontSize: "17px" }}
        >
          {currentPage}/{totalPage}
        </Typography>
      </div>

      <IconButton
        onClick={next}
        style={{
          background: "#f9f9fa",
          marginLeft: "15px",
          borderRadius: "15px",
          padding: "10px 12px"
        }}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </div>
  )
}

export default PaginationController;


