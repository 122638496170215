import { Button, IconButton, makeStyles, Typography } from '@material-ui/core';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import FilterListOutlinedIcon from "@material-ui/icons/FilterListOutlined";
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined';
import React from 'react';
import { User } from '../../../blocks/AdminConsole3/src/user/UserController.web';
import CreateNewUser from './CreateNewUser.web';
import { useUserContext } from '../../../blocks/AdminConsole3/src/user/UserContext.web'
import TableLoader from '../Util/TableLoader.web'
import ActionModal from '../Util/ActionModal';
const useStyles = makeStyles({
  buttonDarkYellow: {
    textTransform: "capitalize",
    background: "#FDCE32",
    boxShadow: "none",
    borderRadius: "15px",
    marginRight: "10px",
    padding: "0px 15px 0px 15px",
    height: "40px",
    color: "black",
    fontWeight: "normal",
    '&:hover': {
      background: "#FDCE32",
    },
  },
  buttonAsh: {
    textTransform: "capitalize",
    background: "#F9F9FA",
    boxShadow: "none",
    borderRadius: "15px",
    marginRight: "10px",
    padding: "0px 15px 0px 15px",
    height: "40px",
    color: "black",
    fontWeight: "normal",
    '&:hover': {
      background: "#F9F9FA",
    },
  },
  ashIconButton: {
    width: "40px",
    height: "40px",
    background: "#f9f9fa",
    borderRadius: "15px",
    margin: "0px 10px"
  }
})

interface Props {
  userErrors: any
}

const UserLists = ({ userErrors }: Props) => {
  const styles = useStyles();
  const {
    changeUserModal: openAddNewUser,
    userList,
    getUserLoading: loading,
    updateUserModal,
    changeUpdateUserModal,
    userPage: page,
    getUserLists,
    createUserLoading,
    updateUser,
    userGroupList,
    allClientList,
    getClientsLoading,
    getUserGroupsLoading,
    deleteUserLoading,
    deleteUser
  } = useUserContext();

  const [numOfItems, _] = React.useState(7);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState<boolean>(false);
  const [actionUser, setActionUser] = React.useState<User>({});


  const changePage = (pageNumber: number) => {
    getUserLists(pageNumber)
  };

  const handleDeleteDialog=(user:User)=>{
    setActionUser(user);
    setOpenDeleteDialog(true)
  }

  const handleUpdateUser=(user:User)=>{
    setActionUser(user);
    changeUpdateUserModal(true)
  }
  const handlePrev=()=>{
    if (page> 1) {
      loading ||  changePage(page - 1);
    }
  }
  const handleNext=()=>{
    if (page<Math.ceil(userList.length / numOfItems)) {
      loading || changePage(page + 1);
    }
  }
  const deleteUserActionHandler=()=>{
    deleteUser(actionUser?.id);
    setOpenDeleteDialog(false);
  }

  
    return(
    <div className="userListContainer">
      <div className="userListOptions">
        <div>
          <Typography variant="h4" style={{ fontSize: "22px", fontWeight: "bolder" }}>
            User lists
          </Typography>
        </div>
        <div className='userButtons'>
          <div>
            <Button
              onClick={() => openAddNewUser(true)}
              className={styles.buttonDarkYellow}
              startIcon={<AccountCircleOutlinedIcon />}
            >
              Add New User
            </Button>
          </div>
          <div>
            <Button
              className={styles.buttonAsh}
              startIcon={<SaveAltOutlinedIcon />}
            >
              CSV Export
            </Button>
          </div>
        </div>
      </div>
      <div className="userListTableContainer">

        <div className="client_list_table">
          <table>
            <thead>
              <tr>
                <th>
                  <h5 style={{ fontWeight: "bold", marginLeft: "10px" }} className='headerTxt'>NAME</h5>
                </th>
                <th>
                  <div style={{ display: "flex", alignItems: "center", width: "100%" }} className='headerTxt'>
                    <div> <h5 style={{ fontWeight: "bold" }}>USERNAME</h5></div>
                    <div><FilterListOutlinedIcon /></div>
                  </div>
                </th>
                <th>
                  <div style={{ display: "flex", alignItems: "center", width: "100%" }} className='headerTxt'>
                    <div> <h5 style={{ fontWeight: "bold" }}>USERGROUP</h5></div>
                    <div><FilterListOutlinedIcon /></div>
                  </div>
                </th>
                <th>
                  <div style={{ display: "flex", alignItems: "center", width: "100%" }} className='headerTxt'>
                    <div> <h5 style={{ fontWeight: "bold" }}>EMAIL</h5></div>
                    <div><FilterListOutlinedIcon /></div>
                  </div>
                </th>
                <th>
                  <h5 style={{ fontWeight: "bold" }} className='headerTxt'>ACTIONS</h5>
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? <TableLoader count={5} column={5} /> :
               userList.length>0? userList?.response?.data
                  .slice((page - 1) * numOfItems, (page - 1) * numOfItems + numOfItems)
                  .map((user, index) => (
                    <tr key={index}>
                      <td>
                        <div>
                          <Typography component="h2" style={{ marginLeft: "10px" }} className="itemTxt">{user?.attributes?.name === null ? "not set" : user?.attributes?.name}</Typography>
                        </div>
                      </td>
                      <td>
                        <div>
                          <Typography component="h2" className="itemTxt">{user?.attributes?.user_name}</Typography>
                        </div>
                      </td>
                      <td>
                        <div>
                          <Typography component="h2" className="itemTxt">{user?.attributes?.user_group === null ? "not set" : user?.attributes?.user_group}</Typography>
                        </div>
                      </td>
                      <td>
                        <div>
                          <Typography component="h2" className="itemTxt">{user?.attributes?.email}</Typography> </div>
                      </td>
                      <td>
                        <div>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <IconButton onClick={() => handleDeleteDialog(user)} style={{ margin: "0", padding: "0" }}><DeleteOutlineOutlinedIcon /></IconButton>
                            <IconButton onClick={() => handleUpdateUser(user)} style={{ marginLeft: "10px", padding: "0" }}><EditOutlinedIcon /></IconButton>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                :<tr><td>No User Found</td></tr>
              }
            </tbody>
          </table>
        </div>
      </div>
      <div className="tablePagination">
        <IconButton
          className={styles.ashIconButton}
          onClick={handlePrev}
          aria-label="delete"
        >
          <ArrowBackIosIcon />
        </IconButton>
        <div className="paginationContent">
          <h2 style={{ fontSize: "14px" }}>
            {page}/{Math.ceil(userList.length / numOfItems)}
          </h2>
        </div>
        <IconButton
          className={styles.ashIconButton}
          onClick={handleNext}
          aria-label="delete">
          <ArrowForwardIosIcon />
        </IconButton>
      </div>
      {
        actionUser?.id && openDeleteDialog ?
          <ActionModal
            title="Delete User?"
            message={`Are you sure want to delete this user? (${actionUser?.attributes?.user_name})`}
            open={openDeleteDialog}
            setOpen={setOpenDeleteDialog}
            actionLoading={deleteUserLoading}
            actionLoadingText="Deleting User.."
            actionHandler={deleteUserActionHandler}
          /> : null
      }
      {updateUserModal && (
        <CreateNewUser
          open={updateUserModal}
          setOpen={changeUpdateUserModal}
          edit={true}
          editUser={actionUser}
          actionLoading={createUserLoading}
          actionHandler={updateUser}
          userGroupList={userGroupList}
          clientList={allClientList}
          getUserGroupsLoading={getUserGroupsLoading}
          getClientsLoading={getClientsLoading}
          userErrors={userErrors}
        />
      )}
    </div>
  )
}

export default UserLists;