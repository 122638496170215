// index.js - WEB
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import registerServiceWorker from '../../components/src/registerServiceWorker';

import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import AuthProvider from '../../blocks/email-account-login/src/AuthContext/AuthProvider.web';

const theme = createTheme({
  typography: {
    fontFamily: ['DM Sans', 'sans-serif'].join(',')
  }
});

ReactDOM.render(
  <Router>
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </ThemeProvider>
  </Router>,
  document.getElementById('root')
);
registerServiceWorker();
