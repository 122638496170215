import { IconButton, makeStyles, Typography } from '@material-ui/core';
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import FilterListOutlinedIcon from "@material-ui/icons/FilterListOutlined";
import React from 'react';
 import {useUserContext} from '../../../blocks/AdminConsole3/src/user/UserContext.web'
import TableLoader from '../Util/TableLoader.web'
const useStyles=makeStyles({
    ashIconButton:{
        width:"40px",
        height:"40px",
        background:"#f9f9fa",
        borderRadius:"15px"
    }
})


const AddedClients=()=>{
    const styles=useStyles();
    const[numOfItems,_]=React.useState(7);
    const {
        getClientLists,
        addedClientPage:page,
        clientList,
        getClientsLoading:loading

    }=useUserContext();

    const changePage = (pageNumber: number) => {
         getClientLists(pageNumber)
    };

    const handlePrev=()=>{
        if (page> 1) {
           loading || changePage(page - 1);
        }
    }
    const handleNext=()=>{
        if (page<Math.ceil(clientList.length / numOfItems)) {
           loading || changePage(page + 1);
        }
    }

  
    return(
          <div className="userListContainer">
            <div className="userListOptions">
              <div>
                <Typography variant="h4" style={{ fontSize: "22px",fontWeight:"bolder" }}>
                  Added Clients
                </Typography>
              </div>
            </div>
            <div className="userListTableContainer">
              <div className="client_list_table">
                <table>
                  <thead>
                      <tr>
                           <th><h5 style={{fontWeight:"bold",marginLeft:"10px"}}>NAME</h5></th> 
                           <th>
                               <div style={{display:"flex",alignItems:"center",width:"100%"}}>
                                   <div><h5 style={{fontWeight:"bold"}}>PLANTS</h5></div>
                                   <div><FilterListOutlinedIcon/></div>
                               </div>
                             
                            </th>  
                           <th>
                               <div style={{display:"flex",alignItems:"center",width:"100%"}}>
                                   <div> <h5 style={{fontWeight:"bold"}}>TOTAL USER</h5></div>
                                   <div><FilterListOutlinedIcon/></div>
                               </div>
                            </th>
                       </tr>
                   </thead>
                   <tbody>
                          { loading?<TableLoader count={5} column={5}/>:
                            clientList?.response?.data?.slice((page-1) * numOfItems, (page-1) * numOfItems + numOfItems)
                            .map((user:any,index:number)=>(
                                <tr key={user.id}>
                                  <td>
                                     <div style={{display:"flex",alignItems:"center"}}>
                                        <div style={{marginRight:"20px",width:"15px",height:"15px",borderRadius:"50%",background:"black"}}></div>
                                        <div>
                                        <Typography>{user?.attributes?.name}</Typography></div>
                                     </div>
                                    </td>
                                    <td>
                                    <Typography component="h2" className="itemTxt">{user?.attributes?.systems}   Plants</Typography>
                                    </td>
                                    <td>
                                      <Typography component="h2" className="itemTxt">{user?.attributes?.users}</Typography>
                                    </td>
                                </tr>
                              ))
                          }   
                    </tbody>
              
                 </table>
        
            </div>
            </div>
             <div className="tablePagination">
             <IconButton
                className={styles.ashIconButton}
                onClick={handlePrev}
                aria-label="delete"
             >
                <ArrowBackIosIcon />
             </IconButton>
                <div className="paginationContent">
                <h2 style={{fontSize:"14px"}}>
                    {page}/{Math.ceil(clientList.length / numOfItems)}
                    </h2>
                </div>
             <IconButton
             className={styles.ashIconButton}
               onClick={handleNext}
                aria-label="delete">
                <ArrowForwardIosIcon />
             </IconButton>
        </div>

         </div>
      )
}

export default AddedClients;