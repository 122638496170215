export const imgBell = require("../assets/bell.png");
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const backgroundCoverImage = require("../assets/bg_image.png");
export const orianaImage = require("../assets/oriana_power-logo.png");
export const cheackedbox = require("../assets/cheackedbox.png");
export const uncheackedbox = require("../assets/uncheackedbox.png");
export const Profileimages3 = require("../assets/imagesssss.png");
export const Profileimages2 = require("../assets/images(1).png");
export const downArrow = require("../assets/downArrow.png");
export const leftArrow = require("../assets/leftArrow.png");
export const rightArrow = require("../assets/rightArrow.png");
export const upArrow = require("../assets/upArrow.png");
export const leftBlackArrow = require("../assets/leftBlackArrow.png");
export const downCheverson = require("../assets/downCheverson.png");
export const orianaPowerImage = require("../assets/orianaPowerImage.png");
export const blankCheackBox = require("../assets/blankCheackBox.png");
export const fillCheackBox = require("../assets/fillCheackBox.png");
export const orianaDownImage = require("../assets/orianaDownImage.png");
export const chatProfile = require("../assets/chatProfile.png");
export const upGreenArrow = require("../assets/upArrow.png");
export const Add = require("../assets/Add.png");
export const Alarm = require("../assets/Alarm.png");
export const Calendar = require("../assets/Calendar.png");
export const CO2 = require("../assets/CO2.png");
export const Coal = require("../assets/Coal.png");
export const Control = require("../assets/Control.png");
export const Dashboard = require("../assets/Dashboard.png");
export const Deforestation = require("../assets/Deforestation.png");
export const Edit = require("../assets/Edit.png");
export const Edit_2 = require("../assets/Edit_2.png");
export const Events = require("../assets/Events.png");
export const Ham = require("../assets/Ham.png");
export const Location = require("../assets/Location.png");
export const Notification = require("../assets/Notification.png");
export const Plants = require("../assets/Plants.png");
export const PowerStationStatus = require("../assets/PowerStationStatus.png");
export const Profile = require("../assets/Profile.png");
export const PVCapacity = require("../assets/PVCapacity.png");
export const PVCapacitycopy = require("../assets/PVCapacitycopy.png");
export const Search = require("../assets/Search.png");
export const Send = require("../assets/Send.png");
export const Services = require("../assets/Services.png");
export const Silvassa = require("../assets/Silvassa.png");
export const SN = require("../assets/SN.png");
export const Upload = require("../assets/Upload.png");
export const Control_2 = require("../assets/Control_2.png");
export const CurrentPower = require("../assets/CurrentPower:0.29KW.png");
export const Dashboard_2 = require("../assets/Dashboard_2.png");
export const DeviceName = require("../assets/DeviceName.png");
export const ecohouse = require("../assets/eco-house.png");
export const Edit_2copy = require("../assets/Edit_2copy.png");
export const Group13 = require("../assets/Group13.png");
export const Group14 = require("../assets/Group14.png");
export const Nonotification = require("../assets/NoNotification.png");
export const Plants_2 = require("../assets/Plants_2.png");
export const Profile_2 = require("../assets/Profile_2.png");
export const Services_2 = require("../assets/Services_2.png");
export const solarenergy = require("../assets/solar-energy.png");
export const CUF = require("../assets/CUF.png");
export const InstallationDate = require("../assets/InstallationDate.png");
export const PR = require("../assets/PR.png");
export const Events_2 = require("../assets/Events_2.png");
export const leftcheverson = require("../assets/leftCheverson.png");
export const ruppes = require("../assets/rupees.png");
export const rightplantarrow = require("../assets/rightplantarrow.png");
export const menubar = require("../assets/menubar.png");
export const tazaImage = require("../assets/tazaImage.png");
export const smile = require("../assets/smile.png");
export const close = require("../assets/close.png");