import { Checkbox,Typography } from "@material-ui/core";
import React from 'react'
interface Props{
    value: any;
    changeEditors:(editor:string)=>void;
    editors:string[];
}

function EditorSelectItem({ value:editor,changeEditors,editors}: Props) {
    const checked=editors.filter(ed=>ed===editor?.email).length>0;
    return (
      <div className="checkItem">
        <div>
          <Checkbox
            checked={checked}
            style={{padding:"0px",margin:"0px 0px 0px 0px"}}
            onChange={e=>changeEditors(editor?.email)}
            color="primary"
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        </div>
        <div style={{ marginLeft: "10px" }}>
            <Typography style={{fontSize:"12px",margin:"0px",padding:"0px"}} className={`${checked?"editorChecked":"checkbox"}`}>{ editor?.email} ({editor?.role})</Typography>
        </div>
      </div>
    );
}


export default EditorSelectItem;