import React, { useContext } from "react";
import { ApprovalResponse } from "./ApprovalController.web";
interface ActionType {
  alert: string;
  action: string;
}
interface ApprovalContextType {
  approvalRequestList: ApprovalResponse;
  getApprovalLoading: boolean;
  currentPage: number;
  loadedApprovalPage: number;
  systemInfoModal: boolean;
  changeSystemInfoModal: (open: boolean, system: any) => void;
  changeApproveModal: (open: boolean, system: any, action: ActionType) => void;
  approveDeclineSystem: () => void;
  selectedApproval: any;
  approveModal: boolean;
  approveLoading: boolean;
  action: {
    alert: string;
    action: string;
  };
}
const defaultContext: ApprovalContextType = {
  approvalRequestList: {
    response: {
      data: [],
    },
    length: 0,
    numOfItem: 6,
  },
  getApprovalLoading: false,
  currentPage: 1,
  loadedApprovalPage: 1,
  systemInfoModal: false,
  changeSystemInfoModal: (open: boolean, system: any) => {},
  approveDeclineSystem: () => {},
  changeApproveModal: (open: boolean, system: any, action: ActionType) => {},
  selectedApproval: {},
  approveModal: false,
  approveLoading: false,
  action: {
    alert: "",
    action: "",
  },
};

export const ApprovalContext = React.createContext<ApprovalContextType>(
  defaultContext
);

export function useApprovalContext() {
  return useContext(ApprovalContext);
}
