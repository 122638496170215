import React from "react";

import {
  StyleSheet,
  Text,
  View,
  Image,
  Platform,
  ImageBackground,
} from "react-native";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import FilledInput from "@material-ui/core/FilledInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

import { withStyles } from "@material-ui/core/styles";
import "../assets/EmailAccountLogin.css";
import { backgroundCoverImage } from "./assets";

//@ts-ignore
import CustomCheckBox from "../../../components/src/CustomCheckBox";

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@material-ui/core/CircularProgress";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { requestLoading } = this.state;

    return (
      <Grid>
        <Container
          maxWidth="xl"
          style={{ padding: "0" }}
          className="email-account-container"
        >
          <ImageBackground source={backgroundCoverImage} style={styles.bgImage}>
            <Box
              sx={{
                width: 360,
                height: 550,
                boxShadow: 1,
              }}
              className="login-box"
            >
              <Grid
                className="login-box-header"
                container
                direction="row"
                alignItems="center"
              >
                <Grid item>
                  <Image
                    source={require("../assets/oriana_power-logo.png")}
                    style={styles.logoImage}
                  />
                </Grid>
                <Grid item className="logo-text">
                  ORIANA POWER
                </Grid>
              </Grid>

              <Grid className="login-form-header-grid">
                <h2 className="login-form-header">Let's Sign You In</h2>
                <div className="login-form-sub-header">
                  Welcome back, administrator
                </div>
              </Grid>

              <Grid className="login-form-grid">
                <InputTextField
                  label="Enter Email"
                  variant="filled"
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                  }}
                  onChange={(e) => this.handleTxtInputEmail(e)}
                />

                {/* <View style={styles.bgPasswordContainer}>
                  <TextInput
                    testID="txtInputEmail"
                    style={styles.bgMobileInput}
                    placeholder="Email"
                    {...this.txtInputEmailProps}
                  />
                </View> */}
                {/* <View style={styles.bgPasswordContainer}>
                  <TextInput
                    testID="txtInputPassword"
                    style={styles.bgPasswordInput}
                    placeholder={this.state.placeHolderPassword}
                    {...this.txtInputPasswordProps}
                  />

                  <TouchableOpacity
                    testID={"btnPasswordShowHide"}
                    style={styles.passwordShowHide}
                    {...this.btnPasswordShowHideProps}
                  >
                    <Image
                      testID={"btnPasswordShowHideImage"}
                      style={styles.imgPasswordShowhide}
                      {...this.btnPasswordShowHideImageProps}
                    />
                  </TouchableOpacity>
                </View> */}

                <InputTextField
                  type={this.state.showPasswordText ? "text" : "password"}
                  label="Enter Password"
                  variant="filled"
                  fullWidth
                  onChange={(e) => this.handleTxtInputPassword(e)}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          onClick={() => this.handleClickShowPassword()}
                        >
                          <Image
                            testID={"btnPasswordShowHideImage"}
                            style={styles.imgPasswordShowhide}
                            {...this.btnPasswordShowHideImageProps}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <Grid xs={12} style={{ display: "flex" }}>
                  <View style={styles.checkBoxContainerView}>
                    <CustomCheckBox
                      testID={"CustomCheckBox"}
                      {...this.CustomCheckBoxProps}
                    />

                    <Text
                      testID={"btnRememberMe"}
                      style={styles.rememberMe}
                      {...this.btnRememberMeProps}
                    >
                      {this.state.labelRememberMe}
                    </Text>
                  </View>

                  <Text
                    testID={"btnForgotPassword"}
                    style={styles.forgotPassword}
                    {...this.btnForgotPasswordProps}
                  >
                    Forgot password
                  </Text>
                </Grid>

                {/* <Button
                  testID={"btnEmailLogIn"}
                  title={this.state.btnTxtLogin}
                  {...this.btnEmailLogInProps}
                /> */}
                <Button
                  size="medium"
                  className="login-button"
                  onClick={() => this.handleLoginSubmit()}
                  disabled={requestLoading}
                >
                  {requestLoading ? (
                    <CircularProgress size={24} />
                  ) : (
                    this.state.btnTxtLogin
                  )}
                </Button>
              </Grid>
            </Box>
          </ImageBackground>
        </Container>
        <ToastContainer theme="dark" />
      </Grid>
    );
  }
}

const styles = StyleSheet.create({
  bgImage: {
    height: "100%",
  },
  logoImage: {
    height: "32px",
    width: "32px",
  },
  bgMobileInput: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#ededed",
    borderColor: "#767676",
    minHeight: 55,
    borderRadius: 15,
    includeFontPadding: true,
  },

  bgPasswordInput: {
    flex: 1,
    outline: "none",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#ededed",
    minHeight: 55,
    includeFontPadding: true,
    borderRadius: 15,
  },
  passwordShowHide: {
    alignSelf: "center",
  },

  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#ededed",
    marginBottom: 16,
    marginTop: 20,
    borderRadius: 15,
    paddingLeft: 5,
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},

  forgotPassword: {
    color: "#FDCE32",
    fontWeight: "bold",
    marginBottom: 10,
    zIndex: 1,
    marginTop: -2,
  },
  checkBoxContainerView: {
    flexDirection: "row",
    marginBottom: 10,
    zIndex: 1,
    flex: 1,
  },
  rememberMe: {
    color: "#171717",
    fontWeight: "bold",
    alignSelf: "center",
    zIndex: 1,
    marginTop: -2,
  },
});

const InputTextField = withStyles({
  root: {
    marginBottom: 20,
    "& .MuiFilledInput-root": {
      borderRadius: `15px`,
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "#ffc100",
    },
  },
})(TextField);

const FilledInputTextField = withStyles({
  root: {
    marginBottom: 20,
    borderRadius: `15px`,
    borderBottom: 0,
    "& .MuiFilledInput-root": {
      borderRadius: `15px`,
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "#ffc100",
    },
    "& .MuiFilledInput-underline:before": {
      borderBottom: 0,
    },
    underline: {
      "&&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
  },
})(FilledInput);
