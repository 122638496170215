import {
  Button, Checkbox, FormControlLabel, Grid, makeStyles, MenuItem,
  TextField
} from "@material-ui/core";
import React from "react";
import MapWeb from "./Map.web";
const mapStyles = {
  width: '500px',
  height: '500px',
};

interface Props {
    loading:boolean;
    isSuccess:boolean;
    createNewSystem:(body:object)=>void;
    goBack:()=>void;
    countryList:any
}

const useStyles=makeStyles({
   inputPlaceholder:{
     color:"#8F92A1",
     fontWeight:"bolder"
   },
   headingText:{
     fontWeight:"bolder",
     fontSize:"22px"

   },
   dropZone:{
     width:"100%",
     flexDirection:"column",
     height:"90px",
     boxSizing:"border-box",
     padding:"10px",
     border:"2px dashed #000",
     borderRadius:"10px",
     background:"#f9f9fa",
     display:"flex",
     justifyContent:"center",
     alignItems:"center",
     cursor:"pointer",
     marginBottom:"15px",
     marginTop:"10px"
   }
})


interface UserError{
  designation?:string|null;
  street?:string|null;
  postalCode?:string|null;
  country?:string|null;
  timezone?:string|null;
  longitute?:string|null;
  latitude?:string|null;
  altitude?:string|null;
  systemProfile?:string|null;
  systemType?:string|null;
  coFactor?:string|null;
}


export default function AddSystem({ 
    loading,
    createNewSystem,
    goBack,
    countryList,
    isSuccess
}: Props) {
  const styles=useStyles();
  const [standardCheck,setStandardCheck]=React.useState<boolean>(false);
  const [advancedCheck,setAdvanceCheck]=React.useState<boolean>(false);
  const [defaultCheck,setDefaultCheck]=React.useState<boolean>(false);
  const [countrySpecificCheck,setCountrySpecificCheck]=React.useState<boolean>(false);
  const [mapCheck,setMapCheck]=React.useState<boolean>(false);
  const [designation,setDesignation]=React.useState<string>("");
  const [street,setStreet]=React.useState<string>("");
  const [postalCode,setPostalCode]=React.useState<string>("");
  const [country,setCountry]=React.useState<string>("");
  const [timezone,setTimeZone]=React.useState<string>("");
  const [longitute,setLongitute]=React.useState<number>(0);
  const [latitude,setLatitude]=React.useState<number>(0);
  const [altitude,setAltitude]=React.useState<string>("");
  const [systemType,setSystemType]=React.useState<string>("");
  const [errors,setErrors]=React.useState<UserError>({});
  const [countrys,setCountrys]=React.useState<any[]>(countryList?.countryList);
  const [timeZones,setTimeZones]=React.useState<any[]>(countryList?.timeZones);


  const validateInput=async()=>{
       let newErrors:UserError={}
       if(!designation){
           newErrors.designation="Enter Designation of the system.";
       }
       if(!street){
           newErrors.street="Enter Street";
       }
       if(!postalCode){
           newErrors.postalCode="Enter Postal Code";
       }
       if(!country){
           newErrors.country="Please Select Country";
       }
       if(!timezone){
           newErrors.timezone="Please Select Time Zone";
       }
       if(!longitute){
           newErrors.longitute="Please Enter Longitute";
       }
       if(!latitude){
           newErrors.latitude="Please Enter Latitude";
       }
       if(!altitude){
           newErrors.altitude="Please Enter Altitude";
       }
       if(!standardCheck && !advancedCheck){
           newErrors.systemProfile="Please Select System Profile";
       }
       if(!systemType){
           newErrors.systemType="Please Select System Type";
       }
       if(!defaultCheck && !countrySpecificCheck){
           newErrors.coFactor="Please Select CO2-Emission factor";
       }
       if(newErrors && Object.keys(newErrors).length === 0 && Object.getPrototypeOf(newErrors) === Object.prototype){
           setErrors({})
           const systemProfile=getSystemProfile();
           const co2Factor=getSystemProfile();
           const body={
            designation:designation,
            street,
            postal_code:postalCode,
            country,
            timezone:timezone,
            latitude:latitude,
            longitude:longitute,
            altitude:altitude,
            system_profile:systemProfile,
            system_type:systemType,
            co2_emission_factor:co2Factor
           }
        createNewSystem(body);
       

       }else{
           setErrors(newErrors)
       }
       
  }
  const getSystemProfile=()=>{
    let sysP="Advanced";
    if(advancedCheck){
        sysP="Advanced";
    }else if(standardCheck){
        sysP="Standard";
    }
    return sysP;
  }
  const getCoFactor=()=>{
    let txt="Default";
    if(defaultCheck){
        txt="Default";
    }else if(countrySpecificCheck){
        txt="Country specific";
    }
    return txt;
  }

  const handleMapClick=(e:any)=>{
    if(mapCheck){
      setLatitude(e.latLng.lat())
      setLongitute(e.latLng.lng())
    }
  }
 
  React.useEffect(()=>{
    if(isSuccess){
            setStandardCheck(false);
            setAdvanceCheck(false);
            setDefaultCheck(false);
            setCountrySpecificCheck(false);
            setMapCheck(false);
            setDesignation("");
            setStreet("");
            setPostalCode("");
            setCountry("");
            setTimeZone("");
            setLongitute(0);
            setLatitude(0);
            setAltitude("");
            setSystemType("");
            setErrors({});
    }
  },[isSuccess])

  return (
    <div>    
            <div
              style={{
                marginTop: "10px",
                marginBottom: "15px",
                height: "2px",
                width: "100%",
                background: "#f9f9fa",
              }}
            ></div>
            <TextField 
                value={designation}
                onChange={(e)=>setDesignation(e.target.value)}
                fullWidth id="standard-basic"
                label={<span className={styles.inputPlaceholder}>Designation of the system*</span>}
                 error={errors?.designation===undefined || errors?.designation===""?false:true}
                  helperText={errors?.designation}
                />
            <Grid container spacing={2} style={{ marginTop: "15px" }}>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                 <TextField 
                    value={street}
                    onChange={(e)=>setStreet(e.target.value)}
                    fullWidth id="standard-basic"
                    label={<span className={styles.inputPlaceholder}>Street</span>}
                    error={errors?.street===undefined || errors?.street===""?false:true}
                    helperText={errors?.street}
                    />
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>

                  <TextField 
                      value={postalCode}
                      onChange={(e)=>setPostalCode(e.target.value)}
                      fullWidth id="standard-basic"
                      label={<span className={styles.inputPlaceholder}>Postal code/Town</span>}
                      error={errors?.postalCode===undefined || errors?.postalCode===""?false:true}
                      helperText={errors?.postalCode}
                      />
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: "15px" }}>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                  <TextField
                      value={country}
                      onChange={(e)=>setCountry(e.target.value)}
                      style={{ marginTop: "15px" }}
                      fullWidth
                      id="standard-select-currency"
                      select
                      label={<span className={styles.inputPlaceholder}>Country</span>}
                      error={errors?.country===undefined || errors?.country===""?false:true}
                      helperText={errors?.country}
                     >
                       {
                         countrys?.map((item,index)=>(
                           <MenuItem key={item.id} value={item?.name}>{item?.name}</MenuItem> 
                         ))
                       }
                      
                    </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>

                  <TextField
                      value={timezone}
                      onChange={(e)=>setTimeZone(e.target.value)}
                      style={{ marginTop: "15px" }}
                      fullWidth
                      id="standard-select-currency"
                      select
                      label={<span className={styles.inputPlaceholder}>Timezone</span>}
                      error={errors?.timezone===undefined || errors?.timezone===""?false:true}
                      helperText={errors?.timezone}
                     >
                        {
                         timeZones?.map((item,index)=>(
                           <MenuItem key={item.id} value={item?.name+item?.utc}>{item?.name}{item?.utc}</MenuItem> 
                         ))
                       }
                     </TextField>
              </Grid>
            </Grid>
            
              
            <Grid container spacing={2} style={{ marginTop: "15px" }}>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                 <TextField 
                     value={longitute===0?"":longitute?.toString()}
                    onChange={(e)=>setLongitute(Number(e.target.value))}
                     type="number"
                      fullWidth 
                      id="standard-basic"
                      label={<span className={styles.inputPlaceholder}>Longitute</span>}
                      error={errors?.longitute===undefined || errors?.longitute===""?false:true}
                      helperText={errors?.longitute}
                      />
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                    <TextField 
                     value={latitude===0?"":latitude?.toString()}
                      onChange={(e)=>setLatitude(Number(e.target.value))}
                     type="number"
                      fullWidth 
                      id="standard-basic"
                      label={<span className={styles.inputPlaceholder}>Latitude</span>}
                      error={errors?.latitude===undefined || errors?.latitude===""?false:true}
                      helperText={errors?.latitude}
                      />
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: "15px" }}>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                 <TextField 
                      value={altitude}
                      onChange={(e)=>setAltitude(e.target.value)}
                      type="number"
                      fullWidth 
                      id="standard-basic"
                      label={<span className={styles.inputPlaceholder}>Altitude above sea leve</span>}
                      error={errors?.altitude===undefined || errors?.altitude===""?false:true}
                      helperText={errors?.altitude}
                      />
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                    
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: "15px" }}>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                 <div style={{display:"flex",flexDirection:"column"}}>
                     <span className={styles.inputPlaceholder}>System Profile</span>
                    <FormControlLabel
                        style={{marginTop:"12px"}}
                        control={
                        <Checkbox
                            checked={standardCheck}
                            onChange={(e)=>{
                                setStandardCheck(e.target.checked);
                                setAdvanceCheck(false);
                            }}
                            name="checkedB"
                            color="primary"
                        />
                        }
                        label="Standard"
                        
                    />
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={advancedCheck}
                             onChange={(e)=>{
                                setStandardCheck(false);
                                setAdvanceCheck(e.target.checked);
                            }}
                            name="checkedB"
                            color="primary"
                        />
                        }
                        label="Advanced"
                    />
                    <span style={{color:"red",fontSize:"12px",fontWeight:200}}>{errors?.systemProfile}</span>
                 </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                    <TextField
                     value={systemType}
                      onChange={(e)=>setSystemType(e.target.value)}
                      style={{ marginTop: "15px" }}
                      fullWidth
                      id="standard-select-currency"
                      select
                      label={<span className={styles.inputPlaceholder}>System Type</span>}
                      error={errors?.systemType===undefined || errors?.systemType===""?false:true}
                      helperText={errors?.systemType}
                     >
                       <MenuItem value="India">India</MenuItem> 
                    </TextField>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: "15px" }}>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                 <div style={{display:"flex",flexDirection:"column"}}>
                     <span className={styles.inputPlaceholder}>CO2-Emission factor</span>
                    <FormControlLabel
                        style={{marginTop:"12px"}}
                        control={
                        <Checkbox
                            checked={defaultCheck}
                            onChange={(e)=>{
                                setDefaultCheck(e.target.checked);
                                setCountrySpecificCheck(false);
                            }}
                            name="checkedB"
                            color="primary"
                        />
                        }
                        label="Default"
                    />
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={countrySpecificCheck}
                             onChange={(e)=>{
                                setDefaultCheck(false);
                                setCountrySpecificCheck(e.target.checked);
                            }}
                            name="checkedB"
                            color="primary"
                        />
                        }
                        label="Country specific"
                    />
                    <span style={{color:"red",fontSize:"12px",fontWeight:200}}>{errors?.coFactor}</span>
                 </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: "15px" }}>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                 <div style={{display:"flex",flexDirection:"column",gap:"10px"}}>
                     <span className={styles.inputPlaceholder}>Location on map</span>
                     <div style={{width:"400px",height:"500px"}}>
                       <MapWeb
                          lat={latitude}
                          lng={longitute}
                          mapClickHandler={handleMapClick}
                       />
                       </div>
                     <FormControlLabel
                        control={
                        <Checkbox
                            checked={mapCheck}
                             onChange={(e)=>setMapCheck(e.target.checked)}
                            name="checkedB"
                            color="primary"
                        />
                        }
                        label="Change system location by clicking on the map"
                    />
                 </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: "15px" }}>
              <Grid item xs={12} sm={6} md={6} xl={6}>
             
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                  <div style={{display:"flex",alignItems:"center",justifyContent:"flex-end"}}>
                    <Button
                        onClick={()=>goBack()}
                        fullWidth
                        variant="contained"
                        style={{
                            background: "#f9f9fa",
                            marginRight: "10x",
                            borderRadius: "15px",
                            marginTop: "10px",
                            marginBottom: "10px",
                            boxShadow: "none",
                            textTransform: "capitalize",
                            fontWeight: 400,
                            width:"100px"
                        }}
                        >
                        Back
                    </Button>
                <Button
                  disabled={loading}
                  onClick={()=>validateInput()}
                  variant="contained"
                  style={{
                    background: "#FFCE21",
                    marginRight: "10x",
                    borderRadius: "15px",
                    marginTop: "10px",
                    marginBottom: "10px",
                    boxShadow: "none",
                    textTransform: "capitalize",
                    fontWeight: 400,
                    width:"100px"
                  }}
                >
                  {loading?"Creating New System...":"Next"}
                </Button>
                  </div>
               
              </Grid>
            </Grid>
            <br/>
    </div>
  );
}
