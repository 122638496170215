import React from "react";
import { createStyles, IconButton, makeStyles, Theme } from "@material-ui/core";
import { ITableHeader } from "./Table.types";
import FilterListIcon from "@material-ui/icons/FilterList";
import PaginationController from "./PaginationController";
interface Props{
  headers: ITableHeader[];
  data: any[];
  renderRow: (data: any, styles: any) => any;
  currentPage:number;
  perPage:number;
  totalPage:number;
  nextFetcher:()=>void;
  prevFetcher:()=>void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table__container: {
      boxSizing: "border-box",
      marginTop: "10px",
      overflowY: "auto",
      width: "100%",
      "& table": {
        width: "100%",
        minWidth: "800px",
        borderSpacing: "0px 15px",
        "& thead": {
          background: "#f9f9fa",
          borderRadius: "10px",
          height: "40px",
        },
        "& tr": {
          textAlign: "left",
          "& td": {
            textTransform: "capitalize",
            padding: "5px 0px",
            borderBottom: "2px solid #f9f9fa",
          },
          "&:hover": {
            backgroundColor: "var(--main-color)",
            color: "var(--txt-white)",
            cursor: "pointer",
          },
        },
      },
    },
    itemText: {
      fontSize: "16px",
      fontWeight: 500,
    },
    table__item__data__containier: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
    row: {
      display: "flex",
      alignItems: "center",
      gap: "15px",
    },
  })
);

const RenderTable =({ 
    headers,
    data,
    renderRow,
    perPage,
    currentPage,
    nextFetcher,
    prevFetcher,
    totalPage
 }: Props) => {
  const styles = useStyles();
  return (
    <div>
    <div className={styles.table__container}>
      <table>
        <thead>
          <tr>
            {headers.map((header) => (
              <th
                key={header.id}
                // style={{ display: "flex", alignItems: "center", rowGap: "4px",...header.containerStyles }}
              >
                <h5 style={{ fontWeight: "bold" }}>{header.name}</h5>
                {header.enableFilter ? (
                  <IconButton>
                    <FilterListIcon />
                  </IconButton>
                ) : null}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
            {
               data?.slice((currentPage-1) * perPage, (currentPage-1) * perPage + perPage)
                .map((item, index)=>{
                    return(
                        <React.Fragment key={item?.id?item?.id:index}>
                            {renderRow(item,styles)}
                        </React.Fragment>
                    )
                })
            }
        </tbody>
      </table>
    </div>
    <PaginationController
        currentPage={currentPage}
        totalPage={totalPage}
        next={nextFetcher}
        prev={prevFetcher}
        />
    </div>
  );
};

export default RenderTable;
