import React from "react";

import UserProfileBasicController, {
  Props,
  configJSON
} from "./UserProfileBasicController.web";
import {imgEdit, imgAdd, imgDelete} from "./assets";

import { withStyles } from "@material-ui/core/styles";
import { Image } from "react-native";
import Dialog from '@material-ui/core/Dialog';
import '../assets/style.css'
import {
  Container,
  Typography,
  Box,
  Paper,
  Avatar,
  TextField,
  CircularProgress,
  Button,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
// Customizable Area Start
// Customizable Area End
export default class UserProfileBasicBlock extends UserProfileBasicController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
// Customizable Area Start
// Customizable Area End
  render() {
  
    return (
      <>
        <main style={{ overflowX: "auto"}}>
          <Container maxWidth="xl" style={{marginBottom: "20px"}}>
            <Typography
              component="h1"
              style={{ margin: "20px 0", fontSize: "34px", fontWeight: "bold" }}
            >
              My Profile
            </Typography>

            <Box
              style={{
                backgroundColor: "#f9f9fa",
                borderRadius: "24px",
                padding: "20px",
                display: "flex",
              }}
            >
              <div
                style={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: "24px",
                  width: "100%",
                }}
              >
              <div style={{display: "flex"}}>
                <Typography
                  component="h2"
                  style={{
                    margin: "20px 32px",
                    fontSize: "22px",
                    fontWeight: "bold",
                    flex: 1
                  }}
                >
                  Profile Details
                </Typography>
                <Button
                variant="contained"
                disableElevation
                style={{margin: "20px 32px",borderRadius: "9px", height: "40px", width: "100px", background: "#FDCE32", fontWeight: 600, textTransform: "capitalize"}}
                startIcon={<Image source={imgEdit} style={{width: "14px", height: "14px"}}/>}
                onClick={() => this.navigateToEditProfile()}
              >
                Edit
              </Button>
              </div>
                {this.state.userProfileInfo === null && (
                  <LoadingCirculeProgress size="100" />
                )}
                {
                  this.state.userProfileInfo && 
                  <Paper elevation={0} style={{ margin: "20px 32px" }}>
                  <Typography
                    component="span"
                    style={{
                      fontSize: "14px",
                      fontWeight: 600,
                      color: "#8F92A1",
                    }}
                  >
                    Profile Picture
                  </Typography>

                  <Avatar src={this.state.userProfileInfo?.image}>OP</Avatar>

                  <InputTextField
                    id="standard-basic"
                    label="Name"
                    value={this.state.userProfileInfo?.name}
                    disabled
                  />

                  <InputTextField
                    id="standard-basic"
                    label="Contact"
                    value={this.state.userProfileInfo?.phone_number}
                    disabled
                  />

                  <InputTextField
                    id="standard-basic"
                    label="Email address"
                    value={this.state.userProfileInfo?.email}
                    disabled
                  />

                  <InputTextField
                    id="standard-basic"
                    label="Password"
                    value={this.state.userProfileInfo?.password}
                    disabled
                    type="password"
                  />
                </Paper>
                }

                <div style={{display: "flex"}}>
                    <Typography
                      component="h2"
                      style={{
                        margin: "20px 32px",
                        fontSize: "22px",
                        fontWeight: "bold",
                        flex: 1
                      }}
                    >
                      Other Accounts
                    </Typography>
                    <Button
                    variant="contained"
                    disableElevation
                    style={{margin: "20px 32px",borderRadius: "9px", height: "40px", width: "175px", fontWeight: 600, textTransform: "capitalize"}}
                    startIcon={<Image source={imgAdd} style={{width: "14px", height: "14px"}}/>}
                    onClick={() => this.addNewAccount()}
                  >
                    Add Account
                  </Button>
                </div>
                
                {
                  this.state.userAccountList?.length > 0 && 
                  <Typography component="strong" style={{margin: "20px 32px", fontSize: "14px", fontWeight: 600}}>
                    Previously shared
                  </Typography>
                }

                {
                  this.state.userAccountList?.length > 0 && 
                  <TableContainer component={Paper} style={{maxWidth: "90%", margin: "20px 32px"}}>
                    <Table aria-label="simple table">
                    <TableBody>
                        {this.state.userAccountList?.map((row) => (
                        <TableRow key={row.attributes.email}>
                          <TableCell component="th" scope="row">
                            {row?.attributes.user_name}
                          </TableCell>
                          <TableCell align="right">{row?.attributes.email}</TableCell>
                          <TableCell align="right">
                            <IconButton aria-label="delete" onClick={() => this.deleteAccount(row)}>
                              <Image source={imgDelete} style={{width: "16px", height: "16px"}}/>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>                  
                    </Table>
                  </TableContainer>
                }
                <Button 
                onClick={()=>this.logoutUser()}
                variant="contained" 
                color="secondary" 
                style={{margin:"15px"}}>Logout</Button>
              </div>
            </Box>
          </Container>
        </main>

        <Dialog
        fullWidth
        maxWidth="xs"
        PaperProps={{style: {borderRadius: 16}}}
        open={this.state.openDeleteAccountConfirmation}
        onClose={() => this.handleAccountDeleteConfimationClose()}
        >
          <DialogTitle id="max-width-dialog-title" style={{paddingBottom: 0}}>
            <strong>Delete Account?</strong>
          </DialogTitle>
          <DialogContent>
            <p>Are you sure want to delete account?</p>
          </DialogContent>
          <DialogActions style={{margin: "15px"}}>
            <Button
                    variant="contained"
                    disableElevation
                    style={{borderRadius: "9px", height: "40px", width: "100%", fontWeight: 600, textTransform: "capitalize"}}
                    onClick={() => this.handleAccountDeleteConfimationClose()}
                  >
              Cancel
            </Button>
            <Button
                    variant="contained"
                    disableElevation
                    style={{borderRadius: "9px", height: "40px", width: "100%", background: "#FDCE32", fontWeight: 600, textTransform: "capitalize"}}
                    onClick={() => {this.deleteConfirmAccount()}}
                  >
              Delete
            </Button>
            </DialogActions>
        </Dialog>

        <Dialog
        fullWidth
        maxWidth="xs"
        PaperProps={{
          style: { borderRadius: 16 }   }}
        open={this.state.open}
        onClose={() => this.handleClose()}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title" style={{paddingBottom: 0}}>
          <strong>Add New Account</strong>
        </DialogTitle>
        <DialogContent>
          <form>
          <InputTextField
                    error={!this.state.isEmailValid}
                    helperText={!this.state.isEmailValid ? configJSON.errorEmailNotValid : ""}
                    required
                    label="Email address"
                    type="email"
                    value={this.state.createNewAccount.email}
                    onChange={(e) => this.handleEmailInput(e)}
                  />

          <InputTextField
                    error={!this.state.isUsernameValid}
                    helperText={!this.state.isUsernameValid ? configJSON.errorUsernameNotValid : ""}
                    required
                    label="Username"
                    value={this.state.createNewAccount.username}
                    onChange={(e) => this.handleUsernameInput(e)}
                  />

             <InputTextField
                    error={!this.state.isContactValid}
                    helperText={!this.state.isContactValid ? this.state.contactNoError : ""}
                    required
                    type="number"
                    label="Contact"
                    value={this.state.createNewAccount.contact}
                    onChange={(e) => this.handleContactInput(e)}
                  />

          <InputTextField
                    error={!this.state.isPasswordValid}
                    helperText={!this.state.isPasswordValid ? configJSON.errorPasswordNotValid : ""}
                    type="password"
                    required
                    label="Password"
                    value={this.state.createNewAccount.password}
                    onChange={(e) => this.handlePasswordInput(e)}
                  />
          
          </form>
          {
            this.state.isCreateAcountResponseError && 
            <div style={{color: "red"}}>
              {this.state.createAccountResponseError}
            </div>
          }
          
        </DialogContent>
        <DialogActions style={{margin: "15px"}}>
        <Button
                variant="contained"
                disableElevation
                style={{borderRadius: "9px", height: "40px", width: "100%", fontWeight: 600, textTransform: "capitalize"}}
                onClick={() => this.handleClose()}
              >
          Cancel
        </Button>
        <Button
                variant="contained"
                disableElevation
                style={{borderRadius: "9px", height: "40px", width: "100%", background: "#FDCE32", fontWeight: 600, textTransform: "capitalize"}}
                disabled={this.state.createAccountAPIInProgress || this.state.isCreateAcountResponseError}
                onClick={() => {this.saveNewAccount()}}
              >
          {
            this.state.createAccountAPIInProgress &&
            <ApiLoadingCirculeProgress size="20"></ApiLoadingCirculeProgress>
          }
          {!this.state.createAccountAPIInProgress && 'Save'}
        </Button>
        </DialogActions>
      </Dialog>

      </>
    );
  }
}

const InputTextField = withStyles({
  root: {
    display: "block",
    margin: "20px 0",
    "& .MuiFormLabel-root": {
      color: "#8F92A1",
      fontSize: "16px",
      fontWeight: 600,
    },
    "& .MuiInput-root": {
      width: "350px",
      color: "#171717",
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "#8F92A1",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#bdbdbd",
    },
  },
})(TextField);

const LoadingCirculeProgress = withStyles({
  root: {
    width: "100px",
    height: "100px",
    color: "#FDCE32",
    marginLeft: "25%",
  },
})(CircularProgress);

const ApiLoadingCirculeProgress = withStyles({
  root: {
    width: "20px",
    height: "20px",
    color: "#181616",
  },
})(CircularProgress);

