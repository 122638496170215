import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import React from "react";
import {
  DataLoggerItemI,
  DataLoggerListI,
} from "../../../blocks/ActivityLog/src/ClientSetting.types";
import * as Yup from 'yup';
import CircularProgress from "@material-ui/core/CircularProgress";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dividerStyle: {
      marginTop: "10px",
      marginBottom: "15px",
      height: "2px",
      width: "100%",
      background: "#f9f9fa",
    },
    ashBoldText: {
      color: "#8F92A1",
      fontSize: "15px",
      marginTop: "10px",
      marginBottom: "10px",
      fontWeight: "bolder",
    },
    selectContainer: {
      border: "none",
      fontSize: "1.2rem",
      fontWeight: "bolder",
      color: "black",
      "&:hover": {
        borderBottom: "0px",
      },
      "&::before": {
        borderBottom: "0px !important",
      },
      "&::after": {
        borderBottom: "0px !important",
      },
      "&$selected": {
        borderBottom: "0px !important",
      },
    },
    blackBoldText: {
      fontSize: "1.2rem",
      fontWeight: "bolder",
      color: "black",
    },
    row: {
      display: "flex",
      alignItems: "center",
      gap: "15px",
    },
    ashButton: {
      background: "#f9f9fa",
      borderRadius: "15px",
      boxShadow: "none",
      textTransform: "capitalize",
      fontWeight: 400,
      color: "black",
    },
    yellowButton: {
      background: "#FFCE21",
      borderRadius: "15px",
      boxShadow: "none",
      textTransform: "capitalize",
      fontWeight: 400,
      color: "black",
    },
  })
);

interface Props {
  edit:boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
  dataLoggers:DataLoggerListI;
  actionHandler:any;
  actionLoading:boolean;
  newError?:any;
}

export default function AddNewDeviceModal({
   open, 
   setOpen,
   dataLoggers,
   actionHandler,
   actionLoading,
   edit,
   newError
  }: Props) {
  const styles = useStyles();
  const [dataLogger, setDataLogger] = React.useState<DataLoggerItemI|null>(null);
  const [deviceAddress, setDeviceAddress] = React.useState<string>("");
  const [deviceType, setDeviceType] = React.useState<string>("");
  const [deviceName, setDeviceName] = React.useState<string>("");
  const [errors, setErrors] = React.useState<any>({});

  
  React.useEffect(()=>{
    setErrors(newError)
  },[newError])



  const handleDeviceChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setDeviceType(event.target.value as string);
  };
  const handleSelectDataLogger=(id?:number)=>{
    if(!id)
    {
      return;
    }
    const newSelectedDataLogger=dataLoggers?.response?.data?.filter((it:any)=>it?.attributes?.id===id);
    if(newSelectedDataLogger?.length>0){
      setDataLogger(newSelectedDataLogger[0])
    }
  }

  const validateInput=()=>{
      let userSchema = Yup.object({
          dataLogger: Yup.string().required("Please Select Data Logger"),
          deviceAddress: Yup.string().required("Please Enter Device Address"),
          deviceType: Yup.string().required("Please Select Device Type"),
          deviceName: Yup.string().required("Please Enter Device Name"),
        });
        userSchema.validate({
           dataLogger:dataLogger?.id,
           deviceAddress,
           deviceType,
           deviceName
        }, { abortEarly: false }).then((value)=>{
           setErrors({})
           const body={
               data: {
                  attributes: {
                      device_name:deviceName,
                      device_type:deviceType,
                      device_Address:Number(deviceAddress),
                      data_logger_id:dataLogger?.id
                  }
                }   
              }
            actionHandler(body)
        }).catch((err)=>{
          let newError:any={};
          err.inner.forEach((item:any)=>{
             newError[item.path]=item.message
          })
          setErrors(newError)
        })
   }

   const getButtonText=()=>{
       if(edit){
        return "Update"
       }else{
        return "Save"
       }
   }


  return (
    <div>
      <Modal
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Paper
          style={{
            width: "680px",
            height: "60vh",
            padding: "20px",
            borderRadius: "20px",
          }}
        >
          <div
            style={{
              padding: "10px",
              height: "100%",
              overflowX: "hidden",
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Typography
                variant="h5"
                style={{ fontWeight: 600, fontSize: "22px" }}
              >
                Add New Device
              </Typography>

              <div className={styles.dividerStyle} />
              <Typography component="h5" className={styles.ashBoldText}>
                Type of files logger:
              </Typography>
               <Select 
                  fullWidth
                  onChange={e=>handleSelectDataLogger(e?.target?.value as number)}
                  className={styles.selectContainer}
                  value={dataLogger?.attributes?.id}
                  >
                    {
                      dataLoggers?.response?.data?.map(logger=>(
                        <MenuItem key={logger.id} value={logger.attributes.id}>{logger.attributes.hardware_s_no}({logger.attributes.data_logger_name})</MenuItem>
                      ))
                    }
                </Select>
                {
                  errors?.dataLogger &&  <p style={{color:"red",marginTop:"10px"}}>{errors?.dataLogger}</p>
                }
               <div className={styles.dividerStyle} />
              <Typography component="h5" className={styles.ashBoldText}>
                Type of devices:
              </Typography>
              <Select
                className={styles.selectContainer}
                fullWidth
                value={deviceType}
                onChange={handleDeviceChange}
              >
                <MenuItem value="inverter">Inverter</MenuItem>
                <MenuItem value="energy_meter">Meter</MenuItem>
                <MenuItem value="sensor">Sensor</MenuItem>
              </Select>
              {
                errors?.deviceType && <p style={{color:"red",marginTop:"10px"}}>{errors?.deviceType}</p>
              }
              <div className={styles.dividerStyle} />
              <br />
              <br />
              <TextField
                value={deviceAddress}
                onChange={(e) => setDeviceAddress(e.target.value)}
                style={{
                  fontWeight: "bolder",
                  fontSize: "1rem",
                  width: "95%",
                }}
                type="text"
                fullWidth
                label={
                  <span className={styles.ashBoldText}>
                    Address of the device
                  </span>
                }
                error={errors?.deviceAddress?true:false}
                helperText={errors?.deviceAddress}
              />
              <br />
              <br />
              <TextField
                value={deviceName}
                onChange={(e) => setDeviceName(e.target.value)}
                style={{
                  fontWeight: "bolder",
                  fontSize: "1rem",
                  width: "95%",
                }}
                fullWidth
                label={<span className={styles.ashBoldText}>Device Name</span>}
                error={errors?.deviceName?true:false}
                helperText={errors?.deviceName}
              />
              <br />
              <br />
            </div>
            {
                  errors?.message &&  <p style={{color:"red",marginTop:"5px",marginBottom:"5px"}}>{errors?.message}</p>
            }
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Button
                  onClick={() => setOpen(false)}
                  fullWidth
                  variant="contained"
                  className={styles.ashButton}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Button
                  disabled={actionLoading}
                  onClick={validateInput}
                  fullWidth
                  variant="contained"
                  className={styles.yellowButton}
                >
                    {actionLoading? <CircularProgress size={24} />: getButtonText()}
                </Button>
              </Grid>
            </Grid>
            <br />
          </div>
        </Paper>
      </Modal>
    </div>
  );
}
