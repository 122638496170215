import React, { useContext } from "react";
interface TransectionContextType {}
const defaultContext: TransectionContextType = {};

export const TransectionContext = React.createContext<TransectionContextType>(
  defaultContext
);

export function useTransectionContext() {
  return useContext(TransectionContext);
}
