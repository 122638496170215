import {
  Button,
  Grid, Paper, Typography
} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import CloseIcon from '@material-ui/icons/Close';
import React from "react";
import { useChatContext } from '../../../blocks/Chat9/src/ChatContext.web';
import { IAllUser } from "../../../blocks/Chat9/src/Interfaces.web";
import ChatModalUserItem from './CreateChatModalUserItem.web';

interface Props{
    open:boolean,
    setOpen:(open:boolean)=>void;
}
const AddNewChatModal=({open,setOpen}:Props)=> {
  const [searchText,setSearchText]=React.useState<string>("");
  const {
    getAllUsers,
    getUserLoading,
    userList,
    selectedDirectChat,
    removeFromDirectChatItem,
    createNewChatLoading,
    createNewDirectMessage
  }=useChatContext();

  React.useEffect(()=>{
    getAllUsers();
  },[])
  
  const filterUser=(e:any)=>{
    const value=e?.target?.value;
    setSearchText(value);
  }
  
  const filterUserItem=(item:IAllUser)=>{
    if(searchText){
      const searchValue = searchText.toLowerCase()
      if(item?.role==="admin"){
        return item?.email?.toLowerCase()?.includes(searchValue) || item?.user_name?.includes(searchValue)
      }else if(item?.role==="client"){
        return item?.email.toLowerCase()?.includes(searchValue)
      }else if(item?.email){
        return item?.email.toLowerCase()?.includes(searchValue)
      }else{
        return false;
      }
    }else{
      return true;
    }
  }
  return (
    <div>
      <Modal
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        
        <Paper
          style={{ width: "550px",height:"75vh", padding: "20px", borderRadius: "20px",overflowY:"auto" }}
        >
          <div style={{ padding: "10px" }}>
            <Typography
              variant="h5"
              style={{ fontWeight: 450, fontSize: "22px" }}
            >
              Add New Chat
            </Typography>

            <div
              style={{
                marginTop: "10px",
                marginBottom: "15px",
                height: "2px",
                width: "100%",
                background: "#f9f9fa",
              }}
            />
            <input
              type="text"
              value={searchText}
               onChange={e=>filterUser(e)}
               className="addnewchat__searchinput"
               placeholder="Search"
              />
            {
              selectedDirectChat?.length>0 &&  
               <div className="addnewchat__selected_user_list">
                 {
                   selectedDirectChat.map(user=>
                    (<div key={user?.id} className="addnewchat__selected_user_item">
                        <h6>
                            {
                              user?.email?user?.email:user?.first_name
                             }
                          </h6>
                        <div onClick={e=>removeFromDirectChatItem(user)} className="addnewchat__selected_user_closebtn">
                          <CloseIcon style={{fontSize:"15px"}}/>
                        </div>
                    </div>
                    )
               )}
                 
              </div>
            }
          

            <div className="addnewchat__item__wraper">
                {
                getUserLoading?<Typography variant="h5">Loading...</Typography>:
                 userList?.filter(filterUserItem).map((user, index) =><ChatModalUserItem key={user?.id} user={user}/> )
                }
            </div>
            

            <Grid container spacing={2} style={{ marginTop: "15px" }}>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <Button
                  onClick={() => setOpen(false)}
                  fullWidth
                  variant="contained"
                  style={{
                    background: "#f9f9fa",
                    marginRight: "10x",
                    borderRadius: "15px",
                    marginTop: "10px",
                    marginBottom: "10px",
                    boxShadow: "none",
                    textTransform: "capitalize",
                    fontWeight: 400,
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <Button
                  onClick={() => createNewDirectMessage()}
                  fullWidth
                  variant="contained"
                  disabled={createNewChatLoading}
                  style={{
                    background: "#FFCE21",
                    marginRight: "10x",
                    borderRadius: "15px",
                    marginTop: "10px",
                    marginBottom: "10px",
                    boxShadow: "none",
                    textTransform: "capitalize",
                    fontWeight: 400,
                  }}
                >
                  {createNewChatLoading?`Creating New Chat`:"Continue"}
                  
                </Button>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Modal>
    </div>
  );
}


export default React.memo(AddNewChatModal);