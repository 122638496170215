export const plantList: any[] = [
  {
    id: 1,
    plantName: "Khalaya No.1",
    locatio: "New York",
    power: 1000,
    production: 854,
    pr: 85,
    installedPower: 10056,
    monitoring: {
      name: "Gupta,Rupal",
      email: "gupta@orianapower",
    },
    operator: {
      name: "Gupta,Rupal",
      email: "gupta@orianapower",
    },
    commissioning: "08/12/2020",
    lastData: "06/10/2021 19:19:24",
  },
  {
    id: 2,
    plantName: "New Delhi Power Plant",
    locatio: "New Delhi",
    power: 500,
    production: 200,
    pr: 150,
    installedPower: 150,
    monitoring: {
      name: "Gupta,Rupal",
      email: "gupta@orianapower",
    },
    operator: {
      name: "Gupta,Rupal",
      email: "gupta@orianapower",
    },
    commissioning: "08/12/2020",
    lastData: "06/10/2021 19:19:24",
  },
  {
    id: 3,
    plantName: "London Plants",
    locatio: "South London",
    power: 200,
    production: 500,
    pr: 130,
    installedPower: 150,
    monitoring: {
      name: "Gupta,Rupal",
      email: "gupta@orianapower",
    },
    operator: {
      name: "Gupta,Rupal",
      email: "gupta@orianapower",
    },
    commissioning: "08/12/2020",
    lastData: "06/10/2021 19:19:24",
  },
  {
    id: 4,
    plantName: "Khalaya No.1",
    locatio: "New York",
    power: 1000,
    production: 854,
    pr: 85,
    installedPower: 10056,
    monitoring: {
      name: "Gupta,Rupal",
      email: "gupta@orianapower",
    },
    operator: {
      name: "Gupta,Rupal",
      email: "gupta@orianapower",
    },
    commissioning: "08/12/2020",
    lastData: "06/10/2021 19:19:24",
  },
  {
    id: 5,
    plantName: "New Delhi Power Plant",
    locatio: "New Delhi",
    power: 500,
    production: 200,
    pr: 150,
    installedPower: 150,
    monitoring: {
      name: "Gupta,Rupal",
      email: "gupta@orianapower",
    },
    operator: {
      name: "Gupta,Rupal",
      email: "gupta@orianapower",
    },
    commissioning: "08/12/2020",
    lastData: "06/10/2021 19:19:24",
  },
  {
    id: 6,
    plantName: "London Plants",
    locatio: "South London",
    power: 200,
    production: 500,
    pr: 130,
    installedPower: 150,
    monitoring: {
      name: "Gupta,Rupal",
      email: "gupta@orianapower",
    },
    operator: {
      name: "Gupta,Rupal",
      email: "gupta@orianapower",
    },
    commissioning: "08/12/2020",
    lastData: "06/10/2021 19:19:24",
  },
  {
    id: 7,
    plantName: "Khalaya No.1",
    locatio: "New York",
    power: 1000,
    production: 854,
    pr: 85,
    installedPower: 10056,
    monitoring: {
      name: "Gupta,Rupal",
      email: "gupta@orianapower",
    },
    operator: {
      name: "Gupta,Rupal",
      email: "gupta@orianapower",
    },
    commissioning: "08/12/2020",
    lastData: "06/10/2021 19:19:24",
  },
  {
    id: 8,
    plantName: "New Delhi Power Plant",
    locatio: "New Delhi",
    power: 500,
    production: 200,
    pr: 150,
    installedPower: 150,
    monitoring: {
      name: "Gupta,Rupal",
      email: "gupta@orianapower",
    },
    operator: {
      name: "Gupta,Rupal",
      email: "gupta@orianapower",
    },
    commissioning: "08/12/2020",
    lastData: "06/10/2021 19:19:24",
  },
  {
    id: 9,
    plantName: "London Plants",
    locatio: "South London",
    power: 200,
    production: 500,
    pr: 130,
    installedPower: 150,
    monitoring: {
      name: "Gupta,Rupal",
      email: "gupta@orianapower",
    },
    operator: {
      name: "Gupta,Rupal",
      email: "gupta@orianapower",
    },
    commissioning: "08/12/2020",
    lastData: "06/10/2021 19:19:24",
  },
  {
    id: 10,
    plantName: "Khalaya No.1",
    locatio: "New York",
    power: 1000,
    production: 854,
    pr: 85,
    installedPower: 10056,
    monitoring: {
      name: "Gupta,Rupal",
      email: "gupta@orianapower",
    },
    operator: {
      name: "Gupta,Rupal",
      email: "gupta@orianapower",
    },
    commissioning: "08/12/2020",
    lastData: "06/10/2021 19:19:24",
  },
  {
    id: 11,
    plantName: "New Delhi Power Plant",
    locatio: "New Delhi",
    power: 500,
    production: 200,
    pr: 150,
    installedPower: 150,
    monitoring: {
      name: "Gupta,Rupal",
      email: "gupta@orianapower",
    },
    operator: {
      name: "Gupta,Rupal",
      email: "gupta@orianapower",
    },
    commissioning: "08/12/2020",
    lastData: "06/10/2021 19:19:24",
  },
  {
    id: 12,
    plantName: "London Plants",
    locatio: "South London",
    power: 200,
    production: 500,
    pr: 130,
    installedPower: 150,
    monitoring: {
      name: "Gupta,Rupal",
      email: "gupta@orianapower",
    },
    operator: {
      name: "Gupta,Rupal",
      email: "gupta@orianapower",
    },
    commissioning: "08/12/2020",
    lastData: "06/10/2021 19:19:24",
  },
];
