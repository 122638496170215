import React, { ReactComponentElement } from "react";
import { ToastContainer } from "react-toastify";
import {
  Typography,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Button,
    FormControlLabel,
    Checkbox
} from "@material-ui/core";
import ClientComparisonItem from "../../../components/src/ClientScreenscomponents/ClientComparisonItem.web";
import SelectClientToCompareController, {
  Props,
  configJSON,
} from "./SelectClientToCompareController.web";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import SelectClientToCompareItem from "../../../components/src/ClientScreenscomponents/SelectClientToCompareItem.web";
import {   
  withStyles,
  createStyles,
  withTheme,
  Theme, } from '@material-ui/core/styles';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));




class SelectClientToCompare extends SelectClientToCompareController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {classes} = this.props;
    const {filterModal}=this.state;
    return (
      <>
        <div className="new__portfolio__report__body__heading">
          <div className="new__portfolio__report__body__heading__left">
            <Typography
              component="h3"
              variant="h5"
              style={{ fontWeight: "bolder" }}
            >
              Select Clients to Compare
            </Typography>
          </div>
          {/* <div>Filter Production</div> */}
          <div>
            <Button
              onClick={this.handleFilterOpen}
              className={classes.ashIconButton}
             >
               <span style={{color:"#b6b6b6",paddingRight:"5px"}}> Filter :</span>  Production
            </Button>
          
            <StyledMenu
              id="selectClientFilterMenu"
              anchorEl={filterModal}
              keepMounted
              open={Boolean(filterModal)}
              onClose={()=>this.handleFilterOpen(null)}
              style={{marginRight:"50px"}}
            >
              {
                ["Production","Power","Irradiance","PR","Specific Yield","Availiability","Energy Performance Index","Revenue"]
                    .map((item,index)=>(
                      <MenuItem key={index}>
                        <FormControlLabel
                          control={
                            <Checkbox
                                // checked={systems?.all}
                                // onChange={handleChecked}
                                color="primary"
                              />
                              }
                              label={
                                <span className={classes.boldLabel}>{item}</span>
                              }
                          />
                    </MenuItem>
                    ))
              }
              
            </StyledMenu>
          </div>



        </div>
        <div className="new__portfolio__report__container">
          <div className="new__portfolio__report__body__container">
            <TableContainer style={{ margin: "10px" }}>
              <Table>
                <TableHead className="tableHeaderContainer">
                  <TableRow>
                    <TableCell
                      style={{
                        minWidth: 200,
                        fontWeight: "bolder",
                        padding: "12px 7px",
                      }}
                    >
                      CLIENT NAME
                    </TableCell>
                    <TableCell
                      style={{
                        minWidth: 150,
                        fontWeight: "bolder",
                        padding: "12px 0px",
                      }}
                    >
                      PRODUCTION
                    </TableCell>
                    <TableCell
                      style={{
                        minWidth: 150,
                        fontWeight: "bolder",
                        padding: "12px 0px",
                      }}
                    >
                      POWER
                    </TableCell>
                    <TableCell
                      style={{
                        minWidth: 150,
                        fontWeight: "bolder",
                        padding: "12px 0px",
                      }}
                    >
                      PR
                    </TableCell>
                    <TableCell
                      style={{
                        minWidth: 200,
                        fontWeight: "bolder",
                        padding: "12px 0px",
                      }}
                    >
                      ENERGY PERFORMANCE<br/> INDEX
                    </TableCell>
                    <TableCell
                      style={{
                        minWidth: 150,
                        fontWeight: "bolder",
                        padding: "12px 0px",
                      }}
                    >
                      PR % JOINT
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* {customerList.map((customer, index) => (
              <ClientTableRow index={index} customer={customer} hover={customer?.status==="normal"?"#8AD0BD":customer?.status==="warnings"?"#FFE696":"#FFAC9C"}/>
            ))} */}

                  {[...new Array(6)].map((item, index) => (
                    <SelectClientToCompareItem />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <div className="selectclient__pagination__container">
              <IconButton
                style={{
                  background: "#f9f9fa",
                  marginRight: "15px",
                  borderRadius: "10px",
                }}
              >
                <ArrowBackIosIcon />
              </IconButton>
              <div className="pagination__text__container">
                <Typography
                  variant="h5"
                  component="p"
                  style={{ fontSize: "17px" }}
                >
                  1/10
                </Typography>
              </div>

              <IconButton
                style={{
                  background: "#f9f9fa",
                  marginLeft: "15px",
                  borderRadius: "10px",
                }}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "29px",
          }}
        >
          <Button
            onClick={()=>this.props.navigation?.goBack()}
            variant="contained"
            style={{
              padding: "10px 40px",
              background: "#f9f9fa",
              borderRadius: "15px",
              boxShadow: "none",
              textTransform: "capitalize",
              fontWeight: 400,
            }}
          >
            Back
          </Button>
          <Button
            onClick={()=>this.props.navigation?.navigate("clientComparison")}
            variant="contained"
            style={{
              padding: "10px 40px",
              marginLeft: "20px",
              background: "#FFCE21",
              borderRadius: "15px",
              boxShadow: "none",
              textTransform: "capitalize",
              fontWeight: 400,
            }}
          >
            Compare
          </Button>
        </div>
        <ToastContainer theme="dark" />
      </>
    );
  }
}


export default withTheme(
  withStyles((theme: Theme) =>
    createStyles({
      row: {
        display: "flex",
        alignItems: "center",
        gap: "8px",
        boxSizing: "border-box",
      },
      ashIconButton:{
        background: "#f9f9fa",
        borderRadius: "15px",
        boxShadow: "none",
        textTransform: "capitalize",
        fontWeight: 400,
        color: "black",
        padding:"10px 15px"
      },
      boldLabel: {
        color: "black",
        fontWeight: 600,
      },
    })
  )(SelectClientToCompare)
);