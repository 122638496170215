import React from "react";
import { Typography, Grid, makeStyles, IconButton } from "@material-ui/core";
import BusinessSharpIcon from "@material-ui/icons/BusinessSharp";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import CustomLineChart from "../Graph/CustomLineChart.web";
import ChartPoint from "../Graph/ChartPoint.web";
import CustomSorting from "../Util/CustomSorting.web";
import CustomBarChart from "../Graph/CustomBarChart.web";

const useStyles = makeStyles({
  shadowColor_cci: {
    color: "#8F92A1",
    fontWeight: "bolder",
  },
  headingLeft_cci: {
    display: "flex",
    gap: "10px",
  },
  headingLeftContent_cci: {
    display: "flex",
    flexDirection: "column",
  },
  iconButtonStyle_cci: {
    background: "#f9f9fa",
  },
});

const ClientComparisonItem = () => {
  const styles = useStyles();
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <div className={styles.headingLeft_cci}>
            <BusinessSharpIcon style={{ color: "black", fontSize: "30px" }} />
            <div className={styles.headingLeftContent_cci}>
              <Typography
                component="h1"
                variant="h5"
                style={{ fontWeight: "bolder" }}
              >
                Client 1
              </Typography>
              <Typography
                component="h1"
                variant="h6"
                className={styles.shadowColor_cci}
                style={{ fontSize: "13px" }}
              >
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <div
            style={{ width: "fit-content", padding: "15px 20px" }}
            className="spinnerContainer"
          >
            <div>
              <h5 style={{ color: "rgb(171 175 177)" }}>Show</h5>
            </div>
            <div>
              <select className="selectContainer">
                <option value={10}>Inverter</option>
              </select>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        alignItems="center"
        style={{ marginTop: "25px" }}
      >
        <Grid item xs={12} sm={12} md={4} xl={4}>
          <h3 style={{ fontSize: "16px" }}>Inverter</h3>
        </Grid>
        <Grid item xs={12} sm={12} md={8} xl={8}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <div>
              <IconButton style={{ background: "#f9f9fa" }}>
                <CalendarTodayIcon />
              </IconButton>
            </div>
            <CustomSorting />
          </div>
        </Grid>
      </Grid>

      {/* space for line chart */}
      <div style={{ width: "100%", height: "300px" }}>
        <CustomLineChart />
      </div>
      <ChartPoint />

      <Grid
        container
        spacing={2}
        alignItems="center"
        style={{ marginTop: "25px" }}
      >
        <Grid item xs={12} sm={12} md={4} xl={4}>
          <h3 style={{ fontSize: "16px" }}>Meter</h3>
        </Grid>
        <Grid item xs={12} sm={12} md={8} xl={8}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <div>
              <IconButton style={{ background: "#f9f9fa" }}>
                <CalendarTodayIcon />
              </IconButton>
            </div>
            <CustomSorting />
          </div>
        </Grid>
      </Grid>

      <div style={{ width: "100%", height: "300px" }}>
        <CustomBarChart />
        <ChartPoint />
      </div>
      <br />
      <br />
      <br />
    </>
  );
};

export default ClientComparisonItem;
