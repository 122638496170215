import {
  Button,
  Paper,
  Typography,
  Grid,
  Select,
  MenuItem,
} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import React from "react";

const dividerStyle = {
  marginTop: "10px",
  marginBottom: "15px",
  height: "2px",
  width: "100%",
  background: "#f9f9fa",
};

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}
export default function GeneratePortfolioReportModal({ open, setOpen }: Props) {
  const [fileFormate, setFileFormate] = React.useState("PDF");
  return (
    <div>
      <Modal
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Paper
          style={{ width: "480px", padding: "20px", borderRadius: "20px" }}
        >
          <div style={{ padding: "10px" }}>
            <Typography
              variant="h5"
              style={{ fontWeight: 600, fontSize: "22px" }}
            >
              Generate a Portfolio Report
            </Typography>

            <div style={dividerStyle} />
            <div className="transection__details__modal__body__container">
              <Typography
                component="h5"
                style={{
                  color: "#8F92A1",
                  fontSize: "15px",
                  marginTop: "10px",
                  marginBottom: "10px",
                  fontWeight: "bolder",
                }}
              >
                To
              </Typography>
              <Typography
                component="h4"
                style={{ fontSize: "16px", fontWeight: "bolder" }}
              >
                name
              </Typography>
              <div style={dividerStyle} />
              {/* <=========From Details=======+> */}
              <Typography
                component="h5"
                style={{
                  color: "#8F92A1",
                  fontSize: "15px",
                  marginTop: "10px",
                  marginBottom: "10px",
                  fontWeight: "bolder",
                }}
              >
                From
              </Typography>
              <Typography
                component="h4"
                style={{ fontSize: "16px", fontWeight: "bolder" }}
              >
                name
              </Typography>
              <div style={dividerStyle} />
              {/* <=========Transaction ID=======+> */}
              <Typography
                component="h5"
                style={{
                  color: "#8F92A1",
                  fontSize: "15px",
                  marginTop: "10px",
                  marginBottom: "10px",
                  fontWeight: "bolder",
                }}
              >
                File Format
              </Typography>
              <Select
                style={{color:"black",fontWeight:"bolder"}}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={fileFormate}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                  setFileFormate(event.target.value as string)
                }
              >
                <MenuItem value="PDF" style={{ fontWeight: "bolder" }}>
                  PDF
                </MenuItem>
                <MenuItem value="Docx" style={{ fontWeight: "bolder" }}>
                  Docx
                </MenuItem>
              </Select>
            </div>
            <br />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Button
                  onClick={() => setOpen(false)}
                  fullWidth
                  variant="contained"
                  style={{
                    background: "#f9f9fa",
                    marginRight: "10x",
                    borderRadius: "15px",
                    marginTop: "10px",
                    marginBottom: "10px",
                    boxShadow: "none",
                    textTransform: "capitalize",
                    fontWeight: 400,
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Button
                  fullWidth
                  variant="contained"
                  style={{
                    background: "#FFCE21",
                    marginRight: "10x",
                    borderRadius: "15px",
                    marginTop: "10px",
                    marginBottom: "10px",
                    boxShadow: "none",
                    textTransform: "capitalize",
                    fontWeight: 400,
                  }}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Modal>
    </div>
  );
}
