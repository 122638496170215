import React from 'react'
import {
  MenuItem, Paper, Select, Table,
  TableBody, TableCell, TableContainer, TableHead, TableRow, Typography
} from "@material-ui/core";
import ClientPageController, {
  Props
} from "./ClientPageController.web";

import {
  createStyles,
  Theme,
  withStyles,
  withTheme
} from "@material-ui/core/styles";
import TableLoader from "../../../components/src/Util/TableLoader.web";
import "../assets/clients.css";
import ClientTableRow from "./ClientTableRow.web";

export interface Customer{
    clientName: string,
    plantLocation: string,
    todayTotalPower: number,
    status: string,
  prJoint: number,
  communication: string,
  onOff:string
}
class ClientPage extends ClientPageController {
  constructor(props: Props) {
    super(props);
  }
  
  render() {
   const{clientList,loading}=this.state;
   const {classes}=this.props;
   return (
     <>
       <div className="client_container">
         <div className="client_header">
           <div>
             <Typography style={{fontSize:"34px",marginTop:"20px",fontWeight:"bold"}} variant="h4">Clients</Typography>
           </div>
           <div className={classes.dropdown_container}>
                <Typography
                    component="h5"
                    variant="h6"
                    className={classes.dropdown__title}
                  >
                 Show :
                </Typography>
                <Select
                   style={{ borderBottom: "0px" }}
                   className={classes.dropdown__main}
                   defaultValue="Week"
                  >
                      <MenuItem value="Week">All Clients</MenuItem>
                      <MenuItem value={20}>Active Clients</MenuItem>
                      <MenuItem value={30}>Recent Clients</MenuItem>
                  </Select>
            </div>
         </div>
         <div className="client_body">
           <Paper
             style={{
               width: "100%",
               height: "100%",
               borderRadius: "15px",
               padding: "20px",
               boxSizing: "border-box",
               overflowY:"auto",
             }}
           >
          <TableContainer style={{ margin: "10px",width:"100%",overflowX:"visible"}}>
            <Table>
              <TableHead className="tableHeaderContainer">
                <TableRow>
                  <TableCell style={{ minWidth: 120,fontWeight:"bolder",padding:"12px 20px" }}>CUSTOMER NAME</TableCell>
                  <TableCell style={{fontWeight:"bolder",padding:"12px 0px" }}>PLANT LOCATIONS</TableCell>
                  <TableCell style={{fontWeight:"bolder" ,padding:"12px 0px"}}>TOAL POWER TODAY (KWP)</TableCell>
                  <TableCell style={{minWidth: 200,fontWeight:"bolder",padding:"12px 0px" }}>STATUS</TableCell>
                  <TableCell style={{minWidth: 150,fontWeight:"bolder",padding:"12px 0px" }}>PR % JOINT</TableCell>
                  <TableCell style={{minWidth: 120,fontWeight:"bolder" ,padding:"12px 0px"}}>COMMUNICATION</TableCell>
                  <TableCell style={{minWidth: 120,fontWeight:"bolder",padding:"12px 5px" }}>ON/OFF</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {
                loading ?<TableLoader count={7} column={7}/>:
                 clientList?.map((customer, index) => (
                  <ClientTableRow navigateToClientDetails={(clientId:string)=>this.goToClientDetailsPage(clientId)} key={""+customer?.attributes.name+index} index={index} customer={customer} hover={"#8AD0BD"}/>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          </Paper>
         </div>
       </div>
     </>
   );
  }
}
export default withTheme(
  withStyles((theme: Theme) =>
    createStyles({
      dropdown_container: {
        display: "flex",
        background: "#f3f2f5",
        borderRadius: "20px",
        padding: "10px 20px",
        alignItems: "center",
      },
      dropdown__title: {
        fontSize: "16px",
        color: "#A19DAF",
        fontWeight: "bolder",
      },
      dropdown__main: {
        marginLeft: "10px",
        fontSize: "18px",
        fontWeight: "bolder",
        "&:hover": {
          borderBottom: "0px",
        },
        "&::before": {
          borderBottom: "0px !important",
        },
      },
    })
  )(ClientPage)
);
