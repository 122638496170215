import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";
import { BlockComponent } from "../../framework/src/BlockComponent";
import AlertBlock from "../../blocks/alert/src/AlertBlock";
import CustomTextItem from "./CustomTextItem";
import NavigationBlock from "../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from "../../framework/src/SingletonFactory";

import HomeScreenAdapter from "../../blocks/adapters/src/HomeScreenAdapter";
import InfoPageAdapter from "../../blocks/adapters/src/InfoPageAdapter";
import AlertPageWebAdapter from "../../blocks/adapters/src/AlertPageWebAdapter";

// Customizable Area Start
import PrivacyPolicyAdapter from "../../blocks/adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../blocks/adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../blocks/adapters/src/SplashScreenAdapter";
import SocialMediaLogInAdapter from "../../blocks/adapters/src/SocialMediaLogInAdapter";
import EmailAccountLogInAdapter from "../../blocks/adapters/src/EmailAccountLogInAdapter";
import EmailAccountSignUpAdapter from "../../blocks/adapters/src/EmailAccountSignUpAdapter";
import ForgotPasswordAdapter from "../../blocks/adapters/src/ForgotPasswordAdapter";
import MobilePhoneToOTPAdapter from "../../blocks/adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../blocks/adapters/src/OtpToNewPasswordAdapter";
import MobilePhoneLogInAdapter from "../../blocks/adapters/src/MobilePhoneLogInAdapter";
import MobilePhoneToAdditionalDetailsAdapter from "../../blocks/adapters/src/MobilePhoneToAdditionalDetailsAdapter";
import EditProfileAdapter from "../../blocks/adapters/src/EditProfileAdapter";
import AdminConsoleAdapter from "../../blocks/adapters/src/AdminConsoleAdapter";
import NotificationAdapter from "../../blocks/adapters/src/NotificationAdapter";
//Assembler generated adapters start

//Assembler generated adapters end

//Assembler generated adapters start

//Assembler generated adapters end

//Assembler generated adapters start
const socialMediaLogInAdapter = new SocialMediaLogInAdapter();
const emailAccountLogInAdapter = new EmailAccountLogInAdapter();
const emailAccountSignUpAdapter = new EmailAccountSignUpAdapter();
const forgotPasswordAdapter = new ForgotPasswordAdapter();
const mobilePhoneToOTPAdapter = new MobilePhoneToOTPAdapter();
const otpToNewPasswordAdapter = new OtpToNewPasswordAdapter();
const mobilePhoneLogInAdapter = new MobilePhoneLogInAdapter();
const mobilePhoneToAdditionalDetailsAdapter = new MobilePhoneToAdditionalDetailsAdapter();
const editProfileAdapter = new EditProfileAdapter();
const adminConsoleAdapter = new AdminConsoleAdapter();
 const notificationAdapter = new NotificationAdapter();

//Assembler generated adapters end

const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
// Customizable Area End

const restAPIBlock = SingletonFactory.getRestBlockInstance();
const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageWebAdapter = new AlertPageWebAdapter();

const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure.",
  // Customizable Area End
});

interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
interface S {}

interface SS {}

class HomeScreen extends BlockComponent<Props, S, SS> {
  static instance: HomeScreen;

  constructor(props: Props) {
    super(props);
    HomeScreen.instance = this;
  }

  render() {
    const { navigation } = this.props;
    const _this = this;

    return (
      <SafeAreaView>
        <ScrollView contentContainerStyle={styles.scrollView} bounces={false}>
          <View style={styles.container}>
            <View style={styles.header}>
              <Text style={styles.welcome}>Welcome to OrianaPower!</Text>
            </View>

            <Text style={styles.instructions}>{instructions}</Text>
            <Text style={styles.header}>DEFAULT BLOCKS</Text>
            <CustomTextItem
              content={"InfoPage"}
              onPress={() => navigation.navigate("InfoPage")}
            />
            <CustomTextItem
              content={"Alert"}
              onPress={() => this.showAlert("Example", "This happened")}
            />
            <CustomTextItem
              content={"SocialMediaAccountRegistrationScreen"}
              onPress={() =>
                navigation.navigate("SocialMediaAccountRegistrationScreen")
              }
            />
            <CustomTextItem
              content={"core"}
              onPress={() =>
                this.showAlert("Error", "Could not determine assembler export")
              }
            />
            <CustomTextItem
              content={"social-media-account"}
              onPress={() =>
                this.showAlert("Error", "Could not determine assembler export")
              }
            />
            <CustomTextItem
              content={"EmailAccountLoginBlock"}
              onPress={() => navigation.navigate("EmailAccountLoginBlock")}
            />
            <CustomTextItem
              content={"EmailAccountRegistration"}
              onPress={() => navigation.navigate("EmailAccountRegistration")}
            />
            <CustomTextItem
              content={"CountryCodeSelector"}
              onPress={() => navigation.navigate("CountryCodeSelector")}
            />
            <CustomTextItem
              content={"ForgotPassword"}
              onPress={() => navigation.navigate("ForgotPassword")}
            />
            <CustomTextItem
              content={"OTPInputAuth"}
              onPress={() => navigation.navigate("OTPInputAuth")}
            />
            <CustomTextItem
              content={"SocialMediaAccountLoginScreen"}
              onPress={() =>
                navigation.navigate("SocialMediaAccountLoginScreen")
              }
            />
            <CustomTextItem
              content={"Contactus"}
              onPress={() => navigation.navigate("Contactus")}
            />
            <CustomTextItem
              content={"PhoneNumberInput"}
              onPress={() => navigation.navigate("PhoneNumberInput")}
            />
            <CustomTextItem
              content={"MobileAccountLoginBlock"}
              onPress={() => navigation.navigate("MobileAccountLoginBlock")}
            />
            <CustomTextItem
              content={"Categoriessubcategories"}
              onPress={() => navigation.navigate("Categoriessubcategories")}
            />
            <CustomTextItem
              content={"Sorting"}
              onPress={() => navigation.navigate("Sorting")}
            />
            <CustomTextItem
              content={"Catalogue"}
              onPress={() => navigation.navigate("Catalogue")}
            />
            <CustomTextItem
              content={"Filteritems"}
              onPress={() => navigation.navigate("Filteritems")}
            />
            <CustomTextItem
              content={"StoreLocator"}
              onPress={() => navigation.navigate("StoreLocator")}
            />
            <CustomTextItem
              content={"PhotoLibrary3"}
              onPress={() => navigation.navigate("PhotoLibrary3")}
            />
            <CustomTextItem
              content={"Scheduling"}
              onPress={() => navigation.navigate("Scheduling")}
            />
            <CustomTextItem
              content={"Prioritise"}
              onPress={() => navigation.navigate("Prioritise")}
            />
            <CustomTextItem
              content={"InvoiceBilling"}
              onPress={() => navigation.navigate("InvoiceBilling")}
            />
            <CustomTextItem
              content={"Pushnotifications"}
              onPress={() => navigation.navigate("Pushnotifications")}
            />
            <CustomTextItem
              content={"RefundManagement"}
              onPress={() => navigation.navigate("RefundManagement")}
            />
            <CustomTextItem
              content={"AddAppointment"}
              onPress={() => navigation.navigate("AddAppointment")}
            />
            <CustomTextItem
              content={"Appointments"}
              onPress={() => navigation.navigate("Appointments")}
            />
            <CustomTextItem
              content={"WeatherApi32"}
              onPress={() => navigation.navigate("WeatherApi32")}
            />
            <CustomTextItem
              content={"WeatherApi22"}
              onPress={() => navigation.navigate("WeatherApi22")}
            />
            <CustomTextItem
              content={"WeatherApi12"}
              onPress={() => navigation.navigate("WeatherApi12")}
            />
            <CustomTextItem
              content={"Sms2"}
              onPress={() => navigation.navigate("Sms2")}
            />
            <CustomTextItem
              content={"UserGroups"}
              onPress={() => navigation.navigate("UserGroups")}
            />
            <CustomTextItem
              content={"RecommendationEngine4"}
              onPress={() => navigation.navigate("RecommendationEngine4")}
            />
            <CustomTextItem
              content={"TextOrExcelFileImport2"}
              onPress={() => navigation.navigate("TextOrExcelFileImport2")}
            />
            <CustomTextItem
              content={"FingerPrintLogin2"}
              onPress={() => navigation.navigate("FingerPrintLogin2")}
            />
            <CustomTextItem
              content={"Calendar"}
              onPress={() => navigation.navigate("Calendar")}
            />
            <CustomTextItem
              content={"AdminConsole3"}
              onPress={() => navigation.navigate("AdminConsole3")}
            />
            <CustomTextItem
              content={"DataImportexportcsv"}
              onPress={() => navigation.navigate("DataImportexportcsv")}
            />
            <CustomTextItem
              content={"FullTeamVisibility2"}
              onPress={() => navigation.navigate("FullTeamVisibility2")}
            />
            <CustomTextItem
              content={"RolesPermissions2"}
              onPress={() => navigation.navigate("RolesPermissions2")}
            />
            <CustomTextItem
              content={"TwilioIntegration2"}
              onPress={() => navigation.navigate("TwilioIntegration2")}
            />
            <CustomTextItem
              content={"FileAttachment"}
              onPress={() => navigation.navigate("FileAttachment")}
            />
            <CustomTextItem
              content={"Notifications"}
              onPress={() => navigation.navigate("Notifications")}
            />
            <CustomTextItem
              content={"VisualAnalytics"}
              onPress={() => navigation.navigate("VisualAnalytics")}
            />
            <CustomTextItem
              content={"PaymentAdmin2"}
              onPress={() => navigation.navigate("PaymentAdmin2")}
            />
            <CustomTextItem
              content={"FormApprovalWorkflow"}
              onPress={() => navigation.navigate("FormApprovalWorkflow")}
            />
            <CustomTextItem
              content={"EmailNotifications"}
              onPress={() => navigation.navigate("EmailNotifications")}
            />
            <CustomTextItem
              content={"ReceiptCustomisation"}
              onPress={() => navigation.navigate("ReceiptCustomisation")}
            />
            <CustomTextItem
              content={"Groups"}
              onPress={() => navigation.navigate("Groups")}
            />
            <CustomTextItem
              content={"RepeatingTasks"}
              onPress={() => navigation.navigate("RepeatingTasks")}
            />
            <CustomTextItem
              content={"TaskAllocator"}
              onPress={() => navigation.navigate("TaskAllocator")}
            />
            <CustomTextItem
              content={"TaskGroups"}
              onPress={() => navigation.navigate("TaskGroups")}
            />
            <CustomTextItem
              content={"TaskList"}
              onPress={() => navigation.navigate("TaskList")}
            />
            <CustomTextItem
              content={"TaskViews"}
              onPress={() => navigation.navigate("TaskViews")}
            />
            <CustomTextItem
              content={"TeamBuilder"}
              onPress={() => navigation.navigate("TeamBuilder")}
            />
            <CustomTextItem
              content={"ApiIntegration8"}
              onPress={() => navigation.navigate("ApiIntegration8")}
            />
            <CustomTextItem
              content={"AdHocReporting"}
              onPress={() => navigation.navigate("AdHocReporting")}
            />
            <CustomTextItem
              content={"Analytics3"}
              onPress={() => navigation.navigate("Analytics3")}
            />
            <CustomTextItem
              content={"AdvancedSearch"}
              onPress={() => navigation.navigate("AdvancedSearch")}
            />
            <CustomTextItem
              content={"CameraAccess"}
              onPress={() => navigation.navigate("CameraAccess")}
            />
            <CustomTextItem
              content={"Chat9"}
              onPress={() => navigation.navigate("Chat9")}
            />
            <CustomTextItem
              content={"CustomForm2"}
              onPress={() => navigation.navigate("CustomForm2")}
            />
            <CustomTextItem
              content={"ContextTagging"}
              onPress={() => navigation.navigate("ContextTagging")}
            />
            <CustomTextItem
              content={"ActivityLog"}
              onPress={() => navigation.navigate("ActivityLog")}
            />
            <CustomTextItem
              content={"FilterSms2"}
              onPress={() => navigation.navigate("FilterSms2")}
            />
            <CustomTextItem
              content={"FacialRecognitionLogin2"}
              onPress={() => navigation.navigate("FacialRecognitionLogin2")}
            />
            <CustomTextItem
              content={"CentralDbServerApi2"}
              onPress={() => navigation.navigate("CentralDbServerApi2")}
            />
            <CustomTextItem
              content={"Analytic2"}
              onPress={() => navigation.navigate("Analytic2")}
            />
            <CustomTextItem
              content={"LandingPage2"}
              onPress={() => navigation.navigate("LandingPage2")}
            />

            <CustomTextItem
              content={"ApiIntegration"}
              onPress={() => navigation.navigate("ApiIntegration")}
            />
            <CustomTextItem
              content={"Analytics"}
              onPress={() => navigation.navigate("Analytics")}
            />
            <CustomTextItem
              content={"Customform"}
              onPress={() => navigation.navigate("Customform")}
            />
            <CustomTextItem
              content={"LandingPage"}
              onPress={() => navigation.navigate("LandingPage")}
            />

            <CustomTextItem
              content={"UserProfileBasicBlock"}
              onPress={() => navigation.navigate("UserProfileBasicBlock")}
            />
          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const styles = StyleSheet.create({
  scrollView: {
    flexGrow: 1,
    height: Platform.OS === "web" ? "100vh" : "auto",
    backgroundColor: "#F5FCFF",
  },
  container: {
    flex: 1,
  },
  welcome: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: "bold",
    color: "white",
  },
  instructions: {
    textAlign: "center",
    color: "#6200EE",
    marginBottom: 5,
    fontWeight: "bold",
    fontSize: 16,

    padding: 10,
  },
  button: {
    backgroundColor: "#6200EE",
    padding: 15,
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
  },
  header: {
    backgroundColor: "#6200EE",
    padding: 15,
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
  },
  item: {
    backgroundColor: "#00000000",
    padding: 18,
    color: "#6200EE",
    fontSize: 16,
    fontWeight: "normal",
  },
});
// Customizable Area End
export default HomeScreen;
