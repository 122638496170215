import React from "react";
import { Typography,TextField ,makeStyles,FormControlLabel,Checkbox,Select,MenuItem,Button} from "@material-ui/core";

import {useNewPortfolioReportContext} from '../../../blocks/LandingPage2/src/Reports/NewPortfolioReportContext.web'
const useStyles=makeStyles({
   inputPlaceholder:{
     color:"#8F92A1",
     fontWeight:"bolder"
   },
    boldLabel: {
    color: "black",
    fontWeight: 600,
  },
})
const GeneralSettingPage = () => {
  const {
    changeActiveStep
    ,goTo
    ,name,
    allSystem,
    specificSystem,
    filter,
    systemId,
    handleNameChange:setName,
    description,
    systemList,
    edit,
    handleDescriptionChange:setDescription,
    handleSystemSelected:setSelectedSystem,
    handleGeneralSettingsItemChecked:setItemChecked,
    generalSettingErrors:errors
  }=useNewPortfolioReportContext()
  const styles=useStyles();
  

  const selectedSystem=systemList?.data?.find(item=>item.id==systemId);
  return (
    <>
          <TextField 
              style={{width:"60%",minWidth:"200px"}}
              value={name}
              onChange={(e)=>setName(e.target.value)}
               id="standard-basic"
              label={<span className={styles.inputPlaceholder}>Name</span>}
              error={errors?.name===undefined || errors?.name===""?false:true}
              helperText={errors?.name}
            />
            <br/>
            <br/>
            
             <TextField 
              style={{width:"60%",minWidth:"200px"}}
              value={description}
              onChange={(e)=>setDescription(e.target.value)}
               id="standard-basic"
              label={<span className={styles.inputPlaceholder}>Description</span>}
              error={errors?.description===undefined || errors?.description===""?false:true}
              helperText={errors?.description}
            />
            <br/>
            <br/>
            <p className={styles.inputPlaceholder}>System Selection</p>
            <br/>
            <FormControlLabel
              control={
                <Checkbox
                  name="all"
                  checked={allSystem}
                  onChange={(e)=>setItemChecked("allSystem",e.target.checked)}
                  color="primary"
                />
              }
               label=  { <span className={styles.boldLabel}>All</span>}
            />
             <br/>
            <FormControlLabel
              control={
                <Checkbox
                  name="systems"
                  checked={specificSystem}
                  onChange={(e)=>setItemChecked("specificSystem",e.target.checked)}
                  color="primary"
                />
              }
              label=  { <span className={styles.boldLabel}>Systems</span>}
            /> 
             {
               edit && systemId && selectedSystem && <>
                  <h3>
                    {selectedSystem.attributes?.designation},{selectedSystem?.attributes?.street}-{selectedSystem.attributes?.postal_code}
                  </h3>
                  <br/>
               </>
             }


            <div style={{marginLeft:"20px",marginTop:"10px",marginBottom:"10px",width:"60%",minWidth:"200px"}}>
               <Select
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={systemId}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                  setSelectedSystem(event.target.value as string)
                }
              >
                {
                  systemList?.data?.map(item=>(
                    <MenuItem key={item?.id} value={item?.id}>{item.attributes?.designation},{item?.attributes?.street}-{item.attributes?.postal_code}</MenuItem>
                  ))
                }
              </Select>
            </div>
            <FormControlLabel
              control={
                <Checkbox
                   name="filter"
                  checked={filter}
                  onChange={(e)=>setItemChecked("filter",e.target.checked)}
                  color="primary"
                />
              }
              label=  { <span className={styles.boldLabel}>Filter</span>}
            />
            <div style={{display: "flex",justifyContent: "flex-end",marginTop:"29px"}}>
               <Button
                onClick={()=>goTo("portfolioReport")}
                  variant="contained"
                  style={{
                    padding:"10px 40px",
                    background: "#f9f9fa",
                    borderRadius: "15px",
                    boxShadow: "none",
                    textTransform: "capitalize",
                    fontWeight: 400,
                  }}
                >
                  Back
                </Button>
                <Button
                  onClick={()=>changeActiveStep(1)}
                  variant="contained"
                  style={{
                    padding:"10px 40px",
                    marginLeft:"20px",
                    background: "#FFCE21",
                    borderRadius: "15px",
                    boxShadow: "none",
                    textTransform: "capitalize",
                    fontWeight: 400,
                  }}
                >
                  Next
                </Button>
            </div>  
    </>
  );
};

export default GeneralSettingPage;
