import React from "react";
import { Button, makeStyles, TextField, IconButton } from "@material-ui/core";
import ReplayIcon from "@material-ui/icons/Replay";
import FilterListIcon from "@material-ui/icons/FilterList";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

const useStyles = makeStyles({
  buttonDarkYellow: {
    textTransform: "capitalize",
    background: "#FDCE32",
    boxShadow: "none",
    borderRadius: "15px",
    marginRight: "10px",
    padding: "0px 15px 0px 15px",
    height: "40px",
    color: "black",
    fontWeight: "normal",
    minWidth: "120px",
    "&:hover": {
      background: "#FDCE32",
    },
  },
  buttonAsh: {
    textTransform: "capitalize",
    background: "#F9F9FA",
    boxShadow: "none",
    borderRadius: "15px",
    marginRight: "10px",
    padding: "0px 15px 0px 15px",
    height: "40px",
    color: "black",
    fontWeight: "normal",
    minWidth: "100px",
    "&:hover": {
      background: "#F9F9FA",
    },
  },
  ashIconButton: {
    width: "40px",
    height: "40px",
    background: "#f9f9fa",
    borderRadius: "15px",
    margin: "0px 10px",
  },
  formFieldInput: {
    minWidth: "450px",
  },
});

const ImportOverview = (props: any) => {
  const styles = useStyles();
  return (
    <div className="wrapper-container">
      <div className="page-inner-header">
        <h3>
          {props.isShowImportSurvery
            ? "Import Survey"
            : "Data Logger Server Contact"}
        </h3>
        <div>
          <Button
            className={styles.buttonDarkYellow}
            startIcon={<FilterListIcon />}
          >
            Filter
          </Button>
          <Button className={styles.buttonAsh} startIcon={<ReplayIcon />}>
            Start
          </Button>
        </div>
      </div>

      <div className="content-container">
        {!props.isShowImportSurvery && (
          <div>
            <p className="guide-information-text">
              By entering the hardware serial number of a data logger or its
              relevent ID, you can check if a data logger has communicated with
              the portal and when. Therefore, the seraial number has to be
              entered carefully, including space characters. Upper and lower
              case will be ignored, You can search retrospectively for the last
              seven days.
            </p>

            <form noValidate autoComplete="off" className="form-container">
              <TextField
                id="EnterSerialNumber"
                label="Enter Serial Number"
                className={styles.formFieldInput}
              />
            </form>

            <Button
              className={styles.buttonDarkYellow}
              onClick={() => props.handleSearchDataLogger()}
            >
              Search
            </Button>
          </div>
        )}

        {props.isShowImportSurvery && (
          <div>
            <div className="client_list_table">
              <table>
                <thead>
                  <tr>
                    <th>
                      {" "}
                      <h5 style={{ fontWeight: "bold", marginLeft: "10px" }}>
                        SYSTEM / DATA LOGGER
                      </h5>
                    </th>
                    <th>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <div>
                          {" "}
                          <h5 style={{ fontWeight: "bold" }}>
                            REFRENCE NUMBER
                          </h5>
                        </div>

                        <div />
                      </div>
                    </th>
                    <th>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <div>
                          {" "}
                          <h5 style={{ fontWeight: "bold" }}>DATA SOURCE</h5>
                        </div>

                        <div />
                      </div>
                    </th>
                    <th>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <div>
                          {" "}
                          <h5 style={{ fontWeight: "bold" }}>LAST FAILURE</h5>
                        </div>

                        <div />
                      </div>
                    </th>
                    <th>
                      <h5 style={{ fontWeight: "bold" }}>STATUS</h5>
                    </th>
                    <th>
                      <h5 style={{ fontWeight: "bold" }}>19/10</h5>
                    </th>
                    <th>
                      <h5 style={{ fontWeight: "bold" }}>20/10</h5>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {props.impoerSurveryData.map((data: any, index: number) => (
                    <tr key={index}>
                      <td>
                        <div>
                          <h2
                            style={{ marginLeft: "10px" }}
                            className="itemTxt"
                          >
                            {data.dataLogger}
                          </h2>
                        </div>
                      </td>
                      <td>
                        <div>
                          <h2 className="itemTxt">{data.renfenceNo}</h2>
                        </div>
                      </td>
                      <td>
                        <div>
                          <h2 className="itemTxt">{data.dataSource}</h2>
                        </div>
                      </td>
                      <td>
                        <div>
                          <h2 className="itemTxt">{data.lastFailure}</h2>
                        </div>
                      </td>
                      <td>
                        <div>
                          <h2
                            className={
                              "itemTxt " +
                              (data.status === "Open" ? "status-open" : "")
                            }
                          >
                            {data.status}
                          </h2>
                        </div>
                      </td>
                      <td>
                        <div
                          className={
                            "status-container" +
                            (data.firstNo === 100
                              ? " status-success"
                              : data.firstNo < 100 && data.firstNo < 50
                              ? " status-warning"
                              : " status-error")
                          }
                        >
                          <h2 className="itemTxt">{data.firstNo}</h2>
                        </div>
                      </td>
                      <td>
                        <div
                          className={
                            "status-container" +
                            (data.secondNo === 100
                              ? " status-success"
                              : data.secondNo < 100 && data.secondNo < 50
                              ? " status-warning"
                              : " status-error")
                          }
                        >
                          <h2 className="itemTxt">{data.secondNo}</h2>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="tablePagination">
              <IconButton className={styles.ashIconButton} aria-label="delete">
                <ArrowBackIosIcon />
              </IconButton>
              <div className="paginationContent">
                <h2 style={{ fontSize: "14px" }}>1 / 10</h2>
              </div>
              <IconButton className={styles.ashIconButton} aria-label="delete">
                <ArrowForwardIosIcon />
              </IconButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImportOverview;
