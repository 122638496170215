import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import {  toast } from 'react-toastify';
import { UserGroup } from "../user/Interfaces";

export const configJSON = require("../config");

export interface Props {
  navigation: any;
  id: string;
}


export interface ClientListResponseType{
   response:{
     data:any[]
   },
   length:number
}



interface S {
    clientsData:ClientListResponseType;
    userGroupList:UserGroup[],
    getUserGroupsLoading:boolean,
    getClientsLoading: boolean,
    addClientModal:boolean,
    setClientLoading:boolean,
    clientSystemsModal: boolean,
    clientSystemsData: any[],
    clientSystemDataLoading: boolean,
    deleteClientConfimation: boolean,
    clientId: any,
    deleteSystemLoading:boolean;
    updateClientDialog:boolean;
    clientPage:number,
    loadedClientPage:number,
    clientUsersDialog:boolean,
    clientUsersList:any[],
    getClientUserLoading:boolean,
    createUserModal:boolean,
    updateUserModal:boolean,
    deleteUserModal:boolean,
    createUserLoading:boolean,
    updateUserLoading:boolean,
    deleteUserLoading:boolean,
    clientCreated: boolean;
    clientErrors:any;
    deleteClientLoading:boolean;
    userCreateError:any;
}

interface SS {
  id: any;
}

export default class ClientsController extends BlockComponent<Props, S, SS> {
  apiCreateNewClientCallId: string = "";
  apiUpdateClientCallId: string = "";
  apiResetGetAllClientCallId: string = "";
  apiResetGetClientSystemCallId: string = "";
  apiDeleteClientCallId: string = "";
  apiDeleteSystemCallId: string = "";
  apiCreateNewUserCallId: string = "";
  apiGetClientUserCallId: string = "";
  apiGetUserGroupCallId: string = "";
  apiUpdateUserCallId: string = "";
  apiDeleteUserCallId: string = "";
  idSystemClients: number = 0;
  selectedClientId: number = 0;
  clientPerPage:number=6;
  userDeleteId:any="";
  updateUserClientId:any="";
  deleteUserClientId:any="";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      clientsData:{
        response:{
          data:[]
        },
        length:0
      },
      getClientsLoading: true,
       userGroupList:[],
       getUserGroupsLoading:false,
       addClientModal:false,
       setClientLoading:false,
       clientSystemsModal: false,
       clientSystemsData: [],
       clientSystemDataLoading: false,
       deleteClientConfimation: false,
       clientId: 0,
       deleteSystemLoading:false,
       updateClientDialog:false,
       clientPage:1,
       loadedClientPage:1,
       clientUsersDialog:false,
       getClientUserLoading:false,
       clientUsersList:[],
       createUserModal:false,
       updateUserModal:false,
       deleteUserModal:false,
       createUserLoading:false,
       updateUserLoading:false,
       deleteUserLoading:false,
       clientCreated:false,
       clientErrors:{},
       deleteClientLoading:false,
       userCreateError:{}
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
       const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson === undefined) {
        return;
      } else if (
        responseJson &&
        responseJson.errors &&
        responseJson.errors[0].token
      ) {
        this.props.navigation.navigate("EmailAccountLoginBlock");
        return;
      }

       if( this.apiResetGetAllClientCallId ===apiRequestCallId){
         this.handleGetAllClientResponse(responseJson);
      }
      if( this.apiCreateNewClientCallId ===apiRequestCallId){
         this.handleAddNewClientsResponse(responseJson);
      }
       if( this.apiUpdateClientCallId ===apiRequestCallId){
         this.handleUpdateClientResponse(responseJson);
      }
      if( this.apiResetGetClientSystemCallId ===apiRequestCallId){
         this.handleGetClientSystemResponse(responseJson);
      }
      if( this.apiDeleteClientCallId ===apiRequestCallId){
         this.handleDeleteClientResponse(responseJson);
      }
       if( this.apiDeleteSystemCallId ===apiRequestCallId){
         this.handleDeleteSystemResponse(responseJson);
      } 
       if( this.apiGetClientUserCallId ===apiRequestCallId){
        this.setState({clientUsersList:responseJson?.data?.data,getClientUserLoading:false})
      }
      if(this.apiGetUserGroupCallId===apiRequestCallId){
        this.handleGetAllUserGroupResponse(responseJson);
      } 
      if(this.apiCreateNewUserCallId===apiRequestCallId){
        this.handleCreateUserResponse(responseJson);
      }
      if(this.apiUpdateUserCallId===apiRequestCallId){
        this.handleUpdateUserResponse(responseJson);
      }
       if(this.apiDeleteUserCallId===apiRequestCallId){
        this.handleDeleteUserResponse(responseJson);
      }
  }

  handleGetAllClientResponse=(responseJson:any)=>{
      if(responseJson?.response){
            this.setState(
                (prevState,props)=>{
                     const nClient:ClientListResponseType={
                      response:{
                          data:prevState?.clientPage===1?[...responseJson.response.data]:[...prevState.clientsData.response.data,...responseJson.response.data]
                         },
                          length:responseJson?.length
                        }
                        return {
                         clientsData:nClient,
                         getClientsLoading:false,
                          }
                      }
                  );
        }
  }
  handleDeleteUserResponse=(responseJson:any)=>{
    this.setState(
          (prevState,props)=>{
            const newUserList=prevState.clientUsersList?.filter(u=>u.id!==this.userDeleteId);
            return {
               deleteUserLoading:false,
               clientUsersList:newUserList,
               deleteUserModal:false
              }
            }
          );
       this.getClients();
      toast(responseJson?.message)
  }
  handleUpdateUserResponse=(responseJson:any)=>{
     if(responseJson?.data?.id){
        this.setState({
          updateUserLoading:false,
          updateUserModal:false
        })
        this.getClientUser(this.updateUserClientId);
        toast("User Updated Successful")
      }else if(responseJson?.errors){
        this.setState({
          updateUserLoading:false
        })
        responseJson?.errors[0]?.user?.map((er:string)=>{
          toast.warn(er);
        })
      
      }
  }
  handleCreateUserResponse=(responseJson:any)=>{
    if(responseJson?.data){
        this.setState(
          (prevState,props)=>{
            const nUser:any[]=[{...responseJson.data},...prevState.clientUsersList]
            return {
              clientUsersList:nUser,
              createUserLoading:false,
              createUserModal:false,
            }
          });
          this.getClients();
          toast("User Created Successful")
      }else if(responseJson?.errors){
       
        const newErrors:string[]=responseJson.errors[0]?.user?.map((er:string)=>{
          toast(er);
          return er;
        })
       this.setState({
          createUserLoading:false,
          userCreateError:{
            message:newErrors?.join(",\n")
          }
        })
      }else if(responseJson?.message){
      toast.error(responseJson.message);
      this.setState({
          createUserLoading:false,
          userCreateError:{
            message:responseJson.message
          }
      })
    }
  }   
  handleGetAllUserGroupResponse=(responseJson:any)=>{
    if(responseJson?.response?.data){
        this.setState({
            getUserGroupsLoading:false,
            userGroupList:responseJson?.response?.data,
        })
      }
  } 
  handleDeleteSystemResponse=(responseJson:any)=>{
      this.setState({deleteSystemLoading:false,deleteClientConfimation:false})
      this.openClientSystems(this.idSystemClients);
      this.getClients();
      toast("System Deleted")
  } 
  handleUpdateClientResponse=(responseJson:any)=>{
      if(responseJson?.data){
        this.setState({
          addClientModal:false,
          updateClientDialog:false,
          setClientLoading:false,
          
        });
        toast("Client Updated Successful")
        this.getClients();
      }    
  }
  handleAddNewClientsResponse=(responseJson:any)=>{
      if(responseJson?.errors){
          toast.error(""+responseJson?.errors);
            this.setState({
            setClientLoading:false,
            clientErrors:{
              userName:responseJson?.errors
            }
          });
          return;
        }
        if(responseJson?.data){
          this.setState({
            addClientModal:false,
            setClientLoading:false,
            clientCreated:true,
            clientErrors:{}
          });
          toast("Client Added Successful.")
          this.getClients();
        }else{
          
          this.setState({
            setClientLoading:false,
            clientErrors:{}
          })
        }
  } 
  handleGetClientSystemResponse=(responseJson:any)=>{
      this.setState({clientSystemDataLoading: false});
      if(responseJson?.data?.data){
        this.setState({clientSystemsData: [...this.state.clientSystemsData, ...responseJson?.data?.data]});
      }  
  }  
  handleDeleteClientResponse=(responseJson:any)=>{
      this.setState({
          deleteClientLoading:false,
          deleteClientConfimation:false,
        })
      if(responseJson?.error){
        toast(responseJson?.error);
        return;
      }
      if(responseJson?.success===true){
       
        this.getClients();
        toast("Client Deleted")
      }else{
        toast.error("Client Delete Failed.")
      }
    
  }
 



  async componentDidMount() {
      this.getClients();
      this.getUserGroups();
  }

  getClients=(page?:number)=> {
    let callPage=1;
    if(page){
      callPage=page;
    }

     if(page!=null && page<=this.state.loadedClientPage){
      this.setState({
          clientPage:callPage,
      })
      return;
    }



    this.setState({
      clientPage:callPage,
      getClientsLoading:true,
      loadedClientPage:callPage
    })
    const hitUrl=`${configJSON.getClientDetailsApiUrl}?per_page=${this.clientPerPage}&page=${callPage}`
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiResetGetAllClientCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      hitUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getClientUser=(clientId:string)=> {
    this.setState({
      getClientUserLoading:true,
      clientUsersDialog:true,
    })
    const hitUrl=`${configJSON.clientUsersApiUrl}?id=${clientId}`
    
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetClientUserCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      hitUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getUserGroups = async() => {
    this.setState({
      getUserGroupsLoading:true
    })
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetUserGroupCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserGroupsApiUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getClientSystem(clientId: any) {
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiResetGetClientSystemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getClientSystemsApiUrl}?id=${clientId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  changeClientModal=(open:boolean)=>{
    this.setState({
      addClientModal:open
    })
  }
  changeClientUpdateModal=(open:boolean)=>{
    this.setState({
      updateClientDialog:open
    })
  }
//<=========ADD NEW CLIENTS FORM DATA================>

  setNewClients=(value:any)=> {
    this.setState({
      setClientLoading:true,
      clientCreated:false,
    })
    const userToken = localStorage.getItem("authToken");
    const header = {
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCreateNewClientCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addNewClientApiUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      value
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  updateClients=(value:any,clientId:string)=> {
    this.setState({
      setClientLoading:true
    })
    const userToken = localStorage.getItem("authToken");
    const header = {
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateClientCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateClientApiUrl}/${clientId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      value
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  deleteSystems=(value:any)=> {
    this.setState({
      deleteSystemLoading:true
    })
    const userToken = localStorage.getItem("authToken");
    const header = {
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteSystemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteSystemApiUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      value
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  openClientSystems = (clientId: number) => {
    this.setState({clientSystemsData: [], clientSystemDataLoading: true});
    this.getClientSystem(clientId);
    this.idSystemClients=clientId;
    this.setState({clientSystemsModal: true});
  }
  closeClientSystems = () => {
    this.setState({clientSystemsModal: false});
  }
  changeClientUserModal=(open:boolean)=>{
    this.setState({
      clientUsersDialog:open
    })
  }
  addNewClientSystem = (clientId: any) => {
    this.props.navigation.navigate("AddNewSystem",{
      clientId:clientId
    });
    // const msg: Message = new Message(
    //   getName(MessageEnum.NavigationNewClientSystemMessage)
    // );
    // msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    // msg.addData(
    //   getName(MessageEnum.NavigationRaiseMessage),
    //   clientId
    // );
    // this.send(msg);
  }
  deleteClient = (clientId: number) => {
    this.selectedClientId = clientId;
    this.setState({deleteClientConfimation: true});
  }
  cancelDeleteClient = () => {
    this.setState({deleteClientConfimation: false});
  }
  confirmDeleteClient =() => {
    this.setState({
      deleteClientLoading:true,
    })
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteClientCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteClientApiUrl}?id=${this.selectedClientId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  /*USER OPERATIONS*/
  createNewUser=(newUser:any)=>{
    this.setState({
      createUserLoading:true
    })
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };

    const httpBody={
      data:newUser
    }


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCreateNewUserCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createNewUserApiUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createNewUserMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  updateUser=(newUser:any,userId:string,clientId:string)=>{
    this.updateUserClientId=clientId;
    this.setState({
      updateUserLoading:true
    })
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };

    const httpBody={
      data:newUser
    }


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateUserCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateUserApiUrl}/${userId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  deleteUser=(userId:string,clientId:string)=>{
    this.userDeleteId=userId;
    this.deleteUserClientId=clientId;
    this.setState({
      deleteUserLoading:true
    })
  const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteUserCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteUserApiUrl}/${userId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteUserMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  changeCreateUserModal=(open:boolean)=>{
    this.setState({
      createUserModal:open,
      userCreateError:{}
    })
  }
  changeUpdateUserModal=(open:boolean)=>{
    this.setState({
      updateUserModal:open,
      userCreateError:{}
    })
  }
  changeDeleteUserModal=(open:boolean)=>{
    this.setState({
      deleteUserModal:open
    })
  }



}
