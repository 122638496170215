import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
export const configJSON = require("../config");

export interface Props {
  navigation?: any;
  id?: string;
  children?: JSX.Element | JSX.Element[] | string | string[];
}

interface S {
  currentUser: any;
  loading: boolean;
}

interface SS {
  id: any;
}

export default class AuthProviderController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetCurrentUserCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      currentUser: {},
      loading: true,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: String, message: Message) {
    //<===============response for get current user============>
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiGetCurrentUserCallId &&
      this.apiGetCurrentUserCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && responseJson.errors && responseJson.errors[0].token) {
        localStorage.removeItem("authToken");
        this.setState({ loading: false });
        return;
      }
      //disable loading
      this.setState({
        loading: false,
        currentUser: responseJson,
      });
    }
  }

  async componentDidMount() {
    this.getCurrentUser();
  }

  getCurrentUser = async () => {
    //const hitUrl=`${configJSON.getApprovalSystemApiUrl}`
  // Customizable Area Start
     this.setState({
      loading:true,
      currentUser:{}
     })
  // Customizable Area End
  // Customizable Area Start
  const userToken = localStorage.getItem("authToken");
  console.log("Getting Current User---====>",userToken)
  // Customizable Area End
   const header = {
      "Content-Type": configJSON.jsonContentType,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCurrentUserCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCurrentUserApiUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  isSuperAdmin = () => {
    const {currentUser}=this.state;
    let value:boolean=false;

    if(currentUser?.status===200 && currentUser?.account?.role==="admin"){
      value=true;
    }
    return value;
  };
  isClient = () => {

    const {currentUser}=this.state;
    let value:boolean=false;

    if(currentUser?.status===200 && currentUser?.account?.account?.role==="client"){
      value=true;
    }
    return value;
  };
  isLoggedIn = () => {
    const {currentUser}=this.state;
    let value:boolean=false;
  // Customizable Area Start
    if(currentUser?.status===200 && (currentUser?.data?.email || currentUser?.account?.email)){
  // Customizable Area End
      value=true;
    }
    return value;
  };
  // Customizable Area Start
  checkAuthorization=(forSuperAdmin:boolean,forWebAdmin:boolean,navigation:any)=>{
    const {loading}=this.state;
    if(this.isLoggedIn() && !loading){
     if(forSuperAdmin && !forWebAdmin){
        if(!this.isSuperAdmin()){
            window.location.href="/webClientLandingPage"
        }
     }

     if(forWebAdmin && !forSuperAdmin){
        if(!this.isClient()){
            navigation?.navigate("/LandingPage2")
        }
     }

    }

    if(!loading && !this.isLoggedIn()){
      navigation?.navigate("/")
    }
   
  }
  logoutUser=(): boolean => {
     localStorage.clear();
     this.setState({
      currentUser:{}
     })
     return true;
  }
  // Customizable Area End

}
