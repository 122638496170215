import React from "react";
import { useState } from "react";
import {
  Checkbox,
  makeStyles,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
interface State {
  check: boolean;
}

interface StyledType {
  hover: string;
}

const useStyles = makeStyles({
  tableRow: {
    borderRadius: "10px",
    padding: "10px",
    marginTop: "20px",
    "&:hover": {
      background: "#FFE696",
      cursor: "pointer",
    },
  },
  tableData: {
    margin: "0",
    padding: "0",
    color: "black",
    fontWeight: "bold",
  },
});

const SelectClientToCompareItem = () => {
  const [check, setCheck] = useState<boolean>(false);
  const styles = useStyles({});
  return (
    <TableRow
      style={
        check ? { background: "#FFE696", height: "85px" } : { height: "85px" }
      }
      className={styles.tableRow}
    >
      <TableCell padding="checkbox">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Checkbox
            value={check}
            onChange={(e) => setCheck(e.target.checked)}
            color="primary"
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
          <Typography
            style={{
              marginLeft: "10px",
              color: "#005AD1",
              fontWeight: "bolder",
            }}
          >
            Client Name
          </Typography>
        </div>
      </TableCell>
      <TableCell component="th" scope="row" padding="none">
        <p className={styles.tableData}>21.2Kwp</p>
      </TableCell>
      <TableCell component="th" scope="row" padding="none">
        <p className={styles.tableData}>21.2Kwp</p>
      </TableCell>
      <TableCell component="th" scope="row" padding="none">
        <p className={styles.tableData}>21.2Kwp</p>
      </TableCell>
      <TableCell style={{ minWidth: 200, padding: "0" }}>
        <p className={styles.tableData}>21.2 Kwp</p>
      </TableCell>
      <TableCell style={{ minWidth: 200, padding: "0" }}>
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <p style={{ textAlign: "center", width: "120px" }}>50%</p>
          <div className="bar">
            <span style={{ width: `${50}%` }} />
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default SelectClientToCompareItem;
