Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.getApiMethodType = "GET";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage2";
exports.labelBodyText = "LandingPage2 Body";
exports.getAllClientAPIURL = "bx_block_dashboard/dashboards/get_all_clients";
exports.getDashboardAPIURL = "bx_block_dashboard/dashboards/get_clients_count";
exports.btnExampleTitle = "CLICK ME";
exports.createPortfolioApiUrl = "bx_block_portfolio/portfolios";
exports.getSinglePortfolioApiUrl = "bx_block_portfolio/portfolios";
exports.getAllPortfolioReportApiUrl = "bx_block_portfolio/portfolios";
exports.getAllSystemListApiUrl = "bx_block_client/get_all_system";
exports.postApiMethodType = "POST";
exports.getApiMethodType = "GET";
exports.patchApiMethodType = "PATCH";
exports.deleteApiMethodType = "DELETE";
exports.getSystemApiUrl = "bx_block_client/get_systems_by_id";
exports.contentType = "application/json";
exports.createUserFromClientCallId = "bx_block_client/create_users_by_client";
exports.getAllUserGroupCallId = "bx_block_usergroups/user_groups/";
exports.getFtpInverterDataApiUrl = "bx_block_ftp_data/get_inverter_data";
exports.getFtpMeterDataApiUrl = "bx_block_ftp_data/get_meter_data";
exports.getClientUserApiURl = "bx_block_client/get_users_by_client";
exports.getMainGraphDataURL = "bx_block_ftp_data/get_graph_data";
exports.deleteUserApiUrl = "bx_block_client/users";
exports.updateUserApiUrl = "bx_block_client/users";
exports.putApiMethodType = "PUT";
// Customizable Area End
