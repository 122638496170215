import React from "react";
import { ToastContainer } from "react-toastify";
import {
  withStyles,
  createStyles,
  withTheme,
  Theme,
} from "@material-ui/core/styles";
import ClientSettingController, { Props } from "./ClientSettingController.web";

import { MenuItem, Select, Tab, Tabs, Typography } from "@material-ui/core";
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import BarChartOutlinedIcon from "@material-ui/icons/BarChartOutlined";
import DataSources from "../../../components/src/client-setting/DataSources.web";
import OverviewTable from "../../../components/src/client-setting/OverviewTable.web";
import AddNewDeviceModal from "../../../components/src/client-setting/AddNewDeviceModal.web";
import MeterTable from "../../../components/src/client-setting/MeterTable.web";
import Inverter from "../../../components/src/client-setting/Inverter.web";
import DataLogger from "../../../components/src/client-setting/DataLogger.web";
import MonitoringPage from "../../../components/src/client-setting/Monitoring.web";
import {ClientSettingContext} from './ClientSettingContext.web'



interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          <div>{children}</div>
        </div>
      )}
    </div>
  );
}
const StyledTabs = withStyles({ indicator: { backgroundColor: "orange" } })(
  Tabs
);

class ClientSetting extends ClientSettingController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    const { activeTab, secondActiveTab, addNewDeviceModal,allDataLogggerList,selectedDataLogger } = this.state;
    return (
      <ClientSettingContext.Provider value={{ ...this, ...this.state }}>
        <div className={classes.client__content__container}>
          <div className={classes.row}>
            <BarChartOutlinedIcon
              style={{ color: "#f2dd00", fontSize: "50px" }}
            />
            <ArrowForwardIosOutlinedIcon style={{ fontSize: "20px" }} />
            <Typography
              component="h4"
              variant="h5"
              className={classes.blackBoldText}
            >
              Reliance
            </Typography>
            <ArrowForwardIosOutlinedIcon style={{ fontSize: "20px" }} />
            <Typography
              component="h4"
              variant="h5"
              className={classes.blackBoldText}
            >
              CHIRAGENCLAVE_KSKV
            </Typography>
          </div>

          <div className={classes.tab__container}>
            {/* tab one  */}
            <div>
              <StyledTabs
                value={activeTab}
                onChange={(e, newValue) => this.changeTab(newValue)}
                className={classes.tab__pannel}
              >
                <Tab label="System" className={classes.tab__label} />
                <Tab label="Data Sources" className={classes.tab__label} />
                <Tab label="Monitoring" className={classes.tab__label} />
                <Tab label="Calculations" className={classes.tab__label} />
                <Tab
                  label="Administrations"
                  className={classes.tab__label}
                />
              </StyledTabs>
              <TabPanel value={activeTab} index={0}>
                <DataSources
                  switchToOverview={this.changeSecondTab}
                  changeAddNewDeviceModal={this.changeAddNewDeviceModal}
                />
              </TabPanel>
              <TabPanel value={activeTab} index={1}>
                <DataSources
                  switchToOverview={this.changeSecondTab}
                  changeAddNewDeviceModal={this.changeAddNewDeviceModal}
                />
              </TabPanel>
              <TabPanel value={activeTab} index={2}>
                <MonitoringPage />
              </TabPanel>
            </div>
            {/* end of the tab one */}
            <br />
            <div style={{display:"flex",alignItems:"center",gap:"15px"}}>
                <Typography style={{fontSize:"20px",fontWeight:600}}>WEBDYN</Typography>
                <ArrowForwardIosOutlinedIcon/>
                <Select onChange={e=>this.handleSelectDataLogger(e.target.value as number)} className={classes.selectContainer} value={selectedDataLogger?.attributes?.id}>
                {
                  allDataLogggerList.response?.data?.map(logger=>(
                    <MenuItem value={logger.attributes.id}>{logger.attributes.hardware_s_no}({logger.attributes.data_logger_name})</MenuItem>
                  ))
                }
              
              </Select>
            </div>
            
            <br />
            {activeTab !== 2 && (
              <>
                {/* tab two  */}
                <div>
                  <StyledTabs
                    value={secondActiveTab}
                    onChange={(e, newValue) =>
                      this.changeSecondTab(newValue)
                    }
                    className={classes.tab__pannel}
                  >
                    <Tab label="Overview" className={classes.tab__label} />
                    <Tab
                      label="Data Logger"
                      className={classes.tab__label}
                    />
                    <Tab label="Inverter" className={classes.tab__label} />
                    <Tab label="Meter" className={classes.tab__label} />
                    <Tab label="Sensor" className={classes.tab__label} />
                  </StyledTabs>
                  <TabPanel value={secondActiveTab} index={0}>
                    <OverviewTable />
                  </TabPanel>
                  <TabPanel value={secondActiveTab} index={1}>
                    <DataLogger />
                  </TabPanel>
                  <TabPanel value={secondActiveTab} index={2}>
                    <Inverter
                      changeAddNewDeviceModal={this.changeAddNewDeviceModal}
                      selectedDataLogger={selectedDataLogger}
                      dataLoggers={allDataLogggerList}
                    />
                  </TabPanel>
                  <TabPanel value={secondActiveTab} index={3}>
                    <MeterTable 
                        title="Meter"
                        filterBy="energy_meter"
                        selectedDataLogger={selectedDataLogger}
                        dataLoggers={allDataLogggerList}
                        deviceList={this.state.deviceList}
                        loader={this.state.getDataLoggerDeviceLoading}
                        handleDeleteModal={this.handleDeleteDeviceModal}
                        deleteDevice={this.deleteDataLoggerDevice}
                        deleteLoading={this.state.deleteDataLoggerDeiveLoading}
                        deleteModal={this.state.deleteDeviceModal}
                    />
                  </TabPanel>
                  <TabPanel value={secondActiveTab} index={4}>
                    <MeterTable 
                        title="Sensor"
                        filterBy="sensor"
                        selectedDataLogger={selectedDataLogger}
                        dataLoggers={allDataLogggerList}
                        deviceList={this.state.deviceList}
                        loader={this.state.getDataLoggerDeviceLoading}
                        handleDeleteModal={this.handleDeleteDeviceModal}
                        deleteDevice={this.deleteDataLoggerDevice}
                        deleteLoading={this.state.deleteDataLoggerDeiveLoading}
                        deleteModal={this.state.deleteDeviceModal}
                    />
                  </TabPanel>
                </div>
              </>
            )}
            {/* end of the tab two */}
            {/* tab three  */}
            {/* <div>
              <StyledTabs
                value={thirdActiveTab}
                onChange={(e, newValue) => this.changeThirdTab(newValue)}
                className={classes.tab__pannel}
              >
                <Tab label="Overview" className={classes.tab__label} />
                <Tab label="Data Logger" className={classes.tab__label} />
                <Tab label="Inverter" className={classes.tab__label} />
                <Tab label="Data Device" className={classes.tab__label} />
              </StyledTabs>
              <TabPanel value={thirdActiveTab} index={0}>
                <OverviewTable />
              </TabPanel>
              <TabPanel value={thirdActiveTab} index={1}>
                <DataLogger />
              </TabPanel>
              <TabPanel value={thirdActiveTab} index={2}></TabPanel>
            </div> */}
            {/* end of the tab two */}
          </div>
        </div>
        {
          addNewDeviceModal &&  
          <AddNewDeviceModal
          edit={false}
          actionLoading={this.state.createDataLoggerDeviceLoading}
          actionHandler={this.createDataLoggerDevice}
          open={addNewDeviceModal}
          setOpen={this.changeAddNewDeviceModal}
          dataLoggers={allDataLogggerList}
          newError={this.state.deviceCreateError}
        />
        }
       
        <ToastContainer theme="dark" />
      </ClientSettingContext.Provider>
    );
  }
}

export default withTheme(
  withStyles((theme: Theme) =>
    createStyles({
      row: {
        display: "flex",
        alignItems: "center",
        gap: "8px",
        boxSizing: "border-box",
      },
      blackBoldText: {
        fontSize: ".85rem",
        color: "black",
        fontWeight: "bolder",
      },
      iconButton: {
        background: "#f9f9fa",
      },
      darkIconButton: {
        background: "#f3f2f5",
      },
      client__content__container: {
        background: "#f9f9fa",
        borderRadius: "15px",
        padding: "30px",
        marginTop: "25px",
      },
      selectContainer: {
        border: "none",
        fontSize: "1.2rem",
        fontWeight: "bolder",
        color: "black",
        "&:hover": {
          borderBottom: "0px",
        },
        "&::before": {
          borderBottom: "0px !important",
        },
        "&::after": {
          borderBottom: "0px !important",
        },
        "&$selected": {
          borderBottom: "0px !important",
        },
      },
      tab__container: {
        background: "white",
        borderRadius: "25px",
        padding: "20px",
        boxSizing: "border-box",
        marginTop: "20px",
      },
      tab__pannel: {
        borderBottom: "1px solid #f9f9fa",
        "&$selected": {
          color: "#1890ff",
          fontWeight: theme.typography.fontWeightMedium,
        },
        "&:focus": {
          color: "#40a9ff",
        },
      },
      tab__label: {
        fontWeight: 600,
        color: "black",
        textTransform: "capitalize",
        paddingBottom: "10px",
      },
    })
  )(ClientSetting)
);
