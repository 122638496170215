import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import {UserGroup,ClientType,UserGroupResponseType} from "./Interfaces"
export const configJSON = require("../config");
import {toast } from 'react-toastify';
import { Notification } from "../../../Notifications/src/Notification2Controller.web";

export interface Props {
  navigation: any;
  id: string;
  theme: any;
}


export interface UserCreateType{
  user_name:string;
  password?:string;
  confirm_password?:string;
  email:string;
  user_group:string;
  name:string;
  client_id:string;
  contact:string;
  client_name:string;
  role:string;
}

export interface User{
  id?:string;
  type?:string;
  attributes?:{
    user_name?:string,
    email?:string,
    user_group?:string|null,
    name?:string|null,
    client_id?:number,
    client_name?:string|null,
    contact?:string|null
  }
}

export interface UserListResponseType{
   response:{
     data:User[]
   },
   length:number
}
export interface ClientListResponseType{
   response:{
     data:ClientType[]
   },
   length:number
}
export interface IPrivilegeActionItem{
    id:number;
    name:string;
    value:string;
}
export interface IPrivilege{
    id:number;
    name:string;
    objName:string;
    permissions:{
        view:string;
        delete:string;
        change:string;
        create:string;
    }
}

export interface INewUserGroup{
     groupName:string;
     groupDescription:string;
     editors:string[];
     privileges:IPrivilege[]
}



interface S {
  value: number;
  userModal: boolean;
  updateUserModal:boolean;
  userList:UserListResponseType,
  userGroupList:UserGroup[],
  allClientList:ClientType[],
  clientList:ClientListResponseType,
  getUserLoading:boolean,
  createUserLoading:boolean,
  getUserGroupsLoading:boolean,
  getClientsLoading:boolean,
  deleteUserLoading:boolean,
  userPage:number,
  loadedUserPage:number,
  getAllClientLoading:boolean,
  addedClientPage:number,
  loadedAddedClientPage:number,
  pUserGroupList:UserGroupResponseType,
  loadedGroupPage:number,
  groupPage:number,
  editUserGroupModal:boolean,
  addUserGroupModal:boolean,
  updateUserGroupLoading:boolean,
  getPaginatedGroupLoading:boolean,
  getEditorLoading:boolean,
  editorList:any[],
  notifiedUser:Notification|null,
  userCreateError:any,
  newGroup:INewUserGroup,
  createUserGroupError:any,
}

interface SS {
  id: any;
}


const DENY="deny";
const ALLOW="allow";

const groupInitialState:INewUserGroup={
         groupName:"",
         groupDescription:"",
         editors:[],
         privileges:[
                  {
                    id:1,
                    name:"Default Module",
                    objName:"default_module",
                    permissions:{
                        view:DENY,
                        create:DENY,
                        change:DENY,
                        delete:DENY,
                    }
                  },
                  {
                    id:2,
                    name:"Monitoring",
                    objName:"monitoring",
                    permissions:{
                        view:DENY,
                        create:DENY,
                        change:DENY,
                        delete:DENY,
                    }
                  },
                  {
                    id:3,
                    name:"Ticket System",
                    objName:"ticket_system",
                    permissions:{
                        view:DENY,
                        create:DENY,
                        change:DENY,
                        delete:DENY,
                    }
                  },
                  {
                    id:4,
                    name:"Evaluation",
                    objName:"evaluation",
                    permissions:{
                        view:DENY,
                        create:DENY,
                        change:DENY,
                        delete:DENY,
                    }
                  },
                  {
                    id:5,
                    name:"Analysis",
                    objName:"analysis",
                    permissions:{
                        view:DENY,
                        create:DENY,
                        change:DENY,
                        delete:DENY,
                    }
                  },
                  {
                    id:6,
                    name:"Calendar",
                    objName:"calendar",
                    permissions:{
                        view:DENY,
                        create:DENY,
                        change:DENY,
                        delete:DENY,
                    }
                  },
                  {
                    id:7,
                    name:"Documents",
                    objName:"documents",
                    permissions:{
                        view:DENY,
                        create:DENY,
                        change:DENY,
                        delete:DENY,
                    }
                  },
                  {
                    id:8,
                    name:"Reports",
                    objName:"reports",
                    permissions:{
                        view:DENY,
                        create:DENY,
                        change:DENY,
                        delete:DENY,
                    }
                  },
                  {
                    id:9,
                    name:"System visualization",
                    objName:"system_visualization",
                    permissions:{
                        view:DENY,
                        create:DENY,
                        change:DENY,
                        delete:DENY,
                    }
                  },
                  {
                    id:10,
                    name:"CSV export",
                    objName:"csv_export",
                    permissions:{
                        view:DENY,
                        create:DENY,
                        change:DENY,
                        delete:DENY,
                    }
                  },
                  {
                    id:11,
                    name:"Portfolio reports",
                    objName:"portfolio_reports",
                    permissions:{
                        view:DENY,
                        create:DENY,
                        change:DENY,
                        delete:DENY,
                    }
                  },
                ]
      }

export default class UserController extends BlockComponent<Props, S, SS>{
  apiResetGetAllClientCallId: string = "";
  userPerPage:number=7;
  clientPerPage:number=7;
  apiGetAllUserCallId: string = "";
  apiCreateNewUserCallId:string="";
  apiDeleteUserCallId:string="";
  apiGetUserGroupCallId:string="";
  apiGetPaginatedUserGroupCallId:string="";
  apiGetClientListCallId:string="";
  apiGetALLClientListCallId:string="";
  apiUpdateUserGroupCallId:string="";
  apiGetEditorsCallId:string="";
  apiCreateUserGroupCallId:string="";
  apiUpdateUserCallId:string="";
  userDeleteId:any="";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      value: 0,
      userModal: false,
      userList:{
        response:{
          data:[]
        },
        length:0
      },
      clientList:{
        response:{
          data:[]
        },
        length:0
      },
      pUserGroupList:{
        response:{
          data:[]
        },
        length:0,
        numOfItem:6
      },
      userGroupList:[],
      getUserLoading:true,
      createUserLoading:false,
      getUserGroupsLoading:true,
      getClientsLoading:true,
      deleteUserLoading:false,
      updateUserModal:false,
      userPage:1,
      loadedUserPage:1,
      addedClientPage:1,
      loadedGroupPage:1,
      groupPage:1,
      allClientList:[],
      getAllClientLoading:true,
      loadedAddedClientPage:1,
      editUserGroupModal:false,
      updateUserGroupLoading:false,
      addUserGroupModal:false,
      getPaginatedGroupLoading:false,
      getEditorLoading:false,
      editorList:[],
      notifiedUser:null,
      userCreateError:{},
      newGroup:groupInitialState,
      createUserGroupError:{}

    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  

  async receive(from: string, message: Message) {
    //<=====RECEIVE DATA FROM NOTIFICATION ===========>
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const notification = message.getData(
        getName(MessageEnum.FromNotificationDataMessage)
      );

      this.setState({
        notifiedUser: notification
      });
    }

    const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

    const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    if (responseJson === undefined) {
        return;
    }else if (
        responseJson &&
        responseJson.errors &&
        responseJson.errors[0].token
      ) {
        this.props.navigation.navigate("EmailAccountLoginBlock");
        return;
    }

    if( this.apiGetAllUserCallId ===apiRequestCallId){
        this.handleGetAllUserResponse(responseJson)
    } 
    if( this.apiGetPaginatedUserGroupCallId ===apiRequestCallId){
        this.handleGetPaginatedUserGroupResponse(responseJson)
    }
    if( this.apiGetUserGroupCallId ===apiRequestCallId){
        this.handleGetAllUserGroupResponse(responseJson)
    }
    if( this.apiGetClientListCallId ===apiRequestCallId){
        this.handleGetPaginatedClientResponse(responseJson)
    } 
    if( this.apiGetALLClientListCallId ===apiRequestCallId){
        this.handleGetAllClientResponse(responseJson);
    }
    if( this.apiCreateNewUserCallId ===apiRequestCallId){
        this.handleCreateUserResponse(responseJson);
    }
    if(this.apiUpdateUserCallId===apiRequestCallId){
       this.handleUpdateUserResponse(responseJson)
    } 
    if(this.apiDeleteUserCallId===apiRequestCallId){
       this.handleDeleteUserResponse(responseJson)
    }
    if(this.apiUpdateUserGroupCallId===apiRequestCallId){
       this.handleUpdateUserGroupResponse(responseJson)
    }
    if(this.apiCreateUserGroupCallId===apiRequestCallId){
       this.handleCreateUserGroupResponse(responseJson)
    } 
    if(this.apiGetEditorsCallId===apiRequestCallId){
       this.setState({
        getEditorLoading:false,
        editorList:responseJson
      })
    }
  }

  async componentDidMount() {
        this.getUserLists();
        this.getClientLists();
        this.getPaginatedUserGroups();
        this.getAllClient();
        this.getUserGroups();
        this.getAllEditors();
  }



//response handler
 handleGetAllUserResponse=(responseJson:any)=>{
  if(responseJson&&responseJson.response){
     this.setState(
          (prevState,props)=>{
            const nUser:UserListResponseType={
                response:{
                  data:prevState?.userPage===1?[...responseJson.response.data]:[...prevState.userList.response.data,...responseJson.response.data]
                },
                length:responseJson?.length
            }
            return {
              userList:nUser,
              getUserLoading:false,
              }
            }
      );    
    }else{
      this.setState({getUserLoading:false})
    }
  }
  handleGetPaginatedUserGroupResponse=(responseJson:any)=>{
    this.setState((prevState,props)=>{
        const nUserGroups:UserGroupResponseType={
            response:{
                  data:prevState?.groupPage===1?[...responseJson.response.data]:[...prevState.pUserGroupList.response.data,...responseJson.response.data]
            },
            length:responseJson?.length,
            numOfItem:prevState.pUserGroupList.numOfItem
          }
        return {
          pUserGroupList:nUserGroups,
          getPaginatedGroupLoading:false,
          }
        }
     );
  } 
  handleGetAllUserGroupResponse=(responseJson:any)=>{
     if(responseJson?.response?.data){
        this.setState({
            getUserGroupsLoading:false,
            userGroupList:responseJson?.response?.data,
        })
      }else{
        this.setState({
           getUserGroupsLoading:false,
        })
      }
  }
  handleGetPaginatedClientResponse=(responseJson:any)=>{
       if(responseJson?.response){
        this.setState(
          (prevState,props)=>{
            const nClient:ClientListResponseType={
              response:{
                    data:prevState?.addedClientPage===1?[...responseJson.response.data]:[...prevState.clientList.response.data,...responseJson.response.data]
              },
              length:responseJson?.length
              }
              return {
                clientList:nClient,
                getClientsLoading:false,
              }
           });
      }else{
        this.setState({
          getClientsLoading:false,
        })
      }
  }
  handleGetAllClientResponse=(responseJson:any)=>{
      if(responseJson?.response){
        this.setState({
            getAllClientLoading:false,
            allClientList:responseJson?.response?.data
        })
      }else{
        this.setState({
            getAllClientLoading:false,
        })
      }
  } 
  handleCreateUserResponse=(responseJson:any)=>{
    if(responseJson?.data){
        this.setState(
          (prevState,props)=>{
            const nUser:UserListResponseType={
                response:{
                  data:[{...responseJson.data},...prevState.userList.response.data]
                },
                length:prevState.userList.length+1,
               
            }
            return {
              userList:nUser,
                createUserLoading:false,
                userModal:false,
                userCreateError:{}
              }
            }
          );
          this.getClientLists();
          toast("User Created Successful")
     }else if(responseJson?.errors){
        
        const newErrors:string[]=responseJson.errors[0]?.user?.map((er:string)=>{
          toast(er);
          return er;
        })
        this.setState({
          createUserLoading:false,
          userCreateError:{
             message:newErrors?.join(",\n")
          }
        })
      
    }else if(responseJson?.message){
      toast.error(responseJson.message);
      this.setState({
          createUserLoading:false,
          userCreateError:{
            message:responseJson.message
          }
      })
    }
    
  }
  handleUpdateUserResponse=(responseJson:any)=>{
    if(responseJson?.data?.id){
        this.setState({
          createUserLoading:false,
          updateUserModal:false
        })
        this.getUserLists();
        toast("User Updated Successful")
    }else if(responseJson?.errors){
        this.setState({
          createUserLoading:false
        })
        responseJson?.errors[0]?.user?.map((er:string)=>{
          toast.warn(er);
        })
   }
    
  }  
  handleDeleteUserResponse=(responseJson:any)=>{
    this.setState(
      (prevState,props)=>{
         const newUserList=prevState.userList?.response?.data?.filter(u=>u.id!==this.userDeleteId);
         const nList:UserListResponseType={
            response:{
              data:newUserList
            },
              length:prevState.userList.length-1
          }
         return {
            deleteUserLoading:false,
            userList:nList
            }
         });
     toast(responseJson?.message)
    
  }  
  handleUpdateUserGroupResponse=(responseJson:any)=>{
    if(responseJson?.data){
        toast(`User Group Updated Successful.`)
        this.getPaginatedUserGroups();
        this.setState({
          editUserGroupModal:false,
          updateUserGroupLoading:false
        })
       this.getUserGroups();
    }else{
      this.setState({
        updateUserGroupLoading:false
      })
    }
    
  } 
  handleCreateUserGroupResponse=(responseJson:any)=>{
   if(responseJson?.data){
        toast(`User Group Created Successful.`)
        this.getPaginatedUserGroups();
        this.setState({
            addUserGroupModal:false,
            updateUserGroupLoading:false,
            createUserGroupError:{}
          })
        this.getUserGroups(); 
   }else{
    this.setState({
       updateUserGroupLoading:false,
       createUserGroupError:{}
    })
   }
    
  }


  //handle user group ui changes
changeGroupName=(name:string)=>{
    this.setState(prevState=>({
      newGroup:{
        ...prevState.newGroup,
        groupName:name
      }
    }))
}
changeGroupDescription=(desc:string)=>{
    this.setState(prevState=>({
      newGroup:{
        ...prevState.newGroup,
        groupDescription:desc
      }
    }))
}

changeRootPrivileges=(id:number,allow:boolean)=>{
  this.setState(prevState=>{
      const newPrivileges=prevState.newGroup.privileges.map((priv:IPrivilege)=>{
          if(priv.id===id){
             const action=allow?ALLOW:DENY;
             const newPriv:IPrivilege={
                  ...priv,
                  permissions:{
                    create:action,
                    change:action,
                    delete:action,
                    view:action
                  }
             }
             return newPriv;
          }else{
            return priv
          }
      })
      return{
         newGroup:{
           ...prevState.newGroup,
           privileges:newPrivileges
         }
      }
  })
}
chngeSubPrivileges=(id:number,key:string,value:string)=>{
  this.setState(prevState=>{
      const newPrivileges=prevState.newGroup.privileges.map((priv:IPrivilege)=>{
         //@ts-ignore
         const keyName = key as keyof typeof privilege.permissions;
      
          if(priv.id===id){
             const newPriv:IPrivilege={
                  ...priv,
                  permissions:{
                     ...priv.permissions,
                     [keyName]:value
                  }
             }
             return newPriv;
          }else{
            return priv
          }
      })
      return{
         newGroup:{
           ...prevState.newGroup,
           privileges:newPrivileges
         }
      }
  })

}
changeNewGroupEditors=(editor:string)=>{
  this.setState((prevState)=>{
     const editors=prevState.newGroup.editors;
     const prevEditor= editors.filter(ed=>ed===editor);
     return{
       newGroup:{
         ...prevState.newGroup,
         editors:prevEditor?.length>0?editors.filter(ed=>ed!==editor):[...editors,editor]
       }
     }
  })
}




  getUserLists = (page?:number) => {
    let callPage=1;
    if(page){
      callPage=page;
    }

    if(page!=null && page<=this.state.loadedUserPage){
      this.setState({
          userPage:callPage,
      })
      return;
    }



    this.setState({
      userPage:callPage,
      getUserLoading:true,
      loadedUserPage:callPage
    })
    const hitUrl=`${configJSON.getAllUserApiUrl}?per_page=${this.userPerPage}&page=${callPage}`
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetAllUserCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      hitUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getPaginatedUserGroups = (page?:number) => {
    let callPage=1;
    if(page){
      callPage=page;
    }

    if(page!=null && page<=this.state.loadedGroupPage){
      this.setState({
          groupPage:callPage,
      })
      return;
    }



    this.setState({
      groupPage:callPage,
      getPaginatedGroupLoading:true,
      loadedGroupPage:callPage
    })
    const hitUrl=`${configJSON.getUserGroupsApiUrl}?per_page=${this.state.pUserGroupList.numOfItem}&page=${callPage}`
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetPaginatedUserGroupCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      hitUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getUserGroups = async() => {
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetUserGroupCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserGroupsApiUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getClientLists = async(page?:number) => {
    let callPage=1;
      if(page){
        callPage=page;
      }


    if(page!=null && page<=this.state.loadedAddedClientPage){
      this.setState({
          addedClientPage:callPage,
      })
      return;
    }


      this.setState({
        addedClientPage:callPage,
        getClientsLoading:true,
        loadedAddedClientPage:callPage
      })
      const hitUrl=`${configJSON.getClientDetailsApiUrl}?per_page=${this.clientPerPage}&page=${callPage}`
    

    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetClientListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      hitUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getAllClient = async() => {
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetALLClientListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getClientDetailsApiUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getAllEditors = async() => {
    this.setState({
      getEditorLoading:true,
      
    })
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetEditorsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getEditorsApiUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleChange=(event: React.ChangeEvent<{}>, newValue: number)=> { 
    this.setState({
      value: newValue,
    });
  }
  changeUserModal=(open: boolean)=> {
    this.setState({
      userModal: open,
      userCreateError:{}
    });
  }
  changeUpdateUserModal=(open: boolean)=> {
    this.setState({
      updateUserModal: open,
      userCreateError:{}
    });
  }
  createNewUser=(newUser:UserCreateType)=>{
    this.setState({
      createUserLoading:true
    })
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };

    const httpBody={
      data:newUser
    }


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCreateNewUserCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createNewUserApiUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createNewUserMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  updateUser=(newUser:UserCreateType,userId:string)=>{
    this.setState({
      createUserLoading:true
    })
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };

    const httpBody={
      data:newUser
    }


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateUserCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateUserApiUrl}/${userId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  deleteUser=(userId?:string)=>{
    this.userDeleteId=userId;
    this.setState({
      deleteUserLoading:true
    })
  const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteUserCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteUserApiUrl}/${userId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteUserMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

 parseData=(data:string)=>{
    if(data && typeof data !== 'object'){
      const r=data.replaceAll("=>",":")
      let json=JSON.parse(r)
      let newData={
        ...json
      }
      return newData;
    }else{
      return data
    }
  }

  changeEditUserGroupModal=(open:boolean,group?:any)=>{
    console.log("Group",group)
    if(open){
      this.setState((prevState)=>{
          const updatedUserGroupPriviliges=prevState.newGroup.privileges.map(item=>{
              return{
                ...item,
                ...this.parseData(group?.attributes[item.objName])
              }
          })
          console.log("Updated Group",updatedUserGroupPriviliges)
          return{
            editUserGroupModal:open,
            newGroup:{
              groupName:group?.attributes?.user_group,
              groupDescription:group?.attributes?.description,
              editors:group?.attributes?.editors?.length>0?group?.attributes?.editors:[],
              privileges:updatedUserGroupPriviliges
            }
          }
      });
  }
  if(!open){
      this.setState({
        newGroup:groupInitialState,
        editUserGroupModal:open
      })
    }
  }
  changeAddUserGroupModal=(open:boolean)=>{
    this.setState({
      addUserGroupModal:open,
      newGroup:groupInitialState
    })
  }
 updateUserGroup=(value:any,groupId:string)=> {
    this.setState({
      updateUserGroupLoading:true
    })
    const userToken = localStorage.getItem("authToken");
    const header = {
       "Content-Type": configJSON.contentType,
      token: userToken,
    };



    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateUserGroupCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateUserGroupApiUrl}/${groupId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(value)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
 }

 checkUserGroupInput=(groupId?:string)=>{
   const {newGroup}=this.state;
    let newError:any={}

    if(!newGroup.groupName){
      newError.userGroups="Please Enter User Group Name."
    }
    if(!newGroup.groupDescription){
      newError.description="Please Enter User Group Desc."
    }
    if(newGroup.editors.length===0){
      newError.editors="Please Select Some Editor."
    }

    if(newGroup.groupName && newGroup.groupDescription && newGroup.editors.length>0){
        //made actual body

        const permissions:any={};

        newGroup.privileges.forEach(item=>{
           permissions[item.objName]=item.permissions
        })

        const body={
          user_group:newGroup.groupName,
          description:newGroup.groupDescription,
          editors:newGroup.editors,
          ...permissions
        }  
        console.log("Group ID-->",groupId)
        if(groupId){
          this.updateUserGroup(body,groupId)
        }else{
           this.createNewUserGroup(body)
        }
      
    }else{
      this.setState({
        createUserGroupError:newError
      })
    }



 }


 createNewUserGroup=(value:any)=> {
    this.setState({
      updateUserGroupLoading:true
    })
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCreateUserGroupCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createUserGroupApiUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(value)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
 }
}
